import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    conversions: {
        file_link: "",
        file_name: ""
    }
}

const Conversions = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.CONVERSIONS_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.CONVERSIONS:

            newState.conversions = payload.data;

            if (payload.msg === "File converted successfully") {
                toast.success(payload.msg, {
                    position: 'top-center',
                    autoClose: 5000
                });
            } else {
                toast.error(payload.msg, {
                    position: 'top-center',
                    autoClose: 5000
                });
            }

            return newState;

        default:
            return newState;
    }
}

export default Conversions