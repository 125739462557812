/* eslint-disable array-callback-return */
import React from 'react'
// import types from '../../types';
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { getTrans as t } from '../../translations';

const AddAttachmentModal = (props) => {

    // console.log("c_state", props.c_state);

    return (
        <>
            <div className="modal fade" id="attachments_modal" style={{ overflow: "auto" }} role="dialog" aria-labelledby="selectAttachmentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="selectAttachmentModalLabel">{t('Select Attachment', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 col-sm-12">
                                <div className="card card-primary card-outline card-outline-tabs">
                                    <div className="card-header p-0 border-bottom-0">
                                        <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">{t('My Computer', props.auth.language)}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">{t('My Documents', props.auth.language)}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-four-settings-tab" data-toggle="pill" href="#custom-tabs-four-settings" role="tab" aria-controls="custom-tabs-four-settings" aria-selected="false">{t('File Manager', props.auth.language)}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill" href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">{t('Templates', props.auth.language)}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-four-tabContent">
                                            <div className="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                                <div className="form-group">
                                                    <div className="btn btn-default btn-file fileinput-button btn-block">
                                                        <i className="fas fa-paperclip"></i> {t('Choose Files', props.auth.language)}
                                                        <input type="file" name="my_computer[]" multiple onChange={(event) => props.handleChange(event.target.files, "my_computer")} />
                                                    </div>
                                                </div>
                                                {
                                                    props.c_state.my_computer_selected ?

                                                        <div className="table table-striped files" id="previews">
                                                            <strong className="lead" style={{ fontWeight: 'bold' }}>{t('Selected Documents', props.auth.language)}: </strong>
                                                            {
                                                                props.c_state.my_computer_selected.map((data, index) => {
                                                                    return (
                                                                        <div key={index} className="row mt-2">
                                                                            <div className="col d-flex align-items-center">
                                                                                <p className="mb-0">
                                                                                    <span className="lead" data-dz-name> {data.file_name} </span>
                                                                                    (<span data-dz-size>{(data.file_size / (1024 * 1000)).toFixed(2)} MB</span>)
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                {/* <h3 className="card-title">Fixed Header Table</h3> */}

                                                                <div className="card-tools">
                                                                    <div className="input-group input-group-sm" style={{ width: 150 }}>
                                                                        <input type="text" onChange={(event) => props.search_attachment(event.target.value, 'documents')} name="table_search" className="form-control float-right" placeholder={t('Search', props.auth.language)} />

                                                                        <div className="input-group-append">
                                                                            <button type="submit" className="btn btn-default">
                                                                                <i className="fas fa-search"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body table-responsive p-0" style={{ height: 300 }}>
                                                                <table className="table table-head-fixed table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>{t('File Name', props.auth.language)}</th>
                                                                            <th>{t('Date Created', props.auth.language)}</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            props.c_state.document_list.map((data, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td><input type="checkbox" checked={props.c_state.selected_items[data.did] ? true : false} onChange={() => props.select_my_document(data)} /></td>
                                                                                        <td>&nbsp;<i style={{ color: 'blue' }} className="fa fa-file-alt"> &nbsp;</i><Link to="#" style={{ color: 'black' }} onClick={() => props.select_my_document(data)}>{data.name}{data.ext}</Link></td>
                                                                                        <td><Link to="#" style={{ color: 'black' }} onClick={() => props.select_my_document(data)}>{data.date_created}</Link></td>
                                                                                        <td className="text-right py-0 align-middle">
                                                                                            <div className="btn-group btn-group-sm">
                                                                                                <button className="btn btn-info" title={t('View Document', props.auth.language)} data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'document')}><i className="fas fa-eye"></i></button>&nbsp;
                                                                                                {/* <button className="btn btn-primary" title="Select Document" onClick={() => console.log("here")}><i className="fas fa-mouse"></i></button> */}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    props.c_state.my_documents_selected ?
                                                        <div className="table table-striped files" id="previews">
                                                            <strong className="lead" style={{ fontWeight: 'bold' }}>{t('Selected Documents', props.auth.language)}: </strong>
                                                            {
                                                                props.c_state.my_documents_selected.map((data, index) => {
                                                                    return (
                                                                        <span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-four-messages" role="tabpanel" aria-labelledby="custom-tabs-four-messages-tab">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                {/* <h3 className="card-title">Fixed Header Table</h3> */}

                                                                <div className="card-tools">
                                                                    <div className="input-group input-group-sm" style={{ width: 150 }}>
                                                                        <input type="text" onChange={(event) => props.search_attachment(event.target.value, 'templates')} name="table_search" className="form-control float-right" placeholder={t('Search', props.auth.language)} />

                                                                        <div className="input-group-append">
                                                                            <button type="submit" className="btn btn-default">
                                                                                <i className="fas fa-search"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body table-responsive p-0" style={{ height: 300 }}>
                                                                <table className="table table-head-fixed table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{t('File Name', props.auth.language)}</th>
                                                                            <th>{t('Date Created', props.auth.language)}</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            props.c_state.template_list.map((data, index) => {
                                                                                // console.log(data);
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td><i style={{ color: 'blue' }} className="fa fa-file-alt"> &nbsp;</i>{data.templates.template_name}{data.templates.ext}</td>
                                                                                        <td>{data.templates.date_created}</td>
                                                                                        <td className="text-right py-0 align-middle">
                                                                                            <div className="btn-group btn-group-sm">
                                                                                                <button className="btn btn-info" title={t('View Template', props.auth.language)} data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data.templates, 'template')}><i className="fas fa-eye"></i></button>&nbsp;
                                                                                                <button className="btn btn-primary" title={t('Use Template', props.auth.language)} data-toggle="modal" data-target="#use_template_modal" onClick={() => props.select_template(data)}><i className="fas fa-mouse"></i></button>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-four-settings" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                {/* <h3 className="card-title">Fixed Header Table</h3> */}
                                                                <div className="col-sm-12">
                                                                    <ol className="breadcrumb float-sm-left">
                                                                        {
                                                                            props.c_state.breadcrumbs.map((data, index) => {
                                                                                return (
                                                                                    <li key={index} className="breadcrumb-item"><Link to="#" onClick={() => props.click_breadcrumbs(index)}>{data.filename === '/' ? t('Home', props.auth.language) : data.filename}</Link></li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ol>
                                                                </div>
                                                                <div className="card-tools">
                                                                    <div className="input-group input-group-sm" style={{ width: 150 }}>
                                                                        <input type="text" onChange={(event) => props.search_attachment(event.target.value, 'files')} name="table_search" className="form-control float-right" placeholder={t('Search', props.auth.language)} />

                                                                        <div className="input-group-append">
                                                                            <button type="submit" className="btn btn-default">
                                                                                <i className="fas fa-search"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body table-responsive p-0" style={{ height: 300 }}>
                                                                <table className="table table-head-fixed table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>{t('File Name', props.auth.language)}</th>
                                                                            <th>{t('Date Created', props.auth.language)}</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            props.c_state.file_list.map((data, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{data.type === "file" ? <input type="checkbox" checked={props.c_state.selected_files[data.fid] ? true : false} onChange={() => props.select_files(data)} /> : null}</td>
                                                                                        <td>&nbsp;<Link to="#" style={{ color: 'black' }} onClick={() => props.enter_folder(data)} onDoubleClick={() => props.enter_folder(data)}>{data.type === "file" ? <i style={{ color: 'blue' }} className="fa fa-file-alt"> &nbsp;</i> : data.type === "folder" ? <i style={{ color: 'red' }} className="fa fa-folder"> &nbsp;</i> : null}{data.filename.substring(0, 20)}{data.filetype}</Link></td>
                                                                                        <td><Link to="#" style={{ color: 'black' }} onClick={() => props.enter_folder(data)} onDoubleClick={() => props.enter_folder(data)}>{data.created_at}</Link></td>
                                                                                        <td className="text-right py-0 align-middle">
                                                                                            <div className="btn-group btn-group-sm">
                                                                                                {data.type === "file" ? <><button className="btn btn-info" title={t('View File', props.auth.language)} data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'file')}><i className="fas fa-eye"></i></button>&nbsp;</> : null}
                                                                                                {/* <button className="btn btn-primary" title="Select Document" onClick={() => console.log("here")}><i className="fas fa-mouse"></i></button> */}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    props.c_state.files_selected ?
                                                        <div className="table table-striped files" id="previews">
                                                            <strong className="lead" style={{ fontWeight: 'bold' }}>{t('Selected Documents', props.auth.language)}: </strong>
                                                            {
                                                                props.c_state.files_selected.map((data, index) => {
                                                                    return (
                                                                        <span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                        <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_attachment_modal">{t('Close', props.auth.language)}</button>
                                        <button type="button" className="btn btn-primary" onClick={props.save_attachments}>{t('Save', props.auth.language)}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="use_template_modal" tabIndex="-1" role="dialog" aria-labelledby="useTemplateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="useTemplateModalLabel">{t('Use Template', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="template_name">{t('Document Name', props.auth.language)} *</label>
                                    <input type="text" value={props.c_state.selected_template_details.document_name ? props.c_state.selected_template_details.document_name : ''} onChange={(event) => props.handleChange({ "field": "document_name", "value": event.target.value }, "template")} className="form-control" name="document_name" placeholder={t('Document Name', props.auth.language)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="category">{t('Tags', props.auth.language)} </label>
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        value={props.c_state.selected_template_details.tags ? typeof props.c_state.selected_template_details.tags !== "string" ? props.c_state.selected_template_details.tags : JSON.parse(props.c_state.selected_template_details.tags.replace(/'/g, '"')) : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "tags", "value": value }, "template")}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                            </div>
                            {
                                props.c_state.selected_template.placeholders ?
                                    props.c_state.selected_template.placeholders.length ?
                                        <p style={{ textDecoration: 'underline' }}>{t('Please answer the ff questions to generate your document', props.auth.language)}</p>
                                        : <p>{t('No placeholders have been defined for the template you selected', props.auth.language)}</p>
                                    : null
                            }
                            {
                                props.c_state.selected_template.placeholders ?
                                    // eslint-disable-next-line array-callback-return
                                    props.c_state.selected_template.placeholders.map((data) => {
                                        return (
                                            <div className="row" key={data.pid}>
                                                <div className="form-group col-12">
                                                    <label htmlFor="template_name">{data.question}</label>
                                                    <input type="text" value={props.c_state.answers ? props.c_state.answers[data.placeholder] : ''} onChange={(event) => props.handleChange({ "field": data.placeholder, "value": event.target.value }, 'answers')} className="form-control" name={data.placeholder} />
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null
                            }
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_use_template_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.auth.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.auth.isLoading === "true" ? true : false} onClick={props.use_template}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAttachmentModal;