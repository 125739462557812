import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { getTrans as t } from '../../translations';

const ViewCorrespondence = (props) => {

    // console.log("correspondence", props.c_state.correspondence);

    return (
        <div className="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{t('Correspondence', props.auth.language)}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                <li className="breadcrumb-item active">{t('Correspondence', props.auth.language)}</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section className="content">
                <div className="row">
                    <div className="col-md-3">
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("compose_correspondence") ?
                                    <Link to="/compose_correspondence" className="btn btn-primary btn-block mb-3" onClick={props.compose}>{t('Compose', props.auth.language)}</Link>
                                    : null
                                : null
                        }
                    </div>
                    <div className="col-md-9">
                        <div className="row col-md-12">
                            <div className="card col-md-3">
                                <div className="card-header p-0">
                                    <Link to="#" onClick={() => props.load_correspondence('Inbox')} className="nav-link" style={{ color: props.c_state.correspondence_category === 'Inbox' ? 'red' : '#6c757d', fontWeight: props.c_state.correspondence_category === 'Inbox' ? 'bold' : 'normal' }}>
                                        <i className="fas fa-inbox"></i> {t('Incoming', props.auth.language)}
                                        {props.c_state.correspondence_category === 'Inbox' ?
                                            <span className="badge bg-primary float-right">{props.c_state.correspondence.limit}</span>
                                            : null}
                                    </Link>
                                </div>
                            </div>
                            <div className="card col-md-3">
                                <div className="card-header p-0">
                                    <Link to="#" onClick={() => props.load_correspondence('Sent')} className="nav-link" style={{ color: props.c_state.correspondence_category === 'Sent' ? 'red' : '#6c757d', fontWeight: props.c_state.correspondence_category === 'Sent' ? 'bold' : 'normal' }}>
                                        <i className="far fa-envelope"></i> {t('Outgoing', props.auth.language)}
                                        {props.c_state.correspondence_category === 'Sent' ?
                                            <span className="badge bg-info float-right">{props.c_state.correspondence.limit ? props.c_state.correspondence.limit : ''}</span>
                                            : null}
                                    </Link>
                                </div>
                            </div>
                            <div className="card col-md-3">
                                <div className="card-header p-0">
                                    <Link to="#" onClick={() => props.load_correspondence('Archived')} className="nav-link" style={{ color: props.c_state.correspondence_category === 'Archived' ? 'red' : '#6c757d', fontWeight: props.c_state.correspondence_category === 'Archived' ? 'bold' : 'normal' }}>
                                        <i className="fas fa-archive"></i> {t('Worked On', props.auth.language)}
                                        {props.c_state.correspondence_category === 'Archived' ?
                                            <span className="badge bg-danger float-right">{props.c_state.correspondence.limit ? props.c_state.correspondence.limit : ''}</span>
                                            : null}
                                    </Link>
                                </div>
                            </div>
                            <div className="card col-md-3">
                                <div className="card-header p-0">
                                    <Link to="#" onClick={() => props.load_correspondence('Drafts')} className="nav-link" style={{ color: props.c_state.correspondence_category === 'Drafts' ? 'red' : '#6c757d', fontWeight: props.c_state.correspondence_category === 'Drafts' ? 'bold' : 'normal' }}>
                                        <i className="far fa-file-alt"></i> {t('Drafts', props.auth.language)}
                                        {props.c_state.correspondence_category === 'Drafts' ?
                                            <span className="badge bg-success float-right">{props.c_state.correspondence.limit ? props.c_state.correspondence.limit : ''}</span>
                                            : null}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary card-outline" id="accordion">
                            <div className="card-header">
                                <h3 className="card-title">{props.c_state.correspondence_category ? t(props.c_state.correspondence_category, props.auth.language) : t("Inbox", props.auth.language)}</h3>
                                <div align="right">
                                    <a className="btn btn-sm btn-outline-info" data-toggle="collapse" href="#collapseTwo"><i className="fa fa-filter"></i> {t('Advanced Filters', props.auth.language)}</a>&nbsp;
                                    <button type="button" className="btn btn-sm btn-outline-primary" onClick={props.reload_correspondence}><i className="fa fa-sync"></i> {t('Reload', props.auth.language)}</button>&nbsp;
                                    {/* <button type="button" className="btn btn-sm btn-outline-success" data-toggle="modal" data-target="#search_tag_modal"><i className="fa fa-filter"></i> {t('Filter By Tags', props.auth.language)}</button> */}
                                    <button type="button" className="btn btn-sm btn-outline-default">
                                        <div className="input-group input-group-sm" style={{ width: 200 }}>
                                            <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_correspondence(event.target.value)} />
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                <div className="card-header">
                                    <div className="row col-md-8">
                                        <div className="col-md-3">
                                            <label htmlFor="search_in">{t('Search In', props.auth.language)}: </label>
                                        </div>
                                        <div className="form-group col-9">
                                            <select onChange={(event) => props.handleChange({ "field": "search_in", "value": event.target.value })} value={props.c_state.correspondence_details.search_in ? props.c_state.correspondence_details.search_in : 'All Correspondences'} className="form-control">
                                                <option value="All Correspondences">All Correspondences</option>
                                                <option value="Inbox">Incoming</option>
                                                <option value="Sent">Outgoing</option>
                                                <option value="Archived">Worked On</option>
                                                <option value="Drafts">Drafts</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row col-md-8">
                                        <div className="col-md-3">
                                            <label htmlFor="status">{t('Status', props.auth.language)}: </label>
                                        </div>
                                        <div className="form-group col-9">
                                            <select onChange={(event) => props.handleChange({ "field": "status", "value": event.target.value })} value={props.c_state.correspondence_details.status ? props.c_state.correspondence_details.status : 'Read & Unread'} className="form-control">
                                                <option value="Read & Unread">Read & Unread</option>
                                                <option value="Read">Read</option>
                                                <option value="Unread">Unread</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row col-md-8">
                                        <div className="col-md-3">
                                            <label htmlFor="c_from">{t('From', props.auth.language)}: </label>
                                        </div>
                                        <div className="form-group col-9">
                                            <Select
                                                options={props.c_state.contacts_dropdown}
                                                name="c_from"
                                                value={props.c_state.correspondence_details.c_from ? props.c_state.correspondence_details.c_from : ''}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange({ "field": "c_from", "value": value })}
                                                // isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="From"
                                            />
                                        </div>
                                    </div>
                                    <div className="row col-md-8">
                                        <div className="col-md-3">
                                            <label htmlFor="c_to">{t('To', props.auth.language)}: </label>
                                        </div>
                                        <div className="form-group col-9">
                                            <Select
                                                options={props.c_state.contacts_dropdown}
                                                name="c_to"
                                                value={props.c_state.correspondence_details.c_to ? props.c_state.correspondence_details.c_to : ''}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange({ "field": "c_to", "value": value })}
                                                // isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="To"
                                            />
                                        </div>
                                    </div>
                                    <div className="row col-md-8">
                                        <div className="col-md-3">
                                            <label htmlFor="subject">{t('Subject', props.auth.language)} </label>
                                        </div>
                                        <div className="form-group col-9">
                                            <input type="text" onChange={(event) => props.handleChange({ "field": "subject", "value": event.target.value })} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row col-md-8">
                                        <div className="col-md-3">
                                            <label htmlFor="date">{t('Date', props.auth.language)} </label>
                                        </div>
                                        <div className="form-group col-4">
                                            <input type="date" onChange={(event) => props.handleChange({ "field": "date_from", "value": event.target.value })} className="form-control" />
                                        </div>
                                        <div className="form-group col-1">
                                            <p className="mt-1">--&gt;</p>
                                        </div>
                                        <div className="form-group col-4">
                                            <input type="date" onChange={(event) => props.handleChange({ "field": "date_to", "value": event.target.value })} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row col-md-8">
                                        <div className="col-md-3">
                                            <label htmlFor="tags">{t('Tags', props.auth.language)}: </label>
                                        </div>
                                        <div className="form-group col-9">
                                            <Select
                                                options={props.c_state.tags_dropdown}
                                                name="search_tags"
                                                id="custom_select"
                                                value={props.c_state.correspondence_details.search_tags ? props.c_state.correspondence_details.search_tags : []}
                                                onChange={(value) => props.handleChange({ "field": "search_tags", "value": value })}
                                                isMulti style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder={t('Select', props.auth.language)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row col-md-8">
                                        <div className="modal-footer justify-content-between">
                                            <button type="button" className="btn btn-md btn-outline-primary" onClick={props.server_search_correspondence}><i className="fa fa-search"></i> {t('Search', props.auth.language)}</button>
                                            <button type="button" className="btn btn-md btn-outline-danger" onClick={props.clear_search}><i className="fa fa-times"></i> {t('Clear', props.auth.language)}</button>
                                            <button type="button" className="btn btn-md btn-default" data-toggle="collapse" href="#collapseTwo"><i className="fa fa-hide"></i> {t('Hide', props.auth.language)}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-body p-0">
                            <div className="mailbox-controls">
                                <div className="float-right">
                                    {
                                        props.c_state.correspondence_category && props.c_state.correspondence.no_of_records ?
                                            props.c_state.correspondence_category === "Inbox" ?
                                                `${props.c_state.inbox_start}-${props.c_state.inbox_finish}/${props.c_state.correspondence.no_of_records}`
                                                : props.c_state.correspondence_category === "Sent" ?
                                                    `${props.c_state.sent_start}-${props.c_state.sent_finish}/${props.c_state.correspondence.no_of_records}`
                                                    : props.c_state.correspondence_category === "Drafts" ?
                                                        `${props.c_state.draft_start}-${props.c_state.draft_finish}/${props.c_state.correspondence.no_of_records}`
                                                        : props.c_state.correspondence_category === "Archived" ?
                                                            `${props.c_state.archived_start}-${props.c_state.archived_finish}/${props.c_state.correspondence.no_of_records}`
                                                            : null
                                            : null
                                    }
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm" onClick={props.go_back}>
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button type="button" className="btn btn-default btn-sm" onClick={props.go_forward}>
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive mailbox-messages">
                                {
                                    props.isLoading === "true" ?
                                        <div align="center">
                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your correspondences. Please wait...', props.auth.language)}</p></span>
                                        </div>
                                        :
                                        props.c_state.correspondence.correspondence ?
                                            <table className="table table-hover table-striped" style={{ width: '100%' }}>
                                                <tbody>
                                                    {
                                                        props.c_state.correspondence.correspondence.map((data) => {
                                                            return (
                                                                <tr key={data.trail_id}>
                                                                    {/* <td>
                                                                        <div className="icheck-primary">
                                                                            <input type="checkbox" value="" id="check4" />
                                                                            <label htmlFor="check4"></label>
                                                                        </div>
                                                                    </td> */}
                                                                    {/* <td className="mailbox-star"><Link to="#"><i className="fas fa-star text-warning"></i></Link></td> */}
                                                                    <td className="mailbox-star"><Link to="#" onClick={() => props.single_correspondence(data)}>#{data.correspondence[0].correspondence_id}#</Link></td>
                                                                    <td className="mailbox-name"><Link to="#" onClick={() => props.single_correspondence(data)}>{props.c_state.correspondence_category === "Sent" || props.c_state.correspondence_category === "Drafts" ? `${t('To', props.auth.language)}: ${props.contact_details(data.c_to)} ${data.number_of_recipients > 1 ? '(' + data.number_of_recipients + ')' : ''}` : props.contact_details(data.c_from)}</Link></td>
                                                                    <td className="mailbox-subject"><Link to="#" style={{ color: 'black' }} onClick={() => props.single_correspondence(data)}>{data.seen === "No" && props.c_state.correspondence_category !== "Drafts" ? <b>{data.correspondence[0].subject}</b> : <>{data.correspondence[0].subject}</>}</Link></td>
                                                                    <td className="mailbox-attachment">
                                                                        {data.correspondence[0].confidential === "true" ?
                                                                            <span className="badge float-right bg-info" style={{ marginLeft: 10 }}> {t('Confidential', props.auth.language)}</span>
                                                                            : ''}
                                                                        <span className={`badge float-right ${data.correspondence[0].priority === "High Priority" ? 'bg-danger' : data.correspondence[0].priority === "Medium Priority" ? 'bg-warning' : 'bg-primary'}`}>{t(data.correspondence[0].priority, props.auth.language)}</span>
                                                                    </td>
                                                                    <td className="mailbox-date">{data.date_created}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <div align="center"><br /><br /><p>{t('Sorry! Your correspondence failed to load due to poor internet connection', props.auth.language)}.<br />{t('Click the reload icon to reload', props.auth.language)}.</p></div>
                                }
                            </div>
                        </div>
                        {/* <div className="card-footer p-0">
                            <div className="mailbox-controls">
                                <button type="button" className="btn btn-default btn-sm checkbox-toggle">
                                    <i className="far fa-square"></i>
                                </button>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-reply"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-share"></i>
                                    </button>
                                </div>
                                <button type="button" className="btn btn-default btn-sm" title="Reload your correspondence" onClick={props.reload_correspondence}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                                <div className="float-right">
                                    {props.c_state.start}-{props.c_state.finish}/{props.c_state.correspondence.limit}
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* Modals */}
                <div className="modal fade" id="search_tag_modal">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-12">
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        // value={props.c_state.task_details.tags ? props.c_state.task_details.tags : []}
                                        id="custom_select"
                                        value={typeof props.c_state.correspondence_details.search_params === "string" ? JSON.parse(props.c_state.correspondence_details.search_params.replace(/'/g, '"')) : props.c_state.correspondence_details.search_params}
                                        onChange={(value) => props.handleChange({ "field": "search_params", "value": value })}
                                        isMulti style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={t('Select', props.auth.language)} />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_params_modal">{t('Close', props.auth.language)}</button>
                                <button type="button" className="btn btn-primary" onClick={props.search_correspondence_by_tags}>{t('Search', props.auth.language)}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default ViewCorrespondence
