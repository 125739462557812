/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import EditQuestionnaire from './EditQuestionnaire';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { serverGetTags } from '../../actions/TagsAction';
import { serverEditQuestionnaire } from '../../actions/QuestionnairesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class EditQuestionnaireContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags_dropdown: [],
            questionnaire_details: {},
            questions: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.editQuestion = this.editQuestion.bind(this);
        this.finish = this.finish.bind(this);
        this.deleteQuestion = this.deleteQuestion.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.moveDown = this.moveDown.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (!this.props.questionnaires.single_questionnaire.title) {
            this.props.history.push("/questionnaires");
        }

        this.setState({ questionnaire_details: this.props.questionnaires.single_questionnaire, questions: this.props.questionnaires.single_questionnaire.questions })
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            questionnaire_details: {
                ...prevState.questionnaire_details,
                [data.field]: data.value
            }
        }));
    }

    addQuestion = () => {

        let questionnaire_details = { ...this.state.questionnaire_details };

        // check required fields
        if (!questionnaire_details.question || !questionnaire_details.field_type || !questionnaire_details.required) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if ((questionnaire_details.field_type === "Dropdown Select" || questionnaire_details.field_type === "Option Buttons") && !questionnaire_details.field_options) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let field_options = '';
        if (questionnaire_details.field_options) {
            field_options = questionnaire_details.field_options;
        }

        let questions = [...this.state.questions, {
            question: questionnaire_details.question,
            field_type: questionnaire_details.field_type,
            field_options: field_options,
            required: questionnaire_details.required
        }];

        $("#dismiss_add_question_modal").click();

        delete questionnaire_details['question'];
        delete questionnaire_details['field_type'];
        delete questionnaire_details['field_options'];
        delete questionnaire_details['required'];

        this.setState({ questionnaire_details: questionnaire_details, questions: questions });

    }

    setModalContent = (data) => {
        let questionnaire_details = { ...this.state.questionnaire_details, ...data };
        this.setState({ questionnaire_details: questionnaire_details });
    }

    clearModalContents = () => {
        let questionnaire_details = { ...this.state.questionnaire_details };

        delete questionnaire_details['question'];
        delete questionnaire_details['field_type'];
        delete questionnaire_details['field_options'];
        delete questionnaire_details['required'];

        this.setState({ questionnaire_details: questionnaire_details });
    }

    editQuestion = () => {
        let questionnaire_details = { ...this.state.questionnaire_details };

        // check required fields
        if (!questionnaire_details.question || !questionnaire_details.field_type || !questionnaire_details.required) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if ((questionnaire_details.field_type === "Dropdown Select" || questionnaire_details.field_type === "Option Buttons") && !questionnaire_details.field_options) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let field_options = '';
        if (questionnaire_details.field_options) {
            field_options = questionnaire_details.field_options;
        }

        let questions = this.state.questions.map((data, index) => { // loop through state.todo
            if (questionnaire_details.index + "" === index + "") { // check what data to update using key
                data = {
                    ...data,
                    question: questionnaire_details.question,
                    field_type: questionnaire_details.field_type,
                    field_options: field_options,
                    required: questionnaire_details.required,
                    index: questionnaire_details.index
                }
            }
            return data
        });

        $("#dismiss_edit_question_modal").click();

        this.setState({ questions: questions });

    }

    deleteQuestion = (i) => {

        let questions = this.state.questions.filter((data, index) => { // loop through state.todo
            if (i + "" !== index + "") { // check what data to update using key
                return data
            }
        });

        this.setState({ questions: questions });
    }

    finish = () => {
        let data = {
            questionnaire: this.state.questionnaire_details,
            questions: this.state.questions
        }
        this.props.serverEditQuestionnaire(data, this.props.auth.user.token);
    }

    swapIncrement = (arr, order) => {

        let result = [...arr];

        let order_value = arr[order];

        let order_plus_one_value = arr[order + 1];
        if (!order_plus_one_value) {
            return result;
        }

        order_value.ordering = parseInt(order_value.ordering) + 1;
        order_plus_one_value.ordering = parseInt(order_plus_one_value.ordering) - 1;

        // swap
        result[order] = order_plus_one_value;

        result[order + 1] = order_value;

        // result.sort((a, b) => (a.ordering > b.ordering) ? 1 : -1);

        return result;
    }

    swapDecrement = (arr, order) => {

        let result = [...arr];

        let order_value = arr[order];

        let order_plus_one_value = arr[order - 1];
        if (!order_plus_one_value) {
            return result;
        }

        order_value.ordering = parseInt(order_value.ordering) - 1;
        order_plus_one_value.ordering = parseInt(order_plus_one_value.ordering) + 1;

        // console.log("order_plus_one_value", order_plus_one_value);
        // console.log("order_value", order_value);

        // swap
        result[order] = order_plus_one_value;

        result[order - 1] = order_value;

        // result.sort((a, b) => (a.ordering > b.ordering) ? 1 : -1);

        return result;
    }

    moveUp = (data) => {
        let res = this.swapDecrement(this.state.questions, parseInt(data.index) - 1);

        this.setState({questions: res});
    }

    moveDown = (data) => {

        let res = this.swapIncrement(this.state.questions, parseInt(data.index) - 1);

        this.setState({questions: res});
    }


    render() {
        return (
            <>
                <Main>
                    <EditQuestionnaire
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        questionnaires={this.props.questionnaires}
                        handleChange={this.handleChange}
                        add_question={this.addQuestion}
                        set_modal_content={this.setModalContent}
                        clear_modal_contents={this.clearModalContents}
                        edit_question={this.editQuestion}
                        delete_question={this.deleteQuestion}
                        finish={this.finish}
                        move_up={this.moveUp}
                        move_down={this.moveDown}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        questionnaires: { ...state.questionnaires },
    }
}

export default connect(mapStateToProps,
    {
        serverGetTags,
        serverEditQuestionnaire
    }
)(EditQuestionnaireContainer)
