/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom'
import types from '../../types';
// import TextField from '@material-ui/core/TextField';
import Select from 'react-select'
import { getTrans as t } from '../../translations';

const EditDocument = (props) => {

    // console.log("page", props.c_state.single_document.tags);

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Edit Document', props.auth.language)} {props.c_state.single_document.name ? `(${props.c_state.single_document.name})` : ''}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item"><Link to="/documents">{t('View Documents', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Edit Document', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{t('Update The Contents Of Your Document', props.auth.language)}</h3>
                            <div align="right">
                                {props.edit_docs_config.document ? <><a className="btn btn-sm btn-outline-info" href={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.edit_docs_config)}`)} target="_blank" rel="noreferrer"><i className="fa fa-file"></i> {t('Open Document In New Tab', props.auth.language)}</a>&nbsp;</> : null}
                                <button type="button" className="btn btn-sm btn-outline-danger" data-toggle="modal" data-target="#rename_doc_modal"><i className="fa fa-edit"></i> {t('Rename Document', props.auth.language)}</button>
                            </div>
                        </div>
                        <div className="card card-outline">
                            {props.edit_docs_config.document ?
                                <div className="row">
                                    <div className="col-12">

                                        <div className="embed-responsive embed-responsive-16by9" style={{ height: 1500 }}>
                                            <iframe
                                                className="embed-responsive-item"
                                                title="doc_viewer"
                                                src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.edit_docs_config)}`)}
                                                allowFullScreen>
                                            </iframe>
                                        </div>

                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>

                    {/* modals will come here */}
                    <div className="modal fade" id="rename_doc_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Update', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="placeholder">{t('Document Title', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.single_document.name ? props.c_state.single_document.name : ''} onChange={(event) => props.handleChange({ "field": "name", "value": event.target.value }, 'document')} className="form-control" name="name" placeholder={t('Document Title', props.auth.language)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Tags', props.auth.language)} </label>
                                            <Select
                                                options={props.c_state.tags_dropdown}
                                                name="tags"
                                                value={props.c_state.single_document.tags ? typeof props.c_state.single_document.tags !== "string" ? props.c_state.single_document.tags : JSON.parse(props.c_state.single_document.tags.replace(/'/g, '"')) : ''}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'document')}
                                                isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder={t('Select', props.auth.language)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_rename_document_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_document}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default EditDocument
