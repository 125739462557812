/* eslint-disable array-callback-return */
import React from 'react'
import types from '../../types';
import { Link } from 'react-router-dom'
import { getTrans as t } from '../../translations';

const ViewAttachmentModal = (props) => {

    // console.log("c_state", props.c_state.view_attachment_data);

    return (
        <div className="modal fade" id="view_attachment_modal" tabIndex="-1" role="dialog" aria-labelledby="viewAttachmentModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="viewAttachmentModalLabel">{t('View Document', props.auth.language)}</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_attachment_info}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            props.c_state.view_attachment_data ?
                                props.c_state.view_attachment_data.ext === ".docx" || props.c_state.view_attachment_data.ext === ".pptx" || props.c_state.view_attachment_data.ext === ".xlsx" || props.c_state.view_attachment_data.ext === ".csv" ?
                                    props.c_state.document_config ?
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe
                                                className="embed-responsive-item"
                                                title="doc_viewer"
                                                src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.c_state.document_config)}`)}
                                                allowFullScreen>
                                            </iframe>
                                        </div>
                                        : null

                                    : props.file_type(props.c_state.view_attachment_data.ext) === "audio" ?
                                        <audio controls preload='auto' autoPlay width='100%' height='100%'>
                                            <source src={`${types.SERVER_URL}static/${props.c_state.view_attachment_data.folder}/${props.c_state.view_attachment_data.id}${props.c_state.view_attachment_data.ext}`} type='audio/ogg; audio/mpeg' />
                                        </audio>

                                        : props.file_type(props.c_state.view_attachment_data.ext) === "image" ?
                                            <img src={`${types.SERVER_URL}static/${props.c_state.view_attachment_data.folder}/${props.c_state.view_attachment_data.id}${props.c_state.view_attachment_data.ext}`} alt="attachment-img" width={'100%'} />

                                            : props.file_type(props.c_state.view_attachment_data.ext) === "video" ?
                                                <video controls preload='auto' width='100%' autoPlay height='100%'>
                                                    <source src={`${types.SERVER_URL}static/${props.c_state.view_attachment_data.folder}/${props.c_state.view_attachment_data.id}${props.c_state.view_attachment_data.ext}`} type='video/mp4; video/webm; video/x-matroska' />
                                                </video>

                                                : props.c_state.view_attachment_data.ext === ".pdf" ?
                                                    <div className="embed-responsive embed-responsive-16by9">
                                                        <iframe
                                                            className="embed-responsive-item"
                                                            title="pdf_viewer"
                                                            src={`${types.SERVER_URL}static/${props.c_state.view_attachment_data.folder}/${props.c_state.view_attachment_data.id}${props.c_state.view_attachment_data.ext}`}
                                                            allowFullScreen>
                                                        </iframe>
                                                    </div>

                                                    : <p align="center"> {t('This file cannot be previewed', props.auth.language)}. <Link to="#" onClick={() => props.download(props.c_state.view_attachment_data.folder, props.c_state.view_attachment_data.id, props.c_state.view_attachment_data.ext, props.c_state.view_attachment_data.file_name)}>{t('Click here to download', props.auth.language)}.</Link></p>
                                : null
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default pull-right" data-dismiss="modal" id="dismiss_view_attachment_modal" onClick={props.clear_attachment_info}>{t('Close', props.auth.language)}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAttachmentModal;