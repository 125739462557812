const types = {
    LOADING: 'LOADING',
    CLEAR_DATA: 'CLEAR_DATA',
    CHECK_USER: 'CHECK_USER',

    INSTITUTION_NAME: 'HO TECHNICAL UNIVERSITY',
    INSTITUTION_REF_NO: 'HTU/BS/AF/',
    INSTITUTION_SHORT: 'HTU - CMS',
    SERVER_URL: 'https://intranet.htu.edu.gh/',
    CHAT_URL: 'https://chat.htu.edu.gh',
    FILES_URL: 'https://files.htu.edu.gh',
    SOCKET_URL: 'https://io.icubicle.net/',
    GOOGLE_CLIENT_ID: '872907740363-df3v1qbipkl7qiu54gmbg8p2vrq5oa8k.apps.googleusercontent.com',
    MICROSOFT_CLIENT_ID: 'bd0321aa-d3e7-4f23-8908-43a488b5679b',

    // INSTITUTION_NAME: 'ROPAT SYSTEMS LTD',
    // INSTITUTION_REF_NO: 'RPT/BS/AF/',
    // INSTITUTION_SHORT: 'ROPAT - CMS',
    // SERVER_URL: 'https://cms.ropatsystems.com/',
    // CHAT_URL: 'https://chat.ropatsystems.com',
    // FILES_URL: 'https://files.ropatsystems.com',
    // SOCKET_URL: 'https://io.icubicle.net/',
    // GOOGLE_CLIENT_ID: '872907740363-0clquvhtdso4bkp81g9u7uqbvak4ps3q.apps.googleusercontent.com',
    // MICROSOFT_CLIENT_ID: 'bd0321aa-d3e7-4f23-8908-43a488b5679b',

    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    UPDATE_SIGNATURE: 'UPDATE_SIGNATURE',

    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    DO_LOGOUT: 'DO_LOGOUT',
    REASSIGN_PRIVILEGES: 'REASSIGN_PRIVILEGES',
    LOGIN_VERIFY: 'LOGIN_VERIFY',

    DASHBOARD_LOADING: 'DASHBOARD_LOADING',
    DASHBOARD_FAIL: 'DASHBOARD_FAIL',
    DASHBOARD: 'DASHBOARD',
    DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',

    CONTACTS_LOADING: 'CONTACTS_LOADING',
    CONTACTS_FAIL: 'CONTACTS_FAIL',
    ALL_CONTACTS: 'ALL_CONTACTS',
    CONTACTS_SUCCESS: 'CONTACTS_SUCCESS',

    COMPANY_FAIL: 'COMPANY_FAIL',
    ALL_COMPANIES: 'ALL_COMPANIES',
    COMPANY_SUCCESS: 'COMPANY_SUCCESS',

    GROUP_FAIL: 'GROUP_FAIL',
    ALL_GROUPS: 'ALL_GROUPS',
    GROUP_SUCCESS: 'GROUP_SUCCESS',

    TAGS_LOADING: 'TAGS_LOADING',
    TAGS_FAIL: 'TAGS_FAIL',
    TAGS: 'TAGS',
    TAGS_SUCCESS: 'TAGS_SUCCESS',

    PROFILE_FAIL: 'PROFILE_FAIL',
    PROFILE_SUCCESS: 'PROFILE_SUCCESS',
    PROFILE_LOADING: 'PROFILE_LOADING',

    TASKS_LOADING: 'TASKS_LOADING',
    TASKS_FAIL: 'TASKS_FAIL',
    TASKS: 'TASKS',
    TASKS_SUCCESS: 'TASKS_SUCCESS',

    TEMPLATES_FAIL: 'TEMPLATES_FAIL',
    TEMPLATES: 'TEMPLATES',
    TEMPLATES_SUCCESS: 'TEMPLATES_SUCCESS',
    TEMPLATES_EDIT: 'TEMPLATES_EDIT',
    TEMPLATES_LOADING: 'TEMPLATES_LOADING',
    DOCUMENTS: 'DOCUMENTS',
    DOCUMENTS_EDIT: 'DOCUMENTS_EDIT',
    ALL_FILES: 'ALL_FILES',

    EMAIL_LOADING: 'EMAIL_LOADING',
    EMAIL_FAIL: 'EMAIL_FAIL',
    EMAIL_CONFIG: 'EMAIL_CONFIG',
    EMAIL_SUCCESS: 'EMAIL_SUCCESS',
    SINGLE_EMAIL: 'SINGLE_EMAIL',

    CORRESPONDENCE_LOADING: 'CORRESPONDENCE_LOADING',
    CORRESPONDENCE_ID: 'CORRESPONDENCE_ID',
    CORRESPONDENCE_FAIL: 'CORRESPONDENCE_FAIL',
    CORRESPONDENCE_SUCCESS: 'CORRESPONDENCE_SUCCESS',
    SINGLE_CORRESPONDENCE: 'SINGLE_CORRESPONDENCE',
    EMAIL_DETAILS: 'EMAIL_DETAILS',
    CHOSEN_CATEGORY: 'CHOSEN_CATEGORY',
    CORRESPONDENCE_FOLDER_DETAILS: 'CORRESPONDENCE_FOLDER_DETAILS',

    ATTACHMENTS_FAIL: 'ATTACHMENTS_FAIL',
    ATTACHMENTS_SUCCESS: 'ATTACHMENTS_SUCCESS',

    ROUTES_LOADING: 'ROUTES_LOADING',
    ROUTES_FAIL: 'ROUTES_FAIL',
    ALL_ROUTES: 'ALL_ROUTES',
    ROUTES_SUCCESS: 'ROUTES_SUCCESS',

    CONVERSIONS_LOADING: 'CONVERSIONS_LOADING',
    CONVERSIONS_FAIL: 'CONVERSIONS_FAIL',
    CONVERSIONS: 'CONVERSIONS',
    CONVERSIONS_SUCCESS: 'CONVERSIONS_SUCCESS',

    PRIVILEGES_LOADING: 'PRIVILEGES_LOADING',
    PRIVILEGES_FAIL: 'PRIVILEGES_FAIL',
    PRIVILEGES: 'PRIVILEGES',
    PRIVILEGES_SUCCESS: 'PRIVILEGES_SUCCESS',

    ROLES_LOADING: 'ROLES_LOADING',
    ROLES_FAIL: 'ROLES_FAIL',
    ROLES: 'ROLES',
    ROLES_SUCCESS: 'ROLES_SUCCESS',

    DEPARTMENTS_LOADING: 'DEPARTMENTS_LOADING',
    DEPARTMENTS_FAIL: 'DEPARTMENTS_FAIL',
    DEPARTMENTS: 'DEPARTMENTS',
    DEPARTMENTS_SUCCESS: 'DEPARTMENTS_SUCCESS',

    QUESTIONNAIRES_LOADING: 'QUESTIONNAIRES_LOADING',
    QUESTIONNAIRES_FAIL: 'QUESTIONNAIRES_FAIL',
    QUESTIONNAIRES: 'QUESTIONNAIRES',
    QUESTIONNAIRES_SUCCESS: 'QUESTIONNAIRES_SUCCESS',
}

export default types
