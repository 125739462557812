import React, { Component } from 'react'
import Task from './Task';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverLoad, serverGetTasks, serverGetAllTasks, serverDeleteTask, serverAddTask, serverEditTask, serverChangeTaskState, serverSendComment, serverSendTaskCC, serverconfirmTaskRating, serverGetCommentAttachments } from '../../actions/TasksAction';
import { serverSaveAttachments, serverClearSavedAttachments, serverGetAllFiles } from '../../actions/AttachmentsAction';
import { serverGetDocuments, serverGetTemplates, serverUseTemplate } from '../../actions/TemplatesAction';
import { serverGetTags } from '../../actions/TagsAction';
import { serverGetContacts, serverGetCompanies } from '../../actions/ContactsAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fileDownload from 'js-file-download';
import axios from 'axios'
import types from '../../types';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class TasksContainer extends Component {

    constructor(props) {

        super(props);
        this.state = {
            task_details: {},
            task_info: {},
            task_list: [],
            task_list_no: [],
            task_list_progress: [],
            task_list_yes: [],
            task_logs: [],
            tags_dropdown: [],
            contact_list: [],
            company_list: [],
            contact_details_list: [],
            reminders: [{ "r_date": "", "r_time": "" }],
            selected_attachments: [],
            task_comment: "",
            task_comments: [],
            task_cc: {},
            contact_groups: [],
            selected_filters: [],
            temp_filters: [],


            options: {
                Sender: {
                    position: "task",
                    value: "user",
                    type: "string",
                    contactNameSingle: true,
                },
                Recipient: {
                    position: "task",
                    value: "recipient",
                    type: "string",
                    contactNameSingle: true,
                },
                Copied: {
                    position: "task",
                    value: "cc",
                    type: "string",
                    contactNameBulk: true
                },
                Subject: {
                    position: "task",
                    value: "text",
                    type: "string"
                },
                Task: {
                    position: "task",
                    value: "note",
                    type: "string",
                    isDangerousHTML: true
                },
                Deadline: {
                    position: "task",
                    value: "deadline_date",
                    type: "string"
                },
                Priority: {
                    position: "task",
                    value: "priority",
                    type: "string",
                    condition: {
                        "1": "High Priority",
                        "2": "Medium Priority",
                        "3": "Low Priority"
                    }
                },
                Reminders: {
                    position: "reminders",
                    value: "reminders",
                    type: "array",
                    array_keys: {
                        "r_date": true,
                        "r_time": true,
                    }
                },
                Status: {
                    position: "task",
                    value: "state",
                    type: "string",
                    condition: {
                        no: "To do",
                        progress: "In Progress",
                        yes: "Done"
                    }
                },
                "Date Created": {
                    position: "task",
                    value: "date_created",
                    type: "string"
                },
                "Rating": {
                    position: "task",
                    value: "rating",
                    type: "string"
                },
                "Comments": {
                    position: "task",
                    value: "task_comments",
                    type: "array",
                    array_keys: {
                        "user": true,
                        "comment": true,
                    }
                },
            },

            // for generic attachments modal
            a_type: "tasks",
            document_config: {},
            my_computer: [],
            file_list: [],
            document_list: [],
            template_list: [],
            breadcrumbs: [],
            my_computer_selected: [],
            my_documents_selected: [],
            files_selected: [],
            selected_items: {},
            selected_files: {},
            selected_template: [],
            selected_template_details: [],
            saved_attachments: [],
            answers: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.removeReminder = this.removeReminder.bind(this);
        this.addReminder = this.addReminder.bind(this);
        this.setReminderDate = this.setReminderDate.bind(this);
        this.setReminderTime = this.setReminderTime.bind(this);
        this.addTask = this.addTask.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
        this.editTask = this.editTask.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.setModalContentAlt = this.setModalContentAlt.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.reload = this.reload.bind(this);
        this.changeTaskState = this.changeTaskState.bind(this)
        this.searchTaskByTags = this.searchTaskByTags.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.searchTaskByUsers = this.searchTaskByUsers.bind(this);
        this.searchTaskByCompany = this.searchTaskByCompany.bind(this);
        this.selectedAttachments = this.selectedAttachments.bind(this);
        this.taskLogs = this.taskLogs.bind(this);
        this.firstTaskLog = this.firstTaskLog.bind(this);
        this.setTaskComment = this.setTaskComment.bind(this);
        this.sendComment = this.sendComment.bind(this);
        this.getContactDetails = this.getContactDetails.bind(this);
        this.getContactName = this.getContactName.bind(this);
        this.getDayDifference = this.getDayDifference.bind(this);
        this.allTasks = this.allTasks.bind(this);
        this.setTaskCc = this.setTaskCc.bind(this);
        this.sendTaskCC = this.sendTaskCC.bind(this);
        this.sortTasks = this.sortTasks.bind(this);
        this.switchTab = this.switchTab.bind(this);
        this.switchTabAll = this.switchTabAll.bind(this);
        this.confirmTaskRating = this.confirmTaskRating.bind(this);
        this.selectFilters = this.selectFilters.bind(this);
        this.printTasks = this.printTasks.bind(this);
        this.filterCommentAttachment = this.filterCommentAttachment.bind(this);

        // attachments
        this.setAttachmentInfo = this.setAttachmentInfo.bind(this);
        this.useTemplate = this.useTemplate.bind(this);
        this.selectMyDocument = this.selectMyDocument.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.clearAttachments = this.clearAttachments.bind(this);
        this.saveAttachments = this.saveAttachments.bind(this);
        this.clearAttachmentInfo = this.clearAttachmentInfo.bind(this);
        this.fileType = this.fileType.bind(this);
        this.download = this.download.bind(this);
        this.searchAttachment = this.searchAttachment.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
        this.enterFolderAlt = this.enterFolderAlt.bind(this);
        this.clickBreadCrumbs = this.clickBreadCrumbs.bind(this);
    }

    componentDidMount() {

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.tasks.tasks.length) {
            this.props.serverGetTasks(this.props.auth.user.token);
        } else {
            let task_list = [...this.props.tasks.tasks];

            let task_list_no = task_list.filter(item => item.task.state === 'no');

            let task_list_progress = task_list.filter(item => item.task.state === 'progress');

            let task_list_yes = task_list.filter(item => item.task.state === 'yes');

            this.setState({ task_list: task_list, task_list_no: task_list_no, task_list_progress: task_list_progress, task_list_yes: task_list_yes });
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (!this.props.templates.documents.length) {
            this.props.serverGetDocuments(this.props.auth.user.token);
        } else {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (!this.props.templates.templates.length) {
            this.props.serverGetTemplates(this.props.auth.user.token);
        } else {
            this.setState({ template_list: [...this.props.templates.templates] });
        }

        if (!this.props.attachments.all_files.length) {
            this.props.serverGetAllFiles(this.props.auth.user.token);
        } else {
            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
            this.props.serverGetCompanies(this.props.auth.user.token);
        } else {
            // let arr = [{ "value": "Myself", "label": "Myself" }]
            let arr, arr2 = [];

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
                arr2.push(data);
            });

            this.setState({ contact_list: arr, contact_details_list: arr2, contact_groups: this.props.contacts.groups, company_list: this.props.contacts.companies });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.tasks !== this.props.tasks) {

            let task_list = [...this.props.tasks.tasks];

            let task_list_no = task_list.filter(item => item.task.state === 'no');

            let task_list_progress = task_list.filter(item => item.task.state === 'progress');

            let task_list_yes = task_list.filter(item => item.task.state === 'yes');

            this.setState({ task_list: task_list, task_list_no: task_list_no, task_list_progress: task_list_progress, task_list_yes: task_list_yes });
        }

        if (prevProps.contacts !== this.props.contacts) {
            // let arr = [{ "value": this.props.auth.user.contact_id, "label": "Myself" }]
            let arr = [];
            let arr2 = [];

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
                arr2.push(data);
            });


            this.setState({ contact_list: arr, contact_details_list: arr2, contact_groups: this.props.contacts.groups, company_list: this.props.contacts.companies });
        }

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.tasks.tasks !== this.props.tasks.tasks) {
            $("#dismiss_edit_task_modal").click();
            $("#dismiss_add_task_modal").click();

            this.clearModalContents();
            this.clearAttachments();
        }

        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                $("#btn-show-doc-modal").click();

                this.setState({ selected_template_details: [], selected_template: [] });
            }
        }

        // generic attachments
        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                $("#btn-show-doc-modal").click();

                this.setState({ selected_template_details: [], selected_template: [] });
            }
        }

        if (prevProps.attachments.all_files !== this.props.attachments.all_files) {

            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (prevProps.templates.documents !== this.props.templates.documents) {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (prevProps.templates.templates !== this.props.templates.templates) {
            this.setState({ template_list: [...this.props.templates.templates] });
        }

        if (prevProps.tasks.task_comments !== this.props.tasks.task_comments) {
            this.setState({ task_comment: "", task_comments: this.props.tasks.task_comments });
            this.clearAttachments();

            let ids = this.props.tasks.task_comments.map(obj => obj.comment_id);
            if (ids.length) {
                this.props.serverGetCommentAttachments({ ids: ids }, this.props.auth.user.token);
            }
        }

        if (prevProps.tasks.rand !== this.props.tasks.rand) {
            $("#dismiss_task_cc_modal_modal").click();
        }
    }

    clickBreadCrumbs = (index) => {
        let breadcrumbs = [...this.state.breadcrumbs];
        let selected_breadcrumbs = this.state.breadcrumbs[index];
        let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === selected_breadcrumbs.fid + '');
        breadcrumbs = breadcrumbs.slice(0, index + 1);
        return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
    }

    handleChange = (data, type) => {

        switch (type) {
            case 'task':
                this.setState(prevState => ({
                    task_details: {                   // object that we want to update
                        ...prevState.task_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            case 'task_comment':
                this.setState({ task_comment: data.value });
                break;
            // generic attachments modal
            case 'answers':
                this.setState(prevState => ({
                    answers: {                   // object that we want to update
                        ...prevState.answers,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'template':
                this.setState(prevState => ({
                    selected_template_details: {                   // object that we want to update
                        ...prevState.selected_template_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'my_computer':
                this.setState({ my_computer: data });

                let attachments = [];

                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    attachments.push({
                        "file_name": element.name,
                        "file_size": element.size
                    });
                }

                this.setState({ my_computer_selected: attachments });

                break;
            default:
                break;
        }

        if (data.field === 'attachments') {

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "attachments": []     // update the value of specific key
                }
            }));

            let attachments = [];

            for (let index = 0; index < data.value.length; index++) {
                const element = data.value[index];
                attachments.push({
                    "file_name": element.name,
                    "file_size": element.size
                });
            }

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "attachments": attachments     // update the value of specific key
                }
            }));
        }
    }

    setModalContent = (data) => {

        this.setState({
            task_details: data.task,
            reminders: data.reminders
        });

        return;
    }


    setModalContentAlt = (data) => {
        this.setState({ task_info: data });
    }

    clearModalContents = () => {
        this.setState({ task_details: {}, reminders: [{ "r_date": "", "r_time": "" }] });
        this.clearAttachments();
        return;
    }

    removeReminder = (marker) => {
        // eslint-disable-next-line array-callback-return
        let reminders = this.state.reminders.filter((data, index) => { // loop through state.todo
            if (index !== marker) { // check what data to update using key
                return data
            }
        });

        this.setState({ reminders: reminders });

        return;
    }

    addReminder = () => {

        let valid = true;

        // console.log(this.state.reminders);

        this.state.reminders.forEach(element => {
            if (element.r_date === "" || element.r_time === "") {

                valid = false;

                return;
            }
        });

        if (valid === true) {
            this.setState({ reminders: [...this.state.reminders, { "r_date": "", "r_time": "" }] });
        } else {
            toast.info(t("Please set reminder date and time before you add a new one", this.props.auth.language), {
                position: 'top-center'
            });
        }
    }

    setReminderDate = (date, marker) => {
        // eslint-disable-next-line array-callback-return
        let reminders = this.state.reminders.filter((data, index) => { // loop through state.todo
            if (index === marker) { // check what data to update using key
                data.r_date = date;
            }

            return data
        });

        this.setState({ reminders: reminders });
    }

    setReminderTime = (time, marker) => {
        // eslint-disable-next-line array-callback-return
        let reminders = this.state.reminders.filter((data, index) => { // loop through state.todo
            if (index === marker) { // check what data to update using key
                data.r_time = time;
            }

            return data
        });

        this.setState({ reminders: reminders });
    }

    addTask = () => {

        let task_details = { ...this.state.task_details };

        // check entry
        if (!task_details.recipients || !task_details.text || !task_details.note) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        // check if recipient is also copied
        if (task_details.cc) {
            let filtered = task_details.recipients.filter(item => task_details.cc.includes(item));

            if (filtered.length) {
                toast.error(t("Recipients cannot also be copied", this.props.auth.language), {
                    position: 'top-center',
                    autoClose: 3000
                });

                return
            }
        }

        if (!task_details.priority) {
            task_details['priority'] = "3";
        }

        // Create an object of formData
        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", "tasks");

        formData.append("task_details", JSON.stringify(task_details));

        formData.append("reminders", JSON.stringify(this.state.reminders));

        this.props.serverAddTask(formData, this.props.auth.user.token)
    }

    deleteTask = (task_id) => {
        this.props.serverLoad("true");
        this.props.serverDeleteTask(task_id, this.props.auth.user.token);
    }

    editTask = () => {

        let task_details = { ...this.state.task_details };

        // check entry
        if (!task_details.recipients || !task_details.text || !task_details.note) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (!task_details.priority) {
            task_details['priority'] = "3";
        }

        if (typeof task_details.recipients === "string") {

            task_details.recipients = JSON.parse(task_details.recipients.replace(/'/g, '"'));
        }

        // Create an object of formData
        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", "tasks");

        formData.append("task_details", JSON.stringify(task_details));

        formData.append("reminders", JSON.stringify(this.state.reminders));

        this.props.serverEditTask(formData, this.props.auth.user.token)

    }

    reload = () => {
        this.props.serverGetTasks(this.props.auth.user.token);

        // toast.success(t("Your tasks have been reloaded successfully", this.props.auth.language), {
        //     position: 'top-center',
        //     autoClose: 3000
        // });

        this.setState({ task_details: {} });
    }

    searchTasks = (value) => {
        // console.log(this.props.tasks);
        // eslint-disable-next-line array-callback-return
        let task_list = this.props.tasks.tasks.filter((data, index) => { // loop through state.todo
            let keys = Object.keys(data.task);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data.task[key] !== "string") {
                    continue;
                }

                if (data.task[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        let task_list_no = task_list.filter(item => item.task.state === 'no');

        let task_list_progress = task_list.filter(item => item.task.state === 'progress');

        let task_list_yes = task_list.filter(item => item.task.state === 'yes');

        this.setState({ task_list: task_list, task_list_no: task_list_no, task_list_progress: task_list_progress, task_list_yes: task_list_yes });
    }

    changeTaskState = (state, task_id) => {
        this.props.serverChangeTaskState(state, task_id, this.props.auth.user.token);
    }

    getContactName = (contact_id, type) => {

        let conts = [...this.state.contact_list];

        if (contact_id) {

            if (type === "single") {

                let contact = conts.filter(item => (item.value === contact_id));

                if (contact[0]) {
                    return contact[0].label;
                }
            } else if (type === "double") {

                let ids = JSON.parse(contact_id.replace(/'/g, '"'));

                let contacts_to_show = "";

                conts.filter(item => (ids.includes(item.value) ? contacts_to_show += item.label + ", " : null));

                return contacts_to_show;
            }
        }

        return contact_id;
    }

    getContactDetails = (contact_id) => {

        let conts = [...this.state.contact_details_list];

        var contact = conts.filter(item => (item.contact_id + "" === contact_id + ""));

        if (contact[0]) {
            return contact[0];
        }

        return contact_id;
    }

    doCompare = (selected_tags, contacts) => {
        // console.log(selected_tags, contacts);
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i];
            let isValid = false
            if (el.task.tags) {
                let tags = JSON.parse(el.task.tags.replace(/'/g, '"'));

                // check if all selected tags exist in contacts
                //  let myValues = {...value}

                for (let ind = 0; ind < tags.length; ind++) {
                    const elt = tags[ind];
                    if (value[elt.value]) {
                        isValid = true;
                        break;
                    }

                    // check if all selected tags exist in contacts
                    // if (myValues[elt.value]) {
                    //     delete myValues[elt.value]
                    // }
                }
            }

            if (isValid) {
                results.push(el);
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }

    searchTaskByTags = () => {
        if (this.state.task_details.search_params) {
            let task_list = this.doCompare(this.state.task_details.search_params, this.props.tasks.tasks);

            let task_list_no = task_list.filter(item => item.task.state === 'no');

            let task_list_progress = task_list.filter(item => item.task.state === 'progress');

            let task_list_yes = task_list.filter(item => item.task.state === 'yes');

            this.setState({ task_list: task_list, task_list_no: task_list_no, task_list_progress: task_list_progress, task_list_yes: task_list_yes });

            $("#dismiss_search_params_modal").click();
            // console.log(new_tasks);
        }
    }

    searchTaskByCompany = () => {
        if (this.state.task_details.search_company) {

            let conts = [...this.state.contact_details_list];

            let tasks = [...this.props.tasks.tasks];

            let contact_ids = [];

            conts.filter(item => (item.company_name + "" === this.state.task_details.search_company + "") ? contact_ids.push(item.contact_id) : null);

            let task_list = tasks.filter(item => (contact_ids.includes(item.task.user) || contact_ids.includes(item.task.recipient)) || this.checkFilterCC(contact_ids, item.task.cc));

            let task_list_no = task_list.filter(item => item.task.state === 'no');

            let task_list_progress = task_list.filter(item => item.task.state === 'progress');

            let task_list_yes = task_list.filter(item => item.task.state === 'yes');

            this.setState({ task_list: task_list, task_list_no: task_list_no, task_list_progress: task_list_progress, task_list_yes: task_list_yes });

            $("#dismiss_search_companies_modal").click();
            // console.log(new_tasks);
        }
    }

    checkFilterCC = (arr1, arr2) => {

        if (arr1 && arr2) {
            let found = arr1.some(r => arr2.indexOf(r) >= 0);
            return found;
        }

        return false
    }

    searchTaskByUsers = () => {
        let task_details = { ...this.state.task_details }

        // console.log(task_details.search_users);

        if (!task_details.search_category || !task_details.search_users) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let search_users = []
        task_details.search_users.forEach(element => {
            search_users.push(element.value);
        });

        let task_list = [];

        switch (task_details.search_category) {
            case 'From':
                // eslint-disable-next-line array-callback-return
                task_list = this.props.tasks.tasks.filter((item) => search_users.includes(item.task.user));
                break;
            case 'To':
                task_list = this.props.tasks.tasks.filter((item) => search_users.includes(item.task.recipient));
                // eslint-disable-next-line array-callback-return
                // task_list = this.props.tasks.tasks.filter((item, index) => {
                //     if (item.task.recipients) {
                //         let people = JSON.parse(item.task.recipients.replace(/'/g, '"'));
                //         people.forEach(el => {
                //             if (search_users.includes(el.value)) {
                //                 return item;
                //             }
                //         });
                //     }
                // });
                break;
            default:
                break;
        }

        let task_list_no = task_list.filter(item => item.task.state === 'no');

        let task_list_progress = task_list.filter(item => item.task.state === 'progress');

        let task_list_yes = task_list.filter(item => item.task.state === 'yes');

        this.setState({ task_list: task_list, task_list_no: task_list_no, task_list_progress: task_list_progress, task_list_yes: task_list_yes });


        $("#dismiss_search_recipients_modal").click();

    }

    clearAttachments = () => {
        this.setState({ my_documents_selected: [], files_selected: [], my_computer_selected: [], selected_items: {}, selected_files: {}, my_computer: [] });
    }

    saveAttachments = () => {
        if (!this.state.my_computer_selected.length && !this.state.my_documents_selected.length && !this.state.files_selected.length) {

            toast.error(t("Please select files to add as attachments", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        $("#dismiss_attachment_modal").click();
    }

    setAttachmentInfo = (data, type) => {

        let id = "";
        let file_name = "";
        let folder = "";
        let ext = "";

        switch (type) {
            case 'attachment':
                id = data.aid;
                file_name = data.file_name;
                folder = "attachments";
                ext = data.ext;
                break;
            case 'document':
                id = data.did;
                file_name = data.name;
                folder = "documents";
                ext = data.ext;
                break;
            case 'file':
                id = data.fid;
                file_name = data.filename;
                folder = "file_manager/files";
                ext = data.filetype;
                break;
            case 'template':
                id = data.tid;
                file_name = data.template_name;
                folder = "templates";
                ext = data.ext;
                break;
            default:
                break;
        }

        if (ext === ".docx" || ext === ".pptx" || ext === ".xlsx" || ext === ".csv") {

            let kind = "";
            if (ext === '.docx') {
                kind = "word";
            } else if (ext === '.pptx') {
                kind = "slide";
            } else if (ext === '.xlsx' || ext === ".csv") {
                kind = "cell";
            }

            let config = {
                "document": {
                    "fileType": ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": false
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": file_name.replace(/&/g, ""),
                    "url": `${types.SERVER_URL}static/${folder}/${id.toString()}${ext}`,
                    "user": {
                        "id": this.props.auth.user.contact_id,
                        "name": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=${folder}:${id.toString()}${ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            this.setState({ document_config: config });
        }

        this.setState({ view_attachment_data: { "id": id, "file_name": file_name, "folder": folder, "ext": ext } })
    }

    selectMyDocument = (data) => {

        let newState = { ...this.state.selected_items };

        if (newState[data.did]) {
            delete newState[data.did];
        } else {
            newState[data.did] = data;
        }

        this.setState({
            selected_items: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ my_documents_selected: results })
    }

    selectFiles = (data) => {

        let newState = { ...this.state.selected_files };

        if (newState[data.fid]) {
            delete newState[data.fid];
        } else {
            newState[data.fid] = data;
        }

        this.setState({
            selected_files: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ files_selected: results })
    }

    enterFolder = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');

            let breadcrumbs = [...this.state.breadcrumbs, data];

            this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    enterFolderAlt = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');
            this.setState({ file_list: file_list });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    selectTemplate = (data) => {
        this.setState({ selected_template: data });
    }

    useTemplate = () => {
        // console.log("stemp", this.state.selected_template_details);
        // console.log("answers", this.state.answers);

        if (!this.state.selected_template_details.document_name || !this.state.answers) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverLoad("true");
        this.props.serverUseTemplate(
            this.state.selected_template.templates.tid,
            this.state.selected_template_details.document_name,
            this.state.selected_template_details.tags ? this.state.selected_template_details.tags : [],
            this.state.answers,
            this.props.auth.user.token
        );
    }

    searchAttachment = (value, type) => {
        switch (type) {
            case 'documents':
                // eslint-disable-next-line array-callback-return
                let document_list = this.props.templates.documents.filter((data, index) => {
                    if (data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ document_list: document_list });
                break;
            case 'files':
                if (value) {
                    // eslint-disable-next-line array-callback-return
                    let file_list = this.props.attachments.all_files.filter((data, index) => {
                        if (data.filename.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                            return data;
                        }
                    })
                    this.setState({ file_list: file_list });
                } else {
                    let file_list = this.state.breadcrumbs[this.state.breadcrumbs.length - 1];
                    if (typeof file_list !== 'undefined') {
                        this.enterFolderAlt(file_list);
                    }
                }
                break;
            case 'templates':
                // eslint-disable-next-line array-callback-return
                let template_list = this.props.templates.templates.filter((data, index) => {
                    if (data.templates.template_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ template_list: template_list });
                break;
            default:
                break;
        }
    }

    clearAttachmentInfo = () => {
        this.setState({ view_attachment_data: {} });
    }

    fileType = (type) => {

        var image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        var video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        var audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        var compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        var doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];


        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    download = async (folder, id, ext, file_name) => {

        axios.get(`${types.SERVER_URL}static/${folder}/${id}${ext}`, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, `${file_name}${ext}`);
        });
    }

    selectedAttachments = (attachments) => {
        this.setState({ selected_attachments: attachments })
    }

    taskLogs = (task_logs) => {
        this.setState({ task_logs: task_logs });
    }

    firstTaskLog = (task_logs, text) => {

        if (task_logs) {

            let date_diff = 0;

            // if (index === 0) {
            let today = new Date();
            let dd = today.getDate();

            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }

            if (mm < 10) {
                mm = '0' + mm;
            }

            today = yyyy + '-' + mm + '-' + dd;

            let one_day = 1000 * 60 * 60 * 24;
            date_diff = Math.ceil((new Date(today).getTime() - new Date(task_logs.date)) / one_day);

            if (date_diff === 0) {
                let the_date = new Date();
                let current_time = the_date.getHours() + ":" + the_date.getMinutes()
                return this.secondsToHMS(this.hmsToSeconds(current_time) - this.hmsToSeconds(task_logs.time));
            }

            return date_diff + " days now";
        }

        return "";
    }

    hmsToSeconds = (s) => {
        var b = s.split(':');
        return b[0] * 3600 + b[1] * 60 + (+b[2] || 0);
    }

    secondsToHMS = (secs) => {
        function z(n) { return (n < 10 ? '0' : '') + n; }
        var sign = secs < 0 ? '-' : '';
        secs = Math.abs(secs);
        return sign + z(secs / 3600 | 0) + ' hours, ' + z((secs % 3600) / 60 | 0) + ' mins now';
        // return sign + z(secs / 3600 | 0) + ':' + z((secs % 3600) / 60 | 0) + ':' + z(secs % 60);
    }


    setTaskComment = (data) => {
        this.setState({
            task_details: data.task,
            reminders: data.reminders,
            task_comments: data.task.task_comments
        });

        let ids = data.task.task_comments.map(obj => obj.comment_id);

        if (ids.length) {
            this.props.serverGetCommentAttachments({ ids: ids }, this.props.auth.user.token);
        }
    }

    filterCommentAttachment = (comment_id) => {
        return this.props.tasks.comment_attachments.filter(item => (item.type_id + '' === comment_id + '' && item.type === 'task_comments'));
    }

    setTaskCc = (data) => {
        this.setState({ task_cc: data.task });
    }

    sendComment = () => {
        if (this.state.task_comment === "") {
            toast.error(t("Type your comment first", this.props.auth.language), {
                position: 'top-center'
            });

            return;
        }

        // Create an object of formData
        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("task_id", this.state.task_details.task_id);
        formData.append("comment", this.state.task_comment);
        formData.append("type", "task_comments");

        this.props.serverSendComment(formData, this.props.auth.user.token);
    }

    getDayDifference = (index, data) => {

        let date_diff = 0;

        // if (index === 0) {
        let today = new Date();
        let dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        today = yyyy + '-' + mm + '-' + dd;

        let one_day = 1000 * 60 * 60 * 24;
        date_diff = Math.ceil((new Date(today).getTime() - new Date(data.date).getTime()) / one_day);

        // } 
        // else {
        //     // console.log(this.state.task_logs[index - 1], this.state.task_logs[index]);

        //     let prev_date = this.state.task_logs[index - 1].date;
        //     let next_date = this.state.task_logs[index].date;

        //     let one_day = 1000 * 60 * 60 * 24;
        //     date_diff = Math.ceil((new Date(next_date).getTime() - new Date(prev_date).getTime()) / one_day);
        // }

        if (date_diff === 0) {
            return "Today";
        }

        return date_diff + " days ago";
    }

    allTasks = () => {
        this.props.serverGetAllTasks(this.props.auth.user.token);

        // toast.success(t("All tasks have been loaded successfully", this.props.auth.language), {
        //     position: 'top-center',
        //     autoClose: 3000
        // });

        this.setState({ task_details: {} });
    }

    sendTaskCC = () => {

        if (!this.state.task_details.task_cc_users) {
            toast.error(t("Please select who to copy this task to", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let data = {
            task_id: this.state.task_cc.task_id,
            cc: this.state.task_details.task_cc_users
        }
        this.props.serverSendTaskCC(data, this.props.auth.user.token);
    }

    sortTasks = (type, state) => {
        switch (type) {
            case "date_created_asc":
                if (state === "no") {
                    let task_list_no = [...this.state.task_list_no];
                    let final_task_list_no = task_list_no.sort((a, b) => (a.task.date_created > b.task.date_created) ? 1 : -1);
                    this.setState({ task_list_no: final_task_list_no });
                }

                if (state === "progress") {
                    let task_list_progress = [...this.state.task_list_progress];
                    let final_task_list_progress = task_list_progress.sort((a, b) => (a.task.date_created > b.task.date_created) ? 1 : -1);
                    this.setState({ task_list_progress: final_task_list_progress });
                }

                if (state === "yes") {
                    let task_list_yes = [...this.state.task_list_yes];
                    let final_task_list_yes = task_list_yes.sort((a, b) => (a.task.date_created > b.task.date_created) ? 1 : -1);
                    this.setState({ task_list_yes: final_task_list_yes });
                }
                break;

            case "date_created_desc":
                if (state === "no") {
                    let task_list_no = [...this.state.task_list_no];
                    let final_task_list_no = task_list_no.sort((a, b) => (a.task.date_created < b.task.date_created) ? 1 : -1);
                    this.setState({ task_list_no: final_task_list_no });
                }
                if (state === "progress") {
                    let task_list_progress = [...this.state.task_list_progress];
                    let final_task_list_progress = task_list_progress.sort((a, b) => (a.task.date_created < b.task.date_created) ? 1 : -1);
                    this.setState({ task_list_progress: final_task_list_progress });
                }
                if (state === "yes") {
                    let task_list_yes = [...this.state.task_list_yes];
                    let final_task_list_yes = task_list_yes.sort((a, b) => (a.task.date_created < b.task.date_created) ? 1 : -1);
                    this.setState({ task_list_yes: final_task_list_yes });
                }
                break;

            case "date_updated_asc":
                if (state === "no") {
                    let task_list_no = [...this.state.task_list_no];
                    let final_task_list_no = task_list_no.sort((a, b) => (a.task.date_updated > b.task.date_updated) ? 1 : -1);
                    this.setState({ task_list_no: final_task_list_no });
                }

                if (state === "progress") {
                    let task_list_progress = [...this.state.task_list_progress];
                    let final_task_list_progress = task_list_progress.sort((a, b) => (a.task.date_updated > b.task.date_updated) ? 1 : -1);
                    this.setState({ task_list_progress: final_task_list_progress });
                }

                if (state === "yes") {
                    let task_list_yes = [...this.state.task_list_yes];
                    let final_task_list_yes = task_list_yes.sort((a, b) => (a.task.date_updated > b.task.date_updated) ? 1 : -1);
                    this.setState({ task_list_yes: final_task_list_yes });
                }
                break;

            case "date_updated_desc":
                if (state === "no") {
                    let task_list_no = [...this.state.task_list_no];
                    let final_task_list_no = task_list_no.sort((a, b) => (a.task.date_updated < b.task.date_updated) ? 1 : -1);
                    this.setState({ task_list_no: final_task_list_no });
                }
                if (state === "progress") {
                    let task_list_progress = [...this.state.task_list_progress];
                    let final_task_list_progress = task_list_progress.sort((a, b) => (a.task.date_updated < b.task.date_updated) ? 1 : -1);
                    this.setState({ task_list_progress: final_task_list_progress });
                }
                if (state === "yes") {
                    let task_list_yes = [...this.state.task_list_yes];
                    let final_task_list_yes = task_list_yes.sort((a, b) => (a.task.date_updated < b.task.date_updated) ? 1 : -1);
                    this.setState({ task_list_yes: final_task_list_yes });
                }
                break;

            case "priority_asc":
                if (state === "no") {
                    let task_list_no = [...this.state.task_list_no];
                    let final_task_list_no = task_list_no.sort((a, b) => (a.task.priority < b.task.priority) ? 1 : -1);
                    this.setState({ task_list_no: final_task_list_no });
                }
                if (state === "progress") {
                    let task_list_progress = [...this.state.task_list_progress];
                    let final_task_list_progress = task_list_progress.sort((a, b) => (a.task.priority < b.task.priority) ? 1 : -1);
                    this.setState({ task_list_progress: final_task_list_progress });
                }
                if (state === "yes") {
                    let task_list_yes = [...this.state.task_list_yes];
                    let final_task_list_yes = task_list_yes.sort((a, b) => (a.task.priority < b.task.priority) ? 1 : -1);
                    this.setState({ task_list_yes: final_task_list_yes });
                }
                break;

            case "priority_desc":
                if (state === "no") {
                    let task_list_no = [...this.state.task_list_no];
                    let final_task_list_no = task_list_no.sort((a, b) => (a.task.priority > b.task.priority) ? 1 : -1);
                    this.setState({ task_list_no: final_task_list_no });
                }
                if (state === "progress") {
                    let task_list_progress = [...this.state.task_list_progress];
                    let final_task_list_progress = task_list_progress.sort((a, b) => (a.task.priority > b.task.priority) ? 1 : -1);
                    this.setState({ task_list_progress: final_task_list_progress });
                }
                if (state === "yes") {
                    let task_list_yes = [...this.state.task_list_yes];
                    let final_task_list_yes = task_list_yes.sort((a, b) => (a.task.priority > b.task.priority) ? 1 : -1);
                    this.setState({ task_list_yes: final_task_list_yes });
                }
                break;
            default:
                break;
        }
    }

    switchTab = (tab, state) => {
        let task_list = [...this.props.tasks.tasks];
        switch (tab) {
            case 'all':
                if (state === "no") {
                    let task_list_no = task_list.filter(item => item.task.state === 'no');
                    this.setState({ task_list_no: task_list_no });
                }
                if (state === "progress") {
                    let task_list_progress = task_list.filter(item => item.task.state === 'progress');
                    this.setState({ task_list_progress: task_list_progress });
                }
                if (state === "yes") {
                    let task_list_yes = task_list.filter(item => item.task.state === 'yes');
                    this.setState({ task_list_yes: task_list_yes });
                }
                break;

            case 'received':
                if (state === "no") {
                    let task_list_no = task_list.filter(item => item.task.state === 'no' && item.task.recipient + "" === this.props.auth.user.contact_id + "");
                    this.setState({ task_list_no: task_list_no });
                }
                if (state === "progress") {
                    let task_list_progress = task_list.filter(item => item.task.state === 'progress' && item.task.recipient + "" === this.props.auth.user.contact_id + "");
                    this.setState({ task_list_progress: task_list_progress });
                }
                if (state === "yes") {
                    let task_list_yes = task_list.filter(item => item.task.state === 'yes' && item.task.recipient + "" === this.props.auth.user.contact_id + "");
                    this.setState({ task_list_yes: task_list_yes });
                }
                break;

            case 'sent':
                if (state === "no") {
                    let task_list_no = task_list.filter(item => item.task.state === 'no' && item.task.user + "" === this.props.auth.user.contact_id + "");
                    this.setState({ task_list_no: task_list_no });
                }
                if (state === "progress") {
                    let task_list_progress = task_list.filter(item => item.task.state === 'progress' && item.task.user + "" === this.props.auth.user.contact_id + "");
                    this.setState({ task_list_progress: task_list_progress });
                }
                if (state === "yes") {
                    let task_list_yes = task_list.filter(item => item.task.state === 'yes' && item.task.user + "" === this.props.auth.user.contact_id + "");
                    this.setState({ task_list_yes: task_list_yes });
                }
                break;

            case 'copied':
                if (state === "no") {
                    let task_list_no = task_list.filter(item => item.task.state === 'no' && item.task.cc.includes('"' + this.props.auth.user.contact_id + '"'));
                    this.setState({ task_list_no: task_list_no });
                }
                if (state === "progress") {
                    let task_list_progress = task_list.filter(item => item.task.state === 'progress' && item.task.cc.includes('"' + this.props.auth.user.contact_id + '"'));
                    this.setState({ task_list_progress: task_list_progress });
                }
                if (state === "yes") {
                    let task_list_yes = task_list.filter(item => item.task.state === 'yes' && item.task.cc.includes('"' + this.props.auth.user.contact_id + '"'));
                    this.setState({ task_list_yes: task_list_yes });
                }
                break;

            default:
                break;
        }
    }

    switchTabAll = (tab) => {
        let task_list = [...this.props.tasks.tasks];
        let task_list_no = [];
        let task_list_progress = [];
        let task_list_yes = [];

        switch (tab) {
            case 'all':
                task_list_no = task_list.filter(item => item.task.state === 'no');
                this.setState({ task_list_no: task_list_no });

                task_list_progress = task_list.filter(item => item.task.state === 'progress');
                this.setState({ task_list_progress: task_list_progress });

                task_list_yes = task_list.filter(item => item.task.state === 'yes');
                this.setState({ task_list_yes: task_list_yes });
                break;

            case 'received':
                task_list_no = task_list.filter(item => item.task.state === 'no' && item.task.recipient + "" === this.props.auth.user.contact_id + "");
                this.setState({ task_list_no: task_list_no });

                task_list_progress = task_list.filter(item => item.task.state === 'progress' && item.task.recipient + "" === this.props.auth.user.contact_id + "");
                this.setState({ task_list_progress: task_list_progress });

                task_list_yes = task_list.filter(item => item.task.state === 'yes' && item.task.recipient + "" === this.props.auth.user.contact_id + "");
                this.setState({ task_list_yes: task_list_yes });
                break;

            case 'sent':
                task_list_no = task_list.filter(item => item.task.state === 'no' && item.task.user + "" === this.props.auth.user.contact_id + "");
                this.setState({ task_list_no: task_list_no });

                task_list_progress = task_list.filter(item => item.task.state === 'progress' && item.task.user + "" === this.props.auth.user.contact_id + "");
                this.setState({ task_list_progress: task_list_progress });

                task_list_yes = task_list.filter(item => item.task.state === 'yes' && item.task.user + "" === this.props.auth.user.contact_id + "");
                this.setState({ task_list_yes: task_list_yes });

                break;

            case 'copied':
                task_list_no = task_list.filter(item => item.task.state === 'no' && item.task.cc.includes('"' + this.props.auth.user.contact_id + '"'));
                this.setState({ task_list_no: task_list_no });

                task_list_progress = task_list.filter(item => item.task.state === 'progress' && item.task.cc.includes('"' + this.props.auth.user.contact_id + '"'));
                this.setState({ task_list_progress: task_list_progress });

                task_list_yes = task_list.filter(item => item.task.state === 'yes' && item.task.cc.includes('"' + this.props.auth.user.contact_id + '"'));
                this.setState({ task_list_yes: task_list_yes });

                break;

            default:
                break;
        }
    }

    confirmTaskRating = (rating) => {
        let data = {
            rating: rating,
            task_id: this.state.task_info.task_id
        }

        this.props.serverconfirmTaskRating(data, this.props.auth.user.token);

        $("#dismiss_task_rating_modal").click();
    }

    selectFilters = (data) => {

        // let newState = [...this.state.selected_filters];
        // if (newState.includes(data)) {
        //     let index = newState.indexOf(data);
        //     if (index > -1) { 
        //         newState.splice(index, 1); 
        //     }
        // } else {
        //     newState.push(data);
        // }

        // this.setState({ selected_filters: newState.sort() });

        let newState = { ...this.state.temp_filters };

        if (newState[data.order]) {
            delete newState[data.order];
        } else {
            newState[data.order] = data;
        }

        this.setState({
            temp_filters: newState
        });

        let results = Object.values(newState);

        this.setState({ selected_filters: results });
    }

    printTasks = () => {
        var divContents = document.getElementById("tasks_printout").innerHTML;
        var a = window.open('', '');
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    render() {
        return (
            <>
                <Main>
                    <Task
                        c_state={{ ...this.state }}
                        isLoading={this.props.tasks.isLoading}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        remove_reminder={this.removeReminder}
                        add_reminder={this.addReminder}
                        set_reminder_date={this.setReminderDate}
                        set_reminder_time={this.setReminderTime}
                        add_task={this.addTask}
                        delete_task={this.deleteTask}
                        edit_task={this.editTask}
                        set_modal_content={this.setModalContent}
                        set_modal_content_alt={this.setModalContentAlt}
                        clear_modal_contents={this.clearModalContents}
                        reload={this.reload}
                        search_tasks={this.searchTasks}
                        change_task_state={this.changeTaskState}
                        getContactName={this.getContactName}
                        getContactDetails={this.getContactDetails}
                        search_task_by_tags={this.searchTaskByTags}
                        search_task_by_users={this.searchTaskByUsers}
                        search_task_by_company={this.searchTaskByCompany}
                        selected_attachments={this.selectedAttachments}
                        task_logs={this.taskLogs}
                        first_task_log={this.firstTaskLog}
                        set_task_comment={this.setTaskComment}
                        set_task_cc={this.setTaskCc}
                        send_comment={this.sendComment}
                        get_day_difference={this.getDayDifference}
                        all_tasks={this.allTasks}
                        send_task_cc={this.sendTaskCC}
                        sort_tasks={this.sortTasks}
                        switch_tab={this.switchTab}
                        switch_tab_all={this.switchTabAll}
                        confirm_task_rating={this.confirmTaskRating}
                        selected_filters={this.selectFilters}
                        print_tasks={this.printTasks}
                        filter_comment_attachment={this.filterCommentAttachment}

                        // attachments component
                        templates={this.props.templates}
                        attachments={this.props.attachments}
                        select_my_document={this.selectMyDocument}
                        select_files={this.selectFiles}
                        enter_folder={this.enterFolder}
                        click_breadcrumbs={this.clickBreadCrumbs}
                        select_template={this.selectTemplate}
                        use_template={this.useTemplate}
                        save_attachments={this.saveAttachments}
                        clear_attachments={this.clearAttachments}
                        search_attachment={this.searchAttachment}
                        // view attachments
                        set_attachment_info={this.setAttachmentInfo}
                        clear_attachment_info={this.clearAttachmentInfo}
                        file_type={this.fileType}
                        download={this.download}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tasks: { ...state.tasks },
        tags: { ...state.tags },
        contacts: { ...state.contacts },
        templates: { ...state.templates },
        attachments: { ...state.attachments }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverGetContacts,
        serverGetCompanies,
        serverGetTasks,
        serverAddTask,
        serverEditTask,
        serverDeleteTask,
        serverChangeTaskState,
        serverUseTemplate,
        serverGetDocuments,
        serverGetTemplates,
        serverSaveAttachments,
        serverClearSavedAttachments,
        serverGetAllFiles,
        serverSendComment,
        serverGetAllTasks,
        serverSendTaskCC,
        serverconfirmTaskRating,
        serverGetCommentAttachments
    }
)(TasksContainer)
