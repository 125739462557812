const fr = {
    "Dashboard": "Tableau de bord",
    "TASKS": "TÂCHES",
    "Task Manager": "Gestionnaire des tâches",
    "PERSONAL": "PERSONNEL",
    "My Profile": "Mon profil",
    "Tags": "Mots clés",
    "My Contacts": "Mes contacts",
    "MAILINGS": "ENVOIS",
    "Routing": "Routage",
    "My Emails": "Mes e-mails",
    "Correspondence": "Correspondance",
    "TEMPLATING": "MODÉLISATION",
    "New Template": "Nouveau modèle",
    "View Templates": "Afficher les modèles",
    "Documents": "Documents",
    "MISCELLANEOUS": "DIVERS",
    "Conversion Service": "Service de transformation",
    "Privileges & Access Ctrl": "Privilèges et contrôle d'accès",
    "User Roles": "Rôles d'utilisateur",
    "Help": "Aider",
    "Home": "Domicile",
    "We are loading some stats. Please wait...": "Nous chargeons quelques statistiques. S'il vous plaît, attendez...",
    "Tasks To Do": "Tâches à faire",
    "More Info": "Plus d'informations",
    "Pending Tasks": "Tâches en attente",
    "Unread Correspondences": "Correspondances non lues",
    "Read Correspondences": "Lire les correspondances",
    "Sent Correspondences": "Correspondances envoyées",
    "Correspondences Worked On": "Correspondances travaillées",
    "Completed Tasks": "Tâches terminées",
    "No of Completed Tasks": "Nombre de tâches terminées",
    "We are loading your info. Please wait...": "Nous chargeons vos informations. S'il vous plaît, attendez...",
    "Logout": "Se déconnecter",
    "Other Modules": "Autres modules",
    "Switch To This Account": "Passer à ce compte",
    "Search": "Recherche",
    "Tasks": "Tâches",
    "Manage Your Tasks": "Gérez vos tâches",
    "Reload": "Recharger",
    "Add New": "Ajouter nouveau",
    "Filter By Tags": "Filtrer par balises",
    "Filter By Users": "Filtrer par utilisateurs",
    "Type your keywords here": "Tapez vos mots-clés ici",
    "We are loading your tasks. Please wait...": "Nous chargeons vos tâches. S'il vous plaît, attendez...",
    "To Do": "Faire",
    "From": "Depuis",
    "To": "Pour",
    "done": "Fini",
    "in progress": "en cours",
    "to do": "faire",
    "Edit": "Modifier",
    "Edit Task": "Modifier la tâche",
    "Delete Task": "Supprimer la tâche",
    "Are you sure you want to delete": "Etes-vous sûr que vous voulez supprimer",
    "In Progress": "En cours",
    "Done": "Fait",
    "Delete": "Supprimer",
    "Select Tags": "Sélectionnez les balises",
    "Close": "proche",
    "Select Users": "Sélectionnez les utilisateurs",
    "Select Category": "Choisir une catégorie",
    "Add New Task": "Ajouter une nouvelle tâche",
    "For": "Pour",
    "Subject": "Matière",
    "Task": "Tâche",
    "Deadline Date": "Date limite",
    "Deadline Time": "Heure limite",
    "Priority": "Priorité",
    "Select": "Sélectionner",
    "Reminder Date": "Date de rappel",
    "Reminder Time": "Heure de rappel",
    "Remove": "Éliminer",
    "Add Attachments": "Ajouter des pièces jointes",
    "Clear Attachments": "Effacer les pièces jointes",
    "Save changes": "Sauvegarder les modifications",
    "Task Details": "Détails de la tâche",
    "Task Logs": "Journaux des tâches",
    "Select Attachment": "Sélectionnez la pièce jointe",
    "My Computer": "Mon ordinateur",
    "My Documents": "Mes documents",
    "File Manager": "Gestionnaire de fichiers",
    "Templates": "Modèles",
    "Choose Files": "Choisir les fichiers",
    "Selected Documents": "Documents sélectionnés",
    "File Name": "Nom de fichier",
    "Date Created": "date créée",
    "View Document": "Afficher le document",
    "View Template": "Afficher le modèle",
    "Use Template": "Utilise le modèle",
    "Save": "Sauver",
    "Document Name": "Nom du document",
    "Please answer the ff questions to generate your document": "Veuillez répondre aux ff questions pour générer votre document",
    "No placeholders have been defined for the template you selected": "Aucun espace réservé n'a été défini pour le modèle que vous avez sélectionné",
    "This file cannot be previewed": "Ce fichier ne peut pas être prévisualisé",
    "Click here to download": "Cliquez ici pour télécharger",
    "Attachments": "Pièces jointes",
    "Profile": "Profil",
    "User Profile": "Profil de l'utilisateur",
    "About Me": "Sur moi",
    "Company/Institution": "Entreprise/Etablissement",
    "Position": "Position",
    "Contact": "Contacter",
    "Address": "Adresse",
    "Type/Category": "Genre/Catégorie",
    "Timeline": "Chronologie",
    "Settings": "Réglages",
    "Manage Tags": "Gérer les balises",
    "Name": "Nom",
    "Category": "Catégorie",
    "Edit Sub Tag": "Modifier la sous-étiquette",
    "Delete Sub Tag": "Supprimer la sous-balise",
    "Add Sub Tag": "Ajouter une sous-étiquette",
    "Add New Tag": "Ajouter une nouvelle balise",
    "Tag Name": "Nom de la balise",
    "Private": "Privé",
    "Public": "Public",
    "View File": "Voir la fiche",
    "Preview Template": "Aperçu du modèle",
    "Edit Tag": "Modifier la balise",
    "Add New Tag Under": "Ajouter une nouvelle balise sous",
    "Contacts": "Contacts",
    "Upload Contacts": "Télécharger des contacts",
    "Add Contact": "Ajouter le contact",
    "Add Company": "Ajouter une entreprise",
    "Add Group": "Ajouter un groupe",
    "Contact List": "Liste de contacts",
    "Companies": "Entreprises",
    "Groups": "Groupes",
    "We are loading your contact list. Please wait...": "Nous chargeons votre liste de contacts. S'il vous plaît, attendez...",
    "Phone": "Téléphoner",
    "Email": "E-mail",
    "Company": "Société",
    "Contact Type/Category": "Type/Catégorie de contact",
    "Action": "Action",
    "View More": "Voir plus",
    "We are loading your company list. Please wait...": "Nous chargeons votre liste d'entreprises. S'il vous plaît, attendez...",
    "Company Name": "Nom de l'entreprise",
    "City": "Ville",
    "Country": "Pays",
    "Region": "Région",
    "URL": "URL",
    "Loading. Please wait...": "Chargement. S'il vous plaît, attendez...",
    "Group Name": "Nom de groupe",
    "Date Added": "date ajoutée",
    "user-avatar": "avatar-utilisateur",
    "Contact Category": "Catégorie de contact",
    "Contact Type": "type de contact",
    "Personal": "Personnel",
    "Positional": "Positionnel",
    "Group": "Grouper",
    "Position Name": "Nom du poste",
    "First Name": "Prénom",
    "Last Name": "Nom de famille",
    "Title": "Titre",
    "Add New Company": "Ajouter une nouvelle société",
    "Company Short Name": "Nom abrégé de l'entreprise",
    "Short Name": "Nom court",
    "Company Website": "site Web d'entreprise",
    "Status": "Statut",
    "Internal": "Interne",
    "External": "Externe",
    "Contact Person": "Personne de contact",
    "Position of Contact Person": "Poste de personne-ressource",
    "Company Phone": "Téléphone de l'entreprise",
    "Company Email": "E-mail de l'entreprise",
    "Edit Company Details": "Modifier les détails de l'entreprise",
    "Upload Photo": "Envoyer la photo",
    "Update": "Mise à jour",
    "Info": "Info",
    "Assign a position to": "Attribuer un poste à",
    "User Profile Picture": "Photo de profil utilisateur",
    "View Contact Details": "Afficher les coordonnées",
    "Click": "Cliquez sur",
    "here": "ici",
    "to download Excel template": "pour télécharger le modèle Excel",
    "Select File To Upload": "Sélectionnez le fichier à télécharger",
    "Upload": "Télécharger",
    "Add A New Group": "Ajouter un nouveau groupe",
    "Enter group name": "Entrez le nom du groupe",
    "Submit": "Soumettre",
    "Edit Group": "Modifier le groupe",
    "View Routes": "Afficher les itinéraires",
    "Routes": "Itinéraires",
    "Add New Route": "Ajouter un nouvel itinéraire",
    "All Routes": "Tous les itinéraires",
    "Correspondence Routes": "Routes de correspondance",
    "We are loading your routes. Please wait...": "Nous chargeons vos itinéraires. S'il vous plaît, attendez...",
    "No of Steps": "Nombre d'étapes",
    "Edit Route": "Modifier l'itinéraire",
    "Delete Route": "Supprimer l'itinéraire",
    "New Route": "Nouvelle route",
    "Route Name": "Nom de l'itinéraire",
    "Make changes to": "Apporter des modifications à",
    "Finish": "Finir",
    "Correspondence Subject": "Objet de la correspondance",
    "Correspondence ID": "Identifiant de correspondance",
    "Route Steps": "Étapes d'itinéraire",
    "Add Route Step": "Ajouter une étape d'itinéraire",
    "Deadline": "Date limite",
    "Hours": "Les heures",
    "Recipient": "Destinataire",
    "Auto Forward": "Transfert automatique",
    "Can Close": "Peut fermer",
    "Edit Route Step": "Modifier l'étape de l'itinéraire",
    "Delete Route Step": "Supprimer l'étape de l'itinéraire",
    "Move Up": "Déplacer vers le haut",
    "Move Down": "Descendre",
    "Save Route": "Enregistrer l'itinéraire",
    "New Route Step": "Nouvelle étape d'itinéraire",
    "Yes": "Oui",
    "No": "Non",
    "Emails": "E-mails",
    "Compose": "Composer",
    "Folders": "Dossiers",
    "Inbox": "Boîte de réception",
    "Sent": "Expédié",
    "Drafts": "Brouillons",
    "Spam": "Spam",
    "Trash": "Déchets",
    "Labels": "Étiquettes",
    "Important": "Important",
    "Promotions": "Promotions",
    "Social": "Social",
    "Archived": "Archivé",
    "Search Mail": "Recherche courrier",
    "Sync Your Emails": "Synchronisez vos e-mails",
    "Are you sure you want to synchronize your email with your correspondences": "Êtes-vous sûr de vouloir synchroniser votre email avec vos correspondances",
    "Update Email Config": "Mettre à jour la configuration des e-mails",
    "We are loading your emails. Please wait...": "Nous chargeons vos e-mails. S'il vous plaît, attendez...",
    "Click the reload icon to reload": "Cliquez sur l'icône de rechargement pour recharger",
    "Sorry! Your emails failed to load due to poor internet connection": "Désolé! Vos e-mails n'ont pas pu être chargés en raison d'une mauvaise connexion Internet",
    "Email Configurations": "Configurations de messagerie",
    "Password": "Mot de passe",
    "Email Server": "Serveur de messagerie",
    "Read Your Email": "Lire votre e-mail",
    "Email List": "Liste de diffusion",
    "Email Details": "Détails de l'e-mail",
    "Read Email": "Lire l'e-mail",
    "Back To": "Retour à",
    "Reload your emails": "Rechargez vos e-mails",
    "Attach this email to a new correspondence": "Joindre cet e-mail à une nouvelle correspondance",
    "Attach To Correspondence": "Joindre à la correspondance",
    "Your email is loading. Please wait...": "Votre e-mail est en cours de chargement. S'il vous plaît, attendez...",
    "Reply": "Réponse",
    "Forward": "Avant",
    "Print": "Imprimer",
    "Sorry! Your email failed to load due to poor internet connection": "Désolé! Votre e-mail n'a pas pu être chargé en raison d'une mauvaise connexion Internet",
    "Compose Email": "Écrire un email",
    "Compose New Message": "Composer un nouveau message",
    "Back To Inbox": "Retour à la boîte de réception",
    "Type your message here...": "Tapez votre message ici...",
    "Send": "Envoyer",
    "Discard": "Jeter",
    "Compose Correspondence": "Composer une correspondance",
    "View Correspondence": "Voir la correspondance",
    "Type body of correspondence here...": "Tapez le corps de la correspondance ici...",
    "Add Attachments To Main Correspondence": "Ajouter des pièces jointes à la correspondance principale",
    "Recipients": "Destinataires",
    "Use Route": "Utiliser l'itinéraire",
    "Select Route": "Sélectionnez l'itinéraire",
    "NB": "N.-B.",
    "Through": "Par",
    "Use Tags": "Utiliser des balises",
    "Correspondence Scope": "Portée de la correspondance",
    "Forward To Anyone": "Transférer à n'importe qui",
    "Forward Back To Me": "Revenir à moi",
    "Forward To Anyone In This List": "Transférer à n'importe qui dans cette liste",
    "User(s)": "Utilisateurs)",
    "Correspondence Info": "Informations sur la correspondance",
    "Correspondence Type": "Type de correspondance",
    "Memo": "Note",
    "Fax": "Fax",
    "Text Message": "Message texte",
    "Call": "Appel",
    "Enquiry": "Enquête",
    "Envelope": "Enveloppe",
    "Other": "Autre",
    "Tag This Correspondence": "Taguer cette correspondance",
    "Low Priority": "Priorité basse",
    "Medium Priority": "Priorité moyenne",
    "High Priority": "Haute priorité",
    "Confidential": "Confidentiel",
    "Attached Email": "Courriel joint",
    "Remove this email attachment": "Supprimer cette pièce jointe",
    "Are you sure you want to remove this email attachment": "Êtes-vous sûr de vouloir supprimer cette pièce jointe",
    "Type minutes/comments for your recipients here...": "Tapez les minutes/commentaires pour vos destinataires ici...",
    "Save As Draft": "Enregistrer comme brouillon",
    "Incoming": "Entrant",
    "Outgoing": "Sortant",
    "Worked On": "Travaillé sur",
    "Sorry! Your correspondence failed to load due to poor internet connection": "Désolé! Votre correspondance n'a pas pu être chargée en raison d'une mauvaise connexion Internet",
    "We are loading your correspondences. Please wait...": "Nous chargeons vos correspondances. S'il vous plaît, attendez...",
    "Correspondence Details": "Détails de la correspondance",
    "Read Correspondence": "Lire la correspondance",
    "Close this correspondence": "Fermer cette correspondance",
    "Recipients will not be able to work on this correspondence. Are you sure you want to close this correspondence?": "Les destinataires ne pourront pas travailler sur cette correspondance. Êtes-vous sûr de vouloir clore cette correspondance ?",
    "Are you sure you want to re-open this correspondence?": "Voulez-vous vraiment rouvrir cette correspondance ?",
    "Make Active": "Rendre active",
    "Your correspondence is loading. Please wait...": "Votre correspondance est en cours de chargement. S'il vous plaît, attendez...",
    "Initiated By": "Initié par",
    "Key Info": "Informations clés",
    "This correspondence is still open": "Cette correspondance est toujours ouverte",
    "This correspondence has been closed": "Cette correspondance a été fermée",
    "Confidentiality": "Confidentialité",
    "Not Confidential": "Non confidentiel",
    "Route Used": "Itinéraire utilisé",
    "Body": "Corps",
    "Download": "Télécharger",
    "Upload To File Manager": "Charger dans le gestionnaire de fichiers",
    "Active Trail": "Sentier actif",
    "Expected Response Date": "Date de réponse prévue",
    "Seen?": "Vu?",
    "Date Seen": "Date de visualisation",
    "Minutes/Comments": "Procès-verbal/Commentaires",
    "Forwarded message": "Message transféré",
    "Actions Performed by": "Actions effectuées par",
    "Date Started": "Le rendez vous a commencé",
    "Description": "La description",
    "Date Completed": "Rendez-vous complet",
    "Nullify": "Annuler",
    "to": "pour",
    "You will not be able to work on an archived trail. Are you sure you want to mark this trail as worked on?": "Vous ne pourrez pas travailler sur un parcours archivé. Êtes-vous sûr de vouloir marquer ce sentier comme travaillé ?",
    "Mark As Worked On": "Marquer comme travaillé",
    "Add An Action": "Ajouter une action",
    "Send Email": "Envoyer un e-mail",
    "This trail has been archived": "Ce sentier a été archivé",
    "Nullify This Trail": "Annuler ce sentier",
    "Activate This Trail": "Activer ce parcours",
    "All Trails": "Tous les sentiers",
    "Pending": "En attente",
    "Completed": "Terminé",
    "View Structure": "Afficher la structure",
    "Nullified": "annulé",
    "Active": "Actif",
    "Forwarded Message": "Message transféré",
    "Source/Type": "Type de Source",
    "You will not be able to work on an archived trail. Are you sure you want to archive this trail?": "Vous ne pourrez pas travailler sur un parcours archivé. Voulez-vous vraiment archiver ce parcours ?",
    "All Emails": "Tous les e-mails",
    "Description...": "La description...",
    "Action Type": "type d'action",
    "Forward Correspondence": "Faire suivre la correspondance",
    "Expected Response Date/Time": "Date/Heure de réponse prévue",
    "Reply To": "Répondre à",
    "Schedule Date/Time": "Date/Heure du programme",
    "Create Template": "Créer un modèle",
    "Upload Template": "Télécharger le modèle",
    "Open Document In New Tab": "Ouvrir le document dans un nouvel onglet",
    "Add Placeholders": "Ajouter des espaces réservés",
    "View/Edit": "Voir la modification",
    "Create New Template": "Créer un nouveau modèle",
    "Template Name": "Nom du modèle",
    "Document Type": "Type de document",
    "Word Document": "Document Word",
    "Choose File": "Choisir le fichier",
    "Add Placeholder": "Ajouter un espace réservé",
    "Placeholder": "Espace réservé",
    "Field Type": "Type de champ",
    "Text": "Texte",
    "Text Area": "Zone de texte",
    "Drop Down": "Menu déroulant",
    "Options": "Choix",
    "Options separated by commas. e.g. Book, Pen, Pencil": "Options séparées par des virgules. par exemple, livre, stylo, crayon",
    "Question": "Question",
    "Type your question here...": "Tapez votre question ici...",
    "Edit Placeholder": "Modifier l'espace réservé",
    "Show Modal": "Afficher modale",
    "Create Documents Using Templates": "Créer des documents à l'aide de modèles",
    "We are loading your templates. Please wait...": "Nous chargeons vos modèles. S'il vous plaît, attendez...",
    "Download Template": "Télécharger le modèle",
    "Delete Template": "Supprimer le modèle",
    "Clone Template": "Modèle de clonage",
    "Edit Template": "Modifier le modèle",
    "Preview Document": "Aperçu du document",
    "New Template Name": "Nom du nouveau modèle",
    "Update Your Template": "Mettez à jour votre modèle",
    "Documents Created From Templates": "Documents créés à partir de modèles",
    "We are loading your documents. Please wait...": "Nous chargeons vos documents. S'il vous plaît, attendez...",
    "Download Document": "Télécharger le document",
    "Delete Document": "Supprimer le document",
    "Edit Document": "Modifier le document",
    "Update The Contents Of Your Document": "Mettre à jour le contenu de votre document",
    "Rename Document": "Renommer le document",
    "Document Title": "Titre du document",
    "View Documents": "Afficher les documents",
    "Define Route Steps": "Définir les étapes de l'itinéraire",
    "Add New Step": "Ajouter une nouvelle étape",
    "Conversions": "Conversions",
    "We are loading your files. Please wait...": "Nous chargeons vos fichiers. S'il vous plaît, attendez...",
    "File manager": "Gestionnaire de fichiers",
    "Coverting File. Please wait...": "Dossier de couverture. S'il vous plaît, attendez...",
    "Convert To Image": "Convertir en image",
    "Convert To PDF": "Convertir en PDF",
    "Convert To Text File": "Convertir en fichier texte",
    "You can only convert text, pdf, word, spreadsheet and powerpoint files": "Vous ne pouvez convertir que des fichiers texte, pdf, word, tableur et powerpoint",
    "Privileges & Access Control": "Privilèges et contrôle d'accès",
    "We are loading some data. Please wait...": "Nous chargeons certaines données. S'il vous plaît, attendez...",
    "Note": "Noter",
    "Select a privilege category to assign individual privileges to users": "Sélectionnez une catégorie de privilèges pour attribuer des privilèges individuels aux utilisateurs",
    "Users": "Utilisateurs",
    "User": "Utilisateur",
    "Privilege Category": "Catégorie Privilège",
    "Privilege": "Privilège",
    "View Tasks": "Afficher les tâches",
    "Add Tasks": "Ajouter des tâches",
    "Edit Tasks": "Modifier les tâches",
    "Delete Tasks": "Supprimer des tâches",
    "View Task Logs": "Afficher les journaux des tâches",
    "View Timeline": "Afficher la chronologie",
    "Update Profile": "Mettre à jour le profil",
    "View Profile/Timeline": "Afficher le profil/la chronologie",
    "View Tags": "Afficher les balises",
    "Create New Tag": "Créer une nouvelle balise",
    "Delete Tag": "Supprimer la balise",
    "Add Sub Tags": "Ajouter des sous-tags",
    "View Sub Tags": "Afficher les sous-tags",
    "Edit Sub Tags": "Modifier les sous-étiquettes",
    "Delete Sub Tags": "Supprimer les sous-tags",
    "My Contacts Menu": "Menu Mes contacts",
    "View Contacts": "Afficher les contacts",
    "View Companies": "Voir les entreprises",
    "Add Contacts": "Ajouter des contacts",
    "Add Companies": "Ajouter des entreprises",
    "Edit Personal Contact": "Modifier le contact personnel",
    "Edit All Contacts": "Modifier tous les contacts",
    "Delete Personal Contact": "Supprimer le contact personnel",
    "Delete All Contacts": "Supprimer tous les contacts",
    "View Company Details": "Afficher les détails de l'entreprise",
    "Delete Company Details": "Supprimer les détails de l'entreprise",
    "Create Positional Contacts": "Créer des contacts positionnels",
    "View Groups": "Afficher les groupes",
    "Add Groups": "Ajouter des groupes",
    "Edit Groups": "Modifier les groupes",
    "Delete Groups": "Supprimer des groupes",
    "Create Private Routes": "Créer des itinéraires privés",
    "Create Public Routes": "Créer des routes publiques",
    "Edit Private Routes": "Modifier les itinéraires privés",
    "Edit Public Routes": "Modifier les itinéraires publics",
    "Delete Private Routes": "Supprimer les routes privées",
    "Delete Public Routes": "Supprimer les routes publiques",
    "View Emails": "Afficher les e-mails",
    "Send/Compose Emails": "Envoyer/composer des e-mails",
    "Synchronize Emails With Correspondence": "Synchroniser les e-mails avec la correspondance",
    "Update/Set Email Configurations": "Mettre à jour/définir les configurations de messagerie",
    "Attach Email To Correspondence": "Joindre un e-mail à la correspondance",
    "View Correspondences": "Voir les correspondances",
    "New Template Menu": "Menu Nouveau modèle",
    "Create Private Template": "Créer un modèle privé",
    "Create Public Template": "Créer un modèle public",
    "Upload Private Template": "Télécharger un modèle privé",
    "Upload Public Template": "Télécharger un modèle public",
    "View Template Documents": "Afficher les modèles de documents",
    "Assign System Privileges": "Attribuer des privilèges système",
    "Create User Roles": "Créer des rôles d'utilisateur",
    "Assign User Roles": "Attribuer des rôles d'utilisateur",
    "Update User Roles": "Mettre à jour les rôles d'utilisateur",
    "Are you sure you want to disable this user's account ": "Êtes-vous sûr de vouloir désactiver le compte de cet utilisateur",
    "Disable": "Désactiver",
    "Enable": "Permettre",
    "Account": "Compte",
    "Create/Assign User Roles": "Créer/attribuer des rôles d'utilisateur",
    "Create or assign pre-defined roles to software users": "Créer ou attribuer des rôles prédéfinis aux utilisateurs du logiciel",
    "Role": "Rôle",
    "E.g. Administrator": "Par exemple, administrateur",
    "Roles": "Rôles",
    "Email and password is required": "L'email et le mot de passe sont requis",
    "Please fill all required fields": "Veuillez remplir tous les champs obligatoires",
    "Please select files to add as attachments": "Veuillez sélectionner les fichiers à ajouter en pièces jointes",
    "Please select your recipients": "Veuillez sélectionner vos destinataires",
    "Please specify the list of people for action type": "Veuillez spécifier la liste des personnes pour le type d'action",
    "Please specify the source of your correspondence": "Merci de préciser la source de votre correspondance",
    "Route step updated succesfully": "L'étape de la route a été mise à jour avec succès",
    "Route step deleted succesfully": "L'étape de la route a été supprimée avec succès",
    "Please set reminder date and time before you add a new one": "Veuillez définir la date et l'heure du rappel avant d'en ajouter un nouveau",
    "Please specify the position for this positional account": "Veuillez spécifier la position de ce compte positionnel",
    "Please select a group": "Veuillez sélectionner un groupe",
    "Please type your message": "Veuillez saisir votre message",
    "Document title cannot be empty": "Le titre du document ne peut pas être vide",
    "Please specify the route name and category": "Veuillez spécifier le nom et la catégorie de l'itinéraire",
    "Please define at least one route step": "Veuillez définir au moins une étape d'itinéraire",
    "Please wait for your email to load first": "Veuillez d'abord attendre que votre e-mail se charge",
    "Please specify drop down options": "Veuillez spécifier les options déroulantes",
    "Please enter your email": "Veuillez saisir votre e-mail",
    "The passwords you entered do not match": "Les mots de passe que vous avez entrés ne correspondent pas",
    "Request failed! Please enter a role": "Demande échoué! Veuillez entrer un rôle",
    "Request failed! Please select at least a single privilege": "Demande échoué! Veuillez sélectionner au moins un privilège",
    "Request failed! Please select a role": "Demande échoué! Veuillez sélectionner un rôle",
    "Request failed! Please fill all required fields": "Demande échoué! Veuillez remplir tous les champs obligatoires",
    "Tags loaded successfully": "Balises chargées avec succès",
    "Your tasks have been reloaded successfully": "Vos tâches ont été rechargées avec succès",
    "Routes reloaded successfully": "Routes rechargées avec succès",
    "Department": "Département",
    "Departments": "Départements"
};
export default fr;