import React, { Component } from 'react'
import EmailDetails from './EmailDetails';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverGetEmailDetails, serverLoad } from '../../actions/EmailAction';
import { serverAttachEmail, serverSetChosenCategory } from '../../actions/CorrespondenceAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import types from '../../types';
import fileDownload from 'js-file-download';
import axios from 'axios'
import { getTrans as t } from '../../translations';

// const $ = require("jquery");

class EmailDetailsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags_dropdown: [],
            single_email: {},
            email_details: [],
            view_attachment_data: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.reloadEmail = this.reloadEmail.bind(this);
        this.attachToCorrespondence = this.attachToCorrespondence.bind(this);

        // attachments
        this.setAttachmentInfo = this.setAttachmentInfo.bind(this);
        this.clearAttachmentInfo = this.clearAttachmentInfo.bind(this);
        this.fileType = this.fileType.bind(this);
        this.download = this.download.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        // console.log("semail", this.props.email.single_email.mail_category);

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        this.props.serverLoad("true");
        this.props.serverGetEmailDetails(this.props.email.single_email.single_email.mail_id, this.props.email.single_email.mail_config, this.props.email.single_email.mail_category, this.props.auth.user.token);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.auth.clear_data !== this.props.auth.clear_data) {

            if (this.props.auth.clear_data === "true") {

                // this.clearModalContents();  

                this.props.clearData("false");
            }
        }

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.email.email_details !== this.props.email.email_details) {
            if (this.props.email.email_details.subject) {
                this.props.serverLoad("false");
                this.setState({ email_details: this.props.email.email_details });
            }
        }

        // check for correspondence attachment
        if (prevProps.correspondence.email_details !== this.props.correspondence.email_details) {
            if (this.props.correspondence.email_details.subject) {
                this.props.history.push("/compose_correspondence");
            }
        }
    }

    // clearModalContents = () => {
    //     this.setState({ email_details: {}, company_details: {}, contact_photo: null });

    //     return;
    // }

    handleChange = (data, type) => {
        switch (type) {
            case 'template':
                this.setState(prevState => ({
                    template_details: {                   // object that we want to update
                        ...prevState.template_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;

            case 'placeholder':
                this.setState(prevState => ({
                    placeholder_details: {                   // object that we want to update
                        ...prevState.placeholder_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;

            default:
                break;
        }
    }

    reloadEmail = () => {
        this.props.serverLoad("true");
        this.props.serverGetEmailDetails(this.props.email.single_email.single_email.mail_id, this.props.email.single_email.mail_config, this.props.email.single_email.mail_category, this.props.auth.user.token);
    }

    setAttachmentInfo = (data, type) => {

        let id = "";
        let file_name = "";
        let folder = "";
        let ext = "";

        switch (type) {
            case 'attachment':
                id = data.aid;
                file_name = data.file_name;
                folder = "attachments";
                ext = data.ext;
                break;
            case 'document':
                id = data.did;
                file_name = data.name;
                folder = "documents";
                ext = data.ext;
                break;
            case 'file':
                id = data.fid;
                file_name = data.filename;
                folder = "file_manager/files";
                ext = data.filetype;
                break;
            case 'template':
                id = data.tid;
                file_name = data.template_name;
                folder = "templates";
                ext = data.ext;
                break;
            default:
                break;
        }

        if (ext === ".docx" || ext === ".pptx" || ext === ".xlsx" || ext === ".csv") {

            let kind = "";
            if (ext === '.docx') {
                kind = "word";
            } else if (ext === '.pptx') {
                kind = "slide";
            } else if (ext === '.xlsx' || ext === ".csv") {
                kind = "cell";
            }

            let config = {
                "document": {
                    "fileType": ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": false
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": file_name.replace(/&/g,""),
                    "url": `${types.SERVER_URL}static/${folder}/${id.toString()}${ext}`,
                    "user": {
                        "id": this.props.auth.user.contact_id,
                        "name": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=${folder}:${id.toString()}${ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            this.setState({ document_config: config });
        }

        this.setState({ view_attachment_data: {"id": id, "file_name": file_name, "folder": folder, "ext": ext} })
    }


    clearAttachmentInfo = () => {
        this.setState({ view_attachment_data: {} });
    }

    fileType = (type) => {

        var image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        var video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        var audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        var compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        var doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];


        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    download = async (folder, id, ext, file_name) => {

        axios.get(`${types.SERVER_URL}static/${folder}/${file_name}`, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, `${file_name}`);
        });
    }

    attachToCorrespondence = () => {
        if (!this.state.email_details.length) {
            this.props.serverAttachEmail(this.state.email_details);
            this.props.serverSetChosenCategory("Inbox");
            // this.props.history.push("/compose_correspondence");
        } else {
            toast.info(t("Please wait for your email to load first", this.props.auth.language), {
                position: 'top-center',
                autoClose: 5000
            });
        }
    }

    render() {
        return (
            <>
                <Main>
                    <EmailDetails
                        c_state={{ ...this.state }}
                        isLoading={this.props.email.isLoading}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        reload_email={this.reloadEmail}
                        single_email={this.props.email.single_email}
                        attach_to_correspondence={this.attachToCorrespondence}

                        // view attachments
                        set_attachment_info={this.setAttachmentInfo}
                        clear_attachment_info={this.clearAttachmentInfo}
                        file_type={this.fileType}
                        download={this.download}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        email: { ...state.email },
        correspondence: { ...state.correspondence }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverGetEmailDetails,
        serverAttachEmail,
        serverSetChosenCategory
    }
)(EmailDetailsContainer)
