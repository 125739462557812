import React, { Component } from 'react'
import Correspondence from './Correspondence';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverGetContacts } from '../../actions/ContactsAction';
import { serverLoad, serverGetCorrespondence, serverSingleCorrespondence, serverMarkSeen, serverSetChosenCategory, serverGetFilteredCorrespondence } from '../../actions/CorrespondenceAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class ViewCorrespondenceContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            correspondence_list: [],
            correspondence: {},
            correspondence_details: {
                search_in: "All Correspondences",
                status: "Read & Unread"
            },
            correspondence_category: this.props.correspondence.single_correspondence.correspondence_category ? this.props.correspondence.single_correspondence.correspondence_category : "Inbox",
            inbox_start: 1,
            inbox_finish: 50,
            sent_start: 1,
            sent_finish: 50,
            draft_start: 1,
            draft_finish: 50,
            archived_start: 1,
            archived_finish: 50,
            tags_dropdown: [],
            contacts_dropdown: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.loadCorrespondence = this.loadCorrespondence.bind(this);
        // this.searchEmail = this.searchEmail.bind(this);
        this.reloadCorrespondence = this.reloadCorrespondence.bind(this);
        this.singleCorrespondence = this.singleCorrespondence.bind(this);
        this.searchCorrespondenceByTags = this.searchCorrespondenceByTags.bind(this);
        this.searchCorrespondence = this.searchCorrespondence.bind(this);
        this.goBack = this.goBack.bind(this);
        this.goForward = this.goForward.bind(this);
        this.compose = this.compose.bind(this);
        this.serverSearchCorrespondence = this.serverSearchCorrespondence.bind(this);
    }

    componentDidMount() {

        // console.log(this.props);

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        // this.props.serverLoad("true");
        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        this.props.serverGetCorrespondence(this.state.correspondence_category, this.state.inbox_start, this.state.inbox_finish, this.props.auth.user.token);

        if (this.props.correspondence.single_correspondence.correspondence_category) {
            this.setState({ correspondence_category: this.props.correspondence.single_correspondence.correspondence_category });
        }

        $(function () {
            //Enable check and uncheck all functionality
            $('.checkbox-toggle').click(function () {
                var clicks = $(this).data('clicks')
                if (clicks) {
                    //Uncheck all checkboxes
                    $('.mailbox-messages input[type=\'checkbox\']').prop('checked', false)
                    $('.checkbox-toggle .far.fa-check-square').removeClass('fa-check-square').addClass('fa-square')
                } else {
                    //Check all checkboxes
                    $('.mailbox-messages input[type=\'checkbox\']').prop('checked', true)
                    $('.checkbox-toggle .far.fa-square').removeClass('fa-square').addClass('fa-check-square')
                }
                $(this).data('clicks', !clicks)
            })

            //Handle starring for font awesome
            $('.mailbox-star').click(function (e) {
                e.preventDefault()
                //detect type
                var $this = $(this).find('a > i')
                var fa = $this.hasClass('fa')

                //Switch states
                if (fa) {
                    $this.toggleClass('fa-star')
                    $this.toggleClass('fa-star-o')
                }
            })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.contacts !== this.props.contacts) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (prevProps.correspondence !== this.props.correspondence) {

            let data = [];

            switch (this.props.correspondence.chosen_category) {
                case 'Inbox':
                    data = this.props.correspondence.correspondence_categories.inbox;
                    // count no of records
                    if (parseInt(data.no_of_records) <= parseInt(this.state.inbox_finish)) {
                        this.setState({ inbox_finish: data.correspondence.length });
                    }

                    if (parseInt(this.state.inbox_finish > parseInt(data.no_of_records))) {
                        this.setState({ inbox_finish: data.no_of_records });
                    }
                    break;

                case 'Sent':
                    data = this.props.correspondence.correspondence_categories.sent;
                    // count no of records
                    if (parseInt(data.no_of_records) <= parseInt(this.state.sent_finish)) {
                        this.setState({ sent_finish: data.correspondence.length });
                    }

                    if (parseInt(this.state.sent_finish > parseInt(data.no_of_records))) {
                        this.setState({ sent_finish: data.no_of_records });
                    }
                    break;

                case 'Drafts':
                    data = this.props.correspondence.correspondence_categories.drafts;
                    // count no of records
                    if (parseInt(data.no_of_records) <= parseInt(this.state.draft_finish)) {
                        this.setState({ draft_finish: data.correspondence.length });
                    }

                    if (parseInt(this.state.draft_finish > parseInt(data.no_of_records))) {
                        this.setState({ draft_finish: data.no_of_records });
                    }
                    break;

                case 'Archived':
                    data = this.props.correspondence.correspondence_categories.archived;
                    // count no of records
                    if (parseInt(data.no_of_records) <= parseInt(this.state.archived_finish)) {
                        this.setState({ archived_finish: data.correspondence.length });
                    }

                    if (parseInt(this.state.archived_finish > parseInt(data.no_of_records))) {
                        this.setState({ archived_finish: data.no_of_records });
                    }
                    break;

                default:
                    break;
            }

            this.setState({ correspondence: data, correspondence_category: this.props.correspondence.chosen_category });
        }


        if (prevProps.correspondence.single_correspondence !== this.props.correspondence.single_correspondence) {
            if (this.props.correspondence.single_correspondence.correspondence_category !== "Drafts") {
                this.props.history.push("/correspondence_details");
            } else {
                this.props.history.push("/compose_correspondence");
            }
        }
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            correspondence_details: {                   // object that we want to update
                ...prevState.correspondence_details,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));

    }

    compose = () => {
        this.props.serverSetChosenCategory("Inbox");
    }

    reloadCorrespondence = () => {
        this.setState({
            correspondence_details: {
                search_in: "All Correspondences",
                status: "Read & Unread"
            }
        });
        $("#collapseTwo").removeClass("show");
        this.props.serverLoad("true");
        this.props.serverGetCorrespondence(this.state.correspondence_category, 1, 50, this.props.auth.user.token);
    }

    singleCorrespondence = (data) => {
        // console.log("bb", data);
        this.props.serverSingleCorrespondence(data.correspondence[0], this.state.correspondence_category, data.trail_id);
        if (this.state.correspondence_category === "Inbox" || this.state.correspondence_category === "Archived") {
            this.props.serverMarkSeen(data.trail_id, this.props.auth.user.token);
        }
    }

    loadCorrespondence = (category) => {

        this.props.serverSetChosenCategory(category);

        // check if category correspondence have already been loaded
        switch (category) {
            case 'Inbox':

                if (this.props.correspondence.correspondence_categories.inbox.correspondence) {
                    this.setState({ correspondence: this.props.correspondence.correspondence_categories.inbox, correspondence_category: category });

                } else {
                    this.setState({ correspondence_category: category })

                    this.props.serverLoad("true");
                    this.props.serverGetCorrespondence(category, this.state.inbox_start, this.state.inbox_finish, this.props.auth.user.token);
                }
                break;

            case 'Sent':
                if (this.props.correspondence.correspondence_categories.sent.correspondence) {
                    this.setState({ correspondence: this.props.correspondence.correspondence_categories.sent, correspondence_category: category });
                } else {
                    this.setState({ correspondence_category: category })

                    this.props.serverLoad("true");
                    this.props.serverGetCorrespondence(category, this.state.sent_start, this.state.sent_finish, this.props.auth.user.token);
                }
                break;

            case 'Drafts':
                if (this.props.correspondence.correspondence_categories.drafts.correspondence) {
                    this.setState({ correspondence: this.props.correspondence.correspondence_categories.drafts, correspondence_category: category });
                } else {
                    this.setState({ correspondence_category: category })

                    this.props.serverLoad("true");
                    this.props.serverGetCorrespondence(category, this.state.draft_start, this.state.draft_finish, this.props.auth.user.token);
                }
                break;

            case 'Archived':
                if (this.props.correspondence.correspondence_categories.archived.correspondence) {
                    this.setState({ correspondence: this.props.correspondence.correspondence_categories.archived, correspondence_category: category });
                } else {
                    this.setState({ correspondence_category: category })

                    this.props.serverLoad("true");
                    this.props.serverGetCorrespondence(category, this.state.archived_start, this.state.archived_finish, this.props.auth.user.token);
                }
                break;

            default:
                break;
        }
    }

    contactDetails = (contact_id) => {
        // eslint-disable-next-line array-callback-return
        // let contact_details = this.props.contacts.contacts.filter((data, index) => {
        //     if (data.contact_id === contact_id) {
        //         return data;
        //     }
        // });

        let contact_details = this.props.contacts.contacts.filter(item => item.contact_id === contact_id);

        if (contact_details.length) {
            contact_details = contact_details[0];
            return `${contact_details.first_name} ${contact_details.last_name}`;
        } else {
            return '';
        }
    }

    searchCorrespondence = (value) => {

        console.log(1, this.state.correspondence);
        // return;

        let to_filter = [];

        switch (this.state.correspondence.correspondence_category) {
            case "Inbox":
                if (this.props.correspondence.correspondence_categories.inbox.correspondence)
                    to_filter = this.props.correspondence.correspondence_categories.inbox.correspondence;
                break;

            case "Sent":
                if (this.props.correspondence.correspondence_categories.sent.correspondence)
                    to_filter = this.props.correspondence.correspondence_categories.sent.correspondence;
                break;

            case "Drafts":
                if (this.props.correspondence.correspondence_categories.drafts.correspondence)
                    to_filter = this.props.correspondence.correspondence_categories.drafts.correspondence;
                break;

            case "Archived":
                if (this.props.correspondence.correspondence_categories.archived.correspondence)
                    to_filter = this.props.correspondence.correspondence_categories.archived.correspondence;
                break;

            default:
                break;
        }

        // eslint-disable-next-line array-callback-return
        let new_correspondence = to_filter.filter((data, index) => {
            data = data.correspondence[0];
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        // console.log(2, new_correspondence);

        this.setState({
            correspondence: {
                correspondence: new_correspondence,
                correspondence_category: this.state.correspondence.correspondence_category
            }
        });
    }

    doCompare = (selected_tags, contacts) => {

        // console.log(contacts);
        // return;

        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i].correspondence[0];
            let cor = contacts[i];
            // console.log("el", el);
            let isValid = false
            if (el.tags) {
                let tags = JSON.parse(el.tags.replace(/'/g, '"'));

                // check if all selected tags exist in contacts
                //  let myValues = {...value}

                for (let ind = 0; ind < tags.length; ind++) {
                    const elt = tags[ind];
                    if (value[elt.value]) {
                        isValid = true;
                        break;
                    }

                    // check if all selected tags exist in contacts
                    // if (myValues[elt.value]) {
                    //     delete myValues[elt.value]
                    // }
                }
            }

            if (isValid) {

                cor['correspondence'] = [el]

                results.push(cor);
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }


    searchCorrespondenceByTags = () => {
        if (this.state.correspondence_details.search_params) {

            let to_filter = [];

            switch (this.state.correspondence.correspondence_category) {
                case "Inbox":
                    if (this.props.correspondence.correspondence_categories.inbox.correspondence)
                        to_filter = this.props.correspondence.correspondence_categories.inbox.correspondence;
                    break;

                case "Sent":
                    if (this.props.correspondence.correspondence_categories.sent.correspondence)
                        to_filter = this.props.correspondence.correspondence_categories.sent.correspondence;
                    break;

                case "Drafts":
                    if (this.props.correspondence.correspondence_categories.drafts.correspondence)
                        to_filter = this.props.correspondence.correspondence_categories.drafts.correspondence;
                    break;

                case "Archived":
                    if (this.props.correspondence.correspondence_categories.archived.correspondence)
                        to_filter = this.props.correspondence.correspondence_categories.archived.correspondence;
                    break;

                default:
                    break;
            }

            let new_correspondence = this.doCompare(this.state.correspondence_details.search_params, to_filter);
            console.log(new_correspondence, this.state.correspondence.correspondence);
            this.setState({
                correspondence: {
                    correspondence: new_correspondence,
                    correspondence_category: this.state.correspondence.correspondence_category
                }
            });

            $("#dismiss_search_params_modal").click();
        }
    }

    goBack = () => {

        let category = this.state.correspondence_category;

        let start, finish = 0;

        switch (category) {
            case "Inbox":
                start = this.state.inbox_start;
                finish = this.state.inbox_finish;

                if (start >= 50) {
                    this.setState({ start: start - 50, finish: finish - 50 });

                    this.props.serverGetCorrespondence(category, start - 50, finish - 50, this.props.auth.user.token);

                }
                break;

            case "Sent":

                start = this.state.sent_start;
                finish = this.state.sent_finish;

                if (start >= 50) {
                    this.setState({ start: start - 50, finish: finish - 50 });

                    this.props.serverGetCorrespondence(category, start - 50, finish - 50, this.props.auth.user.token);

                }

                break;

            case "Drafts":

                start = this.state.draft_start;
                finish = this.state.draft_finish;

                if (start >= 50) {
                    this.setState({ start: start - 50, finish: finish - 50 });

                    this.props.serverGetCorrespondence(category, start - 50, finish - 50, this.props.auth.user.token);

                }

                break;

            case "Archived":

                start = this.state.archived_start;
                finish = this.state.archived_finish;

                if (start >= 50) {
                    this.setState({ start: start - 50, finish: finish - 50 });

                    this.props.serverGetCorrespondence(category, start - 50, finish - 50, this.props.auth.user.token);

                }

                break;

            default:
                break;
        }
    }

    goForward = () => {

        let category = this.state.correspondence_category;

        let start, finish = 0;

        switch (category) {
            case "Inbox":
                start = this.state.inbox_start;
                finish = this.state.inbox_finish;

                if (start >= 50) {
                    this.setState({ start: start + 50, finish: finish + 50 });

                    this.props.serverGetCorrespondence(category, start + 50, finish + 50, this.props.auth.user.token);

                }
                break;

            case "Sent":

                start = this.state.sent_start;
                finish = this.state.sent_finish;

                if (start >= 50) {
                    this.setState({ start: start + 50, finish: finish + 50 });

                    this.props.serverGetCorrespondence(category, start + 50, finish + 50, this.props.auth.user.token);

                }

                break;

            case "Drafts":

                start = this.state.draft_start;
                finish = this.state.draft_finish;

                if (start >= 50) {
                    this.setState({ start: start + 50, finish: finish + 50 });

                    this.props.serverGetCorrespondence(category, start + 50, finish + 50, this.props.auth.user.token);

                }

                break;

            case "Archived":

                start = this.state.archived_start;
                finish = this.state.archived_finish;

                if (start >= 50) {
                    this.setState({ start: start + 50, finish: finish + 50 });

                    this.props.serverGetCorrespondence(category, start + 50, finish + 50, this.props.auth.user.token);

                }

                break;

            default:
                break;
        }
    }

    serverSearchCorrespondence = () => {
        // check entry
        if ((this.state.correspondence_details.date_from && !this.state.correspondence_details.date_to) ||
            (this.state.correspondence_details.date_to && !this.state.correspondence_details.date_from)) {
            toast.error(t("Please specify the date range correctly", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (this.state.correspondence_details.date_from > this.state.correspondence_details.date_to) {
            toast.error(t("Please specify the date range correctly", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverGetFilteredCorrespondence(this.state.correspondence_details, this.props.auth.user.token);

        $("#collapseTwo").removeClass("show");
    }

    clearSearch = () => {
        this.setState({correspondence_details: {
            search_in: "All Correspondences",
            status: "Read & Unread"
        }});
    }

    render() {
        return (
            <>
                <Main>
                    <Correspondence
                        c_state={{ ...this.state }}
                        isLoading={this.props.correspondence.isLoading}
                        handleChange={this.handleChange}
                        auth={this.props.auth}
                        // set_modal_content={this.setModalContent}
                        // search_email={this.searchEmail}
                        // clear_modal_contents={this.clearModalContents}
                        compose={this.compose}
                        search_correspondence_by_tags={this.searchCorrespondenceByTags}
                        reload_correspondence={this.reloadCorrespondence}
                        single_correspondence={this.singleCorrespondence}
                        load_correspondence={this.loadCorrespondence}
                        contact_details={this.contactDetails}
                        search_correspondence={this.searchCorrespondence}
                        go_back={this.goBack}
                        go_forward={this.goForward}
                        server_search_correspondence={this.serverSearchCorrespondence}
                        clear_search={this.clearSearch}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        correspondence: { ...state.correspondence },
        contacts: { ...state.contacts }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        serverGetTags,
        clearData,
        // checkUser,
        serverGetContacts,
        serverGetCorrespondence,
        serverSingleCorrespondence,
        serverMarkSeen,
        serverSetChosenCategory,
        serverGetFilteredCorrespondence
    }
)(ViewCorrespondenceContainer)

