import React, { Component } from 'react'
import Login from './Login'
import { connect } from 'react-redux'
import { serverLogin, serverLoad, serverGoogleLogin, serverMicrosoftLogin } from './../../actions/AuthAction'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { getTrans as t } from '../../translations';
// refresh token
import { refreshTokenSetup } from '../../refreshToken';
import { gapi } from 'gapi-script';
// import { PublicClientApplication } from '@azure/msal-browser';
// import types from '../../types';

class LoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        }

        this.doLogin = this.doLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);
        this.authHandler = this.authHandler.bind(this);

        // this.publicClientApplication = new PublicClientApplication({
        //     auth: {
        //         clientId: types.MICROSOFT_CLIENT_ID,
        //         redirectURL: types.SERVER_URL
        //         // authority: 'https://login.microsoftonline.com/achrafchad.onmicrosoft.com'
        //     },
        //     cache: {
        //         cacheLocation: 'sessionStorage',
        //         storeAuthStateInCookie: true
        //     }
        // });
    }

    onSuccess = (res) => {
        // console.log('Login Success: currentUser:', res.profileObj);
        this.props.serverGoogleLogin({ email: res.profileObj.email, googleId: res.profileObj.googleId })
        // alert(
        //     `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
        // );
        refreshTokenSetup(res);
    };

    onFailure = (res) => {
        // console.log('Login failed: res:', res);
        if (res.error !== "idpiframe_initialization_failed") {
            toast.error(t('Failed to login. 😢', this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });
        }
    };

    authHandler = (err, data, msal) => {
        if (!err && data) {
            if (localStorage.getItem('msal.client.info') && data.uniqueId && data.account.userName) {
                this.props.serverMicrosoftLogin({ email: data.account.userName, microsoftId: data.uniqueId });
                return
            }
        }
        toast.error(t('Failed to login. 😢', this.props.auth.language), {
            position: 'top-center',
            autoClose: 3000
        });

        localStorage.clear();
        window.location.reload();
    }

    // logoutHandler = () => {
    //     this.publicClientApplication.logout();
    // }

    componentDidMount() {

        // check from url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const shareLink = urlParams.get('q');
        if (shareLink) {
            localStorage.setItem("token", shareLink);
            this.props.history.push("/dashboard");
        }

        if (this.props.auth.user && this.props.auth.user.token && this.props.auth.user.token.trim().length) {
            this.props.history.push("/dashboard");
        }

        gapi.load('client:auth2', null);

        // gapi.load('client:auth2', gapi.client.init({
        //     clientId: clientId,
        //     scope: ""
        // }))

        // this.logoutHandler();
    }

    componentDidUpdate(prevProps, prevState) { // this is called anytime there is changes in the props/state

        // console.log(this.props.auth)

        if (prevProps.auth.user !== this.props.auth.user) {
            if (this.props.auth.user !== null) {
                this.props.history.push("/dashboard");
            }
        }

        if (this.props.auth.success === "true") {
            this.props.history.push("/dashboard");
        } else if ((typeof this.props.auth.msg === 'string') && this.props.auth.msg.trim() > 0 && this.props.auth.success === "false") {
            alert(this.props.auth.msg);
        }
    }

    doLogin = () => {

        if (this.state.email === '' || this.state.password === '') {
            toast.error(t('Email and password is required', this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let obj = {
            password: this.state.password,
            email: this.state.email
        }

        this.props.serverLoad("true");
        this.props.serverLogin(obj);
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        return (
            <>
                <Login
                    {...this.state}
                    handleChange={this.handleChange}
                    login_func={this.doLogin}
                    isLoading={this.props.auth.isLoading}
                    onSuccess={this.onSuccess}
                    onFailure={this.onFailure}
                    authHandler={this.authHandler}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { serverLogin, serverLoad, serverGoogleLogin, serverMicrosoftLogin })(LoginContainer)
