import types from '../types'
import axios from 'axios'

export const serverSaveAttachments = (data, token) => {
    return async (dispatch) => {

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'save_attachments',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ATTACHMENTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ATTACHMENTS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                })
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.ATTACHMENTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverClearSavedAttachments = (type, type_id, token) => {
    return async (dispatch) => {

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'clear_saved_attachments',
            data: {
                "type": type,
                "type_id": type_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ATTACHMENTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ATTACHMENTS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                })
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.ATTACHMENTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            })
            return
        })
    }
}


export const serverGetAllFiles = (token) => {
    return async (dispatch) => {

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'fm/get_all_files',
            data: {},
            method: 'post'
        }).then((response) => {
            if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: types.ALL_FILES,
                    payload: response.data
                });
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.ATTACHMENTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverUploadToFm = (data, token) => {
    return async (dispatch) => {

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'upload_to_fm',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ATTACHMENTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ATTACHMENTS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                })
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.ATTACHMENTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            })
            return
        })
    }
}
