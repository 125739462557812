import React from 'react'
import { Link } from "react-router-dom";
// import types from '../../types';
import { getTrans as t } from '../../translations';
import types from '../../types';

const Departments = (props) => {

    // console.log(props.privileges.contact_privileges);

    return (
        <>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">{t('Manage Departments / Reference Numbers', props.auth.language)}</h1>
                            </div>
                            {/* <!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Dept./Reference No', props.auth.language)}</li>
                                </ol>
                            </div>
                            {/* <!-- /.col --> */}
                        </div>
                        {/* <!-- /.row --> */}
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </div>
                {/* <!-- /.content-header --> */}

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="callout callout-info">
                                {/* <h5><i className="fas fa-info"></i> {t('Note', props.auth.language)}:</h5> */}
                                {t('This section allows you to create/manage departments and assign reference numbers to them. Reference numbers can be used in correspondence.', props.auth.language)}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="card" >
                                <div className="card-header">
                                    <h3 className="card-title">{t('Departments', props.auth.language)}</h3>
                                    <div align="right">
                                        <button type="button" className="btn btn-sm btn-info" title={t('Add New Department', props.auth.language)} data-toggle="modal" data-target="#new_department_modal" onClick={props.clear_modal_contents}>
                                            <i className="fas fa-plus"></i> {t('Add New Department', props.auth.language)}
                                        </button>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={props.reload}><i className="fa fa-sync"></i> {t('Reload', props.auth.language)}</button>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-default">
                                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_departments(event.target.value)} />
                                            </div>
                                        </button>&nbsp;
                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        props.departments.isLoading === "true" ?
                                            <div align="center">
                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading some data. Please wait...', props.auth.language)}</p></span>
                                            </div>
                                            :
                                            <table id="example1" className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>{t('Department', props.auth.language)}</th>
                                                        <th>{t('Reference No Format', props.auth.language)}</th>
                                                        <th>{t('Action', props.auth.language)}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        // eslint-disable-next-line array-callback-return
                                                        props.c_state.departments_list.map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{data.title}</td>
                                                                    <td>{data.code}</td>
                                                                    <td className="py-0 align-middle">
                                                                        <div className="btn-group btn-group-sm">
                                                                            <button className="btn btn-info" title={t('Edit Department', props.auth.language)} data-toggle="modal" data-target="#edit_department_modal" onClick={() => props.set_modal_content(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                            <button className="btn btn-danger" title={t('Delete Department', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.title}?`) ? props.delete_department(data.id) : console.log('i')}><i className="fas fa-trash"></i></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- /.content --> */}

                {/* Modals */}

                <div className="modal fade" id="new_department_modal" style={{ overflow: "auto" }}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('New Department', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-12">
                                    <label htmlFor="title">{t('Department', props.auth.language)} *</label>
                                    <input type="text" value={props.c_state.department_details.department ? props.c_state.department_details.department : ''} onChange={(event) => props.handleChange({ "field": "department", "value": event.target.value })} className="form-control" placeholder={t('Department', props.auth.language)} />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="title">{t('Reference No. Format', props.auth.language)} *</label>
                                    <input type="text" value={props.c_state.department_details.reference ? props.c_state.department_details.reference : ''} onChange={(event) => props.handleChange({ "field": "reference", "value": event.target.value })} className="form-control" placeholder={t(types.INSTITUTION_REF_NO, props.auth.language)} />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_new_department_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                <span>{props.departments.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.departments.isLoading === "true" ? true : false} onClick={props.new_department}>{t('Save', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="edit_department_modal" style={{ overflow: "auto" }}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Edit Department', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-12">
                                    <label htmlFor="title">{t('Department', props.auth.language)} *</label>
                                    <input type="text" value={props.c_state.department_details.title ? props.c_state.department_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value })} className="form-control" placeholder={t('Department', props.auth.language)} />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="title">{t('Reference No. Format', props.auth.language)} *</label>
                                    <input type="text" value={props.c_state.department_details.code ? props.c_state.department_details.code : ''} onChange={(event) => props.handleChange({ "field": "code", "value": event.target.value })} className="form-control" placeholder={t('XXX/YY/ZZ/', props.auth.language)} />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_department_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                <span>{props.departments.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.departments.isLoading === "true" ? true : false} onClick={props.edit_department}>{t('Save', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default Departments
