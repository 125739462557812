import types from './../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.CONTACTS_LOADING,
            payload: action
        })
    }
}

export const serverGetContacts = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_contacts',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ALL_CONTACTS,
                    payload: response.data
                });

                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CONTACTS_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.CONTACTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverAddContact = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'add_contact',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CONTACTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CONTACTS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.CONTACTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverDeleteContact = (contact_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'delete_contact',
            data: {
                "contact_id": contact_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CONTACTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CONTACTS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CONTACTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });
            return;
        })
    }
}

export const serverEditContact = (data, token) => {
    // console.log(data);
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'edit_contact',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CONTACTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CONTACTS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CONTACTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });
            return;
        })
    }
}

export const serverGetCompanies = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_companies',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ALL_COMPANIES,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.COMPANY_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.COMPANY_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}


export const serverAddCompany = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'add_company',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.COMPANY_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.COMPANY_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.COMPANY_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverEditCompany = (data, token) => {
    // console.log(data);
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'edit_company',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.COMPANY_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.COMPANY_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.COMPANY_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverDeleteCompany = (company_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'delete_company',
            data: {
                "company_id": company_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.COMPANY_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.COMPANY_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.COMPANY_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetGroups = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_groups',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ALL_GROUPS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.GROUP_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.GROUP_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverAddGroup = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'add_group',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.GROUP_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.GROUP_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.GROUP_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverEditGroup = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'edit_group',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.GROUP_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.GROUP_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.GROUP_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverDeleteGroup = (group_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'delete_group',
            data: {
                "group_id": group_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.GROUP_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.GROUP_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.GROUP_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverUploadContacts = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CONTACTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'upload_contacts',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CONTACTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CONTACTS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CONTACTS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CONTACTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CONTACTS_LOADING,
                payload: "false"
            });
            return
        })
    }
}
