import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: 'false',
    single_route: {},
    single_route_step: [],
    routes: [],
    all_routes: [],
    correspondence_routes: [],
    edit_routes: [],
    edit_route_steps: []
}

const Routes = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.ROUTES_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.ALL_ROUTES:

            newState.all_routes = payload.data;

            return newState;

        case types.ROUTES_SUCCESS:

            if (payload.msg === "Route defined successfully") {
                // eslint-disable-next-line array-callback-return
                newState.single_route = { ...payload.data }
            }

            if (payload.msg === "Route step defined successfully") {
                newState.single_route_step = [...newState.single_route_step, { ...payload.data }]
            }

            if (payload.msg === "Route step deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.single_route_step = newState.single_route_step.filter((data, index) => { // loop through state.todo
                    if (payload.data !== index) { // check what data to update using key
                        return data
                    }
                });

                // eslint-disable-next-line array-callback-return
                newState.edit_route_steps = newState.edit_route_steps.filter((data, index) => { // loop through state.todo
                    if (payload.data !== data.step_id) { // check what data to update using key
                        return data
                    }
                })
            }

            if (payload.msg === "Route step updated successfully") {
                newState.single_route_step = newState.single_route_step.map((data, index) => { // loop through state.todo
                    if (payload.data.index === index) { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                        // console.log("reducer", data);
                    }
                    return data
                });

                // newState.tasks.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
            }

            if (payload.msg === "Route step edited successfully") {
                newState.edit_route_steps = newState.edit_route_steps.map((data, index) => { // loop through state.todo
                    if (payload.data.step_id === data.step_id) { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                        // console.log("reducer", data);
                    }
                    return data
                });
            }

            if (payload.msg === "Route created successfully") {
                newState.routes = [];
                newState.single_route = {};
                newState.single_route_step = [];
            }

            if (payload.msg === "Route deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.all_routes = newState.all_routes.filter((data, index) => { // loop through state.todo
                    if (data.route_id !== payload.data) { // check what data to update using key
                        return data
                    }
                });

                newState.all_routes.sort((a, b) => (a.title > b.title) ? 1 : -1)
            }

            if (payload.msg === "Route selected successfully") {
                newState.edit_routes = payload.data;
                newState.edit_route_steps = payload.data.route_steps;
            }

            // if (payload.msg === "Route has been updated successfully") {
            //     newState.edit_routes = [];
            //     newState.edit_route_steps = [];
            // }

            if (payload.msg === "Route step held successfully") {
                newState.edit_route_steps = [...newState.edit_route_steps, { ...payload.data }];
            }

            if (payload.msg === "Route step moved successfully") {

                newState.edit_route_steps = payload.data;
            }

            if (payload.msg !== "Route defined successfully" && payload.msg !== "Route step defined successfully"
                && payload.msg !== "Route step deleted successfully" && payload.msg !== "Route step updated successfully"
                && payload.msg !== "Route selected successfully" && payload.msg !== "Route step editted successfully"
                && payload.msg !== "Route step held successfully" && payload.msg !== "Route step moved successfully") {
                toast.success(payload.msg, {
                    position: 'top-center'
                });
            }

            return newState;

        case types.ROUTES_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        default:
            return newState;
    }
}

export default Routes