import React from 'react'
import { Link } from 'react-router-dom'
import { getTrans as t } from '../../translations';

const Tags = (props) => {

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Tags', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Tags', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        {/* <div className="col-md-2"></div> */}
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">{t('Manage Tags', props.auth.language)}</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-sm btn-outline-primary" title={t('Reload Tags', props.auth.language)} onClick={props.reload}>
                                            <i className="fas fa-sync"></i> {t('Reload', props.auth.language)}
                                        </button>&nbsp;
                                        {
                                            props.auth.user ?
                                                props.auth.user.privileges.includes("add_tags") ?
                                                    <button type="button" className="btn btn-sm btn-outline-info" title={t('Add New Tag', props.auth.language)} data-toggle="modal" data-target="#add_tag_modal" onClick={props.clear_modal_contents}>
                                                        <i className="fas fa-plus"></i> {t('Add New', props.auth.language)}
                                                    </button>
                                                    : null
                                                : null
                                        }
                                        <button type="button" className="btn btn-sm btn-outline-default">
                                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_tag(event.target.value)} />
                                            </div>
                                        </button>&nbsp;
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{t('Name', props.auth.language)}</th>
                                                <th>{t('Category', props.auth.language)}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.c_state.tag_list ?
                                                // eslint-disable-next-line array-callback-return
                                                props.c_state.tag_list.map((data, index) => {
                                                    return (
                                                        <tr key={data.tid}>
                                                            <td>
                                                                <div id="accordion">
                                                                    <a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>{data.name}</a>
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("view_sub_tags") ?
                                                                                <div id={`collapse${index}`} className="collapse" data-parent={"#accordion"}>
                                                                                    <div className="card-body">
                                                                                        <table className="table">
                                                                                            <tbody>
                                                                                                {
                                                                                                    data.children ?
                                                                                                        // eslint-disable-next-line array-callback-return
                                                                                                        data.children.map((child) => {
                                                                                                            if (child.active === "1") {
                                                                                                                return (
                                                                                                                    <tr key={child.child_id}>
                                                                                                                        <td>{child.name}</td>
                                                                                                                        <td className="text-right py-0 align-middle">
                                                                                                                            <div className="btn-group btn-group-sm">
                                                                                                                                {
                                                                                                                                    props.auth.user ?
                                                                                                                                        props.auth.user.privileges.includes("edit_sub_tags") ?
                                                                                                                                            <>
                                                                                                                                                <button className="btn btn-info" title={t('Edit Sub Tag', props.auth.language)} data-toggle="modal" data-target="#edit_sub_tag_modal" onClick={() => props.set_modal_content(child)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                                                                            </>
                                                                                                                                            : null
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    props.auth.user ?
                                                                                                                                        props.auth.user.privileges.includes("delete_sub_tags") ?
                                                                                                                                            <button className="btn btn-danger" title={t('Delete Sub Tag', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${child.name} ?`) ? props.delete_sub_tag(child.child_id) : ''}><i className="fas fa-trash"></i></button>
                                                                                                                                            : null
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                        : null
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                            : null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>{data.category}</td>
                                                            <td className="text-right">
                                                                <div className="btn-group btn-group-sm">
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("edit_tags") ?
                                                                                <>
                                                                                    <button className="btn btn-success" title={t('Edit Tag', props.auth.language)} data-toggle="modal" data-target="#edit_tag_modal" onClick={() => props.set_modal_content(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                </>
                                                                                : null
                                                                            : null
                                                                    }
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("add_sub_tags") ?
                                                                                <>
                                                                                    <button className="btn btn-info" title={t('Add Sub Tag', props.auth.language)} data-toggle="modal" data-target="#add_sub_tag_modal" onClick={() => props.set_modal_content(data)}><i className="fas fa-plus"></i></button>&nbsp;
                                                                                </>
                                                                                : null
                                                                            : null
                                                                    }
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("delete_tags") ?
                                                                                <button className="btn btn-danger" title={t('Delete Tag', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.name} ?`) ? props.delete_tag(data.tid) : ''}><i className="fas fa-trash"></i></button>
                                                                                : null
                                                                            : null
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* modals */}

                    <div className="modal fade" id="add_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add New Tag', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="name">{t('Tag Name', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.tag_details.name ? props.c_state.tag_details.name : ''} onChange={(event) => props.handleChange({ "field": "name", "value": event.target.value }, "tag")} className="form-control" name="name" placeholder={t('Tag Name', props.auth.language)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Category', props.auth.language)} *</label>
                                            <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, "tag")} value={props.c_state.tag_details.category ? props.c_state.tag_details.category : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="Private">{t('Private', props.auth.language)}</option>
                                                <option value="Public">{t('Public', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_tag_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.tags.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.tags.isLoading === "true" ? true : false} onClick={props.add_tag}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Tag', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="name">{t('Tag Name', props.auth.language)} *</label>
                                            <input type="text" onChange={(event) => props.handleChange({ "field": "name", "value": event.target.value }, "tag")} value={props.c_state.tag_details.name} className="form-control" name="name" placeholder={t('Tag Name', props.auth.language)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Category', props.auth.language)} *</label>
                                            <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, "tag")} value={props.c_state.tag_details.category}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="Private">{t('Private', props.auth.language)}</option>
                                                <option value="Public">{t('Public', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_tag_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.tags.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.tags.isLoading === "true" ? true : false} onClick={props.edit_tag}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="add_sub_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add New Tag Under', props.auth.language)} ({props.c_state.tag_details.name})</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="name">{t('Tag Name', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.sub_tag_details.name ? props.c_state.sub_tag_details.name : ''} onChange={(event) => props.handleChange({ "field": "name", "value": event.target.value }, "sub_tag")} className="form-control" name="name" placeholder={t('Tag Name', props.auth.language)} />
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">Category *</label>
                                            <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value })} value={props.c_state.tag_details.category ? props.c_state.tag_details.category : ''}>
                                                <option value="">--Select--</option>
                                                <option value="Private">Private</option>
                                                <option value="Public">Public</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_sub_tag_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.tags.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.tags.isLoading === "true" ? true : false} onClick={props.add_sub_tag}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_sub_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Sub Tag', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="name">{t('Tag Name', props.auth.language)} *</label>
                                            <input type="text" onChange={(event) => props.handleChange({ "field": "name", "value": event.target.value }, "tag")} value={props.c_state.tag_details.name} className="form-control" name="name" placeholder={t('Tag Name', props.auth.language)} />
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">Category *</label>
                                            <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, "tag")} value={props.c_state.tag_details.category}>
                                                <option value="">--Select--</option>
                                                <option value="Private">Private</option>
                                                <option value="Public">Public</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_sub_tag_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.tags.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.tags.isLoading === "true" ? true : false} onClick={props.edit_sub_tag}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default Tags
