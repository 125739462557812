import types from './../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_LOADING,
            payload: action
        })
    }
}

export const serverKeepRoute = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route defined successfully",
                "data": data
            }
        })
    }
}

export const serverKeepEditRoute = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route selected successfully",
                "data": data
            }
        })
    }
}

export const serverRemoveRouteStep = (index) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route step deleted successfully",
                "data": index
            }
        })
    }
}

export const serverKeepRouteStep = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route step defined successfully",
                "data": data
            }
        })
    }
}

export const serverEditRouteStep = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route step updated successfully",
                "data": data
            }
        })
    }
}

export const serverUpdateRouteStep = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route step edited successfully",
                "data": data
            }
        })
    }
}

export const serverHoldRouteStep = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route step held successfully",
                "data": data
            }
        })
    }
}

export const serverSaveRoute = (route, route_steps, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'save_route',
            data: {
                "route": route,
                "route_steps": route_steps
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ROUTES_SUCCESS,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverFinish = (route, route_steps, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'update_route',
            data: {
                "route": route,
                "route_steps": route_steps
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ROUTES_SUCCESS,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverGetRoutes = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_routes',
            data: {},
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ALL_ROUTES,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverDeleteRoute = (route_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'delete_route',
            data: {
                "route_id": route_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ROUTES_SUCCESS,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverMoveUp = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route step moved up successfully",
                "data": data
            }
        })
    }
}

export const serverMoveOrder = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROUTES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Route step moved successfully",
                "data": data
            }
        })
    }
}

export const serverSaveQuestionnaire = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'save_questionnaire',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ROUTES_SUCCESS,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverGetQuestionnaires = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_questionnaires',
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ROUTES_SUCCESS,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverDeleteQuestionnaire = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'delete_questionnaire',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ROUTES_SUCCESS,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverEditQuestionnaire = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'edit_questionnaire',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ROUTES_SUCCESS,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverAddQuestion = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROUTES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'add_question',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.ROUTES_SUCCESS,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.ROUTES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.ROUTES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.ROUTES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.ROUTES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}
