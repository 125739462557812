import React from 'react'
import { Link } from "react-router-dom";
// import types from '../../types';
import Select from 'react-select'
import { getTrans as t } from '../../translations';

const Privileges = (props) => {

    // console.log(props.privileges.contact_privileges);

    return (
        <>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">{t('Privileges & Access Control', props.auth.language)}</h1>
                            </div>
                            {/* <!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Privileges', props.auth.language)}</li>
                                </ol>
                            </div>
                            {/* <!-- /.col --> */}
                        </div>
                        {/* <!-- /.row --> */}
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </div>
                {/* <!-- /.content-header --> */}

                {/* <!-- Main content --> */}
                <section className="content">
                    {
                        props.privileges.isLoading === "true" ?
                            <div align="center">
                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading some data. Please wait...', props.auth.language)}</p></span>
                            </div>
                            :
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="callout callout-info">
                                            <h5><i className="fas fa-info"></i> {t('Note', props.auth.language)}:</h5>
                                            {t('Select a privilege category to assign individual privileges to users', props.auth.language)}.
                                        </div>

                                        <div className="invoice p-3 mb-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            &nbsp;&nbsp;<label htmlFor="users">{t('User', props.auth.language)} * </label>
                                                            <div className="form-group col-md-12">
                                                                <Select
                                                                    options={props.c_state.contacts_dropdown}
                                                                    name="users"
                                                                    value={props.c_state.privilege_details.users ? typeof props.c_state.privilege_details.users !== "string" ? props.c_state.privilege_details.users : JSON.parse(props.c_state.privilege_details.users.replace(/'/g, '"')) : ''}
                                                                    id="custom_select"
                                                                    onChange={(value) => props.handleChange({ "field": "users", "value": value }, 'privilege_details')}
                                                                    // isMulti
                                                                    style={{ width: '100%' }}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder={t('Users', props.auth.language)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-6">
                                                            &nbsp;&nbsp;<label htmlFor="category">{t('Privilege Category', props.auth.language)} * </label>
                                                            <div className="form-group col-md-12">
                                                                <Select
                                                                    options={props.c_state.privileges_dropdown}
                                                                    name="category"
                                                                    value={props.c_state.privilege_details.category ? typeof props.c_state.privilege_details.category !== "string" ? props.c_state.privilege_details.category : JSON.parse(props.c_state.privilege_details.category.replace(/'/g, '"')) : ''}
                                                                    id="custom_select"
                                                                    onChange={(value) => props.handleChange({ "field": "category", "value": value }, 'privilege_details')}
                                                                    // isMulti
                                                                    style={{ width: '100%' }}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder={t('Category', props.auth.language)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                props.c_state.selected_privilege_category.length ?
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12 table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{t('Privilege', props.auth.language)}</th>
                                                                            {/* <th>Status</th> */}
                                                                            <th>{t('Action', props.auth.language)}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            props.c_state.selected_privilege_category[0].privileges.map((data) => {
                                                                                return (
                                                                                    <tr key={data.id}>
                                                                                        <td>{t(data.description, props.auth.language)}</td>
                                                                                        {/* <td></td> */}
                                                                                        <td>
                                                                                            <div className="icheck-primary">
                                                                                                <input type="checkbox" id="check4" checked={props.c_state.selected_privileges[data.id] ? true : false} onChange={() => props.select_privilege(data)} />
                                                                                                <label htmlFor="check4"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div align="center">
                                                            <button type="button" className="btn btn-primary btn-md" onClick={() => props.save_privileges()}><i className="fa fa-save"></i> {t('Save', props.auth.language)}</button>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                            {
                                                props.c_state.privilege_details.users ?
                                                    <div className='row mt-5'>
                                                        {
                                                            props.c_state.contact_disabled === "0" ?
                                                                <button type="button" className="btn btn-outline-danger btn-md btn-block" onClick={() => window.confirm(`${t('Are you sure you want to disable this user\'s account', props.auth.language)}?`) ? props.disable_account("1") : null}><i className="fa fa-times"></i> {t('Disable', props.auth.language)} {props.c_state.privilege_details.users.label}'s {t('Account', props.auth.language)}</button>
                                                                : props.c_state.contact_disabled === "1" ?
                                                                    <button type="button" className="btn btn-outline-success btn-md btn-block" onClick={() => window.confirm(`${t('Are you sure you want to enable this user\'s account', props.auth.language)}?`) ? props.disable_account("0") : null}><i className="fa fa-check"></i> {t('Enable', props.auth.language)} {props.c_state.privilege_details.users.label}'s {t('Account', props.auth.language)}</button>
                                                                    : console.log(props.c_state.contact_disabled)
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- /.content --> */}

                {/* modals */}

            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default Privileges
