import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    questionnaires: [],
    single_questionnaire: {}
}

const Questionnaires = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.QUESTIONNAIRES_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.QUESTIONNAIRES:

            newState.questionnaires = payload.data;

            if (payload.msg !== "Questionnaires loaded successfully") {
                toast.success(payload.msg, {
                    position: 'top-center'
                });
            }

            return newState;

        case types.QUESTIONNAIRES_SUCCESS:

            if (payload.msg === "Single Questionnaire") {
                newState.single_questionnaire = payload.data;
            }

            return newState;

        case types.QUESTIONNAIRES_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        default:
            return newState;
    }
}

export default Questionnaires