import React, { Component } from 'react'
import TemplateDocs from './TemplateDocs';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { serverGetTags } from '../../actions/TagsAction';
import { serverLoad, clearData } from '../../actions/AuthAction'
import { serverGetDocuments, serverDeleteDocument, serverEditDocument } from '../../actions/TemplatesAction';
import { serverUploadToFm } from '../../actions/AttachmentsAction';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const $ = require("jquery");

class TemplateDocsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            document_list: [],
            search_params: [],
            tags_dropdown: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.editDocument = this.editDocument.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.searchDocs = this.searchDocs.bind(this);
        this.searchDocumentByTags = this.searchDocumentByTags.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.uploadToFm = this.uploadToFm.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.templates.documents.length) {
            this.props.serverGetDocuments(this.props.auth.user.token);
        } else {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.templates !== this.props.templates) {

            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (prevProps.templates.single_document !== this.props.templates.single_document) {
            if (this.props.templates.edit_docs_config.document) {
                this.props.history.push("/edit_document");
            }
        }
    }

    handleChange = (data, type) => {
        switch (type) {
            case 'search_params':
                this.setState({ search_params: data });
                break;
            default:
                break;
        }
    }

    editDocument = (data) => {
        this.props.serverEditDocument(data);
    }

    deleteDocument = (did) => {
        this.props.serverLoad("true");
        this.props.serverDeleteDocument(did, this.props.auth.user.token);
    }

    searchDocs = (value) => {
        // eslint-disable-next-line array-callback-return
        let new_docs = this.props.templates.documents.filter((data, index) => { // loop through state.todo
            // data = data.templates
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        this.setState({ document_list: new_docs });
    }

    reload = () => {
        this.props.serverGetDocuments(this.props.auth.user.token);
        this.props.serverGetTags(this.props.auth.user.token);
    }

    doCompare = (selected_tags, contacts) => {
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i];
            let isValid = false
            if (el.tags) {
                let tags = JSON.parse(el.tags.replace(/'/g, '"'));

                // check if all selected tags exist in contacts
                //  let myValues = {...value}

                for (let ind = 0; ind < tags.length; ind++) {
                    const elt = tags[ind];
                    if (value[elt.value]) {
                        isValid = true;
                        break;
                    }

                    // check if all selected tags exist in contacts
                    // if (myValues[elt.value]) {
                    //     delete myValues[elt.value]
                    // }
                }
            }

            if (isValid) {

                // all['templates'] = el;

                results.push(el);
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }

    searchDocumentByTags = () => {
        if (this.state.search_params) {
            let new_documents = this.doCompare(this.state.search_params, this.props.templates.documents);
            this.setState({ document_list: new_documents });
            $("#dismiss_search_params_modal").click();
            // console.log(this.props.templates.templates, new_templates);
        }
    }

    uploadToFm = (category, id) => {
        let obj = {
            "category": category,
            "id": id
        }
        this.props.serverUploadToFm(obj, this.props.auth.user.token);
    }


    render() {
        return (
            <>
                <Main>
                    <TemplateDocs
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        templates={this.props.templates}
                        handleChange={this.handleChange}
                        search_docs={this.searchDocs}
                        edit_document={this.editDocument}
                        delete_document={this.deleteDocument}
                        reload={this.reload}
                        search_document_by_tags={this.searchDocumentByTags}
                        upload_to_fm={this.uploadToFm}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        templates: { ...state.templates }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverGetDocuments,
        serverDeleteDocument,
        serverEditDocument,
        serverUploadToFm
    }
)(TemplateDocsContainer)
