import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    activities: []
}

const Profile = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.PROFILE_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.PROFILE_SUCCESS:

            if (payload.msg === "Activity loaded successfully") {
                newState.activities = payload.data;
            }

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        case types.PROFILE_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        default:
            return newState;
    }
}

export default Profile