import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { getTrans as t } from '../../translations';

const RouteSteps = (props) => {

    // console.log("steps", props.c_state.route_step_details.recipient);

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Route Steps', props.auth.language)} ({props.routes.single_route.title})</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Route Steps', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">{t('Define Route Steps', props.auth.language)}</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-sm btn-warning" title={t('Add New Route Step', props.auth.language)} data-toggle="modal" data-target="#new_route_step_modal" onClick={props.clear_modal_contents}>
                                            <i className="fas fa-plus"></i> {t('Add New Step', props.auth.language)}
                                        </button>
                                        <button type="button" className="btn btn-sm btn-outline-default">
                                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_tag(event.target.value)} />
                                            </div>
                                        </button>&nbsp;
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{t('Recipient', props.auth.language)}</th>
                                                <th>{t('Deadline', props.auth.language)}</th>
                                                <th>{t('Auto Forward', props.auth.language)}</th>
                                                <th>{t('Can Close', props.auth.language)}</th>
                                                <th>{t('Questionnaire', props.auth.language)}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.routes.single_route_step ?
                                                // eslint-disable-next-line array-callback-return
                                                props.routes.single_route_step.map((data, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{data.recipient.label}</td>
                                                            <td>{data.deadline}</td>
                                                            <td>{t(data.autoforward, props.auth.language)}</td>
                                                            <td>{t(data.can_close, props.auth.language)}</td>
                                                            <td><Link to="#" data-toggle="modal" data-target="#view_questions_modal" onClick={() => props.set_modal_content(data, i)}>{props.questionnaire_title(data.questionnaire)}</Link></td>
                                                            <td className="text-right py-0 align-middle">
                                                                <div className="btn-group btn-group-sm">
                                                                    <button className="btn btn-info" title={t('Edit Route Step', props.auth.language)} data-toggle="modal" data-target="#edit_route_step_modal" onClick={() => props.set_modal_content(data, i)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                    <button className="btn btn-danger" title={t('Delete Route Step', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)}?`) ? props.delete_route_step(i) : console.log(i)}><i className="fas fa-trash"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.routes.single_route_step.length ?
                        <div align="center">
                            <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                            <button className="btn btn-primary" title="Save route" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.save_route}><i className="fas fa-save"></i> {t('Finish', props.auth.language)}</button>
                        </div>
                        : null}
                    <br />
                </section>

                {/* Modals */}

                <div className="modal fade" id="new_route_step_modal" style={{ overflow: "auto" }}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('New Route Step', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-12">
                                    <label htmlFor="recipient">{t('Recipient', props.auth.language)} *</label>
                                    <Select
                                        options={props.c_state.contacts_dropdown}
                                        name="recipient"
                                        value={props.c_state.route_step_details.recipient ? typeof props.c_state.route_step_details.recipient !== "string" ? props.c_state.route_step_details.recipient : JSON.parse(props.c_state.route_step_details.recipient.replace(/'/g, '"')) : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "recipient", "value": value })}
                                        // isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={t('To', props.auth.language)}
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="cc">CC</label>
                                    <Select
                                        options={props.c_state.contacts_dropdown}
                                        name="cc"
                                        value={props.c_state.route_step_details.cc ? typeof props.c_state.route_step_details.cc !== "string" ? props.c_state.route_step_details.cc : JSON.parse(props.c_state.route_step_details.cc.replace(/'/g, '"')) : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "cc", "value": value })}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="CC"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="deadline">{t('Deadline', props.auth.language)} ({t('Hours', props.auth.language)}) *</label>
                                    <input type="text" value={props.c_state.route_step_details.deadline ? props.c_state.route_step_details.deadline : ''} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value })} className="form-control" name="deadline" placeholder={t('Deadline', props.auth.language)} />
                                </div>
                                {/* <div className="form-group col-12">
                                    <label htmlFor="expected_response_date">{t('Expected Response Date', props.auth.language)} *</label>
                                    <input type="date" value={props.c_state.route_step_details.expected_response_date ? props.c_state.route_step_details.expected_response_date : ''} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value })} className="form-control" name="expected_response_date" placeholder={t('Expected Response Date', props.auth.language)} />
                                </div> */}
                                <div className="form-group col-12">
                                    <label htmlFor="autoforward">{t('Auto Forward', props.auth.language)} *</label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "autoforward", "value": event.target.value })} value={props.c_state.route_step_details.autoforward ? props.c_state.route_step_details.autoforward : ""}>
                                        <option value=""></option>
                                        <option value="Yes">{t('Yes', props.auth.language)}</option>
                                        <option value="No">{t('No', props.auth.language)}</option>
                                    </select>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="can_close">{t('Can Close', props.auth.language)} *</label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value })} value={props.c_state.route_step_details.can_close ? props.c_state.route_step_details.can_close : ""}>
                                        <option value=""></option>
                                        <option value="Yes">{t('Yes', props.auth.language)}</option>
                                        <option value="No">{t('No', props.auth.language)}</option>
                                    </select>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="can_close">Questionnaire </label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "questionnaire", "value": event.target.value })} value={props.c_state.route_step_details.questionnaire ? props.c_state.route_step_details.questionnaire : ""}>
                                        <option value=""></option>
                                        {
                                            props.questionnaires.questionnaires.map((data, index) => {
                                                return (
                                                    data.usage_type === null || data.usage_type === "" ?
                                                        <option value={data.questionnaire_id}>{data.title}</option>
                                                        : null
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_new_route_step_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.new_route_step}>{t('Save', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="edit_route_step_modal" style={{ overflow: "auto" }}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Edit Route Step', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-12">
                                    <label htmlFor="recipient">{t('Recipient', props.auth.language)} *</label>
                                    <Select
                                        options={props.c_state.contacts_dropdown}
                                        name="recipient"
                                        value={props.c_state.route_step_details.recipient}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "recipient", "value": value })}
                                        // isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={t('To', props.auth.language)}
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="cc">CC</label>
                                    <Select
                                        options={props.c_state.contacts_dropdown}
                                        name="cc"
                                        value={props.c_state.route_step_details.cc}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "cc", "value": value })}
                                        // isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="CC"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="deadline">{t('Deadline', props.auth.language)} (hours) *</label>
                                    <input type="text" value={props.c_state.route_step_details.deadline} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value })} className="form-control" name="deadline" placeholder={t('Deadline', props.auth.language)} />
                                </div>
                                {/* <div className="form-group col-12">
                                    <label htmlFor="expected_response_date">{t('Expected Response Date', props.auth.language)} *</label>
                                    <input type="date" value={props.c_state.route_step_details.expected_response_date} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value })} className="form-control" name="expected_response_date" placeholder={t('Expected Response Date', props.auth.language)} />
                                </div> */}
                                <div className="form-group col-12">
                                    <label htmlFor="autoforward">{t('Auto Forward', props.auth.language)} *</label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "autoforward", "value": event.target.value })} value={props.c_state.route_step_details.autoforward}>
                                        <option value=""></option>
                                        <option value="Yes">{t('Yes', props.auth.language)}</option>
                                        <option value="No">{t('No', props.auth.language)}</option>
                                    </select>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="can_close">{t('Can Close', props.auth.language)} *</label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value })} value={props.c_state.route_step_details.can_close}>
                                        <option value=""></option>
                                        <option value="Yes">{t('Yes', props.auth.language)}</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_route_step_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.edit_route_step}>{t('Save', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="view_questions_modal" style={{ overflow: "auto" }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('View Questions', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 row">
                                    {
                                        props.questionnaire_info(props.c_state.route_step_details.questionnaire).questionnaire_id ?
                                            props.questionnaire_info(props.c_state.route_step_details.questionnaire).questions.map((question, i) => {
                                                return (
                                                    <div key={i} className="form-group col-md-4">
                                                        <label htmlFor={question.question}>{question.question} {question.required === "Yes" ? "*" : null}</label><br />
                                                        {
                                                            question.field_type === "Text Field" ?
                                                                <textarea className="form-control" />
                                                                :
                                                                question.field_type === "Option Buttons" ?
                                                                    <>
                                                                        {
                                                                            props.explode(question.field_options).map((item, ind) => {
                                                                                return (
                                                                                    <><label key={ind}>{item.replace(/ /g, '')} <input type="radio" name={question.question} disabled /></label>&nbsp;&nbsp;</>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                    :
                                                                    question.field_type === "Dropdown Select" ?
                                                                        <select className="form-control">
                                                                            <option value="" selected disabled></option>
                                                                            {
                                                                                props.explode(question.field_options).map((item, ind) => {
                                                                                    return (
                                                                                        <option value={item.replace(/ /g, '')}>{item.replace(/ /g, '')}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        : null
                                                        }
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="modal-footer justify-content-right">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_view_questions_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default RouteSteps
