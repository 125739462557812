import fr from './french';
import en from './english';
const translations = {
  fr,
  en
};
const getTrans = (
  key,
  locale = 'en'
) => {
  const currTranslation = translations[locale] ? translations[locale] : en;
  let transWord = currTranslation[key]
    ? currTranslation[key]
    : en[key]
    ? en[key]
    : key;

  return transWord;
};

export { getTrans };