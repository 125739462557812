import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    all_files: []
}

const Attachments = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.ATTACHMENTS_SUCCESS:

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        case types.ALL_FILES:

            newState.all_files = payload;

            return newState;
        
        case types.ATTACHMENTS_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        default:
            return newState;
    }
}

export default Attachments