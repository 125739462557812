import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { getTrans as t } from '../../translations';

const EditRoute = (props) => {

    // console.log("route_steps", props.contact_tags(2));

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Edit Route', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item"><Link to="/view_routes">{t('View Routes', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Edit Route', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="col-md-12">
                        <div className="card" >
                            <div className="card-header">
                                <h3 className="card-title">{t('Make changes to', props.auth.language)} {props.routes.edit_routes.title} </h3>

                                <div className="card-tools">
                                    {props.routes.edit_route_steps ?
                                        <div align="center">
                                            <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                                            <button className="btn btn-sm btn-outline-primary" title={t('Save Route', props.auth.language)} disabled={props.routes.isLoading === "true" ? true : false} onClick={props.finish}><i className="fas fa-save"></i> {t('Finish', props.auth.language)}</button> &nbsp;&nbsp;
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    props.c_state.route_details.correspondence_id ?
                                        <>
                                            <p>{t('Correspondence Subject', props.auth.language)}: <strong>{props.c_state.route_details.correspondence_details.subject}</strong></p>
                                            <p>{t('Correspondence ID', props.auth.language)}: <Link to="#">#{props.c_state.route_details.correspondence_id}#</Link></p>
                                        </>
                                        : null
                                }
                                <div className="form-group col-12">
                                    <label htmlFor="title">{t('Route Name', props.auth.language)} *</label>
                                    <input type="text" value={props.c_state.route_details.title ? props.c_state.route_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value }, "routes")} className="form-control" name="title" placeholder={t('Route Name', props.auth.language)} />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="title">{t('Category', props.auth.language)} *</label>
                                    <select className="form-control" value={props.c_state.route_details.category ? props.c_state.route_details.category : ''} onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, "routes")}>
                                        <option value="" disabled>{t('Select Category', props.auth.language)}</option>
                                        <option value="Private">{t('Private', props.auth.language)}</option>
                                        {
                                            props.auth.user ?
                                                props.auth.user.privileges.includes("add_public_routes") ?
                                                    <option value="Public">{t('Public', props.auth.language)}</option>
                                                    : null
                                                : null
                                        }
                                    </select>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        value={props.c_state.route_details.tags ? typeof props.c_state.route_details.tags !== "string" ? props.c_state.route_details.tags : JSON.parse(props.c_state.route_details.tags.replace(/'/g, '"')) : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "tags", "value": value }, "routes")}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select" />
                                </div>
                                <br />
                                <hr />
                                <div className="card-header">
                                    <h3 className="card-title">{t('Route Steps', props.auth.language)} </h3>

                                    <div className="card-tools">
                                        <button className="btn btn-sm btn-outline-info" title={t('Add Route Step', props.auth.language)} data-toggle="modal" data-target="#add_route_step_modal" onClick={props.clear_modal_contents}><i className="fas fa-plus"></i> {t('Add Route Step', props.auth.language)}</button>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t('Recipient', props.auth.language)}</th>
                                            <th>{t('Deadline', props.auth.language)} ({t('Hours', props.auth.language)})</th>
                                            <th>{t('Auto Forward', props.auth.language)}</th>
                                            <th>{t('Can Close', props.auth.language)}</th>
                                            <th>{t('Questionnaire', props.auth.language)}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.routes.edit_route_steps ?
                                            // eslint-disable-next-line array-callback-return
                                            props.routes.edit_route_steps.map((data, i) => {
                                                return (
                                                    <tr key={i} style={props.c_state.route_details.correspondence_details ? props.c_state.route_details.correspondence_details.current_step_order === data.ordering ? { color: 'red', fontWeight: 'bolder' } : null : null}>
                                                        <td>{i + 1}</td>
                                                        <td>{props.contact_details(data.user)}</td>
                                                        <td>{data.deadline}</td>
                                                        <td>{t(data.auto_forward, props.auth.language)}</td>
                                                        <td>{t(data.can_close, props.auth.language)}</td>
                                                        <td><Link to="#" data-toggle="modal" data-target="#view_questions_modal" onClick={() => props.set_modal_content(data)}>{props.questionnaire_title(data.questionnaire)}</Link></td>
                                                        <td className="align-middle">
                                                            <div className="btn-group btn-group-sm">
                                                                <button className="btn btn-info" title={t('Edit Route Step', props.auth.language)} data-toggle="modal" data-target="#edit_route_step_modal" onClick={() => props.set_modal_content(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                <button className="btn btn-danger" title={t('Delete Route Step', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)}?`) ? props.delete_route_step(data.step_id) : console.log(i)}><i className="fas fa-trash"></i></button>&nbsp;
                                                                {i !== 0 ?
                                                                    <><button className="btn btn-default" title={t('Move Up', props.auth.language)} onClick={() => props.move_up(data)}><i className="fas fa-arrow-up"></i></button>&nbsp;</>
                                                                    : null}
                                                                {props.routes.edit_route_steps.length - 1 !== i ?
                                                                    <><button className="btn btn-default" title={t('Move Down', props.auth.language)} onClick={() => props.move_down(data)}><i className="fas fa-arrow-down"></i></button>&nbsp;</>
                                                                    : null}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : null
                                        }
                                    </tbody>
                                </table>
                                <hr />
                                {props.routes.edit_route_steps ?
                                    <div align="center">
                                        <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                                        <button className="btn btn-primary" title={t('Save Route', props.auth.language)} disabled={props.routes.isLoading === "true" ? true : false} onClick={props.finish}><i className="fas fa-save"></i> {t('Finish', props.auth.language)}</button>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>

                    {/* Modals */}

                    <div className="modal fade" id="add_route_step_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('New Route Step', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="user">{t('Recipient', props.auth.language)} *</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="user"
                                            value={props.c_state.route_step_details.user ? typeof props.c_state.route_step_details.user !== "string" ? props.c_state.route_step_details.user : JSON.parse(props.c_state.route_step_details.user.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "user", "value": value }, "route_steps")}
                                            // isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder={t('To', props.auth.language)}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="cc">CC</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="cc"
                                            value={props.c_state.route_step_details.cc ? typeof props.c_state.route_step_details.cc !== "string" ? props.c_state.route_step_details.cc : JSON.parse(props.c_state.route_step_details.cc.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "cc", "value": value }, "route_steps")}
                                            // isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="CC"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="deadline">{t('Deadline', props.auth.language)} ({t('Hours', props.auth.language)}) *</label>
                                        <input type="text" value={props.c_state.route_step_details.deadline ? props.c_state.route_step_details.deadline : ''} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value }, "route_steps")} className="form-control" name="deadline" placeholder={t('Deadline', props.auth.language)} />
                                    </div>
                                    {/* <div className="form-group col-12">
                                        <label htmlFor="expected_response_date">Expected Response Date *</label>
                                        <input type="date" value={props.c_state.route_step_details.expected_response_date ? props.c_state.route_step_details.expected_response_date : ''} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value }, "route_steps")} className="form-control" name="expected_response_date" placeholder="Expected Response Date" />
                                    </div> */}
                                    <div className="form-group col-12">
                                        <label htmlFor="auto_forward">{t('Auto Forward', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "auto_forward", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.auto_forward ? props.c_state.route_step_details.auto_forward : ""}>
                                            <option value=""></option>
                                            <option value="Yes">{t('Yes', props.auth.language)}</option>
                                            <option value="No">{t('No', props.auth.language)}</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="can_close">Can Close *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.can_close ? props.c_state.route_step_details.can_close : ""}>
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="can_close">Questionnaire </label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "questionnaire", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.questionnaire ? props.c_state.route_step_details.questionnaire : ""}>
                                            <option value=""></option>
                                            {
                                                props.questionnaires.questionnaires.map((data, index) => {
                                                    return (
                                                        data.usage_type === null || data.usage_type === "" ?
                                                            <option value={data.questionnaire_id}>{data.title}</option>
                                                            : null
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_route_step_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                    <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.add_route_step}>{t('Save', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_route_step_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Route Step', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="user">{t('Recipient', props.auth.language)} *</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="user"
                                            value={props.c_state.route_step_details.user ? props.contact_tags(props.c_state.route_step_details.user, "user") : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "user", "value": value }, "route_steps")}
                                            // isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder={t('To', props.auth.language)}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="cc">CC</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="cc"
                                            value={props.c_state.route_step_details.cc ? props.contact_tags(props.c_state.route_step_details.cc, "cc") : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "cc", "value": value }, "route_steps")}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="CC"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="deadline">{t('Deadline', props.auth.language)} ({t('Hours', props.auth.language)}) *</label>
                                        <input type="text" value={props.c_state.route_step_details.deadline} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value }, "route_steps")} className="form-control" name="deadline" placeholder={t('Deadline', props.auth.language)} />
                                    </div>
                                    {/* <div className="form-group col-12">
                                        <label htmlFor="expected_response_date">Expected Response Date *</label>
                                        <input type="date" value={props.c_state.route_step_details.expected_response_date} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value }, "route_steps")} className="form-control" name="expected_response_date" placeholder="Expected Response Date" />
                                    </div> */}
                                    <div className="form-group col-12">
                                        <label htmlFor="auto_forward">{t('Auto Forward', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "auto_forward", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.auto_forward}>
                                            <option value=""></option>
                                            <option value="Yes">{t('Yes', props.auth.language)}</option>
                                            <option value="No">{t('No', props.auth.language)}</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="can_close">{t('Can Close', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.can_close}>
                                            <option value=""></option>
                                            <option value="Yes">{t('Yes', props.auth.language)}</option>
                                            <option value="No">{t('No', props.auth.language)}</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="can_close">Questionnaire </label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "questionnaire", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.questionnaire}>
                                            <option value=""></option>
                                            {
                                                props.questionnaires.questionnaires.map((data, index) => {
                                                    return (
                                                        data.usage_type === null || data.usage_type === "" ?
                                                            <option value={data.questionnaire_id}>{data.title}</option>
                                                            : null
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_route_step_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                    <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.edit_route_step}>{t('Save', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="view_questions_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('View Questions', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="col-12 row">
                                        {
                                            props.questionnaire_info(props.c_state.route_step_details.questionnaire).questionnaire_id ?
                                                props.questionnaire_info(props.c_state.route_step_details.questionnaire).questions.map((question, i) => {
                                                    return (
                                                        <div key={i} className="form-group col-md-4">
                                                            <label htmlFor={question.question}>{question.question} {question.required === "Yes" ? "*" : null}</label><br />
                                                            {
                                                                question.field_type === "Text Field" ?
                                                                    <textarea className="form-control" />
                                                                    :
                                                                    question.field_type === "Option Buttons" ?
                                                                        <>
                                                                            {
                                                                                props.explode(question.field_options).map((item, ind) => {
                                                                                    return (
                                                                                        <><label key={ind}>{item.replace(/ /g, '')} <input type="radio" name={question.question} disabled /></label>&nbsp;&nbsp;</>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                        :
                                                                        question.field_type === "Dropdown Select" ?
                                                                            <select className="form-control">
                                                                                <option value="" selected disabled></option>
                                                                                {
                                                                                    props.explode(question.field_options).map((item, ind) => {
                                                                                        return (
                                                                                            <option value={item.replace(/ /g, '')}>{item.replace(/ /g, '')}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                            : null
                                                            }
                                                        </div>
                                                    )
                                                })
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-right">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_view_questions_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default EditRoute