import React, { Component } from 'react'

import NavBar from './NavBar';
import SideBar from './SideBar';
import Footer from './Footer';
import { connect } from 'react-redux';
import { checkUser, serverSwitchAccount, serverChangeLanguage } from './../../actions/AuthAction';
import { serverGetContacts, serverGetCompanies, serverGetGroups } from './../../actions/ContactsAction';
import { serverGetAllFiles } from '../../actions/AttachmentsAction';
import { serverGetCorrespondence } from '../../actions/CorrespondenceAction';
import {  serverGetDepartments } from '../../actions/DepartmentsAction'
import { withRouter } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import types from '../../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            other_accounts: [],
            // socket: socketIOClient(types.SOCKET_URL, { transports: ['websocket'] })
            socket: socketIOClient.connect(types.SOCKET_URL),
            correspondence_category: this.props.correspondence.single_correspondence.correspondence_category ? this.props.correspondence.single_correspondence.correspondence_category : "Inbox",
            start: 1,
            finish: 100,
        }
    }

    componentDidMount() {

        this.props.checkUser(localStorage.getItem("token"));

        // if (localStorage.getItem("first")) {
        //     localStorage.removeItem("first");
        //     window.location.reload();
        // }

        this.initiateSocket();

        if (!this.props.contacts.contacts.length) {
            if (this.props.auth.user) {
                this.props.serverGetContacts(this.props.auth.user.token);
                this.props.serverGetCompanies(this.props.auth.user.token);
                this.props.serverGetGroups(this.props.auth.user.token);
                this.props.serverGetDepartments(this.props.auth.user.token);
            }
        }

        if (!this.props.attachments.all_files.length) {
            if (this.props.auth.user) {
                this.props.serverGetAllFiles(this.props.auth.user.token);
            }
        }

        // window.addEventListener('click', function(e) {
        //     let ev = window.document.activeElement;
        //     let account_switch = window.document.getElementById("accounts-switch");

        //     console.log('ev', account_switch.classList.contains("d-none"));

        //     if (ev.id !== "accounts-switch" && !account_switch.classList.contains("d-none")) {
        //         account_switch.classList.add("d-none");
        //     }
        // })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.auth !== this.props.auth) {
            if (localStorage.getItem("first")) {
                localStorage.removeItem("first");
                window.location.reload();
            }
        }
    }

    initiateSocket = () => {
        // listen on socket
        this.state.socket.on('connect', (data) => {
            // console.log('socket connected');
            if (this.props.auth.user) {
                this.state.socket.on(`correspondence_notification_${this.props.auth.user.contact_id}`, data => {
                    try {
                        if (data) {
                            toast.dark(data.msg, {
                                position: 'top-right',
                                autoClose: 5000
                            });

                            // switch and perform an action based of response from socket

                            switch (data.type) {
                                case "incoming_correspondence":
                                    this.props.serverGetCorrespondence(this.state.correspondence_category, this.state.start, this.state.finish, this.props.auth.user.token);
                                    break;

                                default:
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });
            }
        });

        this.state.socket.on('disconnect', (data) => {
            // console.log('socket disconnected');
        });
    }

    otherAccounts = () => {
        if (this.props.auth.user) {
            if (this.props.auth.user.other_accounts && this.props.auth.user.other_accounts !== "None") {
                // console.log(this.props.auth.user.other_accounts);
                let other_accounts = JSON.parse(this.props.auth.user.other_accounts.replace(/'/g, '"'));
                // let arr = []
                // console.log("0", other_accounts);
                // other_accounts.filter((data, index) => {
                //     arr.push(parseInt(data.value));
                // });
                // let contacts = this.props.contacts.contacts.filter(item => item.contact_id + '' === other_accounts.value + '');
                return other_accounts;
            }
        }

        return [];
    }

    logout = () => {
        localStorage.clear();
        window.location.reload();
    }

    switchAccount = (data) => {
        this.props.serverSwitchAccount({ contact_id: data }, this.props.auth.user.token);
    }

    toggleSwitch = () => {
        let account_switch = window.document.getElementById("accounts-switch");

        if (account_switch && account_switch.classList.contains("d-none")) {
            account_switch.classList.remove("d-none");
        } else {
            account_switch.classList.add("d-none");
        }
    }

    hideSideBar = () => {
        document.body.classList.add('sidebar-closed');
        document.body.classList.remove('sidebar-open');
    }

    changeLanguage = (language) => {
        this.props.serverChangeLanguage(language);
    }

    render() {
        return (
            <div className="hold-transition sidebar-mini">
                {/* Site wrapper */}
                <div className="wrapper">
                    <NavBar
                        user={this.props.auth.user ? this.props.auth.user : ''}
                        auth={this.props.auth}
                        logout={this.logout}
                        otherAccounts={this.otherAccounts()}
                        switchAccount={this.switchAccount}
                        contacts={this.props.contacts.contacts}
                        all_files={this.props.attachments.all_files}
                        changeLanguage={this.changeLanguage}
                    />
                    <SideBar
                        user={this.props.auth.user ? this.props.auth.user : ''}
                        auth={this.props.auth}
                        logout={this.logout}
                        active={this.props.location.pathname}
                        otherAccounts={this.otherAccounts()}
                        switchAccount={this.switchAccount}
                        toggleSwitch={this.toggleSwitch}
                    />
                    <div onClick={this.hideSideBar}>
                        {
                            this.props.children
                        }

                    </div>
                    <Footer />

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        contacts: { ...state.contacts },
        attachments: { ...state.attachments },
        correspondence: { ...state.correspondence }
    }
}

export default connect(mapStateToProps,
    {
        checkUser,
        serverChangeLanguage,
        serverGetContacts,
        serverGetCompanies,
        serverGetGroups,
        serverGetDepartments,
        serverSwitchAccount,
        serverGetAllFiles,
        serverGetCorrespondence
    }
)(withRouter(Main))
