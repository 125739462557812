import React from 'react'
import { Link } from 'react-router-dom'
import types from '../../types';
import Select from 'react-select';
import { getTrans as t } from '../../translations';

const ViewTemplates = (props) => {

    // console.log("tview", props.c_state.template_list);

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('View Templates', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('View Templates', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div hidden>
                        <button type="button" className="btn btn-xs btn-primary" id="btn-show-doc-modal" data-toggle="modal" data-target="#show_document_modal">{t('Show Modal', props.auth.language)}</button>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">{t('Create Documents Using Templates', props.auth.language)}</h3>
                                    <div align="right">
                                        <button type="button" className="btn btn-sm btn-outline-warning" onClick={props.reload}><i className="fa fa-sync"></i> {t('Reload', props.auth.language)}</button>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-success" data-toggle="modal" data-target="#search_tag_modal"><i className="fa fa-filter"></i> {t('Filter By Tags', props.auth.language)}</button>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-default">
                                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_template(event.target.value)} />
                                                <div className="input-group-append">
                                                    <Link to="#" type="submit" disabled className="btn btn-sm btn-default">
                                                        <i className="fa fa-search"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                {
                                    props.templates.isLoading === "true" ?
                                        <div align="center">
                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your templates. Please wait...', props.auth.language)}</p></span>
                                        </div>
                                        :
                                        <div className="card-body col-12 row" style={{ margin: 10 }}>
                                            {
                                                props.c_state.template_list ?
                                                    // eslint-disable-next-line array-callback-return
                                                    props.c_state.template_list.map((data) => {
                                                        return (
                                                            <div className="col-2 card" key={data.templates.tid}>
                                                                {/* <Link to="#" title="Use Template" data-toggle="modal" data-target="#use_template_modal" onClick={() => props.set_modal_content(data)}><span className="mailbox-attachment-icon"><i className={data.templates.ext === ".docx" ? "far fa-file-word" : "far fa-file"}></i></span></Link> */}
                                                                <Link to="#" title={t('Preview Template', props.auth.language)} data-toggle="modal" data-target="#template_preview_modal" onClick={() => props.set_preview_content(data)}><img alt="screenshot" width="100" height="120" src={`${types.SERVER_URL}static/templates/${data.templates.tid}.png`} /></Link>

                                                                <div className="mailbox-attachment-info1">
                                                                    <Link style={{ fontSize: 14 }} to="#" className="mailbox-attachment-name" title={t('Use Template', props.auth.language)} data-toggle="modal" data-target="#use_template_modal" onClick={() => props.set_modal_content(data)}> {data.templates.template_name}{data.templates.ext}</Link>
                                                                    <span className="mailbox-attachment-size clearfix mt-1 mb-1">
                                                                        {/* <span>1,245 KB</span> */}
                                                                        <a href={`${types.SERVER_URL}static/templates/${data.templates.tid}${data.templates.ext}`} title={t('Download Template', props.auth.language)} target="download_template" className="btn btn-default btn-sm float-right"><i className="fas fa-download"></i></a>
                                                                        <button className="btn btn-default btn-sm float-right" title={t('Delete Template', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.templates.template_name} ?`) ? props.delete_template(data.templates.tid) : ''}><i className="fas fa-trash"></i></button>
                                                                        <button className="btn btn-default btn-sm float-right" title={t('Clone Template', props.auth.language)} data-toggle="modal" data-target="#clone_template_modal" onClick={() => props.set_modal_content(data)}><i className="fas fa-copy"></i></button>
                                                                        <button className="btn btn-default btn-sm float-right" title={t('Edit Template', props.auth.language)} onClick={() => props.edit_template(data)}><i className="fas fa-edit"></i></button>
                                                                        {/* <Link to="#" className="btn btn-default btn-sm float-right" title="Upload to File Manager" onClick={() => props.upload_to_fm("templates", data.templates.tid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link> */}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>


                    {/* modals will come here */}

                    <div className="modal fade" id="use_template_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Use Template', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="template_name">{t('Document Name', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.document_name ? props.c_state.document_name : ''} onChange={(event) => props.handleChange(event.target.value, 'document_name')} className="form-control" name="document_name" placeholder={t('Document Name', props.auth.language)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Tags', props.auth.language)} </label>
                                            <Select
                                                options={props.c_state.tags_dropdown}
                                                name="tags"
                                                value={props.c_state.tags ? typeof props.c_state.tags !== "string" ? props.c_state.tags : JSON.parse(props.c_state.tags.replace(/'/g, '"')) : ''}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange(value, 'tags')}
                                                isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder={t('Select', props.auth.language)}
                                            />
                                        </div>
                                    </div>
                                    <p style={{ textDecoration: 'underline' }}>{t('Please answer the ff questions to generate your document', props.auth.language)}</p>
                                    {
                                        props.c_state.template_details.placeholders ?
                                            // eslint-disable-next-line array-callback-return
                                            props.c_state.template_details.placeholders.map((data) => {
                                                let options = []
                                                if (data.type === "drop_down") {
                                                    if (data.options) {
                                                        options = data.options.split(',')
                                                    }
                                                }

                                                return (
                                                    <div className="row" key={data.pid}>
                                                        <div className="form-group col-12">
                                                            <label htmlFor="template_name">{data.question}</label>
                                                            {
                                                                data.type === "drop_down" ?
                                                                    data.options ?
                                                                        <select className="form-control" name={data.placeholder} onChange={(event) => props.handleChange({ "field": data.placeholder, "value": event.target.value }, 'answers')} value={props.c_state.answers[data.placeholder] ? props.c_state.answers[data.placeholder] : ''}>
                                                                            <option value=""></option>
                                                                            {
                                                                                options.map((o, i) => {
                                                                                    return (<option key={i} value={o}>{o}</option>)
                                                                                })
                                                                            }
                                                                        </select>
                                                                        : null
                                                                    : data.type === "text" ?
                                                                        <input type="text" value={props.c_state.answers[data.placeholder] ? props.c_state.answers[data.placeholder] : ''} onChange={(event) => props.handleChange({ "field": data.placeholder, "value": event.target.value }, 'answers')} className="form-control" name={data.placeholder} />
                                                                        : data.type === "textarea" ?
                                                                            <textarea type="text" style={{ resize: 'none' }} value={props.c_state.answers[data.placeholder] ? props.c_state.answers[data.placeholder] : ''} onChange={(event) => props.handleChange({ "field": data.placeholder, "value": event.target.value }, 'answers')} className="form-control" name={data.placeholder}></textarea>
                                                                            : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_use_template_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.templates.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.templates.isLoading === "true" ? true : false} onClick={props.use_template}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="show_document_modal">
                        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{props.c_state.document_name ? props.c_state.document_name : t('View Document', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {
                                        props.templates.use_docs_config.document ?
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <iframe
                                                    className="embed-responsive-item"
                                                    title="doc_viewer"
                                                    src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.templates.use_docs_config)}`)}
                                                    allowFullScreen>
                                                </iframe>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_show_document_modal">{t('Close', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        props.c_state.template_preview.templates ?
                            <div className="modal fade" id="template_preview_modal">
                                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">{props.c_state.template_preview.templates.template_name ? props.c_state.template_preview.templates.template_name : t('Preview Document', props.auth.language)}</h4>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="card-outline card-primary" align="center">
                                                <img alt="screenshot" src={`${types.SERVER_URL}static/templates/${props.c_state.template_preview.templates.tid}.png`} />
                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-between">
                                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_template_preview_modal">{t('Close', props.auth.language)}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }

                    <div className="modal fade" id="search_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="tags"
                                            // value={props.c_state.contact_details.tags ? props.c_state.contact_details.tags : []}
                                            id="custom_select"
                                            value={typeof props.c_state.search_params === "string" ? JSON.parse(props.c_state.search_params.replace(/'/g, '"')) : props.c_state.search_params}
                                            onChange={(value) => props.handleChange(value, "search_params")}
                                            isMulti style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder={t('Select', props.auth.language)} />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_params_modal">{t('Close', props.auth.language)}</button>
                                    <button type="button" className="btn btn-primary" onClick={props.search_template_by_tags}>{t('Search', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="clone_template_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Clone Template', props.auth.language)} ({props.c_state.template_details.templates ? props.c_state.template_details.templates.template_name : ''})</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="template_name">{t('New Template Name', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.template_details.template_name ? props.c_state.template_details.template_name : ''} onChange={(event) => props.handleChange({ "field": "template_name", "value": event.target.value }, 'template')} className="form-control" name="template_name" placeholder={t('Template Name', props.auth.language)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="document_type">{t('Document Type', props.auth.language)} *</label>
                                            <select className="form-control" name="document_type" onChange={(event) => props.handleChange({ "field": "document_type", "value": event.target.value }, 'template')} value={props.c_state.template_details.document_type ? props.c_state.template_details.document_type : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="word">{t('Word Document', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Category', props.auth.language)} *</label>
                                            <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, 'template')} value={props.c_state.template_details.category ? props.c_state.template_details.category : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="private">{t('Private', props.auth.language)}</option>
                                                <option value="public">{t('Public', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Tags', props.auth.language)} </label>
                                            <Select
                                                options={props.c_state.tags_dropdown}
                                                name="tags"
                                                value={props.c_state.template_details.tags ? props.c_state.template_details.tags : ''}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'template')}
                                                isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder={t('Select', props.auth.language)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_clone_template_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.templates.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.templates.isLoading === "true" ? true : false} onClick={props.clone_template}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
            </div>
        </>
    )
}

export default ViewTemplates
