import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    departments: []
}

const Departments = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.DEPARTMENTS_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.DEPARTMENTS:

            newState.departments = payload.data;

            return newState;

        case types.DEPARTMENTS_SUCCESS:

            if (payload.msg === "Department created successfully") {
                // eslint-disable-next-line array-callback-return
                newState.departments = [...newState.departments, { ...payload.data }]

                newState.departments.sort((a, b) => (a.title > b.title) ? 1 : -1)
            }

            if (payload.msg === "Department deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.departments = newState.departments.filter((data, index) => { // loop through state.todo
                    if (data.id !== payload.data.dept_id) { // check what data to update using key
                        return data
                    }
                })
            }

            if (payload.msg === "Department updated successfully") {
                newState.departments = newState.departments.map((data, index) => { // loop through state.todo
                    if (data.id === payload.data.id) { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                        // console.log(data);
                    }
                    return data
                });

                newState.departments.sort((a, b) => (a.title > b.title) ? 1 : -1)
            }

            toast.success(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        case types.DEPARTMENTS_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        default:
            return newState;
    }
}

export default Departments