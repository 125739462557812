import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    tags: []
}

const Tags = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.TAGS_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.TAGS:

            newState.tags = payload.data;

            return newState;

        case types.TAGS_SUCCESS:

            if (payload.msg === "Tag added successfully") {

                newState.tags = [...newState.tags, { ...payload.data }]

                newState.tags.sort((a, b) => (a.name > b.name) ? 1 : -1)
            }

            if (payload.msg === "Sub tag added successfully" || payload.msg === "Sub tag updated successfully" || payload.msg === "Sub tag deleted successfully") {
                newState.tags = payload.data;
            }

            if (payload.msg === "Tag deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.tags = newState.tags.filter((data, index) => { // loop through state.todo
                    if (data.tid !== payload.data.tid) { // check what data to update using key
                        return data
                    }
                })
            }

            if (payload.msg === "Tag updated successfully") {
                newState.tags = newState.tags.map((data, index) => { // loop through state.todo
                    if (data.tid === payload.data.tid) { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                    }
                    return data
                });

                // newState.tags = payload.data

                newState.tags.sort((a, b) => (a.name > b.name) ? 1 : -1)
            }

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        case types.TAGS_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        default:
            return newState;
    }
}

export default Tags