import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: action
        })
    }
}

export const serverSendCorrespondence = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'send_correspondence',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetCorrespondence = (correspondence_category, start, finish, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'get_correspondence',
            data: {
                "correspondence_category": correspondence_category,
                "start": start,
                "finish": finish
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverSingleCorrespondence = (data, correspondence_category, trail_id) => {
    return (dispatch) => {
        dispatch({
            type: types.SINGLE_CORRESPONDENCE,
            payload: {
                "single_correspondence": data,
                "correspondence_category": correspondence_category,
                "trail_id": trail_id
            }
        });

        return

    }
}

export const serverSetChosenCategory = (category) => {
    return (dispatch) => {
        dispatch({
            type: types.CHOSEN_CATEGORY,
            payload: category
        });

        return

    }
}

export const serverGetCorrespondenceDetails = (correspondence_id, correspondence_category, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'get_correspondence_details',
            data: {
                "correspondence_id": correspondence_id,
                "correspondence_category": correspondence_category
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverNullifyAction = (action, action_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'nullify_action',
            data: {
                "action": action,
                "action_id": action_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverNullifyTrail = (action, trail_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'nullify_trail',
            data: {
                "action": action,
                "trail_id": trail_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverAddAction = (formData, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'add_action',
            data: formData,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverForwardTrail = (formData, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'forward_trail',
            data: formData,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverArchiveTrail = (trail_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'archive_trail',
            data: {
                "trail_id": trail_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverCloseCorrespondence = (correspondence_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'close_correspondence',
            data: {
                "correspondence_id": correspondence_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverMarkSeen = (trail_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'mark_seen',
            data: {
                "trail_id": trail_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverAttachEmail = (email_details) => {
    return (dispatch) => {
        return dispatch({
            type: types.EMAIL_DETAILS,
            payload: email_details
        })
    }
}

export const serverSendEmail = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'send_email',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverUnarchive = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'unarchive',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetAttachedCorrespondence = (attached_correspondence, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'get_attached_correspondence',
            data: {
                "attached_correspondence": attached_correspondence
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverSaveFolderDetails = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_FOLDER_DETAILS,
            payload: data
        });
    }
}

export const serverGetCorrespondenceFolders = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'get_correspondence_folders',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                // return dispatch({
                //     type: types.DO_LOGOUT,
                //     payload: response.data
                // })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetCorrespondenceFolderList = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'get_correspondence_folder_list',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                // return dispatch({
                //     type: types.DO_LOGOUT,
                //     payload: response.data
                // })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverDeleteCorrespondenceDraft = (correspondence_id, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'delete_correspondence_draft',
            data: {
                correspondence_id: correspondence_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetFilteredCorrespondence = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.CORRESPONDENCE_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'get_filtered_correspondence',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CORRESPONDENCE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CORRESPONDENCE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CORRESPONDENCE_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.CORRESPONDENCE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CORRESPONDENCE_LOADING,
                payload: "false"
            });
            return
        })
    }
}

