/* eslint-disable array-callback-return */
import React, { Component } from 'react'

import CorrespondenceFolders from './CorrespondenceFolders';
import Main from './../LayoutComponent/Main';
import { connect } from 'react-redux';
// import { checkUser } from '../../actions/AuthAction';
import { serverLoad, serverGetCorrespondenceFolders, serverGetCorrespondenceFolderList, serverSingleCorrespondence, serverMarkSeen, serverSaveFolderDetails } from '../../actions/CorrespondenceAction'
import { serverGetDepartments } from '../../actions/DepartmentsAction'
import { serverGetContacts, serverGetCompanies } from '../../actions/ContactsAction'
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { getTrans as t } from '../../translations';

const $ = require("jquery");

class CorrespondenceFoldersContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            departments_dropdown: [],
            folder_list_title: "",
            correspondence_folder_list: [],
            start: 1,
            finish: 50,
        }

        this.handleChange = this.handleChange.bind(this);
        this.reload = this.reload.bind(this);
        this.getFolderCorrespondence = this.getFolderCorrespondence.bind(this);
        this.contactDetails = this.contactDetails.bind(this);
        this.singleCorrespondence = this.singleCorrespondence.bind(this);
        this.searchCorrespondence = this.searchCorrespondence.bind(this);
        this.goBack = this.goBack.bind(this);
        this.goForward = this.goForward.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");

            return
        }

        if (!this.props.departments.departments.length) {
            this.props.serverGetDepartments(this.props.auth.user.token);
        } else {
            this.setState({ departments_list: this.props.departments.departments });
        }

        let arr = []

        // eslint-disable-next-line array-callback-return
        this.props.departments.departments.filter((data, index) => {
            let v = { "value": data.id, "label": `${data.title}` };
            arr.push(v);
        });

        this.setState({ departments_dropdown: arr });

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
            this.props.serverGetCompanies(this.props.auth.user.token);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.correspondence.folder_list !== this.props.correspondence.folder_list) {
            this.setState({ correspondence_folder_list: this.props.correspondence.folder_list });

            let data = [];

            data = this.props.correspondence.folder_list;
            // count no of records
            if (parseInt(data.no_of_records) <= parseInt(this.state.finish)) {
                this.setState({ finish: data.no_of_records });
            }

            // if (parseInt(data.no_of_records) > parseInt(this.state.finish)) {
            //     this.setState({ finish: data.no_of_records });
            // }
        }

        if (prevProps.correspondence.single_correspondence !== this.props.correspondence.single_correspondence) {
            if (this.props.correspondence.single_correspondence.correspondence_category !== "Drafts") {
                $("#dismiss_view_correspondence_modal").click();
                this.props.history.push("/correspondence_details");
            }
        }
    }

    handleChange = (data) => {

        let state = { ...this.props.correspondence.folder_details };
        state[data.field] = data.value;

        this.props.serverSaveFolderDetails(state);

        if (data.field === "group_by" && data.value) {
            this.props.serverGetCorrespondenceFolders(state, this.props.auth.user.token);
        }

        if (data.field === "category" && data.value && state.group_by) {
            this.props.serverGetCorrespondenceFolders(state, this.props.auth.user.token);
        }
    }

    reload = () => {
        this.props.serverGetDepartments(this.props.auth.user.token);
    }

    getFolderCorrespondence = (value) => {
        let state = { ...this.props.correspondence.folder_details };
        state["search_term"] = value;
        state["start"] = this.state.start;
        state["finish"] = this.state.finish;

        if (state.group_by === "Department") {
            this.setState({ folder_list_title: JSON.parse(value.replace(/'/g, '"')).label })
        }

        if (state.group_by === "Company") {
            this.setState({ folder_list_title: value.name });
        }

        if (state.group_by === "Tags") {
            this.setState({ folder_list_title: value });
        }

        this.props.serverGetCorrespondenceFolderList(state, this.props.auth.user.token);
    }

    contactDetails = (contact_id) => {

        let contact_details = this.props.contacts.contacts.filter(item => item.contact_id === contact_id);

        if (contact_details.length) {
            contact_details = contact_details[0];
            return `${contact_details.first_name} ${contact_details.last_name}`;
        } else {
            return '';
        }
    }

    singleCorrespondence = (data) => {
        // console.log("bb", data);
        this.props.serverSingleCorrespondence(data.correspondence[0], this.props.correspondence.folder_details.category, data.trail_id);
        if (this.props.correspondence.folder_details.category === "Inbox" || this.props.correspondence.folder_details.category === "Archived") {
            this.props.serverMarkSeen(data.trail_id, this.props.auth.user.token);
        }
    }

    searchCorrespondence = (value) => {

        // eslint-disable-next-line array-callback-return
        let new_correspondence = this.props.correspondence.folder_list.filter((data, index) => {
            data = data.correspondence[0];
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        // console.log(2, new_correspondence);

        this.setState({ correspondence_folder_list: new_correspondence });
    }

    goBack = () => {

        let start = this.state.start;
        let finish = this.state.finish;

        if (start >= 50) {
            this.setState({ start: start - 50, finish: finish - 50 });

            let state = { ...this.props.correspondence.folder_details };
            state["start"] = start - 50;
            state["finish"] = finish - 50;

            this.props.serverGetCorrespondenceFolderList(state, this.props.auth.user.token);
        }
    }

    goForward = () => {

        let start = this.state.start;
        let finish = this.state.finish;

        if (start >= 50) {
            this.setState({ start: start + 50, finish: finish + 50 });

            let state = { ...this.props.correspondence.folder_details };
            state["start"] = start + 50;
            state["finish"] = finish + 50;

            this.props.serverGetCorrespondenceFolderList(state, this.props.auth.user.token);
        }
    }

    render() {
        return (
            <>
                <Main>
                    <CorrespondenceFolders
                        c_state={{ ...this.state }}
                        departments={this.props.departments}
                        auth={this.props.auth}
                        correspondence={this.props.correspondence}
                        handleChange={this.handleChange}
                        contacts={this.props.contacts}
                        get_folder_correspondence={this.getFolderCorrespondence}
                        contact_details={this.contactDetails}
                        single_correspondence={this.singleCorrespondence}
                        search_correspondence={this.searchCorrespondence}
                        go_back={this.goBack}
                        go_forward={this.goForward}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        departments: { ...state.departments },
        contacts: { ...state.contacts },
        correspondence: { ...state.correspondence }
    }
}

export default connect(mapStateToProps, {
    // checkUser,
    serverLoad,
    serverGetDepartments,
    serverGetContacts,
    serverGetCompanies,
    serverGetCorrespondenceFolders,
    serverGetCorrespondenceFolderList,
    serverSingleCorrespondence,
    serverMarkSeen,
    serverSaveFolderDetails
})(CorrespondenceFoldersContainer)


