import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    docs_config: {},
    tid: null,
    templates: [],
    edit_templates_config: {},
    edit_docs_config: {},
    single_template: [],
    template_document: [],
    use_docs_config: {},
    documents: [],
    did: null,
    single_document: {},
    isLoading: "false"
}

const Templates = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    let kind = '';
    let config = {};

    // console.log(type, payload);

    switch (type) {

        case types.TEMPLATES_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.TEMPLATES:

            newState.templates = payload.data;

            return newState;

        case types.TEMPLATES_SUCCESS:

            if (payload.msg === "Template created successfully. Please define your placeholders.") {

                let kind = '';

                if (payload.data.ext === '.docx') {
                    kind = "word";
                } else if (payload.data.ext === '.pptx') {
                    kind = "slide";
                } else if (payload.data.ext === '.xlsx' || payload.data.ext === '.csv') {
                    kind = "cell";
                }

                config = {
                    "document": {
                        "fileType": payload.data.ext.replace('.', ''),
                        "permissions": {
                            "download": false,
                            "edit": true
                        },
                        "key": Math.floor(Math.random() * 999999999999) + "",
                        "title": payload.data.template_name,
                        "url": `${types.SERVER_URL}static/templates/${payload.data.tid.toString()}${payload.data.ext}`,
                        "user": {
                            "id": payload.data.created_by,
                            "name": payload.data.created_by
                        },
                    },
                    "documentType": kind,
                    "editorConfig": {
                        "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=templates:${payload.data.tid.toString()}${payload.data.ext}`,
                        "customization": {
                            "forcesave": true
                        }
                    }
                }

                newState.docs_config = config;
                newState.tid = payload.data.tid;
            }

            if (payload.msg === "Template cloned successfully") {
                newState.templates = payload.data;
            }

            if (payload.msg === "Your template has been created successfully. You can now create documents using this template.") {
                newState.docs_config = {};
                newState.edit_templates_config = {};
                newState.tid = null;
            }

            if (payload.msg === "Template deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.templates = newState.templates.filter((data, index) => { // loop through state.todo
                    if (data.templates.tid !== payload.data.tid) { // check what data to update using key
                        return data
                    }
                })
            }

            if (payload.msg === "Your template has been updated successfully") {
                newState.docs_config = {};
                newState.edit_templates_config = {};
                newState.tid = null;
            }

            if (payload.msg === "Document created successfully") {
                if (payload.data.ext === '.docx') {
                    kind = "word";
                } else if (payload.data.ext === '.pptx') {
                    kind = "slide";
                } else if (payload.data.ext === '.xlsx' || payload.data.ext === '.csv') {
                    kind = "cell";
                }

                config = {
                    "document": {
                        "fileType": payload.data.ext.replace('.', ''),
                        "permissions": {
                            "download": false,
                            "edit": true
                        },
                        "key": Math.floor(Math.random() * 999999999999) + "",
                        "title": payload.data.name,
                        "url": `${types.SERVER_URL}static/documents/${payload.data.did.toString()}${payload.data.ext}`,
                        "user": {
                            "id": payload.data.owner,
                            "name": payload.data.owner
                        },
                    },
                    "documentType": kind,
                    "editorConfig": {
                        "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=documents:${payload.data.did.toString()}${payload.data.ext}`,
                        "customization": {
                            "forcesave": true
                        }
                    }
                }

                newState.use_docs_config = config;

                newState.documents = [...newState.documents, { ...payload.data }];
                newState.documents.sort((a, b) => (a.name > b.name) ? 1 : -1)
            }

            if (payload.msg === "Document deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.documents = newState.documents.filter((data, index) => { // loop through state.todo
                    if (data.did !== payload.data.did) { // check what data to update using key
                        return data
                    }
                })
            }

            if (payload.msg === "Document renamed successfully") {

                newState.single_document = payload.data;

            }

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        case types.TEMPLATES_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        case types.TEMPLATES_EDIT:

            if (payload.templates.ext === '.docx') {
                kind = "word";
            } else if (payload.templates.ext === '.pptx') {
                kind = "slide";
            } else if (payload.templates.ext === '.xlsx' || payload.data.ext === '.csv') {
                kind = "cell";
            }

            config = {
                "document": {
                    "fileType": payload.templates.ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": true
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": payload.templates.template_name,
                    "url": `${types.SERVER_URL}static/templates/${payload.templates.tid.toString()}${payload.templates.ext}`,
                    "user": {
                        "id": payload.templates.created_by,
                        "name": payload.templates.created_by
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=templates:${payload.templates.tid.toString()}${payload.templates.ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            newState.edit_templates_config = config;
            newState.tid = payload.templates.tid;
            newState.single_template = payload;

            return newState;

        case types.DOCUMENTS:

            newState.documents = payload.data;

            return newState;

        case types.DOCUMENTS_EDIT:

            if (payload.ext === '.docx') {
                kind = "word";
            } else if (payload.ext === '.pptx') {
                kind = "slide";
            } else if (payload.ext === '.xlsx' || payload.ext === '.csv') {
                kind = "cell";
            }

            config = {
                "document": {
                    "fileType": payload.ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": true
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": payload.name,
                    "url": `${types.SERVER_URL}static/documents/${payload.did.toString()}${payload.ext}`,
                    "user": {
                        "id": payload.owner,
                        "name": payload.owner
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=documents:${payload.did.toString()}${payload.ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            newState.edit_docs_config = config;
            newState.did = payload.did;
            newState.single_document = payload

            return newState;

        default:
            return newState;
    }
}

export default Templates