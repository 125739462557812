import types from './../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.ROLES_LOADING,
            payload: action
        })
    }
}

export const serverGetDepartments = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.DEPARTMENTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_departments',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.DEPARTMENTS,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DEPARTMENTS_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.DEPARTMENTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.DEPARTMENTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}


export const serverAddDepartment = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.DEPARTMENTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'add_department',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.DEPARTMENTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DEPARTMENTS_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.DEPARTMENTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.DEPARTMENTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverDeleteDepartment = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.DEPARTMENTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'delete_department',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.DEPARTMENTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DEPARTMENTS_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.DEPARTMENTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.DEPARTMENTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverEditDepartment = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.DEPARTMENTS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'edit_department',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.DEPARTMENTS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DEPARTMENTS_FAIL,
                    payload: response.data
                });
                dispatch({
                    type: types.DEPARTMENTS_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.DEPARTMENTS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.DEPARTMENTS_LOADING,
                payload: "false"
            });

            return;
        })
    }
}