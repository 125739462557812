/* eslint-disable array-callback-return */
import React from 'react'
// import { getTrans as t } from '../../translations';
import types from '../../types';

const TableData = (props) => {

    let { data, c_state } = props;

    let { options } = c_state;

    return (
        <>
            {
                c_state.selected_filters.map((d, i) => {
                    let selected_option = options[d.name];
                    if (selected_option) {
                        if (selected_option.type === "string") {
                            return <td key={i}>{
                                selected_option.isDangerousHTML ?
                                    <div dangerouslySetInnerHTML={{ __html: data[selected_option.position][selected_option.value] }} />
                                    : selected_option.contactNameSingle ?
                                        props.get_contact_name(data[selected_option.position][selected_option.value], "single")
                                        : selected_option.contactNameBulk ?
                                        props.get_contact_name(data[selected_option.position][selected_option.value], "double")
                                        : selected_option.condition ?
                                            selected_option.condition[data[selected_option.position][selected_option.value]]
                                            : data[selected_option.position][selected_option.value]
                            }</td>
                        } else if (selected_option.type === "array" && selected_option.value === "task_comments") {
                            return <td key={i}>
                                {
                                    data[selected_option.position].task_comments?.map((el, ind) => {
                                        return <span key={ind}>
                                            {ind > 0 ? <div dangerouslySetInnerHTML={{ __html: ' ------------- <br /> ' }} /> : ' '}
                                            {
                                                Object.keys(selected_option.array_keys).map((l, ii) => {
                                                    return <span key={ii}>{l === "user" ? "Comment By: " + props.get_contact_name(el[l], "single") : <div dangerouslySetInnerHTML={{ __html: el[l] }} />}</span>
                                                })
                                            }
                                        </span>
                                    })
                                }
                            </td>
                        } else if (selected_option.type === "array") {
                            return <td key={i}>
                                {
                                    data[selected_option.position]?.map((el, ind) => {
                                        return <span>
                                            {ind > 0 ? <div dangerouslySetInnerHTML={{ __html: ' ------------- <br /> ' }} /> : ' '}
                                            {
                                                Object.keys(selected_option.array_keys).map((l, ii) => {
                                                    return <>{ii > 0 ? ' ' : ' '}{el[l]}</>
                                                })
                                            }
                                        </span>
                                    })
                                }
                            </td>
                        } else {
                            return <td key={i}></td>
                        }
                    } else {
                        return <td key={i}></td>
                    }
                })
            }
        </>
    )
}

const PrintTasks = (props) => {

    // console.log(props.c_state.selected_filters);

    return (
        <div id="tasks_printout" hidden>
            <div align="center">
                <img src="static/assets/dist/img/logo.png" alt="school_logo" width="10%" />
                <h2><strong>{types.INSTITUTION_NAME}</strong></h2>
                <p>CMS TASKS</p>
            </div>
            <table width="100%" border="1">
                <thead>
                    <tr>
                        <th>#</th>
                        {
                            props.c_state.selected_filters.map((data, i) => {
                                return (
                                    <th key={i}>{data.name}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.c_state.task_list.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <TableData {...props} data={data} />
                                </tr>
                            )


                            // if (props.c_state.selected_filters.indexOf({ order: 1, name: "Sender" })) {
                            //     console.log("fff", data);
                            //     return (
                            //         <tr>
                            //             <td key={index}>{data.task.user}</td>
                            //         </tr>
                            //     )
                            // }else if (props.c_state.selected_filters.indexOf({ order: 2, name: "Recipients" })) {
                            //     return (
                            //         <tr>
                            //             <td key={index}>{data.task.recipient}</td>
                            //         </tr>
                            //     )
                            // }else {
                            //     return (
                            //         <></>
                            //     )
                            // }
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default PrintTasks