import React from 'react'
import { Link } from "react-router-dom";
import types from '../../types';
import { getTrans as t } from '../../translations';

const SideBar = (props) => {

    return (
        <div>
            {/* <!-- Main Sidebar Container --> */}
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* <!-- Brand Logo --> */}
                <Link to="#" className="brand-link">
                    <img src="static/assets/dist/img/logo.png" alt="AdminLTE Logo"
                        className="brand-image img-circle elevation-3" style={{ opacity: .8, background: 'white' }} />
                    <span className="brand-text font-weight-light">{types.INSTITUTION_SHORT}</span>
                </Link>

                {/* <!-- Sidebar --> */}
                <div className="sidebar" style={{ overflowY: 'scroll' }}>
                    {/* <!-- Sidebar user (optional) --> */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image1">
                            <img src={props.user.photo !== '' && props.user.photo !== null ? types.SERVER_URL + 'static/contacts/' + props.user.photo : "static/assets/dist/img/user2-160x160.jpg"} className="img-circle elevation-2" alt="User Logo" />
                        </div>
                        <div className="info">
                            <Link to="#" className="d-block1" data-toggle="dropdown1" onClick={() => props.toggleSwitch()}>{`${props.user.first_name} ${props.user.last_name}`}</Link>

                            <div id="accounts-switch" className="rounded d-none" style={{ position: 'fixed', left: 10, zIndex: 1000, backgroundColor: 'whitesmoke', padding: 5, marginTop: 10 }}>
                                {
                                    // eslint-disable-next-line array-callback-return
                                    props.otherAccounts.map((data, index) => {
                                        if (data.value !== props.user.contact_id) {
                                            return (
                                                <Link to="#" key={index} onClick={() => props.switchAccount(data.value)} className="dropdown-item">
                                                    <div style={{ display: 'flex' }}>
                                                        <img alt="img" src="static/assets/dist/img/logo.png"
                                                            className="img-size-50 mr-3 img-circle" style={{ height: 30, width: 30 }} />
                                                        <div className="media-body">
                                                            <h3 className="dropdown-item-title" style={{fontSize: 14}}>
                                                                {data.label}
                                                            </h3>
                                                            {/* <p className="text-sm mb-0">{data.email}</p> */}
                                                            <p className="text-muted mb-0" style={{fontSize: 12}}><i className="far fa-user mr-1"></i> Switch To This Account</p>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown-divider"></div>
                                                </Link>
                                            )
                                        }
                                    })
                                }

                                <Link to="#" onClick={props.logout} className="dropdown-item">
                                    <div style={{ display: 'flex' }}>
                                        <div className="media-body">
                                            <h3 className="dropdown-item-title">
                                                Logout
                                            </h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>



                            {/* <div className="dropdown-menu1 dropdown-menu-lg1 dropdown-menu-right1" >
                                {
                                    // eslint-disable-next-line array-callback-return
                                    props.otherAccounts.map((data, index) => {
                                        if (data.contact_id !== props.user.contact_id) {
                                            return (
                                                <span key={index}>
                                                    <Link to="#" onClick={() => props.switchAccount(data)} className="dropdown-item">
                                                        <div className="media">
                                                            <img alt="img" src={data.photo !== '' && data.photo !== null ? types.SERVER_URL + 'static/contacts/' + data.photo : "static/assets/dist/img/user1-128x128.jpg"}
                                                                className="img-size-50 mr-3 img-circle" />
                                                            <div className="media-body">
                                                                <h3 className="dropdown-item-title">
                                                                    {data.first_name} {data.last_name}
                                                                </h3>
                                                                <p className="text-sm">{data.email}</p>
                                                                <p className="text-sm text-muted"><i className="far fa-user mr-1"></i> Switch To This Account</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <div className="dropdown-divider"></div>
                                                </span>
                                            )
                                        }
                                    })
                                }
                            </div> */}
                            {/* <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span> */}
                        </div>
                    </div>

                    {/* <!-- SidebarSearch Form --> */}
                    <div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder={t('Search', props.auth.language)}
                                aria-label={t('Search', props.auth.language)} />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Sidebar Menu --> */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li className="nav-item">
                                <Link to="/dashboard" className={props.active && props.active === '/dashboard' ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>{t('Dashboard', props.auth.language)}</p>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to="#" className={props.active === '/access_control' ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-users-cog"></i>
                                    <p>
                                        User & Inst Acc Ctrl
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="#" className={props.active && props.active === '/access_control' ? "nav-link active" : "nav-link"}>
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Access Control</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li> */}
                            {
                                props.user ?
                                    props.user.privileges.includes("view_tasks") ?
                                        <>
                                            <li className="nav-header">{t('TASKS', props.auth.language)}</li>
                                            <li className="nav-item">
                                                <Link to="/tasks" className={props.active && props.active === '/tasks' ? "nav-link active" : "nav-link"}>
                                                    <i className="nav-icon fas fa-tasks"></i>
                                                    <p>{t('Task Manager', props.auth.language)}</p>
                                                </Link>
                                            </li>
                                        </>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("view_profile") || props.user.privileges.includes("view_tags") || props.user.privileges.includes("my_contacts_menu") ?
                                        <li className="nav-header">{t('PERSONAL', props.auth.language)}</li>
                                        : null
                                    : null
                            }
                            {/*<li className="nav-item">
                                 <Link to="#" className={props.active && (props.active === '/profile' || props.active === '/tags' ||
                                    props.active === '/contacts') ?
                                    "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-book"></i>
                                    <p>
                                        Personal
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview"> */}
                            {
                                props.user ?
                                    props.user.privileges.includes("view_profile") ?
                                        <li className="nav-item">
                                            <Link to="/profile" className={props.active && props.active === '/profile' ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-user nav-icon"></i>
                                                <p>{t('My Profile', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("view_tags") ?
                                        <li className="nav-item">
                                            <Link to="/tags" className={props.active && props.active === '/tags' ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-tag nav-icon"></i>
                                                <p>{t('Tags', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("my_contacts_menu") ?
                                        <li className="nav-item">
                                            <Link to="/contacts" className={props.active && (props.active === '/contacts' || props.active === '/add_contact' || props.active === '/edit_contact') ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-users nav-icon"></i>
                                                <p>{t('My Contacts', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {/* </ul> */}
                            {/* </li> */}
                            {
                                props.user ?
                                    props.user.privileges.includes("view_routes") || props.user.privileges.includes("view_emails") || props.user.privileges.includes("view_correspondences") || props.user.privileges.includes("view_questionnaires") ?
                                        <li className="nav-header">{t('MAILINGS', props.auth.language)}</li>
                                        : null
                                    : null
                            }
                            {/* <li className="nav-item">
                                <Link to="#" className={props.active && (props.active === '/emails' || props.active === '/email_details' || props.active === '/compose_email'
                                    || props.active === '/correspondence' || props.active === '/compose_correspondence' || props.active === '/correspondence_details' || props.active === '/view_routes'
                                    || props.active === '/new_route_steps' || props.active === '/edit_route') ?
                                    "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-envelope"></i>
                                    <p>
                                        Mailings
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview"> */}
                            {
                                props.user ?
                                    props.user.privileges.includes("view_questionnaires") ?
                                        <li className="nav-item">
                                            <Link to="/questionnaires" className={props.active && (props.active === '/questionnaires' || props.active === '/new_questionnaire') ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-comments nav-icon"></i>
                                                <p>{t('Questionnaires', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("view_routes") ?
                                        <li className="nav-item">
                                            <Link to="/view_routes" className={props.active && (props.active === '/view_routes' || props.active === '/new_route_steps' || props.active === '/edit_route') ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-route nav-icon"></i>
                                                <p>{t('Routing', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("view_emails") ?
                                        <li className="nav-item">
                                            <Link to="/emails" className={props.active && (props.active === '/emails' || props.active === '/email_details' || props.active === '/compose_email') ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-envelope-open nav-icon"></i>
                                                <p>{t('My Emails', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("view_correspondences") ?
                                        <li className="nav-item">
                                            <Link to="/correspondence" className={props.active && (props.active === '/correspondence' || props.active === '/correspondence_details' || props.active === '/compose_correspondence') ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-envelope nav-icon"></i>
                                                <p>{t('Correspondence', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("view_correspondences") ?
                                        <li className="nav-item">
                                            <Link to="/folders" className={props.active && props.active === '/folders' ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-folder nav-icon"></i>
                                                <p>{t('Folders', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {/* </ul>
                            </li> */}
                            {
                                props.user ?
                                    props.user.privileges.includes("new_template_menu") || props.user.privileges.includes("view_templates") || props.user.privileges.includes("view_template_documents") ?
                                        <li className="nav-header">{t('TEMPLATING', props.auth.language)}</li>
                                        : null
                                    : null
                            }
                            {/* <li className="nav-item">
                                <Link to="#" className={props.active && (props.active === '/new_template' || props.active === '/view_templates' ||
                                    props.active === '/documents' || props.active === '/edit_document' || props.active === '/edit_template') ?
                                    "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-file-word"></i>
                                    <p>
                                        Templating
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview"> */}
                            {
                                props.user ?
                                    props.user.privileges.includes("new_template_menu") ?
                                        <li className="nav-item">
                                            <Link to="/new_template" className={props.active && props.active === '/new_template' ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-file-word nav-icon"></i>
                                                <p>{t('New Template', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("view_templates") ?
                                        <li className="nav-item">
                                            <Link to="/view_templates" className={props.active && (props.active === '/view_templates' || props.active === '/edit_template') ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-briefcase nav-icon"></i>
                                                <p>{t('View Templates', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("view_template_documents") ?
                                        <li className="nav-item">
                                            <Link to="/documents" className={props.active && (props.active === '/documents' || props.active === '/edit_document') ? "nav-link active" : "nav-link"}>
                                                <i className="fas fa-file nav-icon"></i>
                                                <p>{t('Documents', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {/* </ul>
                            </li> */}
                            <li className="nav-header">{t('MISCELLANEOUS', props.auth.language)}</li>
                            {
                                props.user ?
                                    props.user.privileges.includes("manage_departments") ?
                                        <li className="nav-item">
                                            <Link to="/departments" className={props.active && (props.active === '/departments') ? "nav-link active" : "nav-link"}>
                                                <i className="nav-icon fas fa-suitcase"></i>
                                                <p>{t('Dept./Reference No', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("conversion_service") ?
                                        <li className="nav-item">
                                            <Link to="/conversions" className={props.active && (props.active === '/conversions') ? "nav-link active" : "nav-link"}>
                                                <i className="nav-icon fas fa-file-pdf"></i>
                                                <p>{t('Conversion Service', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("privileges") ?
                                        <li className="nav-item">
                                            <Link to="/privileges" className={props.active && (props.active === '/privileges') ? "nav-link active" : "nav-link"}>
                                                <i className="nav-icon fas fa-user-secret"></i>
                                                <p>{t('Privileges & Access Ctrl', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            {
                                props.user ?
                                    props.user.privileges.includes("create_user_roles") || props.user.privileges.includes("assign_user_roles") ?
                                        <li className="nav-item">
                                            <Link to="/roles" className={props.active && (props.active === '/roles') ? "nav-link active" : "nav-link"}>
                                                <i className="nav-icon fas fa-cog"></i>
                                                <p>{t('User Roles', props.auth.language)}</p>
                                            </Link>
                                        </li>
                                        : null
                                    : null
                            }
                            <li className="nav-item">
                                <Link to="#" className="nav-link">
                                    <i className="nav-icon fas fa-file-code"></i>
                                    <p>{t('Help', props.auth.language)}</p>
                                </Link>
                            </li>
                            {/*<li className="nav-item">
                                <a href="https://adminlte.io/docs/3.1/" className="nav-link">
                                    <i className="nav-icon fas fa-file-code"></i>
                                    <p>Documentation</p>
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    )
}

export default SideBar
