/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom'
import types from '../../types';
// import TextField from '@material-ui/core/TextField';
// import Select from 'react-select'
import ViewAttachmentModal from '../AttachmentComponent/ViewAttachmentModal';
import { getTrans as t } from '../../translations';

const EmailDetails = (props) => {

    // console.log(props.single_email);

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{t('Read Your Email', props.auth.language)}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                <li className="breadcrumb-item"><Link to="/emails">{t('Email List', props.auth.language)}</Link></li>
                                <li className="breadcrumb-item active">{t('Email Details', props.auth.language)}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">{t('Read Email', props.auth.language)}</h3>

                                    <div className="card-tools">
                                        <Link to="/emails" className="btn btn-sm btn-outline-danger">{t('Back To', props.auth.language)} {t(props.single_email.mail_category, props.auth.language)}</Link>&nbsp;
                                        <button type="button" className="btn btn-outline-info btn-sm" title={t('Reload your emails', props.auth.language)} onClick={props.reload_email}>
                                            <i className="fas fa-sync-alt"></i>&nbsp; {t('Reload', props.auth.language)}
                                        </button>&nbsp;
                                        {
                                            props.auth.user ?
                                                props.auth.user.privileges.includes("attach_email_to_correspondence") ?
                                                    <button type="button" className="btn btn-outline-primary btn-sm" title={t('Attach this email to a new correspondence', props.auth.language)} onClick={props.attach_to_correspondence}>
                                                        <i className="fas fa-sync-alt"></i>&nbsp; {t('Attach To Correspondence', props.auth.language)}
                                                    </button>
                                                    : null
                                                : null
                                        }
                                        {/* <Link to="#" className="btn btn-tool" title="Previous"><i className="fas fa-chevron-left"></i></Link>
                                        <Link to="#" className="btn btn-tool" title="Next"><i className="fas fa-chevron-right"></i></Link> */}
                                    </div>
                                </div>
                                {
                                    props.isLoading === "true" ?
                                        <div align="center">
                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Your email is loading. Please wait...', props.auth.language)}</p></span>
                                        </div>
                                        :
                                        props.c_state.email_details.subject ?
                                            <>
                                                <div className="card-body p-0">
                                                    <div className="mailbox-read-info">
                                                        <h5>{props.c_state.email_details.subject}</h5>
                                                        {props.single_email.mail_category === "Sent" ?
                                                            <h6>{t('To', props.auth.language)}: {props.c_state.email_details.to}
                                                                <span className="mailbox-read-time float-right">{props.c_state.email_details.date.toLocaleString('en-GB', { timeZone: 'GMT' })}</span></h6>
                                                            :
                                                            <h6>{t('From', props.auth.language)}: {props.c_state.email_details.from}
                                                                <span className="mailbox-read-time float-right">{props.c_state.email_details.date.toLocaleString('en-GB', { timeZone: 'GMT' })}</span></h6>
                                                        }
                                                    </div>
                                                    <div className="mailbox-controls with-border text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-default btn-sm" data-container="body" title={t('Delete', props.auth.language)}>
                                                                <i className="far fa-trash-alt"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-default btn-sm" data-container="body" title={t('Reply', props.auth.language)}>
                                                                <i className="fas fa-reply"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-default btn-sm" data-container="body" title={t('Forward', props.auth.language)}>
                                                                <i className="fas fa-share"></i>
                                                            </button>
                                                        </div>
                                                        <button type="button" className="btn btn-default btn-sm" title={t('Print', props.auth.language)}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className="mailbox-read-message">
                                                        <div dangerouslySetInnerHTML={{ __html: props.c_state.email_details.message }} />
                                                    </div>
                                                </div>
                                                {props.c_state.email_details.attachments ?
                                                    <div className="card-footer bg-white">
                                                        <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                                                            {
                                                                props.c_state.email_details.attachments.map((data, indx) => {
                                                                    return (
                                                                        <li key={indx}>
                                                                            <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span>

                                                                            <div className="mailbox-attachment-info">
                                                                                {/* <a href={`${types.SERVER_URL}${data.file_path}`} target="view_attachment" className="mailbox-attachment-name"><i className="fas fa-paperclip"></i> {data.file_name}</a> */}
                                                                                <Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'email_attachments')}><i className="fas fa-paperclip"></i> {data.file_name}</Link>
                                                                                <span className="mailbox-attachment-size clearfix mt-1">
                                                                                    {/* <span>1,245 KB</span> */}
                                                                                    <a href={`${types.SERVER_URL}${data.file_path}`} className="btn btn-default btn-sm float-right"><i className="fas fa-download"></i></a>
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>

                                                    : null}

                                                <div className="card-footer">
                                                    <div className="float-right">
                                                        <button type="button" className="btn btn-default"><i className="fas fa-reply"></i> {t('Reply', props.auth.language)}</button>
                                                        <button type="button" className="btn btn-default"><i className="fas fa-share"></i> {t('Forward', props.auth.language)}</button>
                                                    </div>
                                                    <button type="button" className="btn btn-default"><i className="far fa-trash-alt"></i> {t('Delete', props.auth.language)}</button>
                                                    <button type="button" className="btn btn-default"><i className="fas fa-print"></i> {t('Print', props.auth.language)}</button>
                                                </div>
                                            </>
                                            : <div align="center"><br /><br /><p>{t('Sorry! Your email failed to load due to poor internet connection', props.auth.language)}.<br />{t('Click the reload icon to reload', props.auth.language)}.</p></div>
                                }
                            </div>
                        </div>
                    </div>
                    <ViewAttachmentModal
                        c_state={props.c_state}
                        clear_attachment_info={props.clear_attachment_info}
                        file_type={props.file_type}
                        download={props.download}
                        auth={props.auth}
                    />
                </div>
            </section>
        </div>
    )
}

export default EmailDetails
