/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import RouteSteps from './RouteSteps';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverLoad, serverKeepRouteStep, serverRemoveRouteStep, serverEditRouteStep, serverSaveRoute } from '../../actions/RoutesAction';
import { serverGetContacts } from '../../actions/ContactsAction';
import { serverGetQuestionnaires } from "../../actions/QuestionnairesAction";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class CreateRouteStepsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            route_step_details: [],
            route_steps_list: [],
            contacts_dropdown: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.newRouteStep = this.newRouteStep.bind(this);
        this.deleteRouteStep = this.deleteRouteStep.bind(this);
        this.editRouteStep = this.editRouteStep.bind(this);
        this.saveRoute = this.saveRoute.bind(this);
        this.questionnaireInfo = this.questionnaireInfo.bind(this);
        this.explode = this.explode.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        }else{
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (!this.props.questionnaires.questionnaires.length) {
            this.props.serverGetQuestionnaires(this.props.auth.user.token);
        }

        if (!this.props.routes.single_route.title) {
            this.props.history.push("/view_routes");
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.contacts !== this.props.contacts) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (prevProps.auth.clear_data !== this.props.auth.clear_data) {
            if (this.props.auth.clear_data === "true") {


                this.clearModalContents();

                this.props.clearData("false");
            }
        }

        if (prevProps.routes.single_route_step !== this.props.routes.single_route_step) {

            // this.setState({ single_route: [...this.props.routes.single_route] });

            $("#dismiss_new_route_step_modal").click();
            $("#dismiss_edit_route_step_modal").click();

            this.clearModalContents();

        }

        if (prevProps.routes.routes !== this.props.routes.routes) {

            this.props.history.push("/view_routes");

        }
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            route_step_details: {                   // object that we want to update
                ...prevState.route_step_details,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));
    }

    setModalContent = (data, index) => {

        data['index'] = index;

        this.setState(prevState => ({
            route_step_details: data
        }));
    }

    clearModalContents = () => {

        this.setState(prevState => ({
            route_step_details: []
        }));

        return;
    }

    newRouteStep = () => {

        // check entry
        if (!this.state.route_step_details.recipient || !this.state.route_step_details.deadline
            || !this.state.route_step_details.autoforward || !this.state.route_step_details.can_close) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverKeepRouteStep(this.state.route_step_details);
    }

    deleteRouteStep = (index) => {
        // console.log(index);
        this.props.serverRemoveRouteStep(index);
    }

    editRouteStep = () => {

        // check entry
        if (!this.state.route_step_details.recipient || !this.state.route_step_details.deadline
            || !this.state.route_step_details.autoforward || !this.state.route_step_details.can_close) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverEditRouteStep(this.state.route_step_details);
    }

    saveRoute = () => {
        this.props.serverSaveRoute(this.props.routes.single_route, this.props.routes.single_route_step, this.props.auth.user.token)
    }

    questionnaireTitle = (questionnaire_id) => {
        if (questionnaire_id) {
            let title = this.props.questionnaires.questionnaires.filter((data) => data.questionnaire_id + "" === questionnaire_id + "");
            if (title.length) {
                return title[0].title;
            }
        }

        return null;
    }

    questionnaireInfo = (questionnaire_id) => {
        if (questionnaire_id) {
            let questionnaire = this.props.questionnaires.questionnaires.filter((data, index) => {
                if (data.questionnaire_id + "" === questionnaire_id + "") {
                    return data;
                }
            });

            return questionnaire[0];
        }

        return [];
    }

    explode = (field_options) => {

        if (field_options) {
            let options = [];

            if (field_options.includes('@')) {
                options = field_options.split('@');
            } else {
                options = [field_options];
            }

            return options;
        }

        return [];
    }

    render() {
        return (
            <>
                <Main>
                    <RouteSteps
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        routes={this.props.routes}
                        questionnaires={this.props.questionnaires}
                        handleChange={this.handleChange}
                        set_modal_content={this.setModalContent}
                        clear_modal_contents={this.clearModalContents}
                        new_route_step={this.newRouteStep}
                        delete_route_step={this.deleteRouteStep}
                        edit_route_step={this.editRouteStep}
                        save_route={this.saveRoute}
                        questionnaire_title={this.questionnaireTitle}
                        questionnaire_info={this.questionnaireInfo}
                        explode={this.explode}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        routes: { ...state.routes },
        contacts: { ...state.contacts },
        questionnaires: { ...state.questionnaires },
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        serverGetTags,
        serverGetContacts,
        clearData,
        // checkUser,
        serverKeepRouteStep,
        serverRemoveRouteStep,
        serverEditRouteStep,
        serverSaveRoute,
        serverGetQuestionnaires
    }
)(CreateRouteStepsContainer)
