/* eslint-disable array-callback-return */
import React, { Component } from 'react'

import Departments from './Departments';
import Main from './../LayoutComponent/Main';
import { connect } from 'react-redux';
// import { checkUser } from '../../actions/AuthAction';
import { serverLoad, serverGetDepartments, serverAddDepartment, serverDeleteDepartment, serverEditDepartment } from '../../actions/DepartmentsAction'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class DepartmentsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            department_details: {},
            departments_list: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.editDepartment = this.editDepartment.bind(this);
        this.newDepartment = this.newDepartment.bind(this);
        this.searchDepartments = this.searchDepartments.bind(this);
        this.reload = this.reload.bind(this);
        this.editDepartment = this.editDepartment.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/")
        }

        if (!this.props.departments.departments.length) {
            this.props.serverGetDepartments(this.props.auth.user.token);
        } else {
            this.setState({ departments_list: this.props.departments.departments });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.departments.departments !== this.props.departments.departments) {

            this.setState({ departments_list: this.props.departments.departments });

            $("#dismiss_new_department_modal").click();
            $("#dismiss_edit_department_modal").click();
        }
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            department_details: {                   // object that we want to update
                ...prevState.department_details,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));
    }

    editDepartment = () => {
        if (!this.state.department_details.title || !this.state.department_details.code) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (!this.state.department_details.code.endsWith("/")) {
            toast.error(t("Reference number must end with '/'", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverEditDepartment(this.state.department_details, this.props.auth.user.token);
    }

    newDepartment = () => {
        if (!this.state.department_details.department || !this.state.department_details.reference) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (!this.state.department_details.reference.endsWith("/")) {
            toast.error(t("Reference number must end with '/'", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverAddDepartment(this.state.department_details, this.props.auth.user.token);
    }

    searchDepartments = (value) => {
        // eslint-disable-next-line array-callback-return
        let new_departments = this.props.departments.departments.filter((data, index) => {
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        this.setState({ departments_list: new_departments });
    }

    reload = () => {
        this.props.serverGetDepartments(this.props.auth.user.token);
    }

    deleteDepartment = (id) => {
        this.props.serverDeleteDepartment({ dept_id: id }, this.props.auth.user.token);
    }

    setModalContent = (data) => {
        this.setState({
            department_details: data
        });
    }

    clearModalContents = () => {
        this.setState({ department_details: {} });

        return;
    }

    render() {
        return (
            <>
                <Main>
                    <Departments
                        c_state={{ ...this.state }}
                        departments={this.props.departments}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        edit_department={this.editDepartment}
                        new_department={this.newDepartment}
                        search_departments={this.searchDepartments}
                        reload={this.reload}
                        delete_department={this.deleteDepartment}
                        set_modal_content={this.setModalContent}
                        clear_modal_contents={this.clearModalContents}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        departments: { ...state.departments },
        contacts: { ...state.contacts },
    }
}

export default connect(mapStateToProps, {
    // checkUser,
    serverLoad,
    serverGetDepartments,
    serverAddDepartment,
    serverDeleteDepartment,
    serverEditDepartment
})(DepartmentsContainer)


