import React from 'react'
import { Link } from 'react-router-dom'
// import types from '../../types';
import Select from 'react-select';
import { getTrans as t } from '../../translations';

const NewQuestionnaire = (props) => {

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Add New Questionnaire', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item"><Link to="/questionnaires">{t('View Questionnaires', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('New Questionnaire', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">{t('Add New Questionnnaire', props.auth.language)}</h3>
                                    <div align="right">
                                        {
                                            props.c_state.questions.length && props.c_state.questionnaire_details.title ?
                                                <div>
                                                    <span>{props.questionnaires.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                                                    <button className="btn btn-sm btn-outline-primary" title={t('Save Questionnaire', props.auth.language)} disabled={props.questionnaires.isLoading === "true" ? true : false} onClick={props.finish}><i className="fas fa-save"></i> {t('Finish', props.auth.language)}</button>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                {
                                    props.questionnaires.isLoading === "true" ?
                                        <div align="center">
                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading some data. Please wait...', props.auth.language)}</p></span>
                                        </div>
                                        :
                                        <div className="card-body" style={{ margin: 10 }}>
                                            <div className="form-group col-12">
                                                <label htmlFor="title">{t('Questionnaire Title', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.questionnaire_details.title ? props.c_state.questionnaire_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value })} className="form-control" name="title" placeholder={t('Questionnaire Title', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                                <Select
                                                    options={props.c_state.tags_dropdown}
                                                    name="tags"
                                                    value={props.c_state.questionnaire_details.tags ? props.c_state.questionnaire_details.tags : ''}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "tags", "value": value })}
                                                    isMulti
                                                    style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select" />
                                            </div>
                                            <br />
                                            <hr />
                                            <div className="card-header">
                                                <h3 className="card-title">{t('Questions', props.auth.language)} </h3>

                                                <div className="card-tools">
                                                    <button className="btn btn-sm btn-outline-info" title={t('Add Question', props.auth.language)} data-toggle="modal" data-target="#add_question_modal" onClick={props.clear_modal_contents}><i className="fas fa-plus"></i> {t('Add Question', props.auth.language)}</button>
                                                </div>
                                            </div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Question</th>
                                                        <th>Field Type</th>
                                                        <th>Field Options</th>
                                                        <th>Required?</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.c_state.questions.map((data, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{data.question}</td>
                                                                    <td>{data.field_type}</td>
                                                                    <td>{data.field_options}</td>
                                                                    <td>{data.required}</td>
                                                                    <td className="align-middle">
                                                                        <div className="btn-group btn-group-sm">
                                                                            <button className="btn btn-info" title={t('Edit Question', props.auth.language)} data-toggle="modal" data-target="#edit_question_modal" onClick={() => props.set_modal_content({ ...data, "index": i })}><i className="fas fa-edit"></i></button>&nbsp;
                                                                            <button className="btn btn-danger" title={t('Delete Question', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)}?`) ? props.delete_question(i) : console.log(i)}><i className="fas fa-trash"></i></button>&nbsp;
                                                                            {i !== 0 ?
                                                                                <><button className="btn btn-default" title={t('Move Up', props.auth.language)} onClick={() => props.move_up({ ...data, index: i + 1 })}><i className="fas fa-arrow-up"></i></button>&nbsp;</>
                                                                                : null}
                                                                            {props.c_state.questions.length - 1 !== i ?
                                                                                <><button className="btn btn-default" title={t('Move Down', props.auth.language)} onClick={() => props.move_down({ ...data, index: i + 1 })}><i className="fas fa-arrow-down"></i></button>&nbsp;</>
                                                                                : null}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <hr />
                                            <hr />
                                            {
                                                props.c_state.questions.length && props.c_state.questionnaire_details.title ?
                                                    <div align="center">
                                                        <span>{props.questionnaires.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                                                        <button className="btn btn-primary" title={t('Save Questionnaire', props.auth.language)} disabled={props.questionnaires.isLoading === "true" ? true : false} onClick={props.finish}><i className="fas fa-save"></i> {t('Finish', props.auth.language)}</button>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="add_question_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add Question', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="question">{t('Question', props.auth.language)} *</label>
                                        <input type="text" onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value })} className="form-control" value={props.c_state.questionnaire_details.question ? props.c_state.questionnaire_details.question : ''} placeholder={t('Type your question', props.auth.language)} />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="field_type">{t('Field Type', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value })} value={props.c_state.questionnaire_details.field_type ? props.c_state.questionnaire_details.field_type : ''}>
                                            <option value="" disabled selected>{t('--select--', props.auth.language)}</option>
                                            <option value="Text Field">{t('Text Field', props.auth.language)}</option>
                                            <option value="Dropdown Select">{t('Dropdown Select', props.auth.language)}</option>
                                            <option value="Option Buttons">{t('Option Buttons', props.auth.language)}</option>
                                        </select>
                                    </div>
                                    {
                                        props.c_state.questionnaire_details.field_type ?
                                            props.c_state.questionnaire_details.field_type === "Dropdown Select" || props.c_state.questionnaire_details.field_type === "Option Buttons" ?
                                                <div className="form-group col-12">
                                                    <label htmlFor="field_options">{t('Field Options', props.auth.language)} *</label>
                                                    <textarea type="text" onChange={(event) => props.handleChange({ "field": "field_options", "value": event.target.value })} className="form-control" placeholder={t('Enter options separated with "@" symbol. E.g. Bad @ Good @ Very Good @ Excellent', props.auth.language)} value={props.c_state.questionnaire_details.field_options ? props.c_state.questionnaire_details.field_options : ''} />
                                                </div>
                                                : null
                                            : null
                                    }
                                    <div className="form-group col-12">
                                        <label htmlFor="required">{t('Is The Field Required?', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "required", "value": event.target.value })} value={props.c_state.questionnaire_details.required ? props.c_state.questionnaire_details.required : ''}>
                                            <option value="" disabled selected>{t('--select--', props.auth.language)}</option>
                                            <option value="Yes">{t('Yes', props.auth.language)}</option>
                                            <option value="No">{t('No', props.auth.language)}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_question_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                    <span><button type="button" className="btn btn-primary" onClick={props.add_question}>{t('Save', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_question_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Question', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="question">{t('Question', props.auth.language)} *</label>
                                        <input type="text" onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value })} className="form-control" value={props.c_state.questionnaire_details.question ? props.c_state.questionnaire_details.question : ''} placeholder={t('Type your question', props.auth.language)} />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="field_type">{t('Field Type', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value })} value={props.c_state.questionnaire_details.field_type ? props.c_state.questionnaire_details.field_type : ''}>
                                            <option value="" disabled selected>{t('--select--', props.auth.language)}</option>
                                            <option value="Text Field">{t('Text Field', props.auth.language)}</option>
                                            <option value="Dropdown Select">{t('Dropdown Select', props.auth.language)}</option>
                                            <option value="Option Buttons">{t('Option Buttons', props.auth.language)}</option>
                                        </select>
                                    </div>
                                    {
                                        props.c_state.questionnaire_details.field_type ?
                                            props.c_state.questionnaire_details.field_type === "Dropdown Select" || props.c_state.questionnaire_details.field_type === "Option Buttons" ?
                                                <div className="form-group col-12">
                                                    <label htmlFor="field_options">{t('Field Options', props.auth.language)} *</label>
                                                    <textarea type="text" onChange={(event) => props.handleChange({ "field": "field_options", "value": event.target.value })} className="form-control" placeholder={t('Enter options separated with commas. E.g. Very Bad, Bad, Good, Very Good, Excellent', props.auth.language)} value={props.c_state.questionnaire_details.field_options ? props.c_state.questionnaire_details.field_options : ''} />
                                                </div>
                                                : null
                                            : null
                                    }
                                    <div className="form-group col-12">
                                        <label htmlFor="required">{t('Is The Field Required?', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "required", "value": event.target.value })} value={props.c_state.questionnaire_details.required ? props.c_state.questionnaire_details.required : ''}>
                                            <option value="" disabled selected>{t('--select--', props.auth.language)}</option>
                                            <option value="Yes">{t('Yes', props.auth.language)}</option>
                                            <option value="No">{t('No', props.auth.language)}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_question_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                    <span><button type="button" className="btn btn-primary" onClick={props.edit_question}>{t('Save', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default NewQuestionnaire