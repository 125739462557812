import React from 'react'
import { Link } from 'react-router-dom'
// import types from '../../types';
// import Select from 'react-select';
import { getTrans as t } from '../../translations';

const Questionnaires = (props) => {

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Questionnaires', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Questionnaires', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">{t('Manage Questionnaires For Correspondence Routing', props.auth.language)}</h3>
                                    <div align="right">
                                        <Link to="/new_questionnaire" type="button" className="btn btn-sm btn-info"><i className="fa fa-plus"></i> {t('New Questionnaire', props.auth.language)}</Link>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-warning" onClick={props.reload}><i className="fa fa-sync"></i> {t('Reload', props.auth.language)}</button>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-default">
                                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_docs(event.target.value)} />
                                                <div className="input-group-append">
                                                    <Link to="#" type="submit" disabled className="btn btn-sm btn-default">
                                                        <i className="fa fa-search"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                {
                                    props.questionnaires.isLoading === "true" ?
                                        <div align="center">
                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading some data. Please wait...', props.auth.language)}</p></span>
                                        </div>
                                        :
                                        <div className="card-body p-0">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>{t('Questionnaires', props.auth.language)}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.questionnaires.questionnaires.map((data, index) => {
                                                            return (
                                                                data.usage_type === null || data.usage_type === "" ?
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div id="accordion">
                                                                                <a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>{data.title}</a>

                                                                                <div id={`collapse${index}`} className="collapse" data-parent={"#accordion"}>
                                                                                    <div className="card-body">
                                                                                        <div className="row">
                                                                                            {
                                                                                                data.questions ?
                                                                                                    // eslint-disable-next-line array-callback-return
                                                                                                    data.questions.map((question, i) => {
                                                                                                        return (
                                                                                                            <div key={i} className="form-group col-md-4">
                                                                                                                <label htmlFor={question.question}>{question.question} {question.required === "Yes" ? "*" : null}</label><br />
                                                                                                                {
                                                                                                                    question.field_type === "Text Field" ?
                                                                                                                        <textarea className="form-control" />
                                                                                                                        :
                                                                                                                        question.field_type === "Option Buttons" ?
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    props.explode(question.field_options).map((item, ind) => {
                                                                                                                                        return (
                                                                                                                                            <><label key={ind}>{item.replace(/ /g, '')} <input type="radio" name={question.question} disabled /></label>&nbsp;&nbsp;</>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            question.field_type === "Dropdown Select" ?
                                                                                                                                <select className="form-control">
                                                                                                                                    <option value="" selected disabled></option>
                                                                                                                                    {
                                                                                                                                        props.explode(question.field_options).map((item, ind) => {
                                                                                                                                            return (
                                                                                                                                                <option value={item.replace(/ /g, '')}>{item.replace(/ /g, '')}</option>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </select>
                                                                                                                                : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <div className="btn-group btn-group-sm">
                                                                                <button className="btn btn-success" title={t('Edit Questionnaire', props.auth.language)} onClick={() => props.edit_questionnaire(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                {/* <button className="btn btn-info" title={t('Add Question', props.auth.language)} data-toggle="modal" data-target="#add_question_modal" onClick={() => props.clear_modal_content(data)}><i className="fas fa-plus"></i></button>&nbsp; */}
                                                                                <button className="btn btn-danger" title={t('Delete Questionnaire', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.title}?`) ? props.delete_questionnaire({ "id": data.questionnaire_id, "category": "questionnaire" }) : ''}><i className="fas fa-trash"></i></button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    : null
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* modals will come here */}

                    {/* <div className="modal fade" id="edit_questionnaire_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Questionnaire', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="name">{t('Title', props.auth.language)} *</label>
                                        <input type="text" value={props.c_state.questionnaire_details.title ? props.c_state.questionnaire_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value })} className="form-control" placeholder={t('Title', props.auth.language)} />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="name">{t('Tags', props.auth.language)} *</label>
                                        <input type="text" value={props.c_state.questionnaire_details.title ? props.c_state.questionnaire_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value })} className="form-control" placeholder={t('Title', props.auth.language)} />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_questionnaire_modal">{t('Close', props.auth.language)}</button>
                                    <button type="button" className="btn btn-primary" onClick={() => props.edit_questionnaire("questionnaire")}>{t('Save changes', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="modal fade" id="edit_question_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Question', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="question">{t('Question', props.auth.language)} *</label>
                                        <input type="text" value={props.c_state.questionnaire_details.question ? props.c_state.questionnaire_details.question : ''} onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value })} className="form-control" placeholder={t('Question', props.auth.language)} />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="field_type">{t('Field Type', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value })} value={props.c_state.questionnaire_details.field_type ? props.c_state.questionnaire_details.field_type : ''}>
                                            <option value="" disabled selected>{t('--select--', props.auth.language)}</option>
                                            <option value="Text Field">{t('Text Field', props.auth.language)}</option>
                                            <option value="Dropdown Select">{t('Dropdown Select', props.auth.language)}</option>
                                            <option value="Option Buttons">{t('Option Buttons', props.auth.language)}</option>
                                        </select>
                                    </div>
                                    {
                                        props.c_state.questionnaire_details.field_type ?
                                            props.c_state.questionnaire_details.field_type === "Dropdown Select" || props.c_state.questionnaire_details.field_type === "Option Buttons" ?
                                                <div className="form-group col-12">
                                                    <label htmlFor="field_options">{t('Field Options', props.auth.language)} *</label>
                                                    <textarea type="text" onChange={(event) => props.handleChange({ "field": "field_options", "value": event.target.value })} className="form-control" placeholder={t('Enter options separated with commas. E.g. Very Bad, Bad, Good, Very Good, Excellent', props.auth.language)} value={props.c_state.questionnaire_details.field_options ? props.c_state.questionnaire_details.field_options : ''} />
                                                </div>
                                                : null
                                            : null
                                    }
                                    <div className="form-group col-12">
                                        <label htmlFor="required">{t('Is The Field Required?', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "required", "value": event.target.value })} value={props.c_state.questionnaire_details.required ? props.c_state.questionnaire_details.required : ''}>
                                            <option value="" disabled selected>{t('--select--', props.auth.language)}</option>
                                            <option value="Yes">{t('Yes', props.auth.language)}</option>
                                            <option value="No">{t('No', props.auth.language)}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_question_modal">{t('Close', props.auth.language)}</button>
                                    <button type="button" className="btn btn-primary" onClick={() => props.edit_questionnaire("question")}>{t('Save changes', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="modal fade" id="add_question_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add Question', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="question">{t('Question', props.auth.language)} *</label>
                                        <input type="text" value={props.c_state.questionnaire_details.question ? props.c_state.questionnaire_details.question : ''} onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value })} className="form-control" placeholder={t('Question', props.auth.language)} />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="field_type">{t('Field Type', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value })} value={props.c_state.questionnaire_details.field_type ? props.c_state.questionnaire_details.field_type : ''}>
                                            <option value="" disabled selected>{t('--select--', props.auth.language)}</option>
                                            <option value="Text Field">{t('Text Field', props.auth.language)}</option>
                                            <option value="Dropdown Select">{t('Dropdown Select', props.auth.language)}</option>
                                            <option value="Option Buttons">{t('Option Buttons', props.auth.language)}</option>
                                        </select>
                                    </div>
                                    {
                                        props.c_state.questionnaire_details.field_type ?
                                            props.c_state.questionnaire_details.field_type === "Dropdown Select" || props.c_state.questionnaire_details.field_type === "Option Buttons" ?
                                                <div className="form-group col-12">
                                                    <label htmlFor="field_options">{t('Field Options', props.auth.language)} *</label>
                                                    <textarea type="text" onChange={(event) => props.handleChange({ "field": "field_options", "value": event.target.value })} className="form-control" placeholder={t('Enter options separated with commas. E.g. Very Bad, Bad, Good, Very Good, Excellent', props.auth.language)} value={props.c_state.questionnaire_details.field_options ? props.c_state.questionnaire_details.field_options : ''} />
                                                </div>
                                                : null
                                            : null
                                    }
                                    <div className="form-group col-12">
                                        <label htmlFor="required">{t('Is The Field Required?', props.auth.language)} *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "required", "value": event.target.value })} value={props.c_state.questionnaire_details.required ? props.c_state.questionnaire_details.required : ''}>
                                            <option value="" disabled selected>{t('--select--', props.auth.language)}</option>
                                            <option value="Yes">{t('Yes', props.auth.language)}</option>
                                            <option value="No">{t('No', props.auth.language)}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_question_modal">{t('Close', props.auth.language)}</button>
                                    <button type="button" className="btn btn-primary" onClick={props.add_question}>{t('Save changes', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Questionnaires