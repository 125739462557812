import types from './../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.QUESTIONNAIRES_LOADING,
            payload: action
        })
    }
}

export const serverSingleQuestionnaire = (data) => {
    return (dispatch) => {
        return dispatch({
            type: types.QUESTIONNAIRES_SUCCESS,
            payload: {
                "success": "true", 
                "msg": "Single Questionnaire",
                "data": data
            }
        })
    }
}

export const serverSaveQuestionnaire = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.QUESTIONNAIRES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'save_questionnaire',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.QUESTIONNAIRES,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.QUESTIONNAIRES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.QUESTIONNAIRES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.QUESTIONNAIRES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverGetQuestionnaires = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.QUESTIONNAIRES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_questionnaires',
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.QUESTIONNAIRES,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.QUESTIONNAIRES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.QUESTIONNAIRES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.QUESTIONNAIRES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverDeleteQuestionnaire = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.QUESTIONNAIRES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'delete_questionnaire',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.QUESTIONNAIRES,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.QUESTIONNAIRES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.QUESTIONNAIRES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.QUESTIONNAIRES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverEditQuestionnaire = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.QUESTIONNAIRES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        // let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'edit_questionnaire',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.QUESTIONNAIRES,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.QUESTIONNAIRES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.QUESTIONNAIRES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.QUESTIONNAIRES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}

export const serverAddQuestion = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.QUESTIONNAIRES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'add_question',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.QUESTIONNAIRES,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.QUESTIONNAIRES_FAIL,
                    payload: response.data
                });

                dispatch({
                    type: types.QUESTIONNAIRES_LOADING,
                    payload: "false"
                });

                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.QUESTIONNAIRES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            });

            dispatch({
                type: types.QUESTIONNAIRES_LOADING,
                payload: "false"
            });

            return;
        })
    }
}
