import React, { Component } from 'react'
import Questionnaires from './Questionnaires';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { serverGetQuestionnaires, serverDeleteQuestionnaire, serverSingleQuestionnaire, serverAddQuestion } from '../../actions/QuestionnairesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class QuestionnairesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questionnaire_details: {},
            tags_dropdown: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.reload = this.reload.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContent = this.clearModalContent.bind(this);
        this.deleteQuestionnaire = this.deleteQuestionnaire.bind(this);
        this.editQuestionnaire = this.editQuestionnaire.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.explode = this.explode.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.questionnaires.questionnaires.length) {
            this.props.serverGetQuestionnaires(this.props.auth.user.token);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.questionnaires.questionnaires !== this.props.questionnaires.questionnaires) {
            this.setState({ questionnaire_details: {} });
        }

        if (prevProps.questionnaires.single_questionnaire !== this.props.questionnaires.single_questionnaire) {
            this.props.history.push("/edit_questionnaire");
        }

    }

    handleChange = (data) => {
        this.setState(prevState => ({
            questionnaire_details: {
                ...prevState.questionnaire_details,
                [data.field]: data.value
            }
        }));
    }

    reload = () => {
        this.props.serverGetQuestionnaires(this.props.auth.user.token);
    }

    setModalContent = (data) => {
        let questionnaire_details = { ...this.state.questionnaire_details, ...data };
        this.setState({ questionnaire_details: questionnaire_details });
    }

    clearModalContent = (data) => {
        this.setState({ questionnaire_details: data });
    }

    deleteQuestionnaire = (data) => {
        this.props.serverDeleteQuestionnaire(data, this.props.auth.user.token);
    }

    editQuestionnaire = (data) => {
        this.props.serverSingleQuestionnaire({ ...data, rand: (Math.random() + 1).toString(36).substring(7) }, this.props.auth.user.token);
    }

    addQuestion = () => {
        let questionnaire_details = { ...this.state.questionnaire_details };

        // check required fields
        if (!questionnaire_details.question || !questionnaire_details.field_type || !questionnaire_details.required) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if ((questionnaire_details.field_type === "Dropdown Select" || questionnaire_details.field_type === "Option Buttons") && !questionnaire_details.field_options) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        $("#dismiss_add_question_modal").click();

        this.props.serverAddQuestion(questionnaire_details, this.props.auth.user.token);
    }

    explode = (field_options) => {
        // let options = field_options.replace(/ /g, '');

        if (field_options) {
            let options = [];

            if (field_options.includes('@')) {
                options = field_options.split('@');
            } else {
                options = [field_options];
            }

            return options;
        }

        return [];
    }

    render() {
        return (
            <>
                <Main>
                    <Questionnaires
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        questionnaires={this.props.questionnaires}
                        handleChange={this.handleChange}
                        reload={this.reload}
                        set_modal_content={this.setModalContent}
                        clear_modal_content={this.clearModalContent}
                        delete_questionnaire={this.deleteQuestionnaire}
                        edit_questionnaire={this.editQuestionnaire}
                        add_question={this.addQuestion}
                        explode={this.explode}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        questionnaires: { ...state.questionnaires },
    }
}

export default connect(mapStateToProps,
    {
        serverGetQuestionnaires,
        serverDeleteQuestionnaire,
        serverSingleQuestionnaire,
        serverAddQuestion
    }
)(QuestionnairesContainer)
