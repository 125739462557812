/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom'
import types from '../../types';
// import TextField from '@material-ui/core/TextField';
import Select from 'react-select'
import { getTrans as t } from '../../translations';
import { DocumentEditor } from "@onlyoffice/document-editor-react";

var onDocumentReady = function (event) {
    console.log("Document is loaded");
};

const EditTemplate = (props) => {

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Edit Template', props.auth.language)} {props.c_state.single_template.templates ? `(${props.c_state.single_template.templates.template_name})` : ''}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item"><Link to="/view_templates">{t('View Templates', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Edit Template', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="card" >
                        <div className="card-header">
                            <h3 className="card-title">{t('Update Your Template', props.auth.language)}</h3>
                            <div align="right">
                                {props.docs_config.document ? <><a className="btn btn-sm btn-outline-info" href={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.docs_config)}`)} target="_blank" rel="noreferrer"><i className="fa fa-file"></i> {t('Open Document In New Tab', props.auth.language)}</a>&nbsp;</> : null}
                            </div>
                        </div>
                        <div className="card card-outline">
                            {props.docs_config.document ?
                                <div className="row">
                                    <div className="col-9">

                                        <div className="embed-responsive embed-responsive-16by9" style={{ height: 1500 }}>
                                            {/* <iframe
                                                className="embed-responsive-item"
                                                title="doc_viewer"
                                                src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.docs_config)}`)}
                                                allowFullScreen>
                                            </iframe> */}
                                            <DocumentEditor
                                                id="docxEditor"
                                                documentServerUrl="https://offices.ropatsystems.com"
                                                config={props.docs_config}
                                                events_onDocumentReady={onDocumentReady}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-3">
                                        <div align="center">
                                            <br />
                                            <button className="btn btn-sm btn-outline-danger" data-toggle="modal" data-target="#add_placeholders_modal" onClick={props.clear_modal_contents}><i className="fa fa-plus"></i> {t('Add Placeholders', props.auth.language)}</button>
                                        </div>
                                        <br /><br />
                                        {props.c_state.placeholder_table ?
                                            <table width="100%" className="table">
                                                <tbody>
                                                    {
                                                        props.c_state.placeholder_table.map((val, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td align="left"><span>{val.placeholder}</span></td>
                                                                    <td align="right">
                                                                        <button className="btn btn-xs btn-outline-primary" data-toggle="modal" data-target="#edit_placeholders_modal" onClick={() => props.set_modal_content(val, index)}>{t('View/Edit', props.auth.language)}</button>
                                                                        <button className="btn btn-xs btn-outline-danger" onClick={() => props.delete_placeholder(index)}>{t('Delete', props.auth.language)}</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : null}
                                        {
                                            props.c_state.placeholder_table.length ?
                                                <>
                                                    <div className="row">
                                                        <div className="form-group col-12">
                                                            <label htmlFor="category">{t('Template Name', props.auth.language)} *</label>
                                                            <input className="form-control" type="text" style={{ width: '95%' }} placeholder={t('Template Name', props.auth.language)} value={props.c_state.template_details.template_name ? props.c_state.template_details.template_name : ''} onChange={(event) => props.handleChange({ "field": "template_name", "value": event.target.value }, 'template')} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-12">
                                                            <label htmlFor="category">{t('Tags', props.auth.language)} </label>
                                                            <Select
                                                                options={props.c_state.tags_dropdown}
                                                                name="tags"
                                                                value={props.c_state.template_details.tags ? typeof props.c_state.template_details.tags !== "string" ? props.c_state.template_details.tags : JSON.parse(props.c_state.template_details.tags.replace(/'/g, '"')) : ''}
                                                                id="custom_select"
                                                                onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'template')}
                                                                isMulti
                                                                style={{ width: '95%' }}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder={t('Select', props.auth.language)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div align="center">
                                                        <br />
                                                        <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button className="btn btn-block btn-primary" style={{ width: '95%' }} disabled={props.isLoading === "true" ? true : false} onClick={props.save_placeholders}><i className="fa fa-save"></i> {t('Save', props.auth.language)}</button></span>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>

                    {/* modals will come here */}
                    <div className="modal fade" id="add_placeholders_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add Placeholder', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="placeholder">{t('Placeholder', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.placeholder_details.placeholder ? props.c_state.placeholder_details.placeholder : ''} onChange={(event) => props.handleChange({ "field": "placeholder", "value": event.target.value }, 'placeholder')} className="form-control" name="placeholder" placeholder="Placeholder" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="field_type">{t('Field Type', props.auth.language)} *</label>
                                            <select className="form-control" name="field_type" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value }, 'placeholder')} value={props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="text">{t('Text', props.auth.language)}</option>
                                                <option value="textarea">{t('Text Area', props.auth.language)}</option>
                                                <option value="drop_down">{t('Drop Down', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type === 'drop_down' ?
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label htmlFor="options">{t('Options', props.auth.language)} *</label>
                                                    <textarea onChange={(event) => props.handleChange({ "field": "options", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.options ? props.c_state.placeholder_details.options : ''} className="form-control" name="options" placeholder={t('Options separated by commas. e.g. Book, Pen, Pencil', props.auth.language)}></textarea>
                                                </div>
                                            </div>
                                            : null
                                            : null
                                    }
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="question">{t('Question', props.auth.language)} *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.question ? props.c_state.placeholder_details.question : ''} className="form-control" name="question" placeholder={t('Type your question here...', props.auth.language)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_placeholder_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.add_placeholder}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_placeholders_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Placeholder', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="placeholder">{t('Placeholder', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.placeholder_details.placeholder ? (props.c_state.placeholder_details.placeholder.startsWith('[') && props.c_state.placeholder_details.placeholder.endsWith(']')) ? props.c_state.placeholder_details.placeholder.slice(1, -1) : props.c_state.placeholder_details.placeholder : ''} onChange={(event) => props.handleChange({ "field": "placeholder", "value": event.target.value }, 'placeholder')} className="form-control" name="placeholder" placeholder="Placeholder" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="field_type">{t('Field Type', props.auth.language)} *</label>
                                            <select className="form-control" name="field_type" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value }, 'placeholder')} value={props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="text">{t('Text', props.auth.language)}</option>
                                                <option value="textarea">{t('Text Area', props.auth.language)}</option>
                                                <option value="drop_down">{t('Drop Down', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type === 'drop_down' ?
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label htmlFor="options">{t('Options', props.auth.language)} *</label>
                                                    <textarea onChange={(event) => props.handleChange({ "field": "options", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.options ? props.c_state.placeholder_details.options : ''} className="form-control" name="options" placeholder={t('Options separated by commas. e.g. Book, Pen, Pencil', props.auth.language)}></textarea>
                                                </div>
                                            </div>
                                            : null
                                            : null
                                    }
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="question">{t('Question', props.auth.language)} *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.question ? props.c_state.placeholder_details.question : ''} className="form-control" name="question" placeholder={t('Type your question here...', props.auth.language)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_placeholder_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_placeholder}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default EditTemplate
