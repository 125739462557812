import React, { Component } from 'react'
import ViewTemplates from './ViewTemplates';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { serverGetTags } from '../../actions/TagsAction';
import { serverLoad, clearData } from '../../actions/AuthAction'
import { serverGetTemplates, serverDeleteTemplate, serverEditTemplate, serverUseTemplate, serverCloneTemplate } from '../../actions/TemplatesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import types from '../../types';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class ViewTemplatesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template_list: [],
            template_details: [],
            template_preview: {},
            answers: [],
            document_name: '',
            tags: [],
            tags_dropdown: [],
            search_params: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.editTemplate = this.editTemplate.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.setPreviewContent = this.setPreviewContent.bind(this);
        this.useTemplate = this.useTemplate.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.searchTemplate = this.searchTemplate.bind(this);
        this.searchTemplateByTags = this.searchTemplateByTags.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.cloneTemplate = this.cloneTemplate.bind(this);
    }

    componentDidMount() {

        // console.log(this.props);

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.templates.templates.length) {
            this.props.serverGetTemplates(this.props.auth.user.token);
        } else {
            this.setState({ template_list: [...this.props.templates.templates] });
        }


        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.templates !== this.props.templates) {

            this.setState({ template_list: [...this.props.templates.templates] });
            $("#dismiss_clone_template_modal").click();

            this.setState({ template_details: {} });
        }

        if (prevProps.templates.single_template !== this.props.templates.single_template) {
            if (this.props.templates.edit_templates_config.document) {
                this.props.history.push("/edit_template");
            }
        }

        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                // $("#btn-show-doc-modal").click();

                this.clearModalContents();

                // open document in new tab
                window.open(encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(this.props.templates.use_docs_config)}`), '_blank', 'noopener,noreferrer')
            }
        }
    }

    handleChange = (data, type) => {
        switch (type) {
            case 'answers':
                this.setState(prevState => ({
                    answers: {                   // object that we want to update
                        ...prevState.answers,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            case 'document_name':
                this.setState({ document_name: data })
                break;
            case 'tags':
                this.setState({ tags: data })
                break;
            case 'search_params':
                this.setState({ search_params: data })
                break;
            case 'template':
                this.setState(prevState => ({
                    template_details: {                   // object that we want to update
                        ...prevState.template_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            default:
                break;
        }
    }

    editTemplate = (data) => {
        // console.log(data);
        this.props.serverEditTemplate(data);
    }

    deleteTemplate = (tid) => {
        // this.props.serverLoad("true");
        this.props.serverDeleteTemplate(tid, this.props.auth.user.token);
    }

    setModalContent = (data) => {
        let template_details = data;

        switch (data.templates.ext) {
            case '.docx':
                template_details['document_type'] = "word";
                break;
            case '.pptx':
                template_details['document_type'] = "powerpoint";
                break;
            case '.xlsx' || '.csv':
                template_details['document_type'] = "excel";
                break;
            default:
                template_details['document_type'] = "word";
                break;
        }

        template_details['category'] = data.templates.category;
        template_details['tags'] = data.templates.tags ? typeof data.templates.tags !== "string" ? data.templates.tags : JSON.parse(data.templates.tags.replace(/'/g, '"')) : null;

        this.setState({ template_details: template_details });
    }

    useTemplate = () => {
        if (!this.state.document_name || !this.state.answers) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        // this.props.serverLoad("true");
        this.props.serverUseTemplate(
            this.state.template_details.templates.tid,
            this.state.document_name,
            this.state.tags,
            this.state.answers,
            this.props.auth.user.token
        );
    }

    clearModalContents = () => {
        this.setState({ answers: [], document_name: '' });

        return;
    }

    searchTemplate = (value) => {
        // eslint-disable-next-line array-callback-return
        let new_templates = this.props.templates.templates.filter((data, index) => { // loop through state.todo
            data = data.templates
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        this.setState({ template_list: new_templates });
    }

    doCompare = (selected_tags, contacts) => {
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i].templates;
            let all = contacts[i];
            let isValid = false
            if (el.tags) {
                let tags = JSON.parse(el.tags.replace(/'/g, '"'));

                // check if all selected tags exist in contacts
                //  let myValues = {...value}

                for (let ind = 0; ind < tags.length; ind++) {
                    const elt = tags[ind];
                    if (value[elt.value]) {
                        isValid = true;
                        break;
                    }

                    // check if all selected tags exist in contacts
                    // if (myValues[elt.value]) {
                    //     delete myValues[elt.value]
                    // }
                }
            }

            if (isValid) {

                all['templates'] = el;

                results.push(all);
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }

    searchTemplateByTags = () => {
        if (this.state.search_params) {
            let new_templates = this.doCompare(this.state.search_params, this.props.templates.templates);
            this.setState({ template_list: new_templates });
            $("#dismiss_search_params_modal").click();
            // console.log(this.props.templates.templates, new_templates);
        }
    }

    reload = () => {
        this.props.serverGetTemplates(this.props.auth.user.token);
        this.props.serverGetTags(this.props.auth.user.token);
    }

    cloneTemplate = () => {
        this.props.serverCloneTemplate(this.state.template_details, this.props.auth.user.token);
    }

    setPreviewContent = (data) => {
        this.setState({template_preview: data})
    }

    render() {
        return (
            <>
                <Main>
                    <ViewTemplates
                        c_state={{ ...this.state }}
                        // templates={this.props.templates.templates}
                        auth={this.props.auth}
                        templates={this.props.templates}
                        handleChange={this.handleChange}
                        delete_template={this.deleteTemplate}
                        edit_template={this.editTemplate}
                        search_template={this.searchTemplate}
                        search_template_by_tags={this.searchTemplateByTags}
                        set_modal_content={this.setModalContent}
                        set_preview_content={this.setPreviewContent}
                        use_template={this.useTemplate}
                        reload={this.reload}
                        clone_template={this.cloneTemplate}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        templates: { ...state.templates }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverGetTemplates,
        serverDeleteTemplate,
        serverEditTemplate,
        serverUseTemplate,
        serverCloneTemplate
    }
)(ViewTemplatesContainer)
