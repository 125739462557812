import React from 'react';
import { Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import types from '../../types';
import MicrosoftLogin from "react-microsoft-login";

const Login = (props) => {

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        {/* <Link tp="#" className="h1"><b>i</b>Cubicle</Link> */}
                        <img src="static/assets/dist/img/logo.png" alt="logo" width={50} />
                        <p style={{marginBottom: 0}}><strong>Correspondence Management System</strong></p>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign in to start your session</p>

                        <div className="input-group mb-3">
                            <input type="email" value={props.email} onChange={props.handleChange} name="email" className="form-control" placeholder="Email" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope"></span>
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" value={props.password} onChange={props.handleChange} name="password" className="form-control" placeholder="Password" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row"> */}
                        {/* <div className="col-7">
                                <div className="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        &nbsp;Remember Me
                                    </label>
                                </div>
                            </div> */}
                        {
                            props.isLoading === "true" ?
                                <div align="center">
                                    <img src="static/assets/dist/img/loader.gif" alt="loader" />
                                </div>
                                : ''
                        }
                        <button className="btn btn-primary btn-block" type="button" disabled={props.isLoading === "true" ? true : false} onClick={() => props.login_func()}> Sign In</button>
                        {/* </div> */}
                        <p className="text-center mt-2" style={{ marginBottom: 0 }}>OR</p>
                        <div className="social-auth-links text-center mb-3">
                            {/* <button className="btn btn-block btn-primary"><i className="fab fa-facebook mr-2"></i> Sign in using Facebook</button>
                            <button className="btn btn-block btn-danger"><i className="fab fa-google mr-2"></i> Sign in using Google</button> */}
                            <GoogleLogin
                                clientId={types.GOOGLE_CLIENT_ID}
                                buttonText="Sign in with google"
                                onSuccess={props.onSuccess}
                                onFailure={props.onFailure}
                                cookiePolicy={'single_host_origin'}
                                // style={{ marginTop: '100px' }}
                                isSignedIn={false}
                                className="btn-block"
                            />
                        </div>
                        <MicrosoftLogin className="btn-block" clientId={types.MICROSOFT_CLIENT_ID} authCallback={props.authHandler} useLocalStorageCache={true} />
                        <p className="mb-1 mt-3">
                            <Link to="/reset_password">I forgot my password</Link>
                        </p>
                        {/* <p className="mb-0">
                            <Link to="#" className="text-center">Register a new membership</Link>
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Login