import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { getTrans as t } from '../../translations';

const ViewRoutes = (props) => {

    // console.log(props.routes.all_routes, props.c_state.route_list);

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('View Routes', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('View Routes', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="card" >
                                <div className="card-header">
                                    <h3 className="card-title">{t('Routes', props.auth.language)}</h3>
                                    <div align="right">
                                        {
                                            props.auth.user ?
                                                props.auth.user.privileges.includes("add_private_routes") || props.auth.user.privileges.includes("add_public_routes") ?
                                                    <>
                                                        <button type="button" className="btn btn-sm btn-info" title={t('Add New Route', props.auth.language)} data-toggle="modal" data-target="#new_route_modal" onClick={props.clear_modal_contents}>
                                                            <i className="fas fa-plus"></i> {t('Add New Route', props.auth.language)}
                                                        </button>&nbsp;
                                                    </>
                                                    : null
                                                : null
                                        }
                                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={props.reload}><i className="fa fa-sync"></i> {t('Reload', props.auth.language)}</button>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-success" data-toggle="modal" data-target="#search_tag_modal"><i className="fa fa-filter"></i> {t('Filter By Tags', props.auth.language)}</button>
                                        <button type="button" className="btn btn-sm btn-outline-default">
                                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_routes(event.target.value)} />
                                            </div>
                                        </button>&nbsp;
                                    </div>
                                </div>
                                <div className="card card-primary card-outline card-outline-tabs">
                                    <div className="card-header p-0 border-bottom-0">
                                        <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">{t('All Routes', props.auth.language)}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">{t('Correspondence Routes', props.auth.language)}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-four-tabContent">
                                            <div className="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                                {
                                                    props.routes.isLoading === "true" ?
                                                        <div align="center">
                                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your routes. Please wait...', props.auth.language)}</p></span>
                                                        </div>
                                                        :
                                                        <table id="example1" className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>{t('Name', props.auth.language)}</th>
                                                                    <th>{t('No of Steps', props.auth.language)}</th>
                                                                    <th>{t('Category', props.auth.language)}</th>
                                                                    <th>{t('Action', props.auth.language)}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    // eslint-disable-next-line array-callback-return
                                                                    props.c_state.route_list.map((data, index) => {
                                                                        if (data.correspondence_id === null) {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{data.title}</td>
                                                                                    <td>{data.route_steps.filter(item => item.step_id).length}</td>
                                                                                    <td>{data.category}</td>
                                                                                    <td className="py-0 align-middle">
                                                                                        <div className="btn-group btn-group-sm">
                                                                                            {
                                                                                                props.auth.user ?
                                                                                                    props.auth.user.privileges.includes("edit_public_routes") ?
                                                                                                        <>
                                                                                                            <button className="btn btn-info" title={t('Edit Route', props.auth.language)} onClick={() => props.edit_route(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                                        </>
                                                                                                        : data.created_by + "" === props.auth.user.contact_id + "" ?
                                                                                                            props.auth.user.privileges.includes("edit_private_routes") ?
                                                                                                                <>
                                                                                                                    <button className="btn btn-info" title={t('Edit Route', props.auth.language)} onClick={() => props.edit_route(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                                                </>
                                                                                                                : null
                                                                                                            : null
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                props.auth.user ?
                                                                                                    props.auth.user.privileges.includes("delete_public_routes") ?
                                                                                                        <button className="btn btn-danger" title={t('Delete Route', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.title}?`) ? props.delete_route(data.route_id) : console.log('i')}><i className="fas fa-trash"></i></button>
                                                                                                        : data.created_by + "" === props.auth.user.contact_id + "" ?
                                                                                                            props.auth.user.privileges.includes("delete_private_routes") ?
                                                                                                                <button className="btn btn-danger" title={t('Delete Route', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.title}?`) ? props.delete_route(data.route_id) : console.log('i')}><i className="fas fa-trash"></i></button>
                                                                                                                : null
                                                                                                            : null
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                }
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                                {
                                                    props.routes.isLoading === "true" ?
                                                        <div align="center">
                                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your routes. Please wait...', props.auth.language)}</p></span>
                                                        </div>
                                                        :
                                                        <table id="example1" className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>{t('Name', props.auth.language)}</th>
                                                                    <th>{t('No of Steps', props.auth.language)}</th>
                                                                    <th>{t('Action', props.auth.language)}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    // eslint-disable-next-line array-callback-return
                                                                    props.c_state.route_list.map((data, index) => {
                                                                        if (data.correspondence_id !== null && data.created_by === props.auth.user.contact_id) {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{data.title} (#{data.correspondence_id}#)</td>
                                                                                    <td>{data.route_steps.filter(item => item.step_id).length}</td>
                                                                                    <td className="py-0 align-middle">
                                                                                        <div className="btn-group btn-group-sm">
                                                                                            <button className="btn btn-info" title={t('Edit Route', props.auth.language)} onClick={() => props.edit_route(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                            <button className="btn btn-danger" title={t('Delete Route', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.title}?`) ? props.delete_route(data.route_id) : console.log('i')}><i className="fas fa-trash"></i></button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modals */}

                    <div className="modal fade" id="new_route_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('New Route', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="title">{t('Route Name', props.auth.language)} *</label>
                                        <input type="text" value={props.c_state.route_details.title ? props.c_state.route_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value })} className="form-control" name="title" placeholder={t('Route Name', props.auth.language)} />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="category">{t('Category', props.auth.language)} *</label>
                                        <select className="form-control" value={props.c_state.route_details.category ? props.c_state.route_details.category : ''} onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value })}>
                                            <option value="" disabled>{t('Select Category', props.auth.language)}</option>
                                            <option value="Private">{t('Private', props.auth.language)}</option>
                                            {
                                                props.auth.user ?
                                                    props.auth.user.privileges.includes("add_public_routes") ?
                                                        <option value="Public">{t('Public', props.auth.language)}</option>
                                                        : null
                                                    : null
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                        <Select options={props.c_state.tags_dropdown} name="tags" value={props.c_state.route_details.tags ? props.c_state.route_details.tags : []} id="custom_select" onChange={(value) => props.handleChange({ "field": "tags", "value": value })} isMulti style={{ width: '100%' }} className="basic-multi-select" classNamePrefix="select" />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_new_route_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                                    <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.new_route}>{t('Save', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="search_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="tags"
                                            // value={props.c_state.task_details.tags ? props.c_state.task_details.tags : []}
                                            id="custom_select"
                                            value={typeof props.c_state.route_details.search_params === "string" ? JSON.parse(props.c_state.route_details.search_params.replace(/'/g, '"')) : props.c_state.route_details.search_params}
                                            onChange={(value) => props.handleChange({ "field": "search_params", "value": value })}
                                            isMulti style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select" />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_params_modal">{t('Close', props.auth.language)}</button>
                                    <button type="button" className="btn btn-primary" onClick={props.search_route_by_tags}>{t('Search', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default ViewRoutes
