/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { Link } from 'react-router-dom'
import types from '../../types'
import { getTrans as t } from '../../translations';

const Profile = (props) => {

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Profile', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('User Profile', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3">

                                <div className="card card-primary card-outline">
                                    {
                                        props.auth.user !== null ?
                                            <div className="card-body box-profile">
                                                <div className="text-center">
                                                    <img className="profile-user-img img-fluid img-circle"
                                                        src={props.auth.user.photo !== '' && props.auth.user.photo !== null ? types.SERVER_URL + 'static/contacts/' + props.auth.user.photo : "static/assets/dist/img/user2-160x160.jpg"}
                                                        alt="User profile picture" />
                                                </div>

                                                <h3 className="profile-username text-center">{`${props.auth.user.first_name} ${props.auth.user.last_name}`}</h3>

                                                <p className="text-muted text-center">{props.auth.user.email}</p>

                                                {/* <ul className="list-group list-group-unbordered mb-3">
                                            <li className="list-group-item">
                                                <b>Followers</b> <Link to="#" className="float-right">1,322</Link>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Following</b> <Link to="#" className="float-right">543</Link>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Friends</b> <Link to="#" className="float-right">13,287</Link>
                                            </li>
                                        </ul>

                                        <Link to="#" className="btn btn-primary btn-block"><b>Follow</b></Link> */}
                                            </div>
                                            : null
                                    }
                                </div>

                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">{t('About Me', props.auth.language)}</h3>
                                    </div>
                                    <div className="card-body">
                                        <strong><i className="fas fa-book mr-1"></i> {t('Company/Institution', props.auth.language)}</strong>

                                        <p className="text-muted">
                                            {props.auth.user ? props.auth.user.company: null}
                                        </p>

                                        <hr />

                                        <strong><i className="fas fa-user-alt mr-1"></i> {t('Position', props.auth.language)}</strong>

                                        <p className="text-muted">{props.auth.user ? props.auth.user.position: null}</p>

                                        <hr />

                                        <strong><i className="fas fa-pencil-alt mr-1"></i> {t('Contact', props.auth.language)}</strong>

                                        <p className="text-muted">
                                            <span className="tag tag-success">{props.auth.user ? props.auth.user.phone : null}</span>
                                            {/* <span className="tag tag-info">Javascript</span>
                                            <span className="tag tag-warning">PHP</span>
                                            <span className="tag tag-primary">Node.js</span> */}
                                        </p>

                                        <hr />

                                        <strong><i className="far fa-file-alt mr-1"></i> {t('Address', props.auth.language)}</strong>

                                        <p className="text-muted">{props.auth.user ? props.auth.user.address : null}</p>

                                        <hr />

                                        <strong><i className="fas fa-pencil-alt mr-1"></i> {t('Type/Category', props.auth.language)}</strong>

                                        <p className="text-muted">{props.auth.user ? props.auth.user.contact_type : null} / {props.auth.user ? props.auth.user.category : null}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                            {/* <li className="nav-item"><Link to="#activity" onClick={() => props.change_tab('activity')} className="nav-link active" data-toggle="tab">Activity</Link></li> */}
                                            {
                                                props.auth.user ?
                                                    props.auth.user.privileges.includes("view_timeline") ?
                                                        <li className="nav-item"><Link to="#" onClick={() => props.change_tab('timeline')} className="nav-link active" data-toggle="tab">{t('Timeline', props.auth.language)}</Link></li>
                                                        : null
                                                    : null
                                            }
                                            {
                                                props.auth.user ?
                                                    <li className="nav-item"><Link to="#" onClick={() => props.change_tab('signature')} className="nav-link" data-toggle="tab">{t('Signature', props.auth.language)}</Link></li>
                                                    : null
                                            }
                                            {
                                                props.auth.user ?
                                                    props.auth.user.privileges.includes("update_profile") ?
                                                        <li className="nav-item"><Link to="#" onClick={() => props.change_tab('settings')} className="nav-link" data-toggle="tab">{t('Settings', props.auth.language)}</Link></li>
                                                        : null
                                                    : null
                                            }
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content">
                                            {/* <div className={props.c_state.tab === 'activity' ? 'active tab-pane' : 'tab-pane'} id="activity">
                                                <div className="post">
                                                    <div className="user-block">
                                                        <img className="img-circle img-bordered-sm" src="static/assets/dist/img/user1-128x128.jpg" alt="user image" />
                                                        <span className="username">
                                                            <Link to="#">Jonathan Burke Jr.</Link>
                                                            <Link to="#" className="float-right btn-tool"><i className="fas fa-times"></i></Link>
                                                        </span>
                                                        <span className="description">Shared publicly - 7:30 PM today</span>
                                                    </div>
                                                    <p>
                                                        Lorem ipsum represents a long-held tradition for designers,
                                                        typographers and the like. Some people hate it and argue for
                                                        its demise, but others ignore the hate as they create awesome
                                                        tools to help create filler text for everyone from bacon lovers
                                                        to Charlie Sheen fans.
                                                    </p>

                                                    <p>
                                                        <Link to="#" className="link-black text-sm mr-2"><i className="fas fa-share mr-1"></i> Share</Link>
                                                        <Link to="#" className="link-black text-sm"><i className="far fa-thumbs-up mr-1"></i> Like</Link>
                                                        <span className="float-right">
                                                            <Link to="#" className="link-black text-sm">
                                                                <i className="far fa-comments mr-1"></i> Comments (5)
                                                            </Link>
                                                        </span>
                                                    </p>

                                                    <input className="form-control form-control-sm" type="text" placeholder="Type a comment" />
                                                </div>

                                                <div className="post clearfix">
                                                    <div className="user-block">
                                                        <img className="img-circle img-bordered-sm" src="static/assets/dist/img/user7-128x128.jpg" alt="User Image" />
                                                        <span className="username">
                                                            <Link to="#">Sarah Ross</Link>
                                                            <Link to="#" className="float-right btn-tool"><i className="fas fa-times"></i></Link>
                                                        </span>
                                                        <span className="description">Sent you a message - 3 days ago</span>
                                                    </div>
                                                    <p>
                                                        Lorem ipsum represents a long-held tradition for designers,
                                                        typographers and the like. Some people hate it and argue for
                                                        its demise, but others ignore the hate as they create awesome
                                                        tools to help create filler text for everyone from bacon lovers
                                                        to Charlie Sheen fans.
                                                    </p>

                                                    <form className="form-horizontal">
                                                        <div className="input-group input-group-sm mb-0">
                                                            <input className="form-control form-control-sm" placeholder="Response" />
                                                            <div className="input-group-append">
                                                                <button type="submit" className="btn btn-danger">Send</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div className="post">
                                                    <div className="user-block">
                                                        <img className="img-circle img-bordered-sm" src="static/assets/dist/img/user6-128x128.jpg" alt="User Image" />
                                                        <span className="username">
                                                            <Link to="#">Adam Jones</Link>
                                                            <Link to="#" className="float-right btn-tool"><i className="fas fa-times"></i></Link>
                                                        </span>
                                                        <span className="description">Posted 5 photos - 5 days ago</span>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-6">
                                                            <img className="img-fluid" src="static/assets/dist/img/photo1.png" alt="Photo" />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <img className="img-fluid mb-3" src="static/assets/dist/img/photo2.png" alt="Photo" />
                                                                    <img className="img-fluid" src="static/assets/dist/img/photo3.jpg" alt="Photo" />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <img className="img-fluid mb-3" src="static/assets/dist/img/photo4.jpg" alt="Photo" />
                                                                    <img className="img-fluid" src="static/assets/dist/img/photo1.png" alt="Photo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p>
                                                        <Link to="#" className="link-black text-sm mr-2"><i className="fas fa-share mr-1"></i> Share</Link>
                                                        <Link to="#" className="link-black text-sm"><i className="far fa-thumbs-up mr-1"></i> Like</Link>
                                                        <span className="float-right">
                                                            <Link to="#" className="link-black text-sm">
                                                                <i className="far fa-comments mr-1"></i> Comments (5)
                                                            </Link>
                                                        </span>
                                                    </p>

                                                    <input className="form-control form-control-sm" type="text" placeholder="Type a comment" />
                                                </div>
                                            </div> */}
                                            {
                                                props.auth.user ?
                                                    props.auth.user.privileges.includes("view_timeline") ?
                                                        <div className={props.c_state.tab === 'timeline' ? 'active tab-pane' : 'tab-pane'} id="timeline">
                                                            <div className="timeline timeline-inverse">
                                                                {
                                                                    props.c_state.activities.map((data, index) => {
                                                                        return (
                                                                            <>
                                                                                <div className="time-label">
                                                                                    <span className="bg-danger">
                                                                                        {data.date}
                                                                                    </span>
                                                                                </div>
                                                                                {
                                                                                    data.activity ?
                                                                                        data.activity.map((d, i) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <i className="far fa-clock bg-gray"></i>

                                                                                                    <div className="timeline-item">
                                                                                                        <span className="time"><i className="far fa-clock"></i> {d.time}</span>

                                                                                                        <h3 className="timeline-header"><Link to="#">{d.title}</Link></h3>

                                                                                                        <div className="timeline-body">
                                                                                                            {d.activity}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        : null
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }


                                                                {/* <div className="time-label">
                                                        <span className="bg-success">
                                                            3 Jan. 2014
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <i className="fas fa-camera bg-purple"></i>

                                                        <div className="timeline-item">
                                                            <span className="time"><i className="far fa-clock"></i> 2 days ago</span>

                                                            <h3 className="timeline-header"><Link to="#">Mina Lee</Link> uploaded new photos</h3>

                                                            <div className="timeline-body">
                                                                <img src="https://placehold.it/150x100" alt="..." />
                                                                <img src="https://placehold.it/150x100" alt="..." />
                                                                <img src="https://placehold.it/150x100" alt="..." />
                                                                <img src="https://placehold.it/150x100" alt="..." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <i className="far fa-clock bg-gray"></i>
                                                    </div> */}
                                                            </div>
                                                        </div>
                                                        : null
                                                    : null
                                            }
                                            {
                                                props.auth.user ?
                                                    props.auth.user.privileges.includes("update_profile") ?
                                                        <div className={props.c_state.tab === 'settings' ? 'active tab-pane' : 'tab-pane'} id="settings">
                                                            <div className="form-group row">
                                                                <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                                                <div className="col-sm-10">
                                                                    <input type="email" className="form-control" id="inputName" placeholder="Name" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
                                                                <div className="col-sm-10">
                                                                    <input type="email" className="form-control" id="inputEmail" placeholder="Email" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="inputName2" className="col-sm-2 col-form-label">Name</label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" className="form-control" id="inputName2" placeholder="Name" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="inputExperience" className="col-sm-2 col-form-label">Experience</label>
                                                                <div className="col-sm-10">
                                                                    <textarea className="form-control" id="inputExperience" placeholder="Experience"></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="inputSkills" className="col-sm-2 col-form-label">Skills</label>
                                                                <div className="col-sm-10">
                                                                    <input type="text" className="form-control" id="inputSkills" placeholder="Skills" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="offset-sm-2 col-sm-10">
                                                                    <div className="checkbox">
                                                                        <label>
                                                                            <input type="checkbox" /> I agree to the <Link to="#">terms and conditions</Link>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="offset-sm-2 col-sm-10">
                                                                    <button type="submit" className="btn btn-danger">Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                    : null
                                            }
                                            {
                                                props.auth.user ?
                                                    <div className={props.c_state.tab === 'signature' ? 'active tab-pane' : 'tab-pane'} id="signature">
                                                        <div className="form-group row">
                                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('Passcode', props.auth.language)} *</label>
                                                            <div className="col-sm-10">
                                                                <input type="password" className="form-control" name="passcode" placeholder={t('Signature Passcode', props.auth.language)} onChange={(event) => props.handleChange({ "field": "passcode", "value": event.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('Confirm Passcode', props.auth.language)} *</label>
                                                            <div className="col-sm-10">
                                                                <input type="password" className="form-control" name="passcode2" placeholder={t('Repeat Signature Passcode', props.auth.language)} onChange={(event) => props.handleChange({ "field": "passcode2", "value": event.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">{t('Signature', props.auth.language)} *</label>
                                                            <div className="col-sm-10">
                                                                <input type="file" className="form-control" name="signature" accept="image/png, image/gif, image/jpeg" onChange={(event) => props.handleChange({ "field": "signature", "value": event.target.files })} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="offset-sm-2 col-sm-10">
                                                            <span><button type="button" className="btn btn-danger" disabled={props.profile.isLoading === "true" ? true : false} onClick={props.save_signature}>{t('Save changes', props.auth.language)}</button>{props.profile.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Profile