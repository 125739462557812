import React from 'react'
import { Link } from "react-router-dom";
// import types from '../../types';
import { getTrans as t } from '../../translations';
// import Select from 'react-select';
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const CorrespondenceFolders = (props) => {

    // console.log("uuu", props.c_state.correspondence_folder_list.no_of_records);

    return (
        <>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">{t('Correspondence Folders', props.auth.language)}</h1>
                            </div>
                            {/* <!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Correspondence Folders', props.auth.language)}</li>
                                </ol>
                            </div>
                            {/* <!-- /.col --> */}
                        </div>
                        {/* <!-- /.row --> */}
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </div>
                {/* <!-- /.content-header --> */}

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="callout callout-info">
                                <div className="row">
                                    <div className="col-md-3">
                                        <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value })} value={props.correspondence.folder_details.category ? props.correspondence.folder_details.category : ''} >
                                            <option value="">--{t('Category', props.auth.language)}--</option>
                                            <option value="Inbox">{t('Incoming', props.auth.language)}</option>
                                            <option value="Sent">{t('Outgoing', props.auth.language)}</option>
                                            <option value="Archived">{t('Worked On', props.auth.language)}</option>
                                        </select>
                                    </div>
                                    {
                                        props.correspondence.folder_details.category ?
                                            <div className="col-md-3">
                                                <select className="form-control" name="group_by" onChange={(event) => props.handleChange({ "field": "group_by", "value": event.target.value })} value={props.correspondence.folder_details.group_by ? props.correspondence.folder_details.group_by : ''} >
                                                    <option value="">--{t('Group By', props.auth.language)}--</option>
                                                    <option value="Department">{t('Department', props.auth.language)}</option>
                                                    <option value="Company">{t('Company', props.auth.language)}</option>
                                                    <option value="Tags">{t('Tags', props.auth.language)}</option>
                                                </select>
                                            </div>
                                            : null
                                    }
                                    {/* {
                                        props.correspondence.folder_details.group_by ?
                                            props.correspondence.folder_details.group_by === "Date Range" ?
                                                <div className="col-md-4">
                                                    <DateRangePicker
                                                        style={{ width: 5 }}
                                                        className="form-control"
                                                        format="dd-MM-y"
                                                        onChange={(value) => props.handleChange({ "field": "date_range", "value": value })}
                                                        value={props.correspondence.folder_details.date_range ? props.correspondence.folder_details.date_range : ""}
                                                    />
                                                </div>
                                                : null
                                            : null
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card" >
                                <div className="card-header">
                                    <h3 className="card-title">{props.correspondence.folder_details.group_by ? `Grouped By: ${t(props.correspondence.folder_details.group_by, props.auth.language)}` : "Select a category and a group to view correspondence folders"}</h3>

                                </div>
                                <div className="card-body">
                                    {
                                        props.correspondence.isLoading === "true" ?
                                            <div align="center">
                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading some data. Please wait...', props.auth.language)}</p></span>
                                            </div>
                                            :
                                            props.correspondence.folder_details.group_by ?
                                                props.correspondence.folder_details.group_by === "Department" ?
                                                    <div className="row">
                                                        {
                                                            props.correspondence.folders.map((data, index) => {
                                                                return (
                                                                    <div className="col-md-3" align="center" key={index}>
                                                                        <Link to="#" data-toggle="modal" data-target="#view_correspondence_modal" onClick={() => props.get_folder_correspondence(data)}>
                                                                            <img src="static/assets/dist/img/folder.png" alt="folder" width={60} />
                                                                            <br />
                                                                            <span style={{ color: 'black' }}>{JSON.parse(data.replace(/'/g, '"')).label}</span>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    props.correspondence.folder_details.group_by === "Company" ?
                                                        <div className="row">
                                                            {
                                                                props.correspondence.folders.map((data, index) => {
                                                                    return (
                                                                        <div className="col-md-3" align="center" key={index}>
                                                                            <Link to="#" data-toggle="modal" data-target="#view_correspondence_modal" onClick={() => props.get_folder_correspondence(data)}>
                                                                                <img src="static/assets/dist/img/folder.png" alt="folder" width={60} />
                                                                                <br />
                                                                                <span style={{ color: 'black' }}>{data.name}</span>
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        props.correspondence.folder_details.group_by === "Tags" ?
                                                            <div className="row">
                                                                {
                                                                    props.correspondence.folders.map((data, index) => {
                                                                        return (
                                                                            <div className="col-md-3" align="center" key={index}>
                                                                                <Link to="#" data-toggle="modal" data-target="#view_correspondence_modal" onClick={() => props.get_folder_correspondence(data)}>
                                                                                    <img src="static/assets/dist/img/folder.png" alt="folder" width={60} />
                                                                                    <br />
                                                                                    <span style={{ color: 'black' }}>{data}</span>
                                                                                    <br /><br />
                                                                                </Link>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            : null
                                                : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modals */}
                    <div className="modal fade" id="view_correspondence_modal">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t(props.correspondence.folder_details.category, props.auth.language)} {props.c_state.folder_list_title ? `(${props.c_state.folder_list_title})` : null}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        {
                                            props.correspondence.isLoading === "true" ?
                                                <div align="center">
                                                    <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading some data. Please wait...', props.auth.language)}</p></span>
                                                </div>
                                                :
                                                props.c_state.correspondence_folder_list.correspondence ?
                                                    props.c_state.correspondence_folder_list.correspondence.length ?
                                                        <div className="card card-body p-0">
                                                            <div className="mailbox-controls">
                                                                <button type="button" className="btn btn-sm btn-outline-default">
                                                                    <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                                        <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_correspondence(event.target.value)} />
                                                                    </div>
                                                                </button>
                                                                <div className="float-right">
                                                                    {
                                                                        props.c_state.correspondence_folder_list.no_of_records ?
                                                                            `${props.c_state.start}-${props.c_state.finish}/${props.c_state.correspondence_folder_list.no_of_records}`
                                                                            : null
                                                                    }
                                                                    <div className="btn-group">
                                                                        <button type="button" className="btn btn-default btn-sm" onClick={props.go_back}>
                                                                            <i className="fas fa-chevron-left"></i>
                                                                        </button>
                                                                        <button type="button" className="btn btn-default btn-sm" onClick={props.go_forward}>
                                                                            <i className="fas fa-chevron-right"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table table-hover table-striped" style={{ width: '100%' }}>
                                                                <tbody>
                                                                    {
                                                                        props.c_state.correspondence_folder_list.correspondence ?
                                                                            props.c_state.correspondence_folder_list.correspondence.map((data) => {
                                                                                return (
                                                                                    <tr key={data.trail_id}>
                                                                                        <td className="mailbox-star"><Link to="#" onClick={() => props.single_correspondence(data)}>#{data.correspondence[0].correspondence_id}#</Link></td>
                                                                                        <td className="mailbox-name"><Link to="#" onClick={() => props.single_correspondence(data)}>{props.correspondence.folder_details.category === "Outgoing" || props.correspondence.folder_details.category === "Drafts" ? `${t('To', props.auth.language)}: ${props.contact_details(data.c_to)} ${data.number_of_recipients > 1 ? '(' + data.number_of_recipients + ')' : ''}` : props.contact_details(data.c_from)}</Link></td>
                                                                                        <td className="mailbox-subject"><Link to="#" style={{ color: 'black' }} onClick={() => props.single_correspondence(data)}>{data.seen === "No" && props.correspondence.folder_details.category !== "Drafts" ? <b>{data.correspondence[0].subject}</b> : <>{data.correspondence[0].subject}</>}</Link></td>
                                                                                        <td className="mailbox-date">{data.date_created}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            : null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <div align="center">
                                                            <img src="static/assets/dist/img/sad.gif" alt="sad face" width={50} /><br />
                                                            <p>{t('No data available for the selected category...', props.auth.language)}</p>
                                                        </div>
                                                    :
                                                    <div align="center">
                                                        <img src="static/assets/dist/img/sad.gif" alt="sad face" width={50} /><br />
                                                        <p>{t('No data available for the selected category...', props.auth.language)}</p>
                                                    </div>
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_view_correspondence_modal">{t('Close', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- /.content --> */}
            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default CorrespondenceFolders
