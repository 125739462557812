import React, { Component } from 'react'
import Profile from './Profile';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverEditProfile, serverGetUserActivity, serverLoad, serverSaveSignature } from '../../actions/ProfileAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrans as t } from '../../translations';

// const $ = require("jquery");

class ProfileContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile_details: {},
            tab: this.props.auth.user ? this.props.auth.user.privileges.includes("view_timeline") ? 'timeline' : this.props.auth.user.privileges.includes("update_profile") ? 'settings' : '' : '',
            activities: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.editProfile = this.editProfile.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.saveSignature = this.saveSignature.bind(this);
    }

    componentDidMount() {

        // console.log(this.props);

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        this.setState({ profile: this.props.auth.user });

        this.props.serverGetUserActivity(this.props.auth.user.token);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.profile.activities !== this.props.profile.activities) {
            this.setState({ activities: [...this.props.profile.activities] });
        }
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            profile_details: {                   // object that we want to update
                ...prevState.profile_details,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));
    }

    editProfile = () => {

    }

    changeTab = (tab) => {
        this.setState({ tab: tab });
    }

    saveSignature = () => {
        let profile_details = { ...this.state.profile_details };

        // check entry
        if (!profile_details.signature || !profile_details.passcode || !profile_details.passcode2) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (profile_details.passcode !== profile_details.passcode2) {
            toast.error(t("Passcodes do not match", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (profile_details.passcode.length < 6) {
            toast.error(t("The passcode must be more than five characters long", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        const formData = new FormData();

        formData.append("signature", this.state.profile_details.signature[0]);

        formData.append("passcode", this.state.profile_details.passcode);

        this.props.serverSaveSignature(formData, this.props.auth.user.token)
    }

    render() {
        return (
            <>
                <Main>
                    <Profile
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        profile={this.props.profile}
                        handleChange={this.handleChange}
                        edit_profile={this.editProfile}
                        change_tab={this.changeTab}
                        save_signature={this.saveSignature}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        profile: { ...state.profile }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverEditProfile,
        serverGetUserActivity,
        serverSaveSignature
    }
)(ProfileContainer)
