/* eslint-disable array-callback-return */
import React from 'react'
// import types from '../../types';
import { getTrans as t } from '../../translations';
import types from '../../types';

const PrintCorrespondence = (props) => {

    return (
        <div id="correspondence_printout" hidden>
            {
                props.single_correspondence.single_correspondence ?
                    <>
                        {/* <div className="col-sm-6">
                            <h3>{props.single_correspondence.single_correspondence.reference_no}</h3>
                        </div> */}

                        <div className="row">
                            <table width="100%" border="0">
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <img src="static/assets/dist/img/logo.png" alt="school_logo" width="40%" />
                                        </td>
                                        <td width="70%" align="right">
                                            <h2><strong>{types.INSTITUTION_NAME}</strong></h2>
                                            <p>{props.single_correspondence.single_correspondence.department ? JSON.parse(props.single_correspondence.single_correspondence.department.replace(/'/g, '"')).label : null}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" border="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <h4><strong style={{ textDecoration: 'underline' }}><br />{t('MEMO', props.auth.language)}</strong></h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><br /><strong>{t('FROM', props.auth.language)}:</strong> {props.contact_details(props.single_correspondence.single_correspondence.action_by).toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <td><br /><strong>{t('REF', props.auth.language)}:</strong> {props.single_correspondence.single_correspondence.reference_no}</td>
                                    </tr>
                                    <tr>
                                        <td><br /><strong>{t('DATE', props.auth.language)}:</strong> {props.single_correspondence.single_correspondence.date_created}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div align="center">
                            <br />
                            <h4 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{props.single_correspondence.single_correspondence.subject.toUpperCase()}</h4>
                        </div>
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: props.single_correspondence.single_correspondence.body }} />
                        </div>
                        <br /><hr /> <br />
                        <table width="100%" className="table table-bordered table-striped" border="0">
                            <thead>
                                <tr>
                                    {/* <th></th> */}
                                    <th><h4>MINUTES/COMMENTS</h4></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.c_state.correspondence_details.trails ?
                                        props.c_state.correspondence_details.trails.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    {/* <td>{index + 1}</td> */}
                                                    <td>
                                                        <hr style={{ border: 'solid' }} />
                                                        {data.fyi === '1' ? <><br />---------- FYI ---------<br /><br /></> : ''}
                                                        <div style={{ textDecoration: data.active === '0' ? 'line-through' : '' }} dangerouslySetInnerHTML={{ __html: data.message }} />
                                                        {
                                                            props.get_signature(data.c_from) !== null && props.get_signature(data.c_from) !== "null" && props.get_signature(data.c_from) !== '' ?
                                                                <><br /><img src={`${types.SERVER_URL}static/contacts/${props.get_signature(data.c_from)}`} alt="signed" width={150} /></>
                                                                : null
                                                        }
                                                        <br />
                                                        <strong>{props.contact_details(data.c_from)}</strong>
                                                        <br />
                                                        <strong>{data.date_created}</strong>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : null
                                }
                            </tbody>
                        </table>
                    </>
                    : null
            }
        </div>
    )
}

export default PrintCorrespondence