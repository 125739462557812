import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    correspondence_id: null,
    saved_attachments: [],
    correspondence_categories: {
        inbox: {},
        sent: {},
        drafts: {},
        archived: {},
    },
    chosen_category: "Inbox",
    single_correspondence: {},
    attached_correspondence: {},
    correspondence_details: {},
    reload: "false",
    email_details: {},
    clear_send_correspondence: "false",
    folders: [],
    folder_list: [],
    folder_details: {},
    rand: null
}

const Correspondence = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.CORRESPONDENCE_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.CORRESPONDENCE_ID:

            newState.correspondence_id = payload.data;

            return newState;

        case types.CORRESPONDENCE_SUCCESS:

            if (payload.msg === "Attachments saved successfully") {
                newState.saved_attachments = payload.data;
            }

            if (payload.msg === "Attachments cleared successfully") {
                newState.saved_attachments = [];
            }

            if (payload.msg === "Correspondence loaded successfully") {
                if (payload.data.correspondence_category === 'Inbox')
                    newState.correspondence_categories.inbox = payload.data;
                if (payload.data.correspondence_category === 'Sent')
                    newState.correspondence_categories.sent = payload.data;
                if (payload.data.correspondence_category === 'Drafts')
                    newState.correspondence_categories.drafts = payload.data;
                if (payload.data.correspondence_category === 'Archived')
                    newState.correspondence_categories.archived = payload.data;

                newState.chosen_category = payload.data.correspondence_category;
            }

            if (payload.msg === "Results loaded successfully") {
                if (payload.data.correspondence_category === "Inbox" || payload.data.correspondence_category === "All Correspondences") {
                    let new_data = {
                        correspondence: payload.data.inbox.data,
                        correspondence_category: "Inbox",
                        no_of_records: payload.data.inbox.no_of_records
                    }
                    newState.correspondence_categories.inbox = new_data;
                }

                if (payload.data.correspondence_category === "Sent" || payload.data.correspondence_category === "All Correspondences") {
                    let new_data = {
                        correspondence: payload.data.sent.data,
                        correspondence_category: "Sent",
                        no_of_records: payload.data.sent.no_of_records
                    }
                    newState.correspondence_categories.sent = new_data;
                }

                if (payload.data.correspondence_category === "Archived" || payload.data.correspondence_category === "All Correspondences") {
                    let new_data = {
                        correspondence: payload.data.archived.data,
                        correspondence_category: "Archived",
                        no_of_records: payload.data.archived.no_of_records
                    }
                    newState.correspondence_categories.archived = new_data;
                }

                if (payload.data.correspondence_category === "Drafts" || payload.data.correspondence_category === "All Correspondences") {
                    let new_data = {
                        correspondence: payload.data.drafts.data,
                        correspondence_category: "Drafts",
                        no_of_records: payload.data.drafts.no_of_records
                    }
                    newState.correspondence_categories.drafts = new_data;
                }

                newState.chosen_category = payload.data.correspondence_category;

                if (payload.data.correspondence_category === "All Correspondences") {
                    newState.chosen_category = "Inbox";
                }
            }

            if (payload.msg === "Attached Correspondence loaded successfully") {
                newState.attached_correspondence = payload.data;
            }

            if (payload.msg === "Correspondence details loaded successfully") {
                newState.correspondence_details = payload.data;
                newState.reload = "false";
            }

            if (payload.msg === "Correspondence closed successfully" || payload.msg === "Correspondence re-opened successfully") {
                newState.reload = "true";
                newState.single_correspondence.single_correspondence.closed = payload.data;
            }

            if (payload.msg === "Action updated successfully" || payload.msg === "Action nullified successfully" || payload.msg === "Action added successfully" ||
                payload.msg === "Message forwarded successfully" || payload.msg === "Trail archived successfully" || payload.msg === "Trail nullified successfully") {
                newState.reload = "true";
            }

            if (payload.msg === "Correspondence sent successfully" || payload.msg === "Draft saved successfully") {
                newState.clear_send_correspondence = Math.random();
            }

            if (payload.msg === "Folders fetched successfully") {

                newState.folders = payload.data;

                if (newState.folder_details.group_by) {
                    if (newState.folder_details.group_by === "Tags") {
                        let arr = []

                        // eslint-disable-next-line array-callback-return
                        payload.data.forEach((data, index) => {
                            data.children.forEach((d, i) => {
                                if (d.active === "1") {
                                    let v = `${data.name} : ${d.name}`;
                                    arr.push(v);
                                }
                            })
                        });

                        newState.folders = arr;
                    }
                }
            }

            if (payload.msg === "Correspondence fetched successfully") {

                newState.folder_list = payload.data;
            }

            if (payload.msg === "Draft deleted successfully") {
                newState.rand = payload.data;
            }

            if (payload.msg !== "Correspondence loaded successfully" && payload.msg !== "Attached Correspondence loaded successfully" && payload.msg !== "Correspondence details loaded successfully" &&
                payload.msg !== "Trail seen" && payload.msg !== "Folders fetched successfully" && payload.msg !== "Correspondence fetched successfully") {
                toast.success(payload.msg, {
                    position: 'top-center'
                });
            }

            return newState;

        case types.CORRESPONDENCE_FOLDER_DETAILS:

            newState.folder_details = payload;

            return newState;

        case types.CORRESPONDENCE_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        case types.SINGLE_CORRESPONDENCE:

            newState.single_correspondence = payload;

            return newState;

        case types.CHOSEN_CATEGORY:

            newState.chosen_category = payload;

            return newState;

        case types.EMAIL_DETAILS:

            newState.email_details = payload;

            return newState;

        default:
            return newState;
    }
}

export default Correspondence