import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    privileges: [],
    // contact_privileges: [],
    rand: "",
    roles: []
}

const Roles = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.ROLES_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.ROLES:

            newState.privileges = payload.data.privileges;
            newState.roles = payload.data.roles;

            return newState;

        case types.ROLES_SUCCESS:

            if (payload.msg === "User role assigned successfully") {
                newState.rand = payload.data;
            }

            if (payload.msg === "User role created successfully" || payload.msg === "User role updated successfully") {
                newState.privileges = payload.data.privileges;
                newState.roles = payload.data.roles;
            }

            if (payload.msg !== "Contact privileges loaded successfully") {
                toast.success(payload.msg, {
                    position: 'top-center',
                    autoClose: 5000
                });
            }

            return newState;

        default:
            return newState;
    }
}

export default Roles