import React, { Component } from 'react'
import ComposeCorrespondence from './ComposeCorrespondence';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction';
import { serverGetTags } from '../../actions/TagsAction';
import { serverGetContacts } from '../../actions/ContactsAction'
import { serverSendCorrespondence, serverAttachEmail, serverLoad, serverGetCorrespondenceDetails, serverDeleteCorrespondenceDraft, serverSingleCorrespondence, serverMarkSeen } from '../../actions/CorrespondenceAction';
import { serverSaveAttachments, serverClearSavedAttachments, serverGetAllFiles } from '../../actions/AttachmentsAction';
import { serverGetDocuments, serverGetTemplates, serverUseTemplate } from '../../actions/TemplatesAction';
import { serverGetQuestionnaires } from '../../actions/QuestionnairesAction';
import { serverAddTask } from '../../actions/TasksAction';
import { serverGetRoutes } from '../../actions/RoutesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import types from '../../types';
import fileDownload from 'js-file-download';
import axios from 'axios'
import { getTrans as t } from '../../translations';

const $ = require("jquery");

let today = new Date();
let month = (today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
let day = (today.getDate()) < 10 ? `0${today.getDate()}` : today.getDate();
let hour = (today.getHours()) < 10 ? `0${today.getHours()}` : today.getHours();
let minute = (today.getMinutes()) < 10 ? `0${today.getMinutes()}` : today.getMinutes();
let current_date = today.getFullYear() + '-' + month + '-' + day;
let current_time = hour + ":" + minute;

class ComposeCorrespondenceContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags_dropdown: [],
            contacts_dropdown: [],
            correspondence_details: {
                "schedule_date": current_date,
                "schedule_time": current_time,
                "use_route": "false",
                "confidential": "false",
                "signature": "false",
                "attach_correspondence": "false"
            },
            route_list: [],
            selected_route_list: {},
            route_step_details: {},
            task_details: {},
            reminders: [{ "r_date": "", "r_time": "" }],
            email_details: {},
            inbox_start: 1,
            inbox_finish: 50,
            correspondence: {},
            attached_correspondence: {},

            // for generic attachments modal
            a_type: "correspondence",
            document_config: {},
            my_computer: [],
            file_list: [],
            document_list: [],
            template_list: [],
            breadcrumbs: [],
            my_computer_selected: [],
            my_documents_selected: [],
            files_selected: [],
            selected_items: {},
            selected_files: {},
            selected_template: [],
            selected_template_details: [],
            saved_attachments: [],
            answers: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.sendCorrespondence = this.sendCorrespondence.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.filterByFromTags = this.filterByFromTags.bind(this);
        this.filterByCCTags = this.filterByCCTags.bind(this);
        this.filterByThroughTags = this.filterByThroughTags.bind(this);
        this.filterByToTags = this.filterByToTags.bind(this);
        this.removeEmailAttachment = this.removeEmailAttachment.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.contactTags = this.contactTags.bind(this);
        this.setReminderDate = this.setReminderDate.bind(this);
        this.setReminderTime = this.setReminderTime.bind(this);
        this.addTask = this.addTask.bind(this);
        this.addSignature = this.addSignature.bind(this);
        this.deleteCorrespondenceDraft = this.deleteCorrespondenceDraft.bind(this);
        this.singleCorrespondence = this.singleCorrespondence.bind(this);
        this.attachCorrespondence = this.attachCorrespondence.bind(this);
        this.searchCorrespondence = this.searchCorrespondence.bind(this);

        // attachments
        this.setAttachmentInfo = this.setAttachmentInfo.bind(this);
        this.useTemplate = this.useTemplate.bind(this);
        this.selectMyDocument = this.selectMyDocument.bind(this);
        this.selectFiles = this.selectFiles.bind(this);
        this.clickBreadCrumbs = this.clickBreadCrumbs.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.clearAttachments = this.clearAttachments.bind(this);
        this.saveAttachments = this.saveAttachments.bind(this);
        this.clearAttachmentInfo = this.clearAttachmentInfo.bind(this);
        this.fileType = this.fileType.bind(this);
        this.download = this.download.bind(this);
        this.searchAttachment = this.searchAttachment.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
        this.enterFolderAlt = this.enterFolderAlt.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        this.props.clearData("false");

        if (!this.props.routes.all_routes.length) {
            this.props.serverGetRoutes(this.props.auth.user.token);
        } else {
            this.setState({ route_list: [...this.props.routes.all_routes] });
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (!this.props.templates.documents.length) {
            this.props.serverGetDocuments(this.props.auth.user.token);
        } else {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (!this.props.templates.templates.length) {
            this.props.serverGetTemplates(this.props.auth.user.token);
        } else {
            this.setState({ template_list: [...this.props.templates.templates] });
        }

        if (!this.props.attachments.all_files.length) {
            this.props.serverGetAllFiles(this.props.auth.user.token);
        } else {
            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        // get attached emails if any
        if (this.props.correspondence.email_details) {
            this.setState({ email_details: this.props.correspondence.email_details });
        }

        // fill correspondence page for drafts
        if (this.props.correspondence.chosen_category === "Drafts") {
            if (this.props.correspondence.single_correspondence.single_correspondence) {

                let correspondence_details = { ...this.state.correspondence_details };
                let single_correspondence = this.props.correspondence.single_correspondence.single_correspondence;

                this.props.serverGetCorrespondenceDetails(single_correspondence['correspondence_id'], this.props.correspondence.chosen_category, this.props.auth.user.token);

                correspondence_details['subject'] = single_correspondence['subject'];
                correspondence_details['body'] = single_correspondence['body'];
                correspondence_details['source'] = single_correspondence['source'];
                correspondence_details['priority'] = single_correspondence['priority'];
                correspondence_details['deadline'] = single_correspondence['deadline'];
                correspondence_details['confidential'] = single_correspondence['confidential'];
                correspondence_details['correspondence_id'] = single_correspondence['correspondence_id'];
                correspondence_details['action_type'] = single_correspondence.action_type;

                if (single_correspondence['tags']) {
                    correspondence_details['correspondence_tags'] = JSON.parse(single_correspondence.tags.replace(/'/g, '"'));
                }

                if (single_correspondence.route_id !== "") {

                    correspondence_details['use_route'] = "true";
                    correspondence_details['route_id'] = single_correspondence.route_id;

                    let route_list = [...this.props.routes.all_routes];

                    let selected_route = route_list.filter(item => item.route_id + "" === single_correspondence.route_id + "");
                    if (selected_route.length) {
                        correspondence_details["route_steps"] = selected_route[0].route_steps;
                        this.setState({ selected_route_list: selected_route[0] });
                    }

                    correspondence_details['selected_route'] = single_correspondence.route_id;

                }

                if (this.props.correspondence.correspondence_details.trails) {
                    correspondence_details['message'] = this.props.correspondence.correspondence_details.trails[0].message;
                }

                // if (this.props.correspondence.correspondence_details.emails) {
                //     if (this.props.correspondence.correspondence_details.emails.length) {
                //         let email_details = this.props.correspondence.correspondence_details.emails[0];
                //         correspondence_details['email_details']['date'] = email_details['mail_date'];
                //         this.setState({ email_details: this.props.correspondence.correspondence_details.emails[0] });
                //     }
                // }

                // correspondence_details = {...correspondence_details, ...this.props.correspondence.correspondence_details };

                // console.log("hahaha", this.props.correspondence.correspondence_details);

                this.setState({ correspondence_details: correspondence_details });
            }
        }

        // for referencing another correspondence
        if (this.props.correspondence.chosen_category !== "Drafts") {

            let data = this.props.correspondence.correspondence_categories.inbox;
            // count no of records
            if (parseInt(data.no_of_records) <= parseInt(this.state.inbox_finish)) {
                this.setState({ inbox_finish: data.correspondence.length });
            }

            if (parseInt(this.state.inbox_finish > parseInt(data.no_of_records))) {
                this.setState({ inbox_finish: data.no_of_records });
            }

            this.setState({ correspondence: data });

        }

        if (!this.props.questionnaires.questionnaires.length) {
            this.props.serverGetQuestionnaires(this.props.auth.user.token);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.correspondence.clear_send_correspondence !== this.props.correspondence.clear_send_correspondence) {

            let details = {
                "schedule_date": current_date,
                "schedule_time": current_time,
                "use_route": "false",
                "confidential": "false",
                "message": "",
                "body": "",
                "signature": "false",
                "attach_correspondence": "false"
            }

            this.setState(prevState => ({
                correspondence_details: details, attached_correspondence: {}    // update the value of specific key
            }
            ));

            this.clearAttachments();
            this.removeEmailAttachment();
        }

        if (prevProps.tasks.tasks !== this.props.tasks.tasks) {
            $("#dismiss_add_task_modal").click();

            this.setState({ task_details: {}, reminders: [{ "r_date": "", "r_time": "" }] })
        }

        // this.props.clearData("true");

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.contacts !== this.props.contacts) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        // generic attachments
        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                $("#btn-show-doc-modal").click();

                this.setState({ selected_template_details: [], selected_template: [] });
            }
        }

        if (prevProps.routes.all_routes !== this.props.routes.all_routes) {

            this.setState({ route_list: [...this.props.routes.all_routes] });

            // fill correspondence page for drafts
            if (this.props.correspondence.chosen_category === "Drafts") {
                if (this.props.correspondence.single_correspondence.single_correspondence) {

                    let correspondence_details = { ...this.state.correspondence_details };
                    let single_correspondence = this.props.correspondence.single_correspondence.single_correspondence;

                    let route_list = [...this.props.routes.all_routes];

                    let selected_route = route_list.filter(item => item.route_id + "" === single_correspondence.route_id + "");
                    if (selected_route.length) {
                        correspondence_details["route_steps"] = selected_route[0].route_steps;
                        this.setState({ selected_route_list: selected_route[0] });
                    }
                }
            }
        }

        if (prevProps.correspondence.correspondence_details !== this.props.correspondence.correspondence_details) {
            let correspondence_details = { ...this.state.correspondence_details };

            if (this.props.correspondence.correspondence_details.trails) {
                correspondence_details['message'] = this.props.correspondence.correspondence_details.trails[0].message;
            }

            if (this.props.correspondence.correspondence_details.emails) {
                if (this.props.correspondence.correspondence_details.emails.length) {
                    let email_details = this.props.correspondence.correspondence_details.emails[0];
                    let obj = {
                        "date": email_details['mail_date'],
                        "subject": email_details['subject'],
                        "to": email_details['mail_to'],
                        "from": email_details['mail_from']
                    }

                    email_details = { ...email_details, ...obj };

                    correspondence_details['email_details'] = email_details;
                    this.setState({ email_details: email_details });
                }
            }
        }

        if (prevProps.attachments.all_files !== this.props.attachments.all_files) {

            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (prevProps.templates.documents !== this.props.templates.documents) {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (prevProps.templates.templates !== this.props.templates.templates) {
            this.setState({ template_list: [...this.props.templates.templates] });
        }

        if (prevProps.correspondence.rand !== this.props.correspondence.rand) {
            this.props.history.push("/correspondence");
        }

        if (prevProps.correspondence.single_correspondence !== this.props.correspondence.single_correspondence) {
            $("#dismiss_attach_correspondence_modal").click();
            if (this.props.correspondence.single_correspondence.correspondence_category !== "Drafts") {
                this.props.history.push("/correspondence_details");
            } else {
                this.props.history.push("/compose_correspondence");
            }
        }
    }

    clickBreadCrumbs = (index) => {
        let breadcrumbs = [...this.state.breadcrumbs];
        let selected_breadcrumbs = this.state.breadcrumbs[index];
        let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === selected_breadcrumbs.fid + '');
        breadcrumbs = breadcrumbs.slice(0, index + 1);
        return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
    }

    handleChange = (data, type) => {
        switch (type) {
            case 'correspondence':
                this.setState(prevState => ({
                    correspondence_details: {                   // object that we want to update
                        ...prevState.correspondence_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));

                if (data.field === "selected_route" && data.value !== "") {
                    let selected_route = this.state.route_list.filter(item => item.route_id === data.value);
                    this.setState({ selected_route_list: selected_route[0] });
                }
                break;

            // generic attachments modal
            case 'answers':
                this.setState(prevState => ({
                    answers: {                   // object that we want to update
                        ...prevState.answers,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'template':
                this.setState(prevState => ({
                    selected_template_details: {                   // object that we want to update
                        ...prevState.selected_template_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'my_computer':
                this.setState({ my_computer: data });

                let attachments = [];

                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    attachments.push({
                        "file_name": element.name,
                        "file_size": element.size
                    });
                }

                this.setState({ my_computer_selected: attachments });

                break;

            case 'route_steps':
                this.setState(prevState => ({
                    route_step_details: {                   // object that we want to update
                        ...prevState.route_step_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;

            case 'tasks':
                this.setState(prevState => ({
                    task_details: {                   // object that we want to update
                        ...prevState.task_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            default:
                break;
        }
    }

    doCompare = (selected_tags, contacts) => {
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i];
            let isValid = false;
            if (el.tags) {
                if (el.tags !== 'None') {
                    let tags = JSON.parse(el.tags.replace(/'/g, '"'));

                    // check if all selected tags exist in contacts
                    //  let myValues = {...value}

                    for (let ind = 0; ind < tags.length; ind++) {
                        const elt = tags[ind];
                        if (value[elt.value]) {
                            isValid = true;
                            break;
                        }

                        // check if all selected tags exist in contacts
                        // if (myValues[elt.value]) {
                        //     delete myValues[elt.value]
                        // }
                    }
                }
            }

            if (isValid) {
                results.push({ "value": el.contact_id, "label": `${el.first_name} ${el.last_name}` });
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }

    filterByFromTags = () => {
        if (this.state.correspondence_details.from_tags) {

            let result = this.doCompare(this.state.correspondence_details.from_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                correspondence_details: {                   // object that we want to update
                    ...prevState.correspondence_details,    // keep all other key-value pairs
                    "from": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_from_tag_modal").click();
    }

    filterByThroughTags = () => {
        if (this.state.correspondence_details.through_tags) {

            let result = this.doCompare(this.state.correspondence_details.through_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                correspondence_details: {                   // object that we want to update
                    ...prevState.correspondence_details,    // keep all other key-value pairs
                    "through": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_through_tag_modal").click();
    }

    filterByToTags = () => {
        if (this.state.correspondence_details.to_tags) {

            let result = this.doCompare(this.state.correspondence_details.to_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                correspondence_details: {                   // object that we want to update
                    ...prevState.correspondence_details,    // keep all other key-value pairs
                    "to": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_to_tag_modal").click();
    }

    filterByCCTags = () => {
        if (this.state.correspondence_details.cc_tags) {

            let result = this.doCompare(this.state.correspondence_details.cc_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                correspondence_details: {                   // object that we want to update
                    ...prevState.correspondence_details,    // keep all other key-value pairs
                    "cc": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_cc_tag_modal").click();
    }

    setAttachmentInfo = (data, type) => {

        let id = "";
        let file_name = "";
        let folder = "";
        let ext = "";

        switch (type) {
            case 'attachment':
                id = data.aid;
                file_name = data.file_name;
                folder = "attachments";
                ext = data.ext;
                break;
            case 'document':
                id = data.did;
                file_name = data.name;
                folder = "documents";
                ext = data.ext;
                break;
            case 'file':
                id = data.fid;
                file_name = data.filename;
                folder = "file_manager/files";
                ext = data.filetype;
                break;
            case 'template':
                id = data.tid;
                file_name = data.template_name;
                folder = "templates";
                ext = data.ext;
                break;
            default:
                break;
        }

        if (ext === ".docx" || ext === ".pptx" || ext === ".xlsx" || ext === ".csv") {

            let kind = "";
            if (ext === '.docx') {
                kind = "word";
            } else if (ext === '.pptx') {
                kind = "slide";
            } else if (ext === '.xlsx' || ext === '.csv') {
                kind = "cell";
            }

            let config = {
                "document": {
                    "fileType": ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": false
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": file_name.replace(/&/g, ""),
                    "url": `${types.SERVER_URL}static/${folder}/${id.toString()}${ext}`,
                    "user": {
                        "id": this.props.auth.user.contact_id,
                        "name": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=${folder}:${id.toString()}${ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            this.setState({ document_config: config });
        }

        this.setState({ view_attachment_data: { "id": id, "file_name": file_name, "folder": folder, "ext": ext } })
    }


    selectMyDocument = (data) => {

        let newState = { ...this.state.selected_items };

        if (newState[data.did]) {
            delete newState[data.did];
        } else {
            newState[data.did] = data;
        }

        this.setState({
            selected_items: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ my_documents_selected: results })
    }

    selectFiles = (data) => {

        let newState = { ...this.state.selected_files };

        if (newState[data.fid]) {
            delete newState[data.fid];
        } else {
            newState[data.fid] = data;
        }

        this.setState({
            selected_files: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ files_selected: results })
    }

    enterFolder = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');

            let breadcrumbs = [...this.state.breadcrumbs, data];

            this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    enterFolderAlt = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');
            this.setState({ file_list: file_list });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    selectTemplate = (data) => {
        this.setState({ selected_template: data });
    }

    useTemplate = () => {
        // console.log("stemp", this.state.selected_template_details);
        // console.log("answers", this.state.answers);

        if (!this.state.selected_template_details.document_name || !this.state.answers) {
            toast.error(t('Please fill all required fields', this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverUseTemplate(
            this.state.selected_template.templates.tid,
            this.state.selected_template_details.document_name,
            this.state.selected_template_details.tags ? this.state.selected_template_details.tags : [],
            this.state.answers,
            this.props.auth.user.token
        );
    }

    saveAttachments = () => {
        if (!this.state.my_computer_selected.length && !this.state.my_documents_selected.length && !this.state.files_selected.length) {

            toast.error(t('Please select files to add as attachments', this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        $("#dismiss_attachment_modal").click();
    }

    clearAttachments = () => {
        this.setState({ my_documents_selected: [], files_selected: [], my_computer_selected: [], selected_items: {}, selected_files: {}, my_computer: [] });
    }

    searchAttachment = (value, type) => {
        switch (type) {
            case 'documents':
                // eslint-disable-next-line array-callback-return
                let document_list = this.props.templates.documents.filter((data, index) => {
                    if (data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ document_list: document_list });
                break;
            case 'files':
                if (value) {
                    // eslint-disable-next-line array-callback-return
                    let file_list = this.props.attachments.all_files.filter((data, index) => {
                        if (data.filename.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                            return data;
                        }
                    })
                    this.setState({ file_list: file_list });
                } else {
                    let file_list = this.state.breadcrumbs[this.state.breadcrumbs.length - 1];
                    if (typeof file_list !== 'undefined') {
                        this.enterFolderAlt(file_list);
                    }
                }
                break;
            case 'templates':
                // eslint-disable-next-line array-callback-return
                let template_list = this.props.templates.templates.filter((data, index) => {
                    if (data.templates.template_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ template_list: template_list });
                break;
            default:
                break;
        }
    }

    clearAttachmentInfo = () => {
        this.setState({ view_attachment_data: {} });
    }

    fileType = (type) => {

        var image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        var video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        var audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        var compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        var doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];


        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    download = async (folder, id, ext, file_name) => {

        axios.get(`${types.SERVER_URL}static/${folder}/${id}${ext}`, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, `${file_name}${ext}`);
        });
    }


    sendCorrespondence = (is_draft) => {

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        let correspondence_details = { ...this.state.correspondence_details };

        if (!correspondence_details.from) {
            correspondence_details["from"] = [{ "value": `${this.props.auth.user.contact_id}`, "label": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}` }]     // update the value of specific key
        }

        if (!correspondence_details.to && !correspondence_details.cc && !correspondence_details.use_route) {
            toast.error(t('Please select your recipients', this.props.auth.language), {
                position: 'top-center',
                autoClose: 5000
            });

            return;
        }

        if (!correspondence_details.subject || !correspondence_details.message || !correspondence_details.body || !correspondence_details.schedule_date) {
            toast.error(t('Please fill all required fields', this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (correspondence_details.action_type) {
            if (correspondence_details.action_type === "Forward To Anyone In This List" && !correspondence_details.forward_list) {
                toast.error(t("Please specify the list of people for action type", this.props.auth.language), {
                    position: 'top-center',
                    autoClose: 3000
                });

                return;
            }
        }

        if (this.state.email_details.subject) {
            correspondence_details['source'] = "Email";
        }

        if (!correspondence_details.source) {
            toast.error(t("Please specify the source of your correspondence", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        correspondence_details["is_draft"] = is_draft;

        // check for email attachment
        if (this.state.email_details.subject) {
            correspondence_details["email_details"] = this.state.email_details;
        }

        if (this.state.email_details.attachments) {
            correspondence_details["email_attachments"] = this.state.email_details.attachments;
        }

        // check for routes
        if (this.state.correspondence_details.use_route && this.state.selected_route_list) {
            correspondence_details["route_steps"] = this.state.selected_route_list.route_steps;
        }

        if (this.state.attached_correspondence.trail_id) {
            correspondence_details["attached_correspondence"] = this.state.attached_correspondence.trail_id;
        }

        // Create an object of formData
        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", this.state.a_type);

        formData.append("correspondence_details", JSON.stringify(correspondence_details));

        this.props.serverSendCorrespondence(formData, this.props.auth.user.token);
    }

    removeEmailAttachment = () => {
        this.props.serverAttachEmail([]);
    }

    contactDetails = (contact_id) => {
        // eslint-disable-next-line array-callback-return
        let contact_details = this.props.contacts.contacts.filter((data, index) => {
            if (data.contact_id === contact_id) {
                return data;
            }
        })

        if (contact_details.length) {
            contact_details = contact_details[0];
            return `${contact_details.first_name} ${contact_details.last_name}`;
        } else {
            return '';
        }
    }

    setModalContent = (data) => {
        this.setState({
            route_step_details: data
        });
    }

    clearModalContents = () => {
        this.setState({ route_step_details: [] });

        return;
    }

    contactTags = (contact_id, field) => {

        if (typeof contact_id === "string" && contact_id !== "") {
            if (field === "cc") {
                let temp_contacts = JSON.parse(contact_id.replace(/'/g, '"'));
                let contact = this.props.contacts.contacts.filter(item => temp_contacts.includes(item.contact_id));
                let result = []

                // eslint-disable-next-line array-callback-return
                contact.filter((data, index) => {
                    let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                    result.push(v);
                });

                this.setState(prevState => ({
                    route_step_details: {                   // object that we want to update
                        ...prevState.route_step_details,    // keep all other key-value pairs
                        [field]: result     // update the value of specific key
                    }
                }));

                return result;

            } else if (field === "user") {
                let contact = this.props.contacts.contacts.filter(item => item.contact_id === contact_id);

                let result = { "value": contact[0].contact_id, "label": `${contact[0].first_name} ${contact[0].last_name}` };

                this.setState(prevState => ({
                    route_step_details: {                   // object that we want to update
                        ...prevState.route_step_details,    // keep all other key-value pairs
                        [field]: result     // update the value of specific key
                    }
                }));

                return result;
            }
        }

        return contact_id;
    }

    editRouteStep = (data) => {
        // check entry
        if (!this.state.route_step_details.user || !this.state.route_step_details.deadline
            || !this.state.route_step_details.auto_forward || !this.state.route_step_details.can_close) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let route_info = { ...this.state.route_step_details }

        if (route_info.user) {
            route_info['user'] = route_info['user']['value']
        }

        // if (route_info.cc) {
        //     route_info['cc'] = route_info['cc']
        // }

        let route_list = { ...this.state.selected_route_list }

        let route_steps = this.state.selected_route_list.route_steps.map((data, index) => { // loop through state.todo
            if (route_info.step_id === data.step_id) { // check what data to update using key
                data = { ...data, ...route_info } // replace resulting data with new payload
                // console.log("reducer", data);
            }
            return data
        });

        route_list['route_steps'] = route_steps;

        this.setState({ selected_route_list: route_list });

        $("#dismiss_edit_route_step_modal").click();

        toast.success(t("Route step updated succesfully", this.props.auth.language), {
            position: 'top-center'
        });
    }

    deleteRouteStep = (step_id) => {

        let route_list = { ...this.state.selected_route_list };

        // eslint-disable-next-line array-callback-return
        let route_steps = this.state.selected_route_list.route_steps.filter((data, index) => { // loop through state.todo
            if (step_id !== data.step_id) { // check what data to update using key
                return data
            }
        });

        route_list['route_steps'] = route_steps;

        this.setState({ selected_route_list: route_list });

        toast.success(t("Route step deleted succesfully", this.props.auth.language), {
            position: 'top-center'
        });
    }

    swapIncrement = (arr, order) => {

        let result = [...arr];

        let order_value = arr[order];

        let order_plus_one_value = arr[order + 1];
        if (!order_plus_one_value) {
            return result;
        }

        order_value.ordering = parseInt(order_value.ordering) + 1;
        order_plus_one_value.ordering = parseInt(order_plus_one_value.ordering) - 1;

        // swap
        result[order] = order_plus_one_value;

        result[order + 1] = order_value;

        // result.sort((a, b) => (a.ordering > b.ordering) ? 1 : -1);

        return result;
    }

    swapDecrement = (arr, order) => {

        let result = [...arr];

        let order_value = arr[order];

        let order_plus_one_value = arr[order - 1];
        if (!order_plus_one_value) {
            return result;
        }

        order_value.ordering = parseInt(order_value.ordering) - 1;
        order_plus_one_value.ordering = parseInt(order_plus_one_value.ordering) + 1;

        // swap
        result[order] = order_plus_one_value;

        result[order - 1] = order_value;

        // result.sort((a, b) => (a.ordering > b.ordering) ? 1 : -1);

        return result;
    }

    moveUp = (data) => {

        let route_list = { ...this.state.selected_route_list };

        let res = this.swapDecrement(route_list.route_steps, parseInt(data.ordering) - 1);

        route_list['route_steps'] = res;

        this.setState({ selected_route_list: route_list });
    }

    moveDown = (data) => {

        let route_list = { ...this.state.selected_route_list };

        let res = this.swapIncrement(route_list.route_steps, parseInt(data.ordering) - 1);

        route_list['route_steps'] = res;

        this.setState({ selected_route_list: route_list });
    }

    addReminder = () => {

        let valid = true;

        // console.log(this.state.reminders);

        this.state.reminders.forEach(element => {
            if (element.r_date === "" || element.r_time === "") {

                valid = false;

                return;
            }
        });

        if (valid === true) {
            this.setState({ reminders: [...this.state.reminders, { "r_date": "", "r_time": "" }] });
        } else {
            toast.info(t("Please set reminder date and time before you add a new one", this.props.auth.language), {
                position: 'top-center'
            });
        }
    }

    setReminderDate = (date, marker) => {
        // eslint-disable-next-line array-callback-return
        let reminders = this.state.reminders.filter((data, index) => { // loop through state.todo
            if (index === marker) { // check what data to update using key
                data.r_date = date;
            }

            return data
        });

        this.setState({ reminders: reminders });
    }

    setReminderTime = (time, marker) => {
        // eslint-disable-next-line array-callback-return
        let reminders = this.state.reminders.filter((data, index) => { // loop through state.todo
            if (index === marker) { // check what data to update using key
                data.r_time = time;
            }

            return data
        });

        this.setState({ reminders: reminders });
    }

    addTask = () => {
        // check entry
        if (!this.state.task_details.deadline_date || !this.state.task_details.deadline_time || !this.state.task_details.priority
            || !this.state.task_details.recipients || !this.state.task_details.task) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            // console.log("required", this.state);

            return;
        }

        // this.props.serverLoad("true");

        this.props.serverAddTask({ "task_details": this.state.task_details, "reminders": this.state.reminders }, this.props.auth.user.token)
    }

    addSignature = (value) => {
        if (value === "Yes") {

            if (!this.props.auth.user.passcode) {
                toast.error(t("Signature not found! Please upload your signature under 'My Profile' section", this.props.auth.language), {
                    position: 'top-center',
                    autoClose: 3000
                });
            }

            let passcode = prompt(t("Enter signature passcode", this.props.auth.language), "");

            if (passcode != null) {
                if (passcode + "" === this.props.auth.user.passcode + "") {
                    this.setState(prevState => ({
                        correspondence_details: {                   // object that we want to update
                            ...prevState.correspondence_details,    // keep all other key-value pairs
                            "signature": "true"     // update the value of specific key
                        }
                    }));

                    toast.info(t("Your signature has been attached", this.props.auth.language), {
                        position: 'top-center',
                        autoClose: 3000
                    });
                } else {
                    toast.error(t("Wrong passcode entered!", this.props.auth.language), {
                        position: 'top-center',
                        autoClose: 3000
                    });
                }
            }
        } else {
            this.setState(prevState => ({
                correspondence_details: {                   // object that we want to update
                    ...prevState.correspondence_details,    // keep all other key-value pairs
                    "signature": "false"     // update the value of specific key
                }
            }));
        }
    }

    deleteCorrespondenceDraft = () => {
        if (this.state.correspondence_details.correspondence_id) {
            this.props.serverDeleteCorrespondenceDraft(this.state.correspondence_details.correspondence_id, this.props.auth.user.token);
        }
    }

    singleCorrespondence = (data) => {
        this.props.serverSingleCorrespondence(data.correspondence[0], this.props.correspondence.chosen_category, data.trail_id);
        if (this.props.correspondence.chosen_category === "Inbox" || this.props.correspondence.chosen_category === "Archived") {
            this.props.serverMarkSeen(data.trail_id, this.props.auth.user.token);
        }
    }

    attachCorrespondence = (data) => {
        $("#dismiss_attach_correspondence_modal").click();
        this.setState({ attached_correspondence: data });
    }

    removeAttachedCorrespondence = () => {
        this.setState({ attached_correspondence: {} });
    }

    searchCorrespondence = (value) => {
        // eslint-disable-next-line array-callback-return
        let new_correspondence = this.props.correspondence.correspondence_categories.inbox.correspondence.filter((data, index) => {
            data = data.correspondence[0];
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        let ama = { ...this.state.correspondence };
        ama['correspondence'] = new_correspondence;

        this.setState({ correspondence: ama });
    }

    questionnaireTitle = (questionnaire_id) => {
        if (questionnaire_id) {
            let title = this.props.questionnaires.questionnaires.filter((data) => data.questionnaire_id + "" === questionnaire_id + "");
            return title[0].title;
        }

        return null;
    }

    render() {
        return (
            <>
                <Main>
                    <ComposeCorrespondence
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        tasks={this.props.tasks}
                        routes={this.props.routes}
                        correspondence={this.props.correspondence}
                        questionnaires={this.props.questionnaires}
                        handleChange={this.handleChange}
                        send_correspondence={this.sendCorrespondence}
                        filter_by_from_tags={this.filterByFromTags}
                        filter_by_through_tags={this.filterByThroughTags}
                        filter_by_to_tags={this.filterByToTags}
                        filter_by_cc_tags={this.filterByCCTags}
                        remove_email_attachment={this.removeEmailAttachment}
                        contact_details={this.contactDetails}
                        set_modal_content={this.setModalContent}
                        clear_modal_contents={this.clearModalContents}
                        contact_tags={this.contactTags}
                        edit_route_step={this.editRouteStep}
                        delete_route_step={this.deleteRouteStep}
                        move_up={this.moveUp}
                        move_down={this.moveDown}
                        set_reminder_date={this.setReminderDate}
                        set_reminder_time={this.setReminderTime}
                        add_task={this.addTask}
                        add_reminder={this.addReminder}
                        add_signature={this.addSignature}
                        delete_correspondence_draft={this.deleteCorrespondenceDraft}
                        attach_correspondence={this.attachCorrespondence}
                        single_correspondence={this.singleCorrespondence}
                        remove_attached_correspondence={this.removeAttachedCorrespondence}
                        search_correspondence={this.searchCorrespondence}
                        questionnaire_title={this.questionnaireTitle}

                        // attachments component
                        templates={this.props.templates}
                        attachments={this.props.attachments}
                        select_my_document={this.selectMyDocument}
                        select_files={this.selectFiles}
                        enter_folder={this.enterFolder}
                        click_breadcrumbs={this.clickBreadCrumbs}
                        select_template={this.selectTemplate}
                        use_template={this.useTemplate}
                        save_attachments={this.saveAttachments}
                        clear_attachments={this.clearAttachments}
                        search_attachment={this.searchAttachment}
                        // view attachments
                        set_attachment_info={this.setAttachmentInfo}
                        clear_attachment_info={this.clearAttachmentInfo}
                        file_type={this.fileType}
                        download={this.download}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        correspondence: { ...state.correspondence },
        contacts: { ...state.contacts },
        templates: { ...state.templates },
        routes: { ...state.routes },
        attachments: { ...state.attachments },
        tasks: { ...state.tasks },
        questionnaires: { ...state.questionnaires }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverGetContacts,
        serverGetDocuments,
        serverGetTemplates,
        serverUseTemplate,
        serverSaveAttachments,
        serverClearSavedAttachments,
        serverSendCorrespondence,
        serverAttachEmail,
        serverGetRoutes,
        serverGetAllFiles,
        serverAddTask,
        serverGetCorrespondenceDetails,
        serverDeleteCorrespondenceDraft,
        serverSingleCorrespondence,
        serverMarkSeen,
        serverGetQuestionnaires
    }
)(ComposeCorrespondenceContainer)
