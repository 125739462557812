import React from 'react'
import { Link } from "react-router-dom";
import types from '../../types';
import { getTrans as t } from '../../translations';

const is_word = [".doc", ".docx"];
const is_pdf = [".pdf"];
const is_powerpoint = [".pptx", ".ppt"];
const is_excel = ["xlsx", "xls", ".csv"];

const Conversions = (props) => {
    return (
        <>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">{t('Conversion Service', props.auth.language)}</h1>
                            </div>
                            {/* <!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Conversions', props.auth.language)}</li>
                                </ol>
                            </div>
                            {/* <!-- /.col --> */}
                        </div>
                        {/* <!-- /.row --> */}
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </div>
                {/* <!-- /.content-header --> */}

                {/* <!-- Main content --> */}
                <section className="content">
                    {
                        props.isLoading === "true" ?
                            <div align="center">
                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your files. Please wait...', props.auth.language)}</p></span>
                            </div>
                            :
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header p-2">
                                            <ul className="nav nav-pills">
                                                {/* <li className="nav-item"><Link to="#activity" onClick={() => props.change_tab('activity')} className="nav-link active" data-toggle="tab">Activity</Link></li> */}
                                                <li className="nav-item"><Link to="#my_computer" onClick={() => props.change_tab('my_computer')} className="nav-link active" data-toggle="tab">{t('My Computer', props.auth.language)}</Link></li>
                                                <li className="nav-item"><Link to="#file_manager" onClick={() => props.change_tab('file_manager')} className="nav-link" data-toggle="tab">{t('File Manager', props.auth.language)}</Link></li>
                                                <li className="nav-item"><Link to="#my_documents" onClick={() => props.change_tab('my_documents')} className="nav-link" data-toggle="tab">{t('My Documents', props.auth.language)}</Link></li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-content">
                                                <div className={props.c_state.tab === 'my_computer' ? 'active tab-pane' : 'tab-pane'} id="my_computer">
                                                    <div className="form-group">
                                                        <div className="btn btn-default btn-file fileinput-button btn-block">
                                                            <i className="fas fa-paperclip"></i> {t('Choose File', props.auth.language)}
                                                            <input type="file" name="my_computer" accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-excel.sheet.macroEnabled.12, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" onChange={(event) => props.handleChange(event.target.files[0], "my_computer")} />
                                                        </div>
                                                    </div>
                                                    {
                                                        props.conversions.isLoading === "true" ?
                                                            <div align="center">
                                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Coverting File. Please wait...', props.auth.language)}</p></span>
                                                            </div>
                                                            :
                                                            props.c_state.my_computer.name ?
                                                                <>
                                                                    <div className="table table-striped files" id="previews">
                                                                        <strong className="lead" style={{ fontWeight: 'bold' }}>{t('Selected Documents', props.auth.language)}: </strong> {props.c_state.my_computer.name} (<span data-dz-size>{(props.c_state.my_computer.size / (1024 * 1000)).toFixed(2)} MB</span>)
                                                                    </div>
                                                                    <div className="row">
                                                                        {
                                                                            props.c_state.my_computer.type === "application/pdf" ?
                                                                                <>
                                                                                    {/* <div className="col-3" align="center">
                                                                                    <br />
                                                                                    <Link to="#" onClick={() => props.pdf_to_word("my_computer")}>
                                                                                        <img src="static/assets/dist/img/word.png" alt="word" width="60" height="60" />
                                                                                        <br />
                                                                                        <h5>Convert To Word</h5>
                                                                                    </Link>
                                                                                </div> */}
                                                                                    <div className="col-3" align="center">
                                                                                        <br />
                                                                                        <Link to="#" onClick={() => props.convert("my_computer", "pdfToImage")} >
                                                                                            <img src="static/assets/dist/img/picture.png" alt="image_icon" width="60" height="60" />
                                                                                            <br />
                                                                                            <h5>{t('Convert To Image', props.auth.language)}</h5>
                                                                                        </Link>
                                                                                    </div>
                                                                                </>
                                                                                : props.c_state.my_computer.type === "application/msword" || props.c_state.my_computer.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                                                    <>
                                                                                        <div className="col-3" align="center">
                                                                                            <br />
                                                                                            <Link to="#" onClick={() => props.convert("my_computer", "wordToPdf")} >
                                                                                                <img src="static/assets/dist/img/pdf.png" alt="pdf" width="60" height="60" />
                                                                                                <br />
                                                                                                <h5>{t('Convert To PDF', props.auth.language)}</h5>
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div className="col-3" align="center">
                                                                                            <br />
                                                                                            <Link to="#" onClick={() => props.convert("my_computer", "wordToImage")} >
                                                                                                <img src="static/assets/dist/img/picture.png" alt="image_icon" width="60" height="60" />
                                                                                                <br />
                                                                                                <h5>{t('Convert To Image', props.auth.language)}</h5>
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div className="col-3" align="center">
                                                                                            <br />
                                                                                            <Link to="#" onClick={() => props.convert("my_computer", "wordToTxt")} >
                                                                                                <img src="static/assets/dist/img/file.png" alt="text" width="60" height="60" />
                                                                                                <br />
                                                                                                <h5>{t('Convert To Text File', props.auth.language)}</h5>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </>
                                                                                    : props.c_state.my_computer.type === "application/vnd.ms-excel" || props.c_state.my_computer.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                        props.c_state.my_computer.type === "application/vnd.ms-excel.sheet.binary.macroEnabled.12" || props.c_state.my_computer.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ?
                                                                                        <>
                                                                                            <div className="col-3" align="center">
                                                                                                <br />
                                                                                                <Link to="#" onClick={() => props.convert("my_computer", "excelToPdf")} >
                                                                                                    <img src="static/assets/dist/img/pdf.png" alt="pdf" width="60" height="60" />
                                                                                                    <br />
                                                                                                    <h5>{t('Convert To PDF', props.auth.language)}</h5>
                                                                                                </Link>
                                                                                            </div>
                                                                                            <div className="col-3" align="center">
                                                                                                <br />
                                                                                                <Link to="#" onClick={() => props.convert("my_computer", "excelToImage")} >
                                                                                                    <img src="static/assets/dist/img/picture.png" alt="image_icon" width="60" height="60" />
                                                                                                    <br />
                                                                                                    <h5>{t('Convert To Image', props.auth.language)}</h5>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        props.c_state.my_computer.type === "application/vnd.ms-powerpoint" || props.c_state.my_computer.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ?
                                                                                            <>
                                                                                                <div className="col-3" align="center">
                                                                                                    <br />
                                                                                                    <Link to="#" onClick={() => props.convert("my_computer", "powerpointToPdf")} >
                                                                                                        <img src="static/assets/dist/img/pdf.png" alt="pdf" width="60" height="60" />
                                                                                                        <br />
                                                                                                        <h5>{t('Convert To PDF', props.auth.language)}</h5>
                                                                                                    </Link>
                                                                                                </div>
                                                                                                <div className="col-3" align="center">
                                                                                                    <br />
                                                                                                    <Link to="#" onClick={() => props.convert("my_computer", "powerpointToImage")} >
                                                                                                        <img src="static/assets/dist/img/picture.png" alt="image_icon" width="60" height="60" />
                                                                                                        <br />
                                                                                                        <h5>{t('Convert To Image', props.auth.language)}</h5>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <div className="row col-12" align="center">
                                                                                                <p className="text-danger">{t('You can only convert text, pdf, word, spreadsheet and powerpoint files', props.auth.language)}</p>
                                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </>
                                                                : null
                                                    }
                                                </div>

                                                <div className={props.c_state.tab === 'file_manager' ? 'active tab-pane' : 'tab-pane'} id="file_manager">
                                                    {
                                                        props.conversions.isLoading === "true" ?
                                                            <div align="center">
                                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Coverting File. Please wait...', props.auth.language)}</p></span>
                                                            </div>
                                                            :
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            {/* <h3 className="card-title">Fixed Header Table</h3> */}
                                                                            <div className="col-sm-12">
                                                                                <ol className="breadcrumb float-sm-left">
                                                                                    {
                                                                                        props.c_state.breadcrumbs.map((data, index) => {
                                                                                            return (
                                                                                                <li key={index} className="breadcrumb-item"><Link to="#" onClick={() => props.click_breadcrumbs(index)}>{data.filename === '/' ? 'Home' : data.filename}</Link></li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ol>
                                                                            </div>
                                                                            <div className="card-tools">
                                                                                <div className="input-group input-group-sm" style={{ width: 150 }}>
                                                                                    <input type="text" onChange={(event) => props.search_attachment(event.target.value, 'files')} name="table_search" className="form-control float-right" placeholder={t('Search', props.auth.language)} />

                                                                                    <div className="input-group-append">
                                                                                        <button type="submit" className="btn btn-default">
                                                                                            <i className="fas fa-search"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-body table-responsive p-0" style={{ height: 300 }}>
                                                                            <table className="table table-head-fixed table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>{t('File Name', props.auth.language)}</th>
                                                                                        <th>{t('Date Created', props.auth.language)}</th>
                                                                                        <th></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        props.c_state.file_list.map((data, index) => {
                                                                                            return (
                                                                                                <tr key={index}>
                                                                                                    <td>&nbsp;<Link to="#" style={{ color: 'black' }} onClick={() => props.enter_folder(data)} onDoubleClick={() => props.enter_folder(data)}>{data.type === "file" ? <i style={{ color: 'blue' }} className="fa fa-file-alt"> &nbsp;</i> : data.type === "folder" ? <i style={{ color: 'red' }} className="fa fa-folder"> &nbsp;</i> : null}{data.filename.substring(0, 20)}{data.filetype}</Link></td>
                                                                                                    <td><Link to="#" style={{ color: 'black' }} onClick={() => props.enter_folder(data)} onDoubleClick={() => props.enter_folder(data)}>{data.created_at}</Link></td>
                                                                                                    <td className="text-right py-0 align-middle">
                                                                                                        <div className="btn-group btn-group-sm">
                                                                                                            {data.type === "file" ? <><button className="btn btn-info" title={t('View File', props.auth.language)} data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'file')}><i className="fas fa-eye"></i></button>&nbsp;</> : null}
                                                                                                            {
                                                                                                                is_word.includes(data.filetype) ?
                                                                                                                    <>
                                                                                                                        <Link to="#" onClick={() => props.convert("file_manager", "wordToPdf", data)} >
                                                                                                                            <img src="static/assets/dist/img/pdf.png" alt="pdf" width="30" height="30" title={t('Convert To PDF', props.auth.language)} />
                                                                                                                        </Link>
                                                                                                                        <Link to="#" onClick={() => props.convert("file_manager", "wordToImage", data)} >
                                                                                                                            <img src="static/assets/dist/img/picture.png" alt="image_icon" width="30" height="30" title={t('Convert To Image', props.auth.language)} />
                                                                                                                        </Link>
                                                                                                                        <Link to="#" onClick={() => props.convert("file_manager", "wordToTxt", data)} >
                                                                                                                            <img src="static/assets/dist/img/file.png" alt="txt" width="30" height="30" title={t('Convert To Text File', props.auth.language)} />
                                                                                                                        </Link>
                                                                                                                    </>
                                                                                                                    : is_pdf.includes(data.filetype) ?
                                                                                                                        <>
                                                                                                                            <Link to="#" onClick={() => props.convert("file_manager", "pdfToImage", data)} >
                                                                                                                                <img src="static/assets/dist/img/picture.png" alt="image_icon" width="30" height="30" title={t('Convert To Image', props.auth.language)} />
                                                                                                                            </Link>
                                                                                                                        </>
                                                                                                                        : is_powerpoint.includes(data.filetype) ?
                                                                                                                            <>
                                                                                                                                <Link to="#" onClick={() => props.convert("file_manager", "powerpointToPdf", data)} >
                                                                                                                                    <img src="static/assets/dist/img/pdf.png" alt="image_icon" width="30" height="30" title={t('Convert To PDF', props.auth.language)} />
                                                                                                                                </Link>
                                                                                                                                <Link to="#" onClick={() => props.convert("file_manager", "powerpointToImage", data)} >
                                                                                                                                    <img src="static/assets/dist/img/picture.png" alt="image_icon" width="30" height="30" title={t('Convert To Image', props.auth.language)} />
                                                                                                                                </Link>
                                                                                                                            </>
                                                                                                                            : is_excel.includes(data.filetype) ?
                                                                                                                                <>
                                                                                                                                    <Link to="#" onClick={() => props.convert("file_manager", "excelToPdf", data)} >
                                                                                                                                        <img src="static/assets/dist/img/pdf.png" alt="image_icon" width="30" height="30" title={t('Convert To PDF', props.auth.language)} />
                                                                                                                                    </Link>
                                                                                                                                    <Link to="#" onClick={() => props.convert("file_manager", "excelToImage", data)} >
                                                                                                                                        <img src="static/assets/dist/img/picture.png" alt="image_icon" width="30" height="30" title={t('Convert To Image', props.auth.language)} />
                                                                                                                                    </Link>
                                                                                                                                </>
                                                                                                                                : null
                                                                                                            }
                                                                                                            {/* <button className="btn btn-primary" title="Select Document" onClick={() => console.log("here")}><i className="fas fa-mouse"></i></button> */}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>

                                                <div className={props.c_state.tab === 'my_documents' ? 'active tab-pane' : 'tab-pane'} id="my_documents">
                                                    {
                                                        props.conversions.isLoading === "true" ?
                                                            <div align="center">
                                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Coverting File. Please wait...', props.auth.language)}</p></span>
                                                            </div>
                                                            :
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            {/* <h3 className="card-title">Fixed Header Table</h3> */}

                                                                            <div className="card-tools">
                                                                                <div className="input-group input-group-sm" style={{ width: 150 }}>
                                                                                    <input type="text" onChange={(event) => props.search_attachment(event.target.value, 'documents')} name="table_search" className="form-control float-right" placeholder={t('Search', props.auth.language)} />

                                                                                    <div className="input-group-append">
                                                                                        <button type="submit" className="btn btn-default">
                                                                                            <i className="fas fa-search"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-body table-responsive p-0" style={{ height: 300 }}>
                                                                            <table className="table table-head-fixed table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>{t('File Name', props.auth.language)}</th>
                                                                                        <th>{t('Date Created', props.auth.language)}</th>
                                                                                        <th></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        props.c_state.document_list.map((data, index) => {
                                                                                            return (
                                                                                                <tr key={index}>
                                                                                                    <td>&nbsp;<i style={{ color: 'blue' }} className="fa fa-file-alt"> &nbsp;</i><Link to="#" style={{ color: 'black' }} onClick={() => props.select_my_document(data)}>{data.name}{data.ext}</Link></td>
                                                                                                    <td><Link to="#" style={{ color: 'black' }} onClick={() => props.select_my_document(data)}>{data.date_created}</Link></td>
                                                                                                    <td className="text-right py-0 align-middle">
                                                                                                        <div className="btn-group btn-group-sm">
                                                                                                            <button className="btn btn-info" title={t('View Document', props.auth.language)} data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'document')}><i className="fas fa-eye"></i></button>&nbsp;
                                                                                                            {
                                                                                                                is_word.includes(data.ext) ?
                                                                                                                    <>
                                                                                                                        <Link to="#" onClick={() => props.convert("my_documents", "wordToPdf", data)} >
                                                                                                                            <img src="static/assets/dist/img/pdf.png" alt="pdf" width="30" height="30" title={t('Convert To PDF', props.auth.language)} />
                                                                                                                        </Link>
                                                                                                                        <Link to="#" onClick={() => props.convert("my_documents", "wordToImage", data)} >
                                                                                                                            <img src="static/assets/dist/img/picture.png" alt="image_icon" width="30" height="30" title={t('Convert To Image', props.auth.language)} />
                                                                                                                        </Link>
                                                                                                                        <Link to="#" onClick={() => props.convert("my_documents", "wordToTxt", data)} >
                                                                                                                            <img src="static/assets/dist/img/file.png" alt="txt" width="30" height="30" title={t('Convert To Text File', props.auth.language)} />
                                                                                                                        </Link>
                                                                                                                    </>
                                                                                                                    : is_pdf.includes(data.ext) ?
                                                                                                                        <>
                                                                                                                            <Link to="#" onClick={() => props.convert("my_documents", "pdfToImage", data)} >
                                                                                                                                <img src="static/assets/dist/img/picture.png" alt="image_icon" width="30" height="30" title={t('Convert To Image', props.auth.language)} />
                                                                                                                            </Link>
                                                                                                                        </>
                                                                                                                        : is_powerpoint.includes(data.ext) ?
                                                                                                                            <>
                                                                                                                                <Link to="#" onClick={() => props.convert("my_documents", "powerpointToPdf", data)} >
                                                                                                                                    <img src="static/assets/dist/img/pdf.png" alt="image_icon" width="30" height="30" title={t('Convert To PDF', props.auth.language)} />
                                                                                                                                </Link>
                                                                                                                                <Link to="#" onClick={() => props.convert("my_documents", "powerpointToImage", data)} >
                                                                                                                                    <img src="static/assets/dist/img/picture.png" alt="image_icon" width="30" height="30" title={t('Convert To Image', props.auth.language)} />
                                                                                                                                </Link>
                                                                                                                            </>
                                                                                                                            : is_excel.includes(data.ext) ?
                                                                                                                                <>
                                                                                                                                    <Link to="#" onClick={() => props.convert("my_documents", "excelToPdf", data)} >
                                                                                                                                        <img src="static/assets/dist/img/pdf.png" alt="image_icon" width="30" height="30" title={t('Convert To PDF', props.auth.language)} />
                                                                                                                                    </Link>
                                                                                                                                    <Link to="#" onClick={() => props.convert("my_documents", "excelToImage", data)} >
                                                                                                                                        <img src="static/assets/dist/img/picture.png" alt="image_icon" width="30" height="30" title={t('Convert To Image', props.auth.language)} />
                                                                                                                                    </Link>
                                                                                                                                </>
                                                                                                                                : null
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            {
                                                props.conversions.conversions.file_link ?
                                                    <div className="row col-12">
                                                        <h5><strong>{t('Converted File', props.auth.language)}: </strong> <a href={props.conversions.conversions.file_link} id="converted_file" target="converted_file">{props.conversions.conversions.file_name}</a></h5>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- /.content --> */}

                {/* modals */}

                <div className="modal fade" id="view_attachment_modal" tabIndex="-1" role="dialog" aria-labelledby="viewAttachmentModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="viewAttachmentModalLabel">{t('View Document', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_attachment_info}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    props.c_state.view_attachment_data ?
                                        props.c_state.view_attachment_data.ext === ".docx" || props.c_state.view_attachment_data.ext === ".pptx" || props.c_state.view_attachment_data.ext === ".xlsx" || props.c_state.view_attachment_data.ext === ".csv" ?
                                            props.c_state.document_config ?
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe
                                                        className="embed-responsive-item"
                                                        title="doc_viewer"
                                                        src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.c_state.document_config)}`)}
                                                        allowFullScreen>
                                                    </iframe>
                                                </div>
                                                : null

                                            : props.file_type(props.c_state.view_attachment_data.ext) === "audio" ?
                                                <audio controls preload='auto' autoPlay width='100%' height='100%'>
                                                    <source src={`${types.SERVER_URL}static/${props.c_state.view_attachment_data.folder}/${props.c_state.view_attachment_data.id}${props.c_state.view_attachment_data.ext}`} type='audio/ogg; audio/mpeg' />
                                                </audio>

                                                : props.file_type(props.c_state.view_attachment_data.ext) === "image" ?
                                                    <img src={`${types.SERVER_URL}static/${props.c_state.view_attachment_data.folder}/${props.c_state.view_attachment_data.id}${props.c_state.view_attachment_data.ext}`} alt="attachment-img" />

                                                    : props.file_type(props.c_state.view_attachment_data.ext) === "video" ?
                                                        <video controls preload='auto' width='100%' autoPlay height='100%'>
                                                            <source src={`${types.SERVER_URL}static/${props.c_state.view_attachment_data.folder}/${props.c_state.view_attachment_data.id}${props.c_state.view_attachment_data.ext}`} type='video/mp4; video/webm; video/x-matroska' />
                                                        </video>

                                                        : props.c_state.view_attachment_data.ext === ".pdf" ?
                                                            <div className="embed-responsive embed-responsive-16by9">
                                                                <iframe
                                                                    className="embed-responsive-item"
                                                                    title="pdf_viewer"
                                                                    src={`${types.SERVER_URL}static/${props.c_state.view_attachment_data.folder}/${props.c_state.view_attachment_data.id}${props.c_state.view_attachment_data.ext}`}
                                                                    allowFullScreen>
                                                                </iframe>
                                                            </div>

                                                            : <p align="center">{t('This file cannot be previewed', props.auth.language)}. <Link to="#" onClick={() => props.download(props.c_state.view_attachment_data.folder, props.c_state.view_attachment_data.id, props.c_state.view_attachment_data.ext, props.c_state.view_attachment_data.file_name)}>{t('Click here to download', props.auth.language)}.</Link></p>
                                        : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default pull-right" data-dismiss="modal" id="dismiss_view_attachment_modal" onClick={props.clear_attachment_info}>{t('Close', props.auth.language)}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default Conversions
