import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    privileges: [],
    contact_privileges: [],
    contact_disabled: ''
}

const Privileges = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.PRIVILEGES_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.PRIVILEGES:

            newState.privileges = payload.data;

            // toast.success(payload.msg, {
            //     position: 'top-center',
            //     autoClose: 5000
            // });

            return newState;

        case types.PRIVILEGES_SUCCESS:

            if (payload.msg === "Contact privileges loaded successfully") {
                newState.contact_privileges = payload.data.privileges;
                newState.contact_disabled = payload.data.disabled;
            }

            if (payload.msg === "User account disabled successfully" || payload.msg === "User account enabled successfully") {
                newState.contact_disabled = payload.data;
            }

            if (payload.msg !== "Contact privileges loaded successfully") {
                toast.success(payload.msg, {
                    position: 'top-center',
                    autoClose: 5000
                });
            }

            return newState;

        default:
            return newState;
    }
}

export default Privileges