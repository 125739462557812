import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: 'false',
    tasks: [],
    task_comments: [],
    comment_attachments: [],
    rand: null
}

const Task = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.TASKS_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.TASKS:

            newState.tasks = payload.data;

            newState.rand = Math.floor(Math.random() * 999999999999) + "";

            toast.info(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        case types.TASKS_SUCCESS:

            if (payload.msg === "Task added successfully") {
                // eslint-disable-next-line array-callback-return
                // newState.tasks = [...newState.tasks, { ...payload.data[0] }]
                newState.tasks = payload.data;

                // newState.tasks.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
            }

            if (payload.msg === "Task deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.tasks = newState.tasks.filter((data, index) => { // loop through state.todo
                    if (data.task.task_id !== payload.data.task_id) { // check what data to update using key
                        return data
                    }
                })
            }

            if (payload.msg === "Task updated successfully" || payload.msg === "Task rated successfully") {
                newState.tasks = payload.data;
            }

            if (payload.msg === "Comment submitted successfully") {
                // newState.tasks = newState.tasks.map((data, index) => { // loop through state.todo
                //     if (data.task.task_id === payload.data[0].task.task_id) { // check what data to update using key
                //         data = { ...data, ...payload.data[0] } // replace resulting data with new payload
                //     }
                //     return data
                // });

                newState.task_comments = payload.data;

                // // console.log(newState.tasks);

                // newState.tasks.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
            }

            if (payload.msg === "Task copied successfully") {
                newState.rand = Math.floor(Math.random() * 999999999999) + "";
            }

            if (payload.msg === "Comment attachments loaded successfully") {
                newState.comment_attachments = payload.data;
            }

            if (payload.msg !== "Comment attachments loaded successfully") {
                toast.success(payload.msg, {
                    position: 'top-center'
                });
            }

            return newState;

        case types.TASKS_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        default:
            return newState;
    }
}

export default Task