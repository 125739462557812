/* eslint-disable array-callback-return */
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import types from '../../types';
import TextField from '@material-ui/core/TextField';
// import { makeStyles } from '@material-ui/core/styles';
// import TreeView from '@material-ui/lab/TreeView';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import TreeItem from '@material-ui/lab/TreeItem';
import AddAttachmentModal from '../AttachmentComponent/AddAttachmentModal';
import ViewAttachmentModal from '../AttachmentComponent/ViewAttachmentModal';
import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../styles.css'
import { getTrans as t } from '../../translations';
import PrintCorrespondence from './PrintCorrespondence';

// const ui = makeStyles({
// 	root: {
// 		height: 240,
// 		flexGrow: 1,
// 		maxWidth: 400,
// 	}
// });

const styles = {
	card_tree: {
		// background: '#fff',
		// height: 56,
		// textAlign: 'center',
		// width: 300,
		// paddingTop: 60,
		// borderRadius: 16,
		// borderBottomLeftRadius: 0,
		// borderBottomRightRadius: 0,
		// position: 'relative',
		// zIndex: -1,
		// left: '50%',
		// transform: '-50%'
	}
}

// const renderTree = (nodes) => (
// 	<TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
// 		{Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
// 	</TreeItem>
// );

const Card = (props) => {

	return (
		<ul>
			{props.data.map((item, i) => (
				<Fragment key={i}>
					<li>
						<div className="card card-info card-outline" style={styles.card_tree}>
							<a href={`#ama${item.id}`} onClick={() => props.trailToShow(item.id)}>
								<div className="card-body" style={{ padding: 2 }}>
									<div className="user-block">
										<img className="img-circle img-bordered-sm" src={item.photo !== '' && item.photo !== null ? types.SERVER_URL + 'static/contacts/' + item.photo : "static/assets/dist/img/user4-128x128.jpg"} alt="user_image" />
										<span className="description">
											<strong>{item.name}</strong>
										</span>
										<span className="description">{item.position}</span>
									</div>
								</div>
							</a>
						</div>
						{item.children ? <Card data={item.children} trailToShow={props.trailToShow} /> : null}
					</li>
				</Fragment>
			))}
		</ul>
	);
};

const CorrespondenceDetails = (props) => {

	// console.log("this", props.correspondence.attached_correspondence);

	try {
		return (
			<div className="content-wrapper">
				<section className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1>{props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.reference_no : ''}</h1>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
									<li className="breadcrumb-item"><Link to="/correspondence">{t('Correspondence', props.auth.language)}</Link></li>
									<li className="breadcrumb-item active">{t('Correspondence Details', props.auth.language)}</li>
								</ol>
							</div>
						</div>
					</div>
				</section>

				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<div className="card card-primary card-outline">
									<div className="card-header">
										<h3 className="card-title">{t('Read Correspondence', props.auth.language)}</h3>

										<div className="card-tools">
											<div className="btn-group">
												{
													props.single_correspondence.single_correspondence ? (props.single_correspondence.single_correspondence.action_by === props.auth.user.contact_id || props.can_close_route() === true) ?
														<button type="button" className="btn btn-default btn-sm" data-container="body" title={props.single_correspondence.single_correspondence.closed === "0" ? t('Close this correspondence', props.auth.language) : t('Open this correspondence', props.auth.language)}
															onClick={() => window.confirm(`${props.single_correspondence.single_correspondence.closed === "0" ? t('Recipients will not be able to work on this correspondence. Are you sure you want to close this correspondence?', props.auth.language) : t('Are you sure you want to re-open this correspondence?', props.auth.language)}`) ?
																props.close_correspondence() : ''}>
															<i className="fa fa-archive" style={{ color: props.single_correspondence.single_correspondence.closed === "0" ? 'red' : 'green' }}></i>
														</button>
														: null
														: null
												}
											</div>
											<button type="button" onClick={props.print_correspondence} className="btn btn-default btn-sm" title={t('Print', props.auth.language)}>
												<i className="fas fa-print"></i>
											</button>
											<button type="button" className="btn btn-default btn-sm" title={t('Reload', props.auth.language)} onClick={props.reload_correspondence}>
												<i className="fas fa-sync-alt"></i>
											</button>
											&nbsp;
											{/* <button className="btn btn-sm btn-outline-info" data-toggle="modal" data-target="#view_structure_modal">View Structure</button>&nbsp; */}
											{props.single_correspondence.correspondence_category ?
												props.single_correspondence.correspondence_category === "Archived" ?
													<><Link to="#" onClick={props.unarchive} className="btn btn-sm btn-outline-primary"><i className="fa fa-undo"></i> {t('Make Active', props.auth.language)}</Link>&nbsp;</>
													: null
												: null
											}
											<Link to="/correspondence" className="btn btn-sm btn-outline-danger">{t('Back To', props.auth.language)} {t(props.single_correspondence.correspondence_category, props.auth.language)}</Link>&nbsp;
											{/* <Link to="#" className="btn btn-tool" title="Previous"><i className="fas fa-chevron-left"></i></Link>
                                        <Link to="#" className="btn btn-tool" title="Next"><i className="fas fa-chevron-right"></i></Link> */}
										</div>
									</div>
									{
										props.correspondence.isLoading === "true" ?
											<div align="center">
												<span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Your correspondence is loading. Please wait...', props.auth.language)}</p></span>
											</div>
											:
											<div className="card-body p-0">
												<div className="mailbox-read-info">
													<h5>{props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.subject : ''} <span style={{ fontSize: 10 }} className="badge bg-primary">{t(props.single_correspondence.correspondence_category, props.auth.language)}</span></h5>
													<h6>{t('Initiated By', props.auth.language)}: {props.contact_details(props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.action_by : '')}
														<span className="mailbox-read-time float-right">{props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.date_created : ''}</span>
													</h6>
												</div>
												<div className="mailbox-controls with-border text-center">
													<div className="btn-group">
														{/* {
														props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.action_by === props.auth.user.contact_id ?
															<button type="button" className="btn btn-default btn-sm" data-container="body" title="Close this correspondence"
																onClick={() => window.confirm(`${props.single_correspondence.single_correspondence.closed === "0" ? 'Recipients will not be able to work on this correspondence. Are you sure you want to close this correspondence?' : 'Are you sure you want to re-open this correspondence?'}`) ?
																	props.close_correspondence() : ''}>
																<i className="fa fa-archive" style={{ color: props.single_correspondence.single_correspondence.closed === "0" ? 'red' : 'green' }}></i>
															</button>
															: null
															: null
													} */}
														{/* <button type="button" className="btn btn-default btn-sm" data-container="body" title="Reply">
                                                        <i className="fas fa-reply"></i>
                                                    </button>
                                                    <button type="button" className="btn btn-default btn-sm" data-container="body" title="Forward">
                                                        <i className="fas fa-share"></i>
                                                    </button> */}
													</div>
													{/* <button type="button" className="btn btn-default btn-sm" title="Print">
													<i className="fas fa-print"></i>
												</button>
												<button type="button" className="btn btn-default btn-sm" title="Reload" onClick={props.reload_correspondence}>
													<i className="fas fa-sync-alt"></i>
												</button> */}
												</div>

												<div className="mailbox-read-message">
													<h5 className="text-success" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t('Key Info', props.auth.language)}</h5>
													{props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.closed === "0" ?
														<p className="text-primary"><strong>{t('This correspondence is still open', props.auth.language)}</strong></p> :
														<h4 className="text-danger"><strong>{t('This correspondence has been closed', props.auth.language)}</strong><br /><br /></h4>
														: ''
													}
													<div className="row col-md-12">
														<div className="col-md-4">
															<strong>{t('Confidentiality', props.auth.language)}:</strong> {
																props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.confidential === "true" ?
																	<span className="text-danger">{t('Confidential', props.auth.language)}</span> : <span>{t('Not Confidential', props.auth.language)}</span> : ''
															}
														</div>
														<div className="col-md-4"><strong>{t('Deadline', props.auth.language)}:</strong> {props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.deadline : ''}</div>
														<div className="col-md-4"><strong>{t('Priority', props.auth.language)}: </strong>
															<span className={`${props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.priority === 'High Priority' ? 'text-danger' : props.single_correspondence.single_correspondence.priority === 'Medium Priority' ? 'text-warning' : '' : ''}`}>
																{props.single_correspondence.single_correspondence ? t(props.single_correspondence.single_correspondence.priority, props.auth.language) : ''}
															</span>
														</div>
													</div>
													<br />
													<div className="row col-md-12">
														<div className="col-md-4"><strong>{t('Source/Type', props.auth.language)}:</strong> {props.single_correspondence.single_correspondence ? t(props.single_correspondence.single_correspondence.source, props.auth.language) : ''}</div>
														{
															props.single_correspondence.single_correspondence ?
																props.single_correspondence.single_correspondence.attached_correspondence ?
																	<div className="col-md-6"><strong>{t('Referenced Correspondence', props.auth.language)}: </strong> <Link to="#" data-toggle="modal" data-target="#attached_correspondence_modal" onClick={() => props.get_attached_correspondence(props.single_correspondence.single_correspondence.attached_correspondence)}><span className="badge bg-info">{props.get_trail_info(props.single_correspondence.single_correspondence)}</span></Link></div>
																	: null
																: null
														}
													</div>
													{
														typeof props.c_state.correspondence_details.route !== "undefined" ?
															typeof props.c_state.correspondence_details.route.route_id !== "undefined" ?
																<>
																	<br />
																	<h5 className="text-success" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t('Route Used', props.auth.language)} <span className="text-info">({typeof props.c_state.correspondence_details.route.title !== "undefined" ? props.c_state.correspondence_details.route.title : ''})</span></h5>
																	<div className="card card-default card-outline">
																		<div className="card-body">
																			<table className="table">
																				<thead>
																					<tr>
																						<th>#</th>
																						<th>{t('Recipient', props.auth.language)}</th>
																						<th>{t('Deadline', props.auth.language)} ({t('Hours', props.auth.language)})</th>
																						<th>{t('Auto Forward', props.auth.language)}</th>
																						<th>{t('Can Close', props.auth.language)}</th>
																						{/* <th>Expected Response Date</th> */}
																					</tr>
																				</thead>
																				<tbody>
																					{typeof props.c_state.correspondence_details.route !== "undefined" ?
																						// eslint-disable-next-line array-callback-return
																						typeof props.c_state.correspondence_details.route.route_steps !== "undefined" ?
																							props.c_state.correspondence_details.route.route_steps.map((data, i) => {
																								return (
																									<tr key={i} style={props.single_correspondence.single_correspondence ? (props.single_correspondence.single_correspondence.current_step_order === data.ordering && props.single_correspondence.single_correspondence.closed === "0") ? { color: 'red', fontWeight: 'bolder' } : null : null}>
																										<td>{i + 1}</td>
																										<td>{props.contact_details(data.user)}</td>
																										<td>{data.deadline}</td>
																										<td>{t(data.auto_forward, props.auth.language)}</td>
																										<td>{t(data.can_close, props.auth.language)}</td>
																										{/* <td>{data.expected_response_date}</td> */}
																									</tr>
																								);
																							})
																							: null
																						: null
																					}
																				</tbody>
																			</table>
																		</div>
																	</div>
																</>
																: null
															: null
													}
													<br />
													<h5 className="text-success" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t('Body', props.auth.language)}</h5>
													<div dangerouslySetInnerHTML={{ __html: props.single_correspondence.single_correspondence ? props.single_correspondence.single_correspondence.body : '' }} />
													{
														props.c_state.correspondence_details.correspondence_attachments ?
															props.c_state.correspondence_details.correspondence_attachments.length ?
																<>
																	<br />
																	<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																	<div className="card-footer bg-white">
																		<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																			{
																				props.c_state.correspondence_details.correspondence_attachments.map((data, index) => {
																					return (
																						<li key={index}>
																							{/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

																							<div className="mailbox-attachment-info">
																								<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'attachment')}><i className="fas fa-paperclip"></i> {data.file_name}{data.ext}</Link>
																								<span className="mailbox-attachment-size clearfix mt-1">
																									{/* <span>1,245 KB</span> */}
																									<Link to="#" className="btn btn-default btn-sm float-right" title={t('Download', props.auth.language)} onClick={() => props.download('attachments', data.aid, data.ext, data.file_name)}><i className="fas fa-download"></i></Link>
																									<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", data.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																								</span>
																							</div>
																						</li>
																					)
																				})
																			}
																		</ul>
																	</div>
																</>
																: null
															: null
													}
													{
														props.correspondence.isLoading === "true" ?
															<div align="center">
																<span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Loading. Please wait...', props.auth.language)}</p></span>
															</div>
															:
															props.c_state.correspondence_details.trails ?
																<>
																	{
																		props.single_correspondence.correspondence_category === "Inbox" ?
																			<>
																				<br />
																				<h5 className="text-success" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t('Active Trail', props.auth.language)}</h5>
																				{
																					props.c_state.correspondence_details.trails.map((data, index) => {
																						if (data.trail_id + '' === props.single_correspondence.trail_id + '') {
																							return (
																								<div className="card card-default card-outline" key={index}>
																									<div className="card-header">
																										<h6 className="card-title w-100">
																											<span className="mailbox-read-time float-right">{data.date_created}</span>
																											<span className="text-primary">{t('From', props.auth.language)}: {props.contact_details(data.c_from)}</span>
																										</h6>
																									</div>
																									<div className="card-body">
																										<div className="row col-md-12">
																											<div className="col-md-3"><strong>{t('Expected Response Date', props.auth.language)}: </strong>{data.expected_response_date} {data.expected_response_time}</div>
																											<div className="col-md-3"><strong>{t('Seen?', props.auth.language)} </strong>{data.seen}</div>
																											<div className="col-md-3"><strong>{t('Date Seen', props.auth.language)}: </strong>{data.date_seen}</div>
																											<div className="col-md-3"><strong>{t('Status', props.auth.language)}: </strong>{data.active === '1' ? 'Active' : 'Nullified'}</div>
																										</div>
																										<br /><br />
																										<h5 style={{ textDecoration: 'underline' }}>{t('Minutes/Comments', props.auth.language)}</h5>
																										{data.forwarded === 'true' ? <><br />---------- {t('Forwarded message', props.auth.language)} ---------<br /><br /></> : data.fyi === '1' ? <><br />---------- FYI ---------<br /><br /></> : ''}
																										<div style={{ textDecoration: data.active === '0' ? 'line-through' : '' }} dangerouslySetInnerHTML={{ __html: data.message }} />
																										{
																											data.trail_attachments.length ?
																												<>
																													<br />
																													<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																													<div className="card-footer bg-white">
																														<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																															{
																																data.trail_attachments.map((ele, ind) => {
																																	return (
																																		<li key={ind}>
																																			{/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

																																			<div className="mailbox-attachment-info">
																																				<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(ele, 'attachment')}><i className="fas fa-paperclip"></i> {ele.file_name}{ele.ext}</Link>
																																				<span className="mailbox-attachment-size clearfix mt-1">
																																					<Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', ele.aid, ele.ext, ele.file_name)}><i className="fas fa-download"></i></Link>
																																					<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", ele.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																																				</span>
																																			</div>
																																		</li>
																																	)
																																})
																															}
																														</ul>
																													</div>
																												</>
																												: null
																										}
																										{
																											data.trail_actions.length ?
																												<>
																													<br />
																													<h5 style={{ textDecoration: 'underline' }}>{t('Actions Performed by', props.auth.language)} {props.contact_details(data.c_to)}</h5>
																													<table className="table table-striped">
																														<thead>
																															<tr>
																																<th style={{ width: 10 }}>#</th>
																																<th>{t('Action', props.auth.language)}</th>
																																<th>{t('Description', props.auth.language)}</th>
																																<th>{t('Status', props.auth.language)}</th>
																																<th>{t('Date Started', props.auth.language)}</th>
																																<th>{t('Date Completed', props.auth.language)}</th>
																																<th>{t('Attachments', props.auth.language)}</th>
																																<th></th>
																															</tr>
																														</thead>
																														<tbody>
																															{
																																data.trail_actions.map((el, i) => {
																																	return (
																																		<tr key={i} style={{ textDecoration: el.active === '0' ? 'line-through' : '' }}>
																																			<td>{i + 1}</td>
																																			<td>{el.title}</td>
																																			<td><div dangerouslySetInnerHTML={{ __html: el.description }} /></td>
																																			<td>{t(el.status, props.auth.language)}</td>
																																			<td>{el.date_started}</td>
																																			<td>{el.date_completed}</td>
																																			<td>
																																				{
																																					el.attachments.length ?
																																						el.attachments.map((attachment, o) => {
																																							// console.log(attachment);
																																							return (<button key={o} className="btn btn-xs btn-outline-primary" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(attachment, 'attachment')}><i className="fas fa-paperclip"></i> {`${attachment.file_name.substring(0, 10) + '...'}${attachment.ext}`}</button>)
																																						})
																																						: null
																																				}
																																			</td>
																																			<td>
																																				{
																																					props.auth.user ? props.auth.user.contact_id === el.by ?
																																						(data.archived !== "true" && props.single_correspondence.single_correspondence.closed === "0") ?
																																							el.active === '1' ?
																																								<button type="button" className="btn btn-outline-danger btn-xs" onClick={() => props.nullify_action("0", el.action_id)}><i className="fas fa-trash"></i> {t('Nullify', props.auth.language)}</button>
																																								:
																																								<button type="button" className="btn btn-outline-info btn-xs" onClick={() => props.nullify_action("1", el.action_id)}><i className="fas fa-pencil-alt"></i> {t('Make Active', props.auth.language)}</button>
																																							: null
																																						: null
																																						: null
																																				}
																																			</td>
																																		</tr>
																																	)
																																})
																															}
																														</tbody>
																													</table>
																												</>
																												: null
																										}
																										{
																											props.get_signature(data.c_from) !== null && props.get_signature(data.c_from) !== "null" && props.get_signature(data.c_from) !== '' ?
																												<><br /><img src={`${types.SERVER_URL}static/contacts/${props.get_signature(data.c_from)}`} alt="signed" width={150} /></>
																												: null
																										}
																										{
																											data.trail_emails.length ?
																												<>
																													<br />
																													<h5 style={{ textDecoration: 'underline' }}>{t('Emails', props.auth.language)}</h5>
																													<div className="row">
																														{
																															data.trail_emails.map((email_data, email_index) => {
																																return (
																																	<div style={{ width: '100%' }} className="card card-default card-outline" key={email_index}>
																																		<a className="d-block w-100" data-toggle="collapse" href={`#emailcollapse${email_index}`}>
																																			<div className="card-header">
																																				<h6 className="card-title w-100">
																																					<span className="mailbox-read-time float-right">{email_data.mail_date}</span>
																																					{t('From', props.auth.language)}: {email_data.mail_from}
																																					<br /><span style={{ fontSize: 12, color: props.auth.user.email === email_data.mail_to ? 'red' : '' }}>{t('to', props.auth.language)}: {email_data.mail_to}</span>
																																					{email_data.cc ? <><br />cc: {email_data.mail_cc}</> : null}
																																				</h6>
																																			</div>
																																		</a>
																																		<div id={`emailcollapse${email_index}`} className="collapse" data-parent="#accordion">
																																			<div className="card-body">
																																				<div className="mailbox-read-info">
																																					<h5><strong>{t('Subject', props.auth.language)}:</strong> {email_data.subject}</h5>
																																				</div>

																																				<div className="mailbox-read-message">
																																					<div dangerouslySetInnerHTML={{ __html: email_data.message }} />
																																					{
																																						email_data.email_attachments.length ?
																																							<>
																																								<br />
																																								<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																																								<div className="card-footer bg-white">
																																									<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																																										{
																																											email_data.email_attachments.map((ele, ind) => {
																																												return (
																																													<li key={ind}>
																																														{/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

																																														<div className="mailbox-attachment-info">
																																															<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(ele, 'attachment')}><i className="fas fa-paperclip"></i> {ele.file_name}{ele.ext}</Link>
																																															<span className="mailbox-attachment-size clearfix mt-1">
																																																<Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', ele.aid, ele.ext, ele.file_name)}><i className="fas fa-download"></i></Link>
																																																<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", ele.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																																															</span>
																																														</div>
																																													</li>
																																												)
																																											})
																																										}
																																									</ul>
																																								</div>
																																							</>
																																							: null
																																					}
																																				</div>
																																			</div>
																																		</div>
																																	</div>
																																)
																															})
																														}
																													</div>
																												</>
																												: null
																										}
																										{
																											data.questionnaire ?
																												props.questionnaire_info(data.questionnaire).length ?
																													<>
																														<br />
																														<h5 style={{ fontSize: 16, textDecoration: 'underline' }}><Link to="#" data-toggle="modal" data-target={`#view_questionnaire_modal${data.questionnaire}`}> {t('View & Answer Questionnaire', props.auth.language)}</Link></h5>

																														<div className="modal fade" id={`view_questionnaire_modal${data.questionnaire}`} style={{ overflow: "auto" }} data-backdrop="static" tabindex="-1">
																															<div className="modal-dialog modal-lg">
																																<div className="modal-content">
																																	<div className="modal-header">
																																		<h4 className="modal-title">{props.display_questions() === 1 ? t('Please answer the questions below:', props.auth.language) : t('Questions answered', props.auth.language)}</h4>
																																		{/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
																																			<span aria-hidden="true">&times;</span>
																																		</button> */}
																																	</div>
																																	<div className="modal-body">
																																		<div className="row">
																																			{
																																				props.display_questions() === 1 ?
																																					props.questionnaire_info(data.questionnaire)[0].questions.map((question, idex) => {
																																						return (
																																							<div key={idex} className="form-group col-md-4">
																																								<label>{question.question} {question.required === "Yes" ? "*" : null}</label><br />
																																								{
																																									question.field_type === "Text Field" ?
																																										<textarea className="form-control" onChange={(event) => props.handleChange({ ...question, "answer": event.target.value }, 'questionnaire')} />
																																										:
																																										question.field_type === "Option Buttons" ?
																																											props.explode(question.field_options).map((item, ind) => {
																																												return (
																																													<><label key={ind}>{item.replace(/ /g, '')} <input type="radio" name={question.question} onChange={() => props.handleChange({ ...question, "answer": item.replace(/ /g, '') }, 'questionnaire')} /></label>&nbsp;&nbsp;</>
																																												)
																																											})
																																											:
																																											question.field_type === "Dropdown Select" ?
																																												<select className="form-control" onChange={(event) => props.handleChange({ ...question, "answer": event.target.value }, 'questionnaire')}>
																																													<option value=''></option>
																																													{
																																														props.explode(question.field_options).map((item, ind) => {
																																															return (
																																																<option key={ind} value={item.replace(/ /g, '')}>{item.replace(/ /g, '')}</option>
																																															)
																																														})
																																													}
																																												</select>
																																												: null
																																								}
																																							</div>
																																						)
																																					})
																																					:
																																					<table className="table table-striped">
																																						<thead>
																																							<tr>
																																								<th style={{ width: 10 }}>#</th>
																																								<th>Question</th>
																																								<th>Answer</th>
																																							</tr>
																																						</thead>
																																						<tbody>
																																							{
																																								props.questionnaire_info(data.questionnaire)[0].questions.map((question, idex) => {
																																									return (
																																										<tr>
																																											<td>{idex + 1}</td>
																																											<td>{question.question}</td>
																																											<td>{question.answer}</td>																																									</tr>
																																									)
																																								})
																																							}
																																						</tbody>
																																					</table>
																																			}
																																		</div>
																																	</div>
																																	<div className="modal-footer justify-content-right">
																																		<button hidden type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_view_questionnaire_modal">Close</button>
																																		{
																																			props.display_questions() === 1 ?
																																				<button type="button" className="btn btn-primary" onClick={() => props.check_questionnaire(data.questionnaire)}>{t('Save', props.auth.language)}</button>
																																				:
																																				<button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
																																		}
																																	</div>
																																</div>
																															</div>
																														</div>
																													</>
																													: null
																												: null
																										}
																									</div>
																									{props.single_correspondence.single_correspondence ?
																										data.c_to ?
																											data.c_to === props.auth.user.contact_id ?
																												data.fyi ?
																													data.fyi === '0' && data.archived !== "true" ?
																														<div className="card-footer">
																															{
																																props.single_correspondence.single_correspondence.closed === "0" && data.active === '1' ?
																																	<>
																																		{
																																			!props.single_correspondence.single_correspondence.route_id ?
																																				<><button type="button" className="btn btn-default" data-toggle="modal" data-target="#reply_trail_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-reply"></i> {t('Reply', props.auth.language)}</button>&nbsp;&nbsp;</>
																																				: null
																																		}
																																		{
																																			data.route_step === null || data.route_step === "" ?
																																				<button type="button" className="btn btn-default" data-toggle="modal" data-target="#forward_trail_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-share"></i> {t('Forward', props.auth.language)}</button>
																																				: props.check_current_step_user(data.route_step) === 1 ?
																																					<>
																																						<><button type="button" className="btn btn-default" data-toggle="modal" data-target="#reply_trail_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-reply"></i> {t('Reply', props.auth.language)}</button>&nbsp;&nbsp;</>
																																						<button type="button" className="btn btn-default" data-toggle="modal" data-target="#forward_trail_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-share"></i> {t('Forward', props.auth.language)}</button>
																																					</>
																																					: null
																																		}
																																		<div className="float-right">
																																			<button type="button" className="btn btn-outline-danger btn-sm" onClick={() => window.confirm(`${t('You will not be able to work on an archived trail. Are you sure you want to mark this trail as worked on?', props.auth.language)}`) ? props.archive_trail(data) : ''}><i className="fas fa-times"></i> {t('Mark As Worked On', props.auth.language)}</button>&nbsp;&nbsp;
																																			<button type="button" className="btn btn-outline-info btn-sm" data-toggle="modal" data-target="#add_action_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-plus"></i> {t('Add An Action', props.auth.language)}</button>&nbsp;&nbsp;
																																			<button type="button" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#send_email_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-envelope"></i> {t('Send Email', props.auth.language)}</button>
																																		</div>
																																	</>
																																	: null
																															}
																														</div>
																														: data.archived === "true" ?
																															<h5 className="text-danger" align="center"><strong>{t('This trail has been archived', props.auth.language)}</strong></h5>
																															: null
																													: null
																												: null
																											: null
																										: null
																									}
																									{
																										data.c_from ?
																											data.c_from === props.auth.user.contact_id && props.single_correspondence.single_correspondence.closed === "0" ?
																												<div className="card-footer">
																													<div className="float-right">
																														{data.active === '1' ?
																															<button type="button" className="btn btn-default" onClick={() => props.nullify_trail("0", data.trail_id)}><i className="far fa-trash-alt"></i> {t('Nullify This Trail', props.auth.language)}</button>
																															// {/* <button type="button" className="btn btn-default"><i className="fas fa-print"></i> Print</button> */}
																															:
																															<button type="button" className="btn btn-default" onClick={() => props.nullify_trail("1", data.trail_id)}><i className="fa fa-check"></i> {t('Activate This Trail', props.auth.language)}</button>
																														}
																													</div>
																												</div>
																												: null
																											: null
																									}
																								</div>
																							)
																						}
																					})
																				}
																			</>
																			: null
																	}
																	<br />
																	<hr />
																	<h5 className="text-danger" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t('All Trails', props.auth.language)}</h5>
																	<a data-toggle="collapse" href={`#collapse_structure`}>{t('View Structure', props.auth.language)}</a><br /><br />
																	<div id="collapse_structure" className="collapse card card-primary card-outline" data-parent={"#accordion"} style={{ overflowX: 'auto', width: '30e', whiteSpace: 'nowrap' }}>
																		{
																			props.c_state.correspondence_details.structure ?
																				// <TreeView
																				// 	style={useStyles.root}
																				// 	defaultCollapseIcon={<ExpandMoreIcon />}
																				// 	defaultExpanded={['root']}
																				// 	defaultExpandIcon={<ChevronRightIcon />}
																				// // icon={<i className="fa fa-user"></i>}
																				// >
																				// 	{renderTree(props.c_state.correspondence_details.structure)}
																				// </TreeView>

																				<div className="org-tree">
																					<Card data={[props.c_state.correspondence_details.structure]} trailToShow={props.trailToShow} />
																				</div>

																				: null
																		}
																	</div>
																	<div className="row">
																		<div className="col-12" id="accordion">
																			{
																				props.c_state.correspondence_details.trails.map((data, index) => {
																					return (
																						<div id={`ama${data.trail_id}`} className="card card-default card-outline" key={index}>
																							<a className="d-block w-100" data-toggle="collapse" href={`#collapse${index}`}>
																								<div className="card-header">
																									<h6 className="card-title w-100">
																										<span className="mailbox-read-time float-right">{data.date_created}</span>
																										{t('From', props.auth.language)}: {props.contact_details(data.c_from)}
																										<br /><span style={{ fontSize: 12, color: props.auth.user.contact_id + "" === data.c_to + "" ? 'red' : '' }}>{data.cc === "true" ? 'fyi: ' : data.forwarded === "true" ? 'fwd: ' : t('to', props.auth.language) + ': '} {props.contact_details(data.c_to)}</span>
																									</h6>
																								</div>
																							</a>
																							<div id={`collapse${index}`} className={`collapse ${props.c_state.trail_to_show ? props.c_state.trail_to_show === data.trail_id ? 'show' : '' : ''}`} data-parent="#accordion">
																								<div className="card-body">
																									<div className="row col-md-12">
																										<div className="col-md-3"><strong>{t('Date Created', props.auth.language)}: </strong>{data.schedule_date}</div>
																										<div className="col-md-3"><strong>{t('Seen?', props.auth.language)} </strong>{data.seen}</div>
																										<div className="col-md-3"><strong>{t('Date Seen', props.auth.language)}: </strong>{data.date_seen}</div>
																										<div className="col-md-3"><strong>{t('Status', props.auth.language)}: </strong>{data.active === '1' ? t('Active', props.auth.language) : t('Nullified', props.auth.language)}</div>
																									</div>
																									<br /><br />
																									<h5 style={{ textDecoration: 'underline' }}>{t('Minutes/Comments', props.auth.language)}</h5>
																									{data.forwarded === 'true' ? <><br />---------- {t('Forwarded Message', props.auth.language)} ---------<br /></> : data.fyi === '1' ? <><br />---------- FYI ---------<br /></> : ''}
																									<div style={{ textDecoration: data.active === '0' ? 'line-through' : '' }} dangerouslySetInnerHTML={{ __html: data.message }} />
																									{
																										data.trail_attachments.length ?
																											<>
																												<br />
																												<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																												<div className="card-footer bg-white">
																													<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																														{
																															data.trail_attachments.map((ele, ind) => {
																																return (
																																	<li key={ind}>
																																		{/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

																																		<div className="mailbox-attachment-info">
																																			<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(ele, 'attachment')}><i className="fas fa-paperclip"></i> {ele.file_name}{ele.ext}</Link>
																																			<span className="mailbox-attachment-size clearfix mt-1">
																																				<Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', ele.aid, ele.ext, ele.file_name)}><i className="fas fa-download"></i></Link>
																																				<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", ele.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																																			</span>
																																		</div>
																																	</li>
																																)
																															})
																														}
																													</ul>
																												</div>
																											</>
																											: null
																									}
																									{
																										data.trail_actions.length ?
																											<>
																												<br />
																												<h5 style={{ textDecoration: 'underline' }}>{t('Actions Performed by', props.auth.language)} {props.contact_details(data.c_to)}</h5>
																												<table className="table table-striped">
																													<thead>
																														<tr>
																															<th style={{ width: 10 }}>#</th>
																															<th>{t('Action', props.auth.language)}</th>
																															<th>{t('Description', props.auth.language)}</th>
																															<th>{t('Status', props.auth.language)}</th>
																															<th>{t('Date Started', props.auth.language)}</th>
																															<th>{t('Date Completed', props.auth.language)}</th>
																															<th>{t('Attachments', props.auth.language)}</th>
																															<th></th>
																														</tr>
																													</thead>
																													<tbody>
																														{
																															data.trail_actions.map((el, i) => {
																																return (
																																	<tr key={i} style={{ textDecoration: el.active === '0' ? 'line-through' : '' }}>
																																		<td>{i + 1}</td>
																																		<td>{el.title}</td>
																																		<td><div dangerouslySetInnerHTML={{ __html: el.description }} /></td>
																																		<td>{t(el.status, props.auth.language)}</td>
																																		<td>{el.date_started}</td>
																																		<td>{el.date_completed}</td>
																																		<td>
																																			{
																																				el.attachments.length ?
																																					el.attachments.map((attachment, o) => {
																																						// console.log(attachment);
																																						return (<button key={o} className="btn btn-xs btn-outline-primary" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(attachment, 'attachment')}><i className="fas fa-paperclip"></i> {`${attachment.file_name.substring(0, 10) + '...'}${attachment.ext}`}</button>)
																																					})
																																					: null
																																			}
																																		</td>
																																		<td>
																																			{
																																				props.auth.user ? props.auth.user.contact_id === el.by ?
																																					(data.archived !== "true" && props.single_correspondence.single_correspondence.closed === "0") ?
																																						el.active === '1' ?
																																							<button type="button" className="btn btn-outline-danger btn-xs" onClick={() => props.nullify_action("0", el.action_id)}><i className="fas fa-trash"></i> {t('Nullify', props.auth.language)}</button>
																																							:
																																							<button type="button" className="btn btn-outline-info btn-xs" onClick={() => props.nullify_action("1", el.action_id)}><i className="fas fa-pencil-alt"></i> {t('Make Active', props.auth.language)}</button>
																																						: null
																																					: null
																																					: null
																																			}
																																		</td>
																																	</tr>
																																)
																															})
																														}
																													</tbody>
																												</table>
																											</>
																											: null
																									}
																									{
																										data.trail_emails.length ?
																											<>
																												<br />
																												<h5 style={{ textDecoration: 'underline' }}>{t('Emails', props.auth.language)}</h5>
																												<div className="row">
																													{
																														data.trail_emails.map((email_data, email_index) => {
																															return (
																																<div style={{ width: '100%' }} className="card card-default card-outline" key={email_index}>
																																	<Link href="#" className="d-block w-100">
																																		<div className="card-header">
																																			<h6 className="card-title w-100">
																																				<span className="mailbox-read-time float-right">{email_data.mail_date}</span>
																																				{t('From', props.auth.language)}: {email_data.mail_from}
																																				<br /><span style={{ fontSize: 12, color: props.auth.user.email === email_data.mail_to ? 'red' : '' }}>{t('to', props.auth.language)}: {email_data.mail_to}</span>
																																				{email_data.cc ? <><br />cc: {email_data.mail_cc}</> : null}
																																			</h6>
																																		</div>
																																	</Link>
																																	<div className="card-body">
																																		<div className="mailbox-read-info">
																																			<h5><strong>{t('Subject', props.auth.language)}:</strong> {email_data.subject}</h5>
																																		</div>

																																		<div className="mailbox-read-message">
																																			<div dangerouslySetInnerHTML={{ __html: email_data.message }} />
																																			{
																																				email_data.email_attachments.length ?
																																					<>
																																						<br />
																																						<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																																						<div className="card-footer bg-white">
																																							<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																																								{
																																									email_data.email_attachments.map((ele, ind) => {
																																										return (
																																											<li key={ind}>
																																												{/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

																																												<div className="mailbox-attachment-info">
																																													<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(ele, 'attachment')}><i className="fas fa-paperclip"></i> {ele.file_name}{ele.ext}</Link>
																																													<span className="mailbox-attachment-size clearfix mt-1">
																																														<Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', ele.aid, ele.ext, ele.file_name)}><i className="fas fa-download"></i></Link>
																																														<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", ele.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																																													</span>
																																												</div>
																																											</li>
																																										)
																																									})
																																								}
																																							</ul>
																																						</div>
																																					</>
																																					: null
																																			}
																																		</div>
																																	</div>
																																</div>
																															)
																														})
																													}
																												</div>
																											</>
																											: null
																									}

																									{
																										props.get_signature(data.c_from) !== null && props.get_signature(data.c_from) !== "null" && props.get_signature(data.c_from) !== '' ?
																											<><br /><img src={`${types.SERVER_URL}static/contacts/${props.get_signature(data.c_from)}`} alt="signed" width={150} /></>
																											: null
																									}

																									{
																										data.questionnaire ?
																											props.questionnaire_info(data.questionnaire).length ?
																												<>
																													<br />
																													<h5 style={{ fontSize: 16, textDecoration: 'underline' }}><Link to="#" data-toggle="modal" data-target={`#view_questionnaire_modal${data.questionnaire}`}> {t('View & Answer Questionnaire', props.auth.language)}</Link></h5>

																													<div className="modal fade" id={`view_questionnaire_modal${data.questionnaire}`} style={{ overflow: "auto" }}>
																														<div className="modal-dialog modal-lg">
																															<div className="modal-content">
																																<div className="modal-header">
																																	<h4 className="modal-title">{t('Please answer the questions below:', props.auth.language)}</h4>
																																	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
																																		<span aria-hidden="true">&times;</span>
																																	</button>
																																</div>
																																<div className="modal-body">
																																	<div className="row">
																																		{
																																			<table className="table table-striped">
																																				<thead>
																																					<tr>
																																						<th style={{ width: 10 }}>#</th>
																																						<th>Question</th>
																																						<th>Answer</th>
																																					</tr>
																																				</thead>
																																				<tbody>
																																					{
																																						props.questionnaire_info(data.questionnaire)[0].questions.map((question, idex) => {
																																							return (
																																								<tr>
																																									<td>{idex + 1}</td>
																																									<td>{question.question}</td>
																																									<td>{question.answer}</td>																																									</tr>
																																							)
																																						})
																																					}
																																				</tbody>
																																			</table>
																																		}
																																	</div>
																																</div>
																																<div className="modal-footer justify-content-right">
																																	<button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
																																</div>
																															</div>
																														</div>
																													</div>
																												</>
																												: null
																											: null
																									}
																								</div>
																								{props.single_correspondence.single_correspondence ?
																									data.c_to ?
																										data.c_to === props.auth.user.contact_id ?
																											data.fyi ?
																												data.fyi === '0' && data.archived !== "true" ?
																													<div className="card-footer">
																														{
																															props.single_correspondence.single_correspondence.closed === "0" && data.active === '1' ?
																																<>
																																	{
																																		!props.single_correspondence.single_correspondence.route_id ?
																																			<><button type="button" className="btn btn-default" data-toggle="modal" data-target="#reply_trail_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-reply"></i> {t('Reply', props.auth.language)}</button>&nbsp;&nbsp;</>
																																			: null
																																	}
																																	{
																																		data.route_step === null ?
																																			<button type="button" className="btn btn-default" data-toggle="modal" data-target="#forward_trail_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-share"></i> {t('Forward', props.auth.language)}</button>
																																			: props.check_current_step_user(data.route_step) === 1 ?
																																				<>
																																					<><button type="button" className="btn btn-default" data-toggle="modal" data-target="#reply_trail_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-reply"></i> {t('Reply', props.auth.language)}</button>&nbsp;&nbsp;</>
																																					<button type="button" className="btn btn-default" data-toggle="modal" data-target="#forward_trail_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-share"></i> {t('Forward', props.auth.language)}</button>
																																				</>
																																				: null
																																	}
																																	<div className="float-right">
																																		<button type="button" className="btn btn-outline-danger btn-sm" onClick={() => window.confirm(`${t('You will not be able to work on an archived trail. Are you sure you want to archive this trail?', props.auth.language)}`) ? props.archive_trail(data) : ''}><i className="fas fa-times"></i> {t('Mark As Worked On', props.auth.language)}</button>&nbsp;&nbsp;
																																		<button type="button" className="btn btn-outline-info btn-sm" data-toggle="modal" data-target="#add_action_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-plus"></i> {t('Add An Action', props.auth.language)}</button>&nbsp;&nbsp;
																																		<button type="button" className="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#send_email_modal" onClick={() => props.set_trail_info(data)}><i className="fas fa-envelope"></i> {t('Send Email', props.auth.language)}</button>
																																	</div>
																																</>
																																: null
																														}
																													</div>
																													: data.archived === "true" ?
																														<h5 className="text-danger" align="center"><strong>{t('This trail has been archived', props.auth.language)}</strong></h5>
																														: null
																												: null
																											: null
																										: null
																									: null
																								}
																								{
																									data.c_from ?
																										data.c_from === props.auth.user.contact_id && props.single_correspondence.single_correspondence.closed === "0" ?
																											<div className="card-footer">
																												<div className="float-right">
																													{data.active === '1' ?
																														<button type="button" className="btn btn-default" onClick={() => props.nullify_trail("0", data.trail_id)}><i className="far fa-trash-alt"></i> {t('Nullify This Trail', props.auth.language)}</button>
																														// {/* <button type="button" className="btn btn-default"><i className="fas fa-print"></i> Print</button> */}
																														:
																														<button type="button" className="btn btn-default" onClick={() => props.nullify_trail("1", data.trail_id)}><i className="fa fa-check"></i> {t('Activate This Trail', props.auth.language)}</button>
																													}
																												</div>
																											</div>
																											: null
																										: null
																								}
																							</div>
																						</div>
																					)
																				})
																			}
																		</div>
																	</div>
																</>
																: null
													}

													{/* Emails */}

													{
														props.correspondence.isLoading === "true" ?
															<div align="center">
																<span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Loading. Please wait...', props.auth.language)}</p></span>
															</div>
															:
															props.c_state.correspondence_details.emails ?
																props.c_state.correspondence_details.emails.length ?
																	<>
																		<br />
																		<h5 style={{ textDecoration: 'underline' }}>{t('All Emails', props.auth.language)}</h5>
																		<div className="row">
																			{
																				props.c_state.correspondence_details.emails.map((email_data, _index) => {
																					return (
																						<div style={{ width: '100%' }} className="card card-default card-outline" key={_index}>
																							<a className="d-block w-100" data-toggle="collapse" href={`#allemailscollapse${_index}`}>
																								<div className="card-header">
																									<h6 className="card-title w-100">
																										<span className="mailbox-read-time float-right">{email_data.mail_date}</span>
																										{t('From', props.auth.language)}: {email_data.mail_from}
																										<br /><span style={{ fontSize: 12, color: props.auth.user.email === email_data.mail_to ? 'red' : '' }}>{t('to', props.auth.language)}: {email_data.mail_to}</span>
																										{email_data.cc ? <><br />cc: {email_data.mail_cc}</> : null}
																									</h6>
																								</div>
																							</a>
																							<div id={`allemailscollapse${_index}`} className="collapse" data-parent="#accordion">
																								<div className="card-body">
																									<div className="mailbox-read-info">
																										<h5><strong>{t('Subject', props.auth.language)}:</strong> {email_data.subject}</h5>
																									</div>

																									<div className="mailbox-read-message">
																										<div dangerouslySetInnerHTML={{ __html: email_data.message }} />
																										{
																											email_data.email_attachments.length ?
																												<>
																													<br />
																													<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																													<div className="card-footer bg-white">
																														<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																															{
																																email_data.email_attachments.map((ele, ind) => {
																																	return (
																																		<li key={ind}>
																																			<span className="mailbox-attachment-icon"><i className="far fa-file"></i></span>

																																			<div className="mailbox-attachment-info">
																																				<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(ele, 'attachment')}><i className="fas fa-paperclip"></i> {ele.file_name}{ele.ext}</Link>
																																				<span className="mailbox-attachment-size clearfix mt-1">
																																					<Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', ele.aid, ele.ext, ele.file_name)}><i className="fas fa-download"></i></Link>
																																					<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", ele.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																																				</span>
																																			</div>
																																		</li>
																																	)
																																})
																															}
																														</ul>
																													</div>
																												</>
																												: null
																										}
																									</div>
																								</div>
																							</div>
																						</div>
																					)
																				})
																			}
																		</div>
																	</>
																	: null
																: null
													}
												</div>
											</div>
									}

									{/* </>
                                            : <div align="center"><br /><br /><p>Sorry! Your correspondence failed to load due to poor internet connection.<br />Click the reload icon to reload.</p></div> */}
								</div>
							</div>
						</div>

						{/* Modals */}

						<div className="modal fade" id="add_action_modal" style={{ overflow: "auto" }}>
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('Add Action', props.auth.language)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="form-group col-12">
											<label htmlFor="title">{t('Title', props.auth.language)} *</label>
											<input type="text" value={props.c_state.action_details.title ? props.c_state.action_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value }, 'action')} className="form-control" name="title" placeholder="Title" />
										</div>
										<div className="form-group col-12">
											<label htmlFor="date_started">{t('Date Started', props.auth.language)} *</label>
											<TextField
												id="date_started"
												type="date"
												// defaultValue={data.r_date}
												value={props.c_state.action_details.date_started ? props.c_state.action_details.date_started : ''}
												className="form-control"
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(event) => props.handleChange({ "field": "date_started", "value": event.target.value }, 'action')}
											/>
										</div>
										<div className="form-group col-12">
											<label htmlFor="date_completed">{t('Date Completed', props.auth.language)} *</label>
											<TextField
												id="date_completed"
												type="date"
												// defaultValue={data.r_date}
												value={props.c_state.action_details.date_completed ? props.c_state.action_details.date_completed : ''}
												className="form-control"
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(event) => props.handleChange({ "field": "date_completed", "value": event.target.value }, 'action')}
											/>
										</div>
										<div className="form-group col-12">
											<label htmlFor="status">{t('Status', props.auth.language)} *</label>
											<select className="form-control" name="status" onChange={(event) => props.handleChange({ "field": "status", "value": event.target.value }, 'action')} value={props.c_state.action_details.status ? props.c_state.action_details.status : ''}>
												<option value="" disabled>--{t('Select', props.auth.language)}--</option>
												<option value="Pending">{t('Pending', props.auth.language)}</option>
												<option value="Completed">{t('Completed', props.auth.language)}</option>
											</select>
										</div>
										<div className="form-group">
											<CKEditor
												editor={ClassicEditor}
												config={{ placeholder: t('Description...', props.auth.language) }}
												data={props.c_state.action_details.description ? props.c_state.action_details.description : ""}
												onReady={editor => {
													if (editor) {
														// You can store the "editor" and use when it is needed.
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
												onChange={(event, editor) => {
													if (editor) {
														const data = editor.getData();
														// console.log({ event, editor, data });
														props.handleChange({ "field": "description", "value": data }, 'action')
													}
												}}
												onBlur={(event, editor) => {
													if (editor) {
														// console.log('Blur.', editor);
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
												onFocus={(event, editor) => {
													if (editor) {
														// console.log('Focus.', editor);
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
											/>
										</div>
										<div className="row col-12">
											<div className="form-group">
												<div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal" onClick={() => props.set_a_type("actions")}>
													<i className="fas fa-paperclip"></i> {t('Add Attachments', props.auth.language)}
													{/* <input type="file" name="attachments[]" multiple onChange={(event) => props.handleChange({ "field": "attachments", "value": event.target.files })} /> */}
												</div>
											</div>
											{props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
												<div className="form-group">
													<div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
														<i className="fas fa-times"></i> {t('Clear Attachments', props.auth.language)}
														{/* <input type="button" onClick={props.clear_attachments} /> */}
													</div>
												</div>
												: null}
										</div>
										<div className="row col-12">
											{
												props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
													props.c_state.a_type === "actions" ?
														<div className="table table-striped files" id="previews">
															{/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
															{
																props.c_state.my_computer_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.file_name}, </span>
																	)
																})
															}
															{
																props.c_state.my_documents_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
																	)
																})
															}
															{
																props.c_state.files_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
																	)
																})
															}
														</div>
														: null
													: null
											}
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_action_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
										<span>{props.correspondence.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.correspondence.isLoading === "true" ? true : false} onClick={props.add_action}>{t('Save changes', props.auth.language)}</button></span>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="send_email_modal" style={{ overflow: "auto" }}>
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('Send Email', props.auth.language)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="row">
											<div className="col-6">
												<div className="row">
													&nbsp;&nbsp;<label htmlFor="recipients">{t('To', props.auth.language)} * &nbsp;&nbsp;<button className="btn btn-xs btn-success" data-toggle="modal" data-target="#recipients_tag_modal"> Use Tags</button> </label>
													<div className="form-group col-md-12">
														<Select
															options={props.c_state.contacts_dropdown}
															name="recipients"
															value={props.c_state.email_details.recipients ? typeof props.c_state.email_details.recipients !== "string" ? props.c_state.email_details.recipients : JSON.parse(props.c_state.email_details.recipients.replace(/'/g, '"')) : ''}
															id="custom_select"
															onChange={(value) => props.handleChange({ "field": "recipients", "value": value }, 'email_details')}
															isMulti
															style={{ width: '100%' }}
															className="basic-multi-select"
															classNamePrefix="select"
															placeholder="To"
														/>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="row">
													&nbsp;&nbsp;<label htmlFor="recipients">CC &nbsp;&nbsp;<button className="btn btn-xs btn-success" data-toggle="modal" data-target="#email_cc_tag_modal"> Use Tags</button> </label>
													<div className="form-group col-md-12">
														<Select
															options={props.c_state.contacts_dropdown}
															name="cc"
															value={props.c_state.email_details.cc ? typeof props.c_state.email_details.cc !== "string" ? props.c_state.email_details.cc : JSON.parse(props.c_state.email_details.cc.replace(/'/g, '"')) : ''}
															id="custom_select"
															onChange={(value) => props.handleChange({ "field": "cc", "value": value }, 'email_details')}
															isMulti
															style={{ width: '100%' }}
															className="basic-multi-select"
															classNamePrefix="select"
															placeholder="CC"
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="form-group">
											<input className="form-control" placeholder={t('Subject:', props.auth.language)} onChange={(event) => props.handleChange({ "field": "subject", "value": event.target.value }, 'email_details')} value={props.c_state.email_details.subject ? props.c_state.email_details.subject : ''} />
										</div>
										<div className="form-group">
											{/* <textarea id="compose-textarea" className="form-control" style={{ height: 300 }} onChange={(event) => props.handleChange({ "field": "message", "value": event.target.value })} value={props.c_state.email_details.message ? props.c_state.email_details.message : ''} placeholder="Type your message here..."> </textarea> */}
											<CKEditor
												editor={ClassicEditor}
												config={{ placeholder: t('Type your message here...', props.auth.language) }}
												data={props.c_state.email_details.message ? props.c_state.email_details.message : ""}
												onReady={editor => {
													if (editor) {
														// You can store the "editor" and use when it is needed.
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
												onChange={(event, editor) => {
													if (editor) {
														const data = editor.getData();
														// console.log({ event, editor, data });
														props.handleChange({ "field": "message", "value": data }, 'email_details')
													}
												}}
												onBlur={(event, editor) => {
													if (editor) {
														// console.log('Blur.', editor);
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
												onFocus={(event, editor) => {
													if (editor) {
														// console.log('Focus.', editor);
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
											/>
										</div>
										<div className="row col-12">
											<div className="form-group">
												<div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal" onClick={() => props.set_a_type("actions")}>
													<i className="fas fa-paperclip"></i> {t('Add Attachments', props.auth.language)}
													{/* <input type="file" name="attachments[]" multiple onChange={(event) => props.handleChange({ "field": "attachments", "value": event.target.files })} /> */}
												</div>
											</div>
											{props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
												<div className="form-group">
													<div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
														<i className="fas fa-times"></i> {t('Clear Attachments', props.auth.language)}
														{/* <input type="button" onClick={props.clear_attachments} /> */}
													</div>
												</div>
												: null}
										</div>
										<div className="row col-12">
											{
												props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
													props.c_state.a_type === "actions" ?
														<div className="table table-striped files" id="previews">
															{/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
															{
																props.c_state.my_computer_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.file_name}, </span>
																	)
																})
															}
															{
																props.c_state.my_documents_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
																	)
																})
															}
															{
																props.c_state.files_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
																	)
																})
															}
														</div>
														: null
													: null
											}
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_send_email_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
										<span>{props.correspondence.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.correspondence.isLoading === "true" ? true : false} onClick={props.send_email}>{t('Send', props.auth.language)}</button></span>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="forward_trail_modal" style={{ overflow: "auto" }}>
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('Forward Correspondence', props.auth.language)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="form-group">
											<label htmlFor="to">{t('To', props.auth.language)} * &nbsp;&nbsp;
												{props.c_state.trail_info.forward_to === null && props.single_correspondence.single_correspondence.route_id === null ?
													<button className="btn btn-xs btn-danger" data-toggle="modal" data-target="#to_tag_modal"> {t('Use Tags', props.auth.language)}</button>
													: null}
											</label>
											{props.single_correspondence.single_correspondence ?
												// check for routing
												props.single_correspondence.single_correspondence.route_id !== "" ?
													<div className="form-group">
														<label>{props.next_route_step("user")}</label>
													</div>
													:
													props.single_correspondence.single_correspondence.action_type === "Forward Back To Me" ?
														<div className="form-group">
															{/* <label htmlFor="to">To * </label><br /> */}
															<label>{props.contact_details(props.c_state.trail_info.c_from)}</label>
														</div> :
														props.single_correspondence.single_correspondence.action_type === "Forward To Anyone In This List" ?
															<Select
																options={props.single_correspondence.single_correspondence.forward_list ? props.forward_to_contacts(JSON.parse(props.single_correspondence.single_correspondence.forward_list.replace(/'/g, '"'))) : []}
																name="to"
																value={props.c_state.trail_details.to ? typeof props.c_state.trail_details.to !== "string" ? props.c_state.trail_details.to : JSON.parse(props.c_state.trail_details.to.replace(/'/g, '"')) : ''}
																id="custom_select"
																onChange={(value) => props.handleChange({ "field": "to", "value": value }, "trail_details")}
																isMulti
																style={{ width: '100%' }}
																className="basic-multi-select"
																classNamePrefix="select"
																placeholder={t('To', props.auth.language)}
															/>
															:
															props.c_state.trail_info.forward_to ?
																props.c_state.trail_info.forward_to !== 'None' ?
																	<Select
																		options={props.c_state.trail_info.forward_to === null ? props.c_state.contacts_dropdown : props.c_state.trail_info.forward_to ? props.forward_to_contacts(JSON.parse(props.c_state.trail_info.forward_to.replace(/'/g, '"'))) : []}
																		name="to"
																		value={props.c_state.trail_details.to ? typeof props.c_state.trail_details.to !== "string" ? props.c_state.trail_details.to : JSON.parse(props.c_state.trail_details.to.replace(/'/g, '"')) : ''}
																		id="custom_select"
																		onChange={(value) => props.handleChange({ "field": "to", "value": value }, "trail_details")}
																		isMulti
																		style={{ width: '100%' }}
																		className="basic-multi-select"
																		classNamePrefix="select"
																		placeholder={t('To', props.auth.language)}
																	/>
																	: null
																: <Select
																	options={props.c_state.contacts_dropdown ? props.c_state.contacts_dropdown : []}
																	name="to"
																	value={props.c_state.trail_details.to ? typeof props.c_state.trail_details.to !== "string" ? props.c_state.trail_details.to : JSON.parse(props.c_state.trail_details.to.replace(/'/g, '"')) : ''}
																	id="custom_select"
																	onChange={(value) => props.handleChange({ "field": "to", "value": value }, "trail_details")}
																	isMulti
																	style={{ width: '100%' }}
																	className="basic-multi-select"
																	classNamePrefix="select"
																	placeholder={t('To', props.auth.language)}
																/>
												: null
											}
										</div>
										<div className="form-group">
											{
												props.single_correspondence.single_correspondence ?
													// check for routing
													props.single_correspondence.single_correspondence.route_id !== "" ?
														<>
															{props.next_route_step("cc") ? <label htmlFor="cc">CC * &nbsp;&nbsp;<button className="btn btn-xs btn-warning" data-toggle="modal" data-target="#cc_tag_modal"> {t('Use Tags', props.auth.language)}</button></label> : null}
															<div className="form-group">
																<label>{props.next_route_step("cc")}</label>
															</div>
														</>
														:
														props.single_correspondence.single_correspondence.action_type === "Forward To Anyone In This List" ?
															<>
																<label htmlFor="cc">CC * &nbsp;&nbsp;<button className="btn btn-xs btn-warning" data-toggle="modal" data-target="#cc_tag_modal"> Use Tags</button></label>
																<Select
																	options={props.single_correspondence.single_correspondence.forward_list ? props.forward_to_contacts(JSON.parse(props.single_correspondence.single_correspondence.forward_list.replace(/'/g, '"'))) : []}
																	name="cc"
																	value={props.c_state.trail_details.cc ? typeof props.c_state.trail_details.cc !== "string" ? props.c_state.trail_details.cc : JSON.parse(props.c_state.trail_details.cc.replace(/'/g, '"')) : ''}
																	id="custom_select"
																	onChange={(value) => props.handleChange({ "field": "cc", "value": value }, "trail_details")}
																	isMulti
																	style={{ width: '100%' }}
																	className="basic-multi-select"
																	classNamePrefix="select"
																	placeholder="CC"
																/>
															</>
															:
															<>
																<label htmlFor="cc">CC * &nbsp;&nbsp;<button className="btn btn-xs btn-warning" data-toggle="modal" data-target="#cc_tag_modal"> Use Tags</button></label>
																<Select
																	options={props.c_state.contacts_dropdown}
																	name="cc"
																	value={props.c_state.trail_details.cc ? typeof props.c_state.trail_details.cc !== "string" ? props.c_state.trail_details.cc : JSON.parse(props.c_state.trail_details.cc.replace(/'/g, '"')) : ''}
																	id="custom_select"
																	onChange={(value) => props.handleChange({ "field": "cc", "value": value }, "trail_details")}
																	isMulti
																	style={{ width: '100%' }}
																	className="basic-multi-select"
																	classNamePrefix="select"
																	placeholder="CC"
																/>
															</>
													: null
											}
										</div>
										{/* <div className="form-group">
										<label htmlFor="schedule_date">Schedule Date/Time: </label>
										<div className="row col-12">
											<input className="form-control col-6" type="date" name="schedule_date" onChange={(event) => props.handleChange({ "field": "schedule_date", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.schedule_date ? props.c_state.trail_details.schedule_date : ''} />
											<input className="form-control col-6" type="time" name="schedule_time" onChange={(event) => props.handleChange({ "field": "schedule_time", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.schedule_time ? props.c_state.trail_details.schedule_time : ''} />
										</div>
									</div> */}
										{
											props.single_correspondence.single_correspondence.route_id === "" ?
												props.single_correspondence.single_correspondence.action_type === "Forward To Anyone" ?
													<>
														<div className="form-group">
															<label htmlFor="action_type">{t('Action Type', props.auth.language)}: </label>
															<select className="form-control" onChange={(event) => props.handleChange({ "field": "action_type", "value": event.target.value }, 'trail_details')} value={props.c_state.trail_details.action_type ? props.c_state.trail_details.action_type : "Forward To Anyone"}>
																<option value=""></option>
																<option value="Forward To Anyone">{t('Forward To Anyone', props.auth.language)}</option>
																<option value="Forward Back To Me">{t('Forward Back To Me', props.auth.language)}</option>
																<option value="Forward To Anyone In This List">{t('Forward To Anyone In This List', props.auth.language)}</option>
															</select>
														</div>
														{
															props.c_state.trail_details.action_type ?
																props.c_state.trail_details.action_type === "Forward To Anyone In This List" ?
																	<div className="row">
																		<div className="form-group col-md-12">
																			<label htmlFor="forward_list">{t('User(s)', props.auth.language)}: </label>
																			<Select
																				options={props.c_state.contacts_dropdown}
																				name="forward_list"
																				value={props.c_state.trail_details.forward_list ? typeof props.c_state.trail_details.forward_list !== "string" ? props.c_state.trail_details.forward_list : JSON.parse(props.c_state.trail_details.forward_list.replace(/'/g, '"')) : ''}
																				id="custom_select"
																				onChange={(value) => props.handleChange({ "field": "forward_list", "value": value }, 'trail_details')}
																				isMulti
																				style={{ width: '100%' }}
																				className="basic-multi-select"
																				classNamePrefix="select"
																				placeholder={t('User(s)', props.auth.language)}
																			/>
																		</div>
																	</div>
																	: null
																: null
														}
													</>
													: null
												: null
										}
										{
											props.single_correspondence.single_correspondence.route_id === "" ?
												<div className="form-group">
													<label htmlFor="expected_response_date">{t('Expected Response Date/Time', props.auth.language)}: </label>
													<div className="row col-12">
														<input className="form-control col-6" type="date" name="expected_response_date" onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.expected_response_date ? props.c_state.trail_details.expected_response_date : ''} />
														<input className="form-control col-6" type="time" name="expected_response_time" onChange={(event) => props.handleChange({ "field": "expected_response_time", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.expected_response_time ? props.c_state.trail_details.expected_response_time : ''} />
													</div>
												</div>
												: null
										}
										<div className="form-group col-md-6">
											<label htmlFor="signature">{t('Add Signature', props.auth.language)}? </label><br />
											<label>{t('Yes', props.auth.language)} <input type="radio" onChange={() => props.add_signature("Yes")} name="signature1" checked={props.c_state.trail_details.signature === "true" ? true : false} /></label>&nbsp;&nbsp;
											<label>{t('No', props.auth.language)} <input type="radio" onChange={() => props.add_signature("No")} name="signature1" checked={props.c_state.trail_details.signature === "false" ? true : false} /></label>
										</div>
										<div className="form-group">
											{/* <textarea id="compose-textarea" className="form-control" style={{ height: 300 }} onChange={(event) => props.handleChange({ "field": "message", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.message ? props.c_state.trail_details.message : ''} placeholder="Type your message here..."> </textarea> */}
											<CKEditor
												editor={ClassicEditor}
												config={{ placeholder: t('Type your minutes/comments here...', props.auth.language) }}
												// data="<p>Type your message here...</p>"
												onReady={editor => {
													if (editor) {
														// You can store the "editor" and use when it is needed.
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
												onChange={(event, editor) => {
													if (editor) {
														const data = editor.getData();
														// console.log({ event, editor, data });
														props.handleChange({ "field": "message", "value": data }, 'trail_details')
													}
												}}
												onBlur={(event, editor) => {
													if (editor) {
														// console.log('Blur.', editor);
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
												onFocus={(event, editor) => {
													if (editor) {
														// console.log('Focus.', editor);
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
											/>
										</div>
										<div className="row col-12">
											<div className="form-group">
												<div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal" onClick={() => props.set_a_type("trails")}>
													<i className="fas fa-paperclip"></i> {t('Add Attachments', props.auth.language)}
													{/* <input type="file" name="attachments[]" multiple onChange={(event) => props.handleChange({ "field": "attachments", "value": event.target.files })} /> */}
												</div>
											</div>
											{props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
												<div className="form-group">
													<div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
														<i className="fas fa-times"></i> {t('Clear Attachments', props.auth.language)}
														{/* <input type="button" onClick={props.clear_attachments} /> */}
													</div>
												</div>
												: null}
										</div>
										<div className="row col-12">
											{
												props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
													props.c_state.a_type === "trails" ?
														<div className="table table-striped files" id="previews">
															{/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
															{
																props.c_state.my_computer_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.file_name}, </span>
																	)
																})
															}
															{
																props.c_state.my_documents_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
																	)
																})
															}
															{
																props.c_state.files_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
																	)
																})
															}
														</div>
														: null
													: null
											}
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_forward_trail_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
										<span>{props.correspondence.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.correspondence.isLoading === "true" ? true : false} onClick={() => props.forward_trail('forward')}>{t('Send', props.auth.language)}</button></span>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="reply_trail_modal" style={{ overflow: "auto" }}>
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('Reply To', props.auth.language)} {props.contact_details(props.c_state.trail_info.c_from)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="form-group">
											<label htmlFor="to">{t('To', props.auth.language)} * </label><br />
											<label>{props.contact_details(props.c_state.trail_info.c_from)}</label>
										</div>
										<div className="form-group">
											<label htmlFor="to">CC * &nbsp;&nbsp;<button className="btn btn-xs btn-warning" data-toggle="modal" data-target="#cc_tag_modal"> {t('Use Tags', props.auth.language)}</button></label>
											<Select
												options={props.c_state.contacts_dropdown}
												name="cc"
												value={props.c_state.trail_details.cc ? typeof props.c_state.trail_details.cc !== "string" ? props.c_state.trail_details.cc : JSON.parse(props.c_state.trail_details.cc.replace(/'/g, '"')) : ''}
												id="custom_select"
												onChange={(value) => props.handleChange({ "field": "cc", "value": value }, "trail_details")}
												isMulti
												style={{ width: '100%' }}
												className="basic-multi-select"
												classNamePrefix="select"
												placeholder="CC"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="schedule_date">{t('Schedule Date/Time', props.auth.language)}: </label>
											<div className="row col-12">
												<input className="form-control col-6" type="date" name="schedule_date" onChange={(event) => props.handleChange({ "field": "schedule_date", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.schedule_date ? props.c_state.trail_details.schedule_date : ''} />
												<input className="form-control col-6" type="time" name="schedule_time" onChange={(event) => props.handleChange({ "field": "schedule_time", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.schedule_time ? props.c_state.trail_details.schedule_time : ''} />
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="expected_response_date">{t('Expected Response Date/Time', props.auth.language)}: </label>
											<div className="row col-12">
												<input className="form-control col-6" type="date" name="expected_response_date" onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.expected_response_date ? props.c_state.trail_details.expected_response_date : ''} />
												<input className="form-control col-6" type="time" name="expected_response_time" onChange={(event) => props.handleChange({ "field": "expected_response_time", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.expected_response_time ? props.c_state.trail_details.expected_response_time : ''} />
											</div>
										</div>
										<div className="form-group col-md-6">
											<label htmlFor="signature">{t('Add Signature', props.auth.language)}? </label><br />
											<label>{t('Yes', props.auth.language)} <input type="radio" onChange={() => props.add_signature("Yes")} name="signature2" checked={props.c_state.trail_details.signature === "true" ? true : false} /></label>&nbsp;&nbsp;
											<label>{t('No', props.auth.language)} <input type="radio" onChange={() => props.add_signature("No")} name="signature2" checked={props.c_state.trail_details.signature === "false" ? true : false} /></label>
										</div>
										<div className="form-group">
											{/* <textarea id="compose-textarea" className="form-control" style={{ height: 300 }} onChange={(event) => props.handleChange({ "field": "message", "value": event.target.value }, "trail_details")} value={props.c_state.trail_details.message ? props.c_state.trail_details.message : ''} placeholder="Type your message here..."> </textarea> */}
											<CKEditor
												editor={ClassicEditor}
												config={{ placeholder: t('Type your minutes/comments here...', props.auth.language) }}
												// data="<p>Type your message here...</p>"
												onReady={editor => {
													if (editor) {
														// You can store the "editor" and use when it is needed.
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
												onChange={(event, editor) => {
													if (editor) {
														const data = editor.getData();
														// console.log({ event, editor, data });
														props.handleChange({ "field": "message", "value": data }, 'trail_details')
													}
												}}
												onBlur={(event, editor) => {
													if (editor) {
														// console.log('Blur.', editor);
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
												onFocus={(event, editor) => {
													if (editor) {
														// console.log('Focus.', editor);
														editor.ui.view.editable.element.style.minHeight = "300px";
													}
												}}
											/>
										</div>
										<div className="row col-12">
											<div className="form-group">
												<div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal" onClick={() => props.set_a_type("trails")}>
													<i className="fas fa-paperclip"></i> {t('Add Attachments', props.auth.language)}
													{/* <input type="file" name="attachments[]" multiple onChange={(event) => props.handleChange({ "field": "attachments", "value": event.target.files })} /> */}
												</div>
											</div>
											{props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
												<div className="form-group">
													<div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
														<i className="fas fa-times"></i> {t('Clear Attachments', props.auth.language)}
														{/* <input type="button" onClick={props.clear_attachments} /> */}
													</div>
												</div>
												: null}
										</div>
										<div className="row col-12">
											{
												props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
													props.c_state.a_type === "trails" ?
														<div className="table table-striped files" id="previews">
															{/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
															{
																props.c_state.my_computer_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.file_name}, </span>
																	)
																})
															}
															{
																props.c_state.my_documents_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
																	)
																})
															}
															{
																props.c_state.files_selected.map((data, index) => {
																	return (
																		<span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
																	)
																})
															}
														</div>
														: null
													: null
											}
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_reply_trail_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
										<span>{props.correspondence.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.correspondence.isLoading === "true" ? true : false} onClick={() => props.forward_trail('reply')}>{t('Send', props.auth.language)}</button></span>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="to_tag_modal">
							<div className="modal-dialog modal-md">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="form-group col-12">
											<Select
												options={props.c_state.tags_dropdown}
												name="to_tags"
												value={props.c_state.trail_details.to_tags ? typeof props.c_state.trail_details.to_tags !== "string" ? props.c_state.trail_details.to_tags : JSON.parse(props.c_state.trail_details.to_tags.replace(/'/g, '"')) : ''}
												id="custom_select"
												onChange={(value) => props.handleChange({ "field": "to_tags", "value": value }, "trail_details")}
												isMulti
												style={{ width: '100%' }}
												className="basic-multi-select"
												classNamePrefix="select"
												placeholder={t('Tags', props.auth.language)}
											/>
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_to_tag_modal">{t('Close', props.auth.language)}</button>
										<button type="button" className="btn btn-primary" onClick={props.filter_by_to_tags}>{t('Save', props.auth.language)}</button>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="recipients_tag_modal">
							<div className="modal-dialog modal-md">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="form-group col-12">
											<Select
												options={props.c_state.tags_dropdown}
												name="to_tags"
												value={props.c_state.email_details.to_tags ? typeof props.c_state.email_details.to_tags !== "string" ? props.c_state.email_details.to_tags : JSON.parse(props.c_state.email_details.to_tags.replace(/'/g, '"')) : ''}
												id="custom_select"
												onChange={(value) => props.handleChange({ "field": "to_tags", "value": value }, "email_details")}
												isMulti
												style={{ width: '100%' }}
												className="basic-multi-select"
												classNamePrefix="select"
												placeholder={t('Tags', props.auth.language)}
											/>
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_email_to_tag_modal">{t('Close', props.auth.language)}</button>
										<button type="button" className="btn btn-primary" onClick={props.filter_by_email_to_tags}>{t('Save', props.auth.language)}</button>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="email_cc_tag_modal">
							<div className="modal-dialog modal-md">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="form-group col-12">
											<Select
												options={props.c_state.tags_dropdown}
												name="to_tags"
												value={props.c_state.email_details.cc_tags ? typeof props.c_state.email_details.cc_tags !== "string" ? props.c_state.email_details.cc_tags : JSON.parse(props.c_state.email_details.cc_tags.replace(/'/g, '"')) : ''}
												id="custom_select"
												onChange={(value) => props.handleChange({ "field": "cc_tags", "value": value }, "email_details")}
												isMulti
												style={{ width: '100%' }}
												className="basic-multi-select"
												classNamePrefix="select"
												placeholder={t('Tags', props.auth.language)}
											/>
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_email_cc_tag_modal">{t('Close', props.auth.language)}</button>
										<button type="button" className="btn btn-primary" onClick={props.filter_by_email_cc_tags}>{t('Save', props.auth.language)}</button>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="cc_tag_modal">
							<div className="modal-dialog modal-md">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="form-group col-12">
											<Select
												options={props.c_state.tags_dropdown}
												name="cc_tags"
												value={props.c_state.trail_details.cc_tags ? typeof props.c_state.trail_details.cc_tags !== "string" ? props.c_state.trail_details.cc_tags : JSON.parse(props.c_state.trail_details.cc_tags.replace(/'/g, '"')) : ''}
												id="custom_select"
												onChange={(value) => props.handleChange({ "field": "cc_tags", "value": value }, "trail_details")}
												isMulti
												style={{ width: '100%' }}
												className="basic-multi-select"
												classNamePrefix="select"
												placeholder={t('Tags', props.auth.language)}
											/>
										</div>
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_cc_tag_modal">{t('Close', props.auth.language)}</button>
										<button type="button" className="btn btn-primary" onClick={props.filter_by_cc_tags}>{t('Save', props.auth.language)}</button>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="view_structure_modal">
							<div className="modal-dialog modal-xl">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">{t('View Structure', props.auth.language)}</h4>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body" style={{ overflowX: 'auto', width: '30e', whiteSpace: 'nowrap' }}>
										{
											props.c_state.correspondence_details.structure ?
												// <TreeView
												// 	style={useStyles.root}
												// 	defaultCollapseIcon={<ExpandMoreIcon />}
												// 	defaultExpanded={['root']}
												// 	defaultExpandIcon={<ChevronRightIcon />}
												// // icon={<i className="fa fa-user"></i>}
												// >
												// 	{renderTree(props.c_state.correspondence_details.structure)}
												// </TreeView>

												<div className="org-tree">
													<Card data={[props.c_state.correspondence_details.structure]} trailToShow={props.trailToShow} />
												</div>

												: null
										}
									</div>
									<div className="modal-footer justify-content-between">
										<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_view_structure_modal">{t('Close', props.auth.language)}</button>
									</div>
								</div>
							</div>
						</div>

						<div className="modal fade" id="attached_correspondence_modal">
							<div className="modal-dialog modal-xl">
								<div className="modal-content">
									{
										props.correspondence.attached_correspondence.correspondence ?
											<>
												<div className="modal-header">
													<h4 className="modal-title">{props.correspondence.attached_correspondence.correspondence[0].subject}</h4>
													<button type="button" className="close" data-dismiss="modal" aria-label="Close">
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
												<div className="modal-body">
													{
														props.correspondence.isLoading === "true" ?
															<div align="center">
																<span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Your correspondence is loading. Please wait...', props.auth.language)}</p></span>
															</div>
															:
															props.correspondence.attached_correspondence.correspondence[0].subject ?
																<>
																	<div className="mailbox-read-info">
																		<h6>{t('Initiated By', props.auth.language)}: {props.contact_details(props.correspondence.attached_correspondence.action_by)}
																			<span className="mailbox-read-time float-right">{props.correspondence.attached_correspondence.correspondence[0].date_created}</span>
																		</h6>
																	</div>
																	<div className="mailbox-controls with-border text-center">
																		<div className="btn-group">

																		</div>
																	</div>
																	<div className="mailbox-read-message">
																		<h5 className="text-success" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t('Key Info', props.auth.language)}</h5>
																		{
																			props.correspondence.attached_correspondence.correspondence[0].closed === "0" ?
																				<p className="text-primary"><strong>{t('This correspondence is still open', props.auth.language)}</strong></p> :
																				<h4 className="text-danger"><strong>{t('This correspondence has been closed', props.auth.language)}</strong><br /><br /></h4>
																		}
																		<div className="row col-md-12">
																			<div className="col-md-4">
																				<strong>{t('Confidentiality', props.auth.language)}:</strong> {
																					props.correspondence.attached_correspondence.correspondence[0].confidential === "true" ?
																						<span className="text-danger">{t('Confidential', props.auth.language)}</span> : <span>{t('Not Confidential', props.auth.language)}</span>
																				}
																			</div>
																			<div className="col-md-4"><strong>{t('Deadline', props.auth.language)}:</strong> {props.correspondence.attached_correspondence.correspondence[0].deadline}</div>
																			<div className="col-md-4"><strong>{t('Priority', props.auth.language)}: </strong>
																				<span className={`${props.correspondence.attached_correspondence.correspondence[0].priority === 'High Priority' ? 'text-danger' : props.correspondence.attached_correspondence.correspondence[0].priority === 'Medium Priority' ? 'text-warning' : ''}`}>
																					{t(props.correspondence.attached_correspondence.correspondence[0].priority, props.auth.language)}
																				</span>
																			</div>
																		</div>
																		<br />
																		<div className="row col-md-12">
																			<div className="col-md-4"><strong>{t('Source/Type', props.auth.language)}:</strong> {t(props.correspondence.attached_correspondence.correspondence[0].source, props.auth.language)}</div>
																		</div>
																		<br />
																		<h5 className="text-success" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t('Body', props.auth.language)}</h5>
																		<div dangerouslySetInnerHTML={{ __html: props.correspondence.attached_correspondence.correspondence[0].body }} />
																		{
																			props.correspondence.attached_correspondence.correspondence_attachments.length ?
																				<>
																					<br />
																					<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																					<div className="card-footer bg-white">
																						<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																							{
																								props.correspondence.attached_correspondence.correspondence_attachments.map((data, index) => {
																									return (
																										<li key={index}>
																											{/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

																											<div className="mailbox-attachment-info">
																												<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'attachment')}><i className="fas fa-paperclip"></i> {data.file_name}{data.ext}</Link>
																												<span className="mailbox-attachment-size clearfix mt-1">
																													{/* <span>1,245 KB</span> */}
																													<Link to="#" className="btn btn-default btn-sm float-right" title={t('Download', props.auth.language)} onClick={() => props.download('attachments', data.aid, data.ext, data.file_name)}><i className="fas fa-download"></i></Link>
																													<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", data.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																												</span>
																											</div>
																										</li>
																									)
																								})
																							}
																						</ul>
																					</div>
																				</>
																				: null
																		}
																		<br />
																		<h5 className="text-success" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{t('Active Trail', props.auth.language)}</h5>
																		<div className="card card-default card-outline">
																			<div className="card-header">
																				<h6 className="card-title w-100">
																					<span className="mailbox-read-time float-right">{props.correspondence.attached_correspondence.date_created}</span>
																					<span className="text-primary">{t('From', props.auth.language)}: {props.contact_details(props.correspondence.attached_correspondence.c_from)}</span>
																				</h6>
																			</div>
																			<div className="card-body">
																				<div className="row col-md-12">
																					<div className="col-md-3"><strong>{t('Expected Response Date', props.auth.language)}: </strong>{props.correspondence.attached_correspondence.expected_response_date} {props.correspondence.attached_correspondence.expected_response_time}</div>
																					<div className="col-md-3"><strong>{t('Seen?', props.auth.language)} </strong>{props.correspondence.attached_correspondence.seen}</div>
																					<div className="col-md-3"><strong>{t('Date Seen', props.auth.language)}: </strong>{props.correspondence.attached_correspondence.date_seen}</div>
																					<div className="col-md-3"><strong>{t('Status', props.auth.language)}: </strong>{props.correspondence.attached_correspondence.active === '1' ? 'Active' : 'Nullified'}</div>
																				</div>
																				<br /><br />
																				<h5 style={{ textDecoration: 'underline' }}>{t('Minutes/Comments', props.auth.language)}</h5>
																				{props.correspondence.attached_correspondence.forwarded === 'true' ? <><br />---------- {t('Forwarded message', props.auth.language)} ---------<br /><br /></> : props.correspondence.attached_correspondence.fyi === '1' ? <><br />---------- FYI ---------<br /><br /></> : ''}
																				<div style={{ textDecoration: props.correspondence.attached_correspondence.active === '0' ? 'line-through' : '' }} dangerouslySetInnerHTML={{ __html: props.correspondence.attached_correspondence.message }} />
																				{
																					props.correspondence.attached_correspondence.trail_attachments.length ?
																						<>
																							<br />
																							<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																							<div className="card-footer bg-white">
																								<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																									{
																										props.correspondence.attached_correspondence.trail_attachments.map((ele, ind) => {
																											return (
																												<li key={ind}>
																													{/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

																													<div className="mailbox-attachment-info">
																														<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(ele, 'attachment')}><i className="fas fa-paperclip"></i> {ele.file_name}{ele.ext}</Link>
																														<span className="mailbox-attachment-size clearfix mt-1">
																															<Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', ele.aid, ele.ext, ele.file_name)}><i className="fas fa-download"></i></Link>
																															<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", ele.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																														</span>
																													</div>
																												</li>
																											)
																										})
																									}
																								</ul>
																							</div>
																						</>
																						: null
																				}
																				{
																					props.correspondence.attached_correspondence.trail_actions.length ?
																						<>
																							<br />
																							<h5 style={{ textDecoration: 'underline' }}>{t('Actions Performed by', props.auth.language)} {props.contact_details(props.correspondence.attached_correspondence.c_to)}</h5>
																							<table className="table table-striped">
																								<thead>
																									<tr>
																										<th style={{ width: 10 }}>#</th>
																										<th>{t('Action', props.auth.language)}</th>
																										<th>{t('Description', props.auth.language)}</th>
																										<th>{t('Status', props.auth.language)}</th>
																										<th>{t('Date Started', props.auth.language)}</th>
																										<th>{t('Date Completed', props.auth.language)}</th>
																										<th>{t('Attachments', props.auth.language)}</th>
																									</tr>
																								</thead>
																								<tbody>
																									{
																										props.correspondence.attached_correspondence.trail_actions.map((el, i) => {
																											return (
																												<tr key={i} style={{ textDecoration: el.active === '0' ? 'line-through' : '' }}>
																													<td>{i + 1}</td>
																													<td>{el.title}</td>
																													<td><div dangerouslySetInnerHTML={{ __html: el.description }} /></td>
																													<td>{t(el.status, props.auth.language)}</td>
																													<td>{el.date_started}</td>
																													<td>{el.date_completed}</td>
																													<td>
																														{
																															el.attachments.length ?
																																el.attachments.map((attachment, o) => {
																																	// console.log(attachment);
																																	return (<button key={o} className="btn btn-xs btn-outline-primary" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(attachment, 'attachment')}><i className="fas fa-paperclip"></i> {`${attachment.file_name.substring(0, 10) + '...'}${attachment.ext}`}</button>)
																																})
																																: null
																														}
																													</td>
																												</tr>
																											)
																										})
																									}
																								</tbody>
																							</table>
																						</>
																						: null
																				}
																				{
																					props.get_signature(props.correspondence.attached_correspondence.c_from) !== null && props.get_signature(props.correspondence.attached_correspondence.c_from) !== "null" && props.get_signature(props.correspondence.attached_correspondence.c_from) !== '' ?
																						<><br /><img src={`${types.SERVER_URL}static/contacts/${props.get_signature(props.correspondence.attached_correspondence.c_from)}`} alt="signed" width={150} /></>
																						: null
																				}
																				{
																					props.correspondence.attached_correspondence.trail_emails.length ?
																						<>
																							<br />
																							<h5 style={{ textDecoration: 'underline' }}>{t('Emails', props.auth.language)}</h5>
																							<div className="row">
																								{
																									props.correspondence.attached_correspondence.trail_emails.map((email_data, email_index) => {
																										return (
																											<div style={{ width: '100%' }} className="card card-default card-outline" key={email_index}>
																												<a className="d-block w-100" data-toggle="collapse" href={`#emailcollapse${email_index}`}>
																													<div className="card-header">
																														<h6 className="card-title w-100">
																															<span className="mailbox-read-time float-right">{email_data.mail_date}</span>
																															{t('From', props.auth.language)}: {email_data.mail_from}
																															<br /><span style={{ fontSize: 12, color: props.auth.user.email === email_data.mail_to ? 'red' : '' }}>{t('to', props.auth.language)}: {email_data.mail_to}</span>
																															{email_data.cc ? <><br />cc: {email_data.mail_cc}</> : null}
																														</h6>
																													</div>
																												</a>
																												<div id={`emailcollapse${email_index}`} className="collapse" data-parent="#accordion">
																													<div className="card-body">
																														<div className="mailbox-read-info">
																															<h5><strong>{t('Subject', props.auth.language)}:</strong> {email_data.subject}</h5>
																														</div>

																														<div className="mailbox-read-message">
																															<div dangerouslySetInnerHTML={{ __html: email_data.message }} />
																															{
																																email_data.email_attachments.length ?
																																	<>
																																		<br />
																																		<h5 style={{ textDecoration: 'underline' }}>{t('Attachments', props.auth.language)}</h5>
																																		<div className="card-footer bg-white">
																																			<ul className="mailbox-attachments d-flex align-items-stretch clearfix">
																																				{
																																					email_data.email_attachments.map((ele, ind) => {
																																						return (
																																							<li key={ind}>
																																								{/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

																																								<div className="mailbox-attachment-info">
																																									<Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(ele, 'attachment')}><i className="fas fa-paperclip"></i> {ele.file_name}{ele.ext}</Link>
																																									<span className="mailbox-attachment-size clearfix mt-1">
																																										<Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', ele.aid, ele.ext, ele.file_name)}><i className="fas fa-download"></i></Link>
																																										<Link to="#" className="btn btn-default btn-sm float-right" title={t('Upload To File Manager', props.auth.language)} onClick={() => props.upload_to_fm("attachments", ele.aid)}><i className="fas fa-cloud-upload-alt text-danger"></i></Link>
																																									</span>
																																								</div>
																																							</li>
																																						)
																																					})
																																				}
																																			</ul>
																																		</div>
																																	</>
																																	: null
																															}
																														</div>
																													</div>
																												</div>
																											</div>
																										)
																									})
																								}
																							</div>
																						</>
																						: null
																				}
																			</div>
																		</div>

																	</div>
																</>
																: null
													}
												</div>
												<div className="modal-footer justify-content-between">
													<button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_attached_correspondence_modal">{t('Close', props.auth.language)}</button>
												</div>
											</>
											: null
									}
								</div>
							</div>
						</div>

						<AddAttachmentModal
							handleChange={props.handleChange}
							templates={props.templates}
							attachments={props.attachments}
							set_attachment_info={props.set_attachment_info}
							set_template_modal_content={props.set_template_modal_content}
							select_my_document={props.select_my_document}
							select_template={props.select_template}
							select_files={props.select_files}
							enter_folder={props.enter_folder}
							click_breadcrumbs={props.click_breadcrumbs}
							c_state={props.c_state}
							use_template={props.use_template}
							auth={props.auth}
							save_attachments={props.save_attachments}
							search_attachment={props.search_attachment}
						/>

						<ViewAttachmentModal
							c_state={props.c_state}
							clear_attachment_info={props.clear_attachment_info}
							file_type={props.file_type}
							download={props.download}
							auth={props.auth}
						/>

						<PrintCorrespondence
							c_state={props.c_state}
							single_correspondence={props.single_correspondence}
							get_contact_department={props.get_contact_department}
							auth={props.auth}
							contact_details={props.contact_details}
							get_signature={props.get_signature}
						/>
					</div>
				</section>
			</div>
		)
	} catch (error) {
		console.log("an error occured", error);
		// window.location.href = "/correspondence";
		return (
			<>
				<br /><br /><br />
				<div align="center">
					<h5>Sorry! An error may have occured. Click <Link to="/">here</Link> to reload app.</h5>
				</div>
				<br /><br /><br />
			</>
		)
	}
}

export default CorrespondenceDetails
