import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.CONVERSIONS_LOADING,
            payload: action
        })
    }
}

export const serverConvert = (formData, token) => {
    return (dispatch) => {

        dispatch({
            type: types.CONVERSIONS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'convert',
            data: formData,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.CONVERSIONS,
                    payload: response.data
                }); 
                dispatch({
                    type: types.CONVERSIONS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.CONVERSIONS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.CONVERSIONS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.CONVERSIONS_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            // console.log(err);
            dispatch({
                type: types.CONVERSIONS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.CONVERSIONS_LOADING,
                payload: "false"
            });
            return;
        })
    }
}
