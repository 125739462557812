/* eslint-disable array-callback-return */
import React from 'react'
import types from '../../types';
import { Link } from 'react-router-dom'
// import TextField from '@material-ui/core/TextField';
import Select from 'react-select'
import { getTrans as t } from '../../translations';

const NewTemplate = (props) => {

    // console.log("page", props.c_state.placeholder_table);

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('New Template', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('New Template', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="card" >
                        <div className="card-header">
                            {/* k<h3 className="card-title">Contact List</h3> */}
                            <div align="left">
                                {
                                    props.auth.user ?
                                        props.auth.user.privileges.includes("create_private_template") || props.auth.user.privileges.includes("create_public_template") ?
                                            <>
                                                <button className="btn btn-sm btn-info" data-toggle="modal" data-target="#create_template_modal" onClick={props.clear_modal_contents}><i className="fa fa-plus"></i> {t('Create Template', props.auth.language)}</button>&nbsp;
                                            </>
                                            : null
                                        : null
                                }
                                {
                                    props.auth.user ?
                                        props.auth.user.privileges.includes("upload_private_template") || props.auth.user.privileges.includes("upload_public_template") ?
                                            <>
                                                <button className="btn btn-sm btn-primary" data-toggle="modal" data-target="#upload_template_modal" onClick={props.clear_modal_contents}><i className="fa fa-upload"></i> {t('Upload Template', props.auth.language)}</button>&nbsp;
                                            </>
                                            : null
                                        : null
                                }
                                {props.docs_config.document ? <><a className="btn btn-sm btn-outline-danger" href={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.docs_config)}`)} target="_blank" rel="noreferrer"><i className="fa fa-file"></i> {t('Open Document In New Tab', props.auth.language)}</a>&nbsp;</> : null}
                            </div>
                        </div>
                        <div className="card card-outline">
                            {props.docs_config.document ?
                                <div className="row">
                                    <div className="col-9">

                                        <div className="embed-responsive embed-responsive-16by9" style={{ height: 1500 }}>
                                            <iframe
                                                id="the_canvas"
                                                className="embed-responsive-item"
                                                title="doc_viewer"
                                                src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.docs_config)}`)}
                                                allowFullScreen>
                                            </iframe>
                                        </div>

                                    </div>
                                    <div className="col-3">
                                        <div align="center">
                                            <br />
                                            <button className="btn btn-sm btn-outline-danger" data-toggle="modal" data-target="#add_placeholders_modal" onClick={props.clear_modal_contents}><i className="fa fa-plus"></i> {t('Add Placeholders', props.auth.language)}</button>
                                        </div>
                                        <br /><br />
                                        {
                                            props.c_state.placeholder_table ?
                                                <table width="100%" className="table">
                                                    <tbody>
                                                        {
                                                            props.c_state.placeholder_table.map((val, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td align="left"><span>{val.placeholder}</span></td>
                                                                        <td align="right">
                                                                            <button className="btn btn-xs btn-outline-primary" data-toggle="modal" data-target="#edit_placeholders_modal" onClick={() => props.set_modal_content(val, index)}>{t('View/Edit', props.auth.language)}</button>
                                                                            <button className="btn btn-xs btn-outline-danger" onClick={() => props.delete_placeholder(index)}>{t('Delete', props.auth.language)}</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                : null
                                        }
                                        {
                                            props.c_state.placeholder_table.length ?
                                                <div align="center">
                                                    <br />
                                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button className="btn btn-block btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.save_placeholders}><i className="fa fa-save"></i> {t('Save', props.auth.language)}</button></span>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>

                    {/* modals will come here */}

                    <div className="modal fade" id="create_template_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Create New Template', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="template_name">{t('Template Name', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.template_details.template_name ? props.c_state.template_details.template_name : ''} onChange={(event) => props.handleChange({ "field": "template_name", "value": event.target.value }, 'template')} className="form-control" name="template_name" placeholder={t('Template Name', props.auth.language)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="document_type">{t('Document Type', props.auth.language)} *</label>
                                            <select className="form-control" name="document_type" onChange={(event) => props.handleChange({ "field": "document_type", "value": event.target.value }, 'template')} value={props.c_state.template_details.document_type ? props.c_state.template_details.document_type : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="word">{t('Word Document', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Category', props.auth.language)} *</label>
                                            <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, 'template')} value={props.c_state.template_details.category ? props.c_state.template_details.category : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                {
                                                    props.auth.user ?
                                                        props.auth.user.privileges.includes("create_private_template") ?
                                                            <option value="private">{t('Private', props.auth.language)}</option>
                                                            : null
                                                        : null
                                                }
                                                {
                                                    props.auth.user ?
                                                        props.auth.user.privileges.includes("create_public_template") ?
                                                            <option value="public">{t('Public', props.auth.language)}</option>
                                                            : null
                                                        : null
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Tags', props.auth.language)} </label>
                                            <Select
                                                options={props.c_state.tags_dropdown}
                                                name="tags"
                                                value={props.c_state.template_details.tags ? props.c_state.template_details.tags : ''}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'template')}
                                                isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder={t('Tags', props.auth.language)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_template_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.create_template}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="upload_template_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Upload Template', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="template_name">{t('Template Name', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.template_details.template_name ? props.c_state.template_details.template_name : ''} onChange={(event) => props.handleChange({ "field": "template_name", "value": event.target.value }, 'template')} className="form-control" name="template_name" placeholder="Template Name" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="document_type">{t('Document Type', props.auth.language)} *</label>
                                            <select className="form-control" name="document_type" onChange={(event) => props.handleChange({ "field": "document_type", "value": event.target.value }, 'template')} value={props.c_state.template_details.document_type ? props.c_state.template_details.document_type : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="word">{t('Word Document', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Category', props.auth.language)} *</label>
                                            <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, 'template')} value={props.c_state.template_details.category ? props.c_state.template_details.category : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                {
                                                    props.auth.user ?
                                                        props.auth.user.privileges.includes("upload_private_template") ?
                                                            <option value="private">{t('Private', props.auth.language)}</option>
                                                            : null
                                                        : null
                                                }
                                                {
                                                    props.auth.user ?
                                                        props.auth.user.privileges.includes("upload_public_template") ?
                                                            <option value="public">{t('Public', props.auth.language)}</option>
                                                            : null
                                                        : null
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="category">{t('Tags', props.auth.language)} </label>
                                            <Select
                                                options={props.c_state.tags_dropdown}
                                                name="tags"
                                                value={props.c_state.template_details.tags ? props.c_state.template_details.tags : ''}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'template')}
                                                isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder={t('Tags', props.auth.language)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="template_file">{t('Choose File', props.auth.language)} *</label>
                                            <input type="file" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(event) => props.handleChange({ "field": "template_file", "value": event.target.files[0] }, 'template')} className="form-control" name="template_file" placeholder="Template File" />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_upload_template_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.upload_template}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="add_placeholders_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add Placeholder', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="placeholder">{t('Placeholder', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.placeholder_details.placeholder ? props.c_state.placeholder_details.placeholder : ''} onChange={(event) => props.handleChange({ "field": "placeholder", "value": event.target.value }, 'placeholder')} className="form-control" name="placeholder" placeholder="Placeholder" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="field_type">{t('Field Type', props.auth.language)} *</label>
                                            <select className="form-control" name="field_type" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value }, 'placeholder')} value={props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="text">{t('Text', props.auth.language)}</option>
                                                <option value="textarea">{t('Text Area', props.auth.language)}</option>
                                                <option value="drop_down">{t('Drop Down', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type === 'drop_down' ?
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label htmlFor="options">{t('Options', props.auth.language)} *</label>
                                                    <textarea onChange={(event) => props.handleChange({ "field": "options", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.options ? props.c_state.placeholder_details.options : ''} className="form-control" name="options" placeholder={t('Options separated by commas. e.g. Book, Pen, Pencil', props.auth.language)}></textarea>
                                                </div>
                                            </div>
                                            : null
                                            : null
                                    }
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="question">{t('Question', props.auth.language)} *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.question ? props.c_state.placeholder_details.question : ''} className="form-control" name="question" placeholder={t('Type your question here...', props.auth.language)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_placeholder_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.add_placeholder}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_placeholders_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Placeholder', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="placeholder">{t('Placeholder', props.auth.language)} *</label>
                                            <input type="text" value={props.c_state.placeholder_details.placeholder ? props.c_state.placeholder_details.placeholder : ''} onChange={(event) => props.handleChange({ "field": "placeholder", "value": event.target.value }, 'placeholder')} className="form-control" name="placeholder" placeholder={t('Placeholder', props.auth.language)} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="field_type">{t('Field Type', props.auth.language)} *</label>
                                            <select className="form-control" name="field_type" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value }, 'placeholder')} value={props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type : ''}>
                                                <option value="">--{t('Select', props.auth.language)}--</option>
                                                <option value="text">{t('Text', props.auth.language)}</option>
                                                <option value="textarea">{t('Text Area', props.auth.language)}</option>
                                                <option value="drop_down">{t('Drop Down', props.auth.language)}</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type === 'drop_down' ?
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label htmlFor="options">{t('Options', props.auth.language)} *</label>
                                                    <textarea onChange={(event) => props.handleChange({ "field": "options", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.options ? props.c_state.placeholder_details.options : ''} className="form-control" name="options" placeholder={t('Options separated by commas. e.g. Book, Pen, Pencil', props.auth.language)}></textarea>
                                                </div>
                                            </div>
                                            : null
                                            : null
                                    }
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="question">{t('Question', props.auth.language)} *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.question ? props.c_state.placeholder_details.question : ''} className="form-control" name="question" placeholder={t('Type your question here...', props.auth.language)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_placeholder_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_placeholder}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default NewTemplate
