import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.TAGS_LOADING,
            payload: action
        })
    }
}

export const serverGetTags = (token) => {
    return (dispatch) => {

        dispatch({
            type: types.TAGS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        axios({
            headers,
            url: types.SERVER_URL + 'get_tags',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TAGS,
                    payload: response.data
                });

                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
                return
            }
        }).catch((err) => {
            return dispatch({
                type: types.DO_LOGOUT,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
        })
    }
}


export const serverDeleteTag = (tid, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TAGS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'delete_tag',
            data: {
                "tid": tid
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TAGS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TAGS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TAGS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TAGS_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverAddTag = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TAGS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'add_tag',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TAGS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TAGS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TAGS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TAGS_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverEditTag = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TAGS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'edit_tag',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TAGS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TAGS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TAGS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TAGS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverAddSubTag = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TAGS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'add_sub_tag',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TAGS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TAGS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TAGS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TAGS_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverEditSubTag = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TAGS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'edit_sub_tag',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TAGS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TAGS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TAGS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TAGS_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverDeleteSubTag = (child_id, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TAGS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'delete_sub_tag',
            data: {
                "child_id": child_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TAGS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TAGS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TAGS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TAGS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TAGS_LOADING,
                payload: "false"
            });
            return
        })
    }
}
