import React, { Component } from 'react'

import Dashboard from './Dashboard';
import Main from './../LayoutComponent/Main';
import { connect } from 'react-redux';
import { serverGetContacts } from '../../actions/ContactsAction';
import { serverLoad, serverGetDashboardInfo } from '../../actions/DashboardAction';

class DashboardContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dashboard: [],
            contact_list: []
        }
    }

    componentDidMount() {

        if (localStorage.getItem("token")) {
                this.props.serverGetDashboardInfo(localStorage.getItem("token"));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dashboard !== this.props.dashboard) {
            this.setState({ dashboard: [...this.props.dashboard.dashboard] });
        }
    }

    render() {
        return (
            <>
                <Main>
                    <Dashboard
                        c_state={{ ...this.state }}
                        isLoading={this.props.dashboard.isLoading}
                        auth={this.props.auth}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        dashboard: { ...state.dashboard },
        contacts: { ...state.contacts }
    }
}

export default connect(mapStateToProps, {
    // checkUser,
    serverLoad,
    serverGetDashboardInfo,
    serverGetContacts
})(DashboardContainer)


