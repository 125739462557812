import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.TASKS_LOADING,
            payload: action
        })
    }
}

export const serverGetTasks = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_tasks',
            data,
            method: 'post'
        }).then((response) => {

            // console.log("ttt", res.data.data);

            // let response = JSON.parse(res.data.data);

            // console.log(response);

            // return;

            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS,
                    payload: response.data
                });

                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            return dispatch({
                type: types.DO_LOGOUT,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
        })
    }
}

export const serverGetAllTasks = (token) => {
    return (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        axios({
            headers,
            url: types.SERVER_URL + 'get_all_tasks',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS,
                    payload: response.data
                });

                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return;

            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return;

            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            return dispatch({
                type: types.DO_LOGOUT,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
        })
    }
}


export const serverDeleteTask = (task_id, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'delete_task',
            data: {
                "task_id": task_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TASKS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TASKS_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverAddTask = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'add_task',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });

                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TASKS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TASKS_LOADING,
                payload: "false"
            });
            return
        })
    }
}


export const serverEditTask = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'edit_task',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TASKS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TASKS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverChangeTaskState = (state, task_id, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'change_task_state',
            data: {
                state: state,
                task_id: task_id
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TASKS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TASKS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverSendComment = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'send_comment',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TASKS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TASKS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverSendTaskCC = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'send_task_cc',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TASKS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TASKS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverconfirmTaskRating = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TASKS_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'confirm_task_rating',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TASKS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TASKS_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetCommentAttachments = (data, token) => {
    return (dispatch) => {

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'get_comment_attachments',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TASKS_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TASKS_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TASKS_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TASKS_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TASKS_LOADING,
                payload: "false"
            });
            return
        })
    }
}