import React from 'react'
import { Link } from "react-router-dom";
import PersonalContactDetailsModal from './PersonalContactDetailsModal';
import CompanyContactDetailsModal from './CompanyContactDetailsModal';
import types from '../../types';
import Select from 'react-select';
import { getTrans as t } from '../../translations';

const Contacts = (props) => {

    // console.log("here", props.c_state.groups_dropdown);

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>{t('Contacts', props.auth.language)}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                    <li className="breadcrumb-item active">{t('Contacts', props.auth.language)}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div align="right" className="mb-2">
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("upload_contacts") ?
                                    <>
                                        <button className="btn btn-sm btn-outline-info" data-toggle="modal" data-target="#upload_contact_modal" onClick={props.clear_modal_contents}><i className="fa fa-upload"></i> {t('Upload Contacts', props.auth.language)}</button>&nbsp;
                                    </>
                                    : null
                                : null
                        }
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("add_contacts") ?
                                    <>
                                        <button className="btn btn-sm btn-outline-danger" data-toggle="modal" data-target="#add_contact_modal" onClick={props.clear_modal_contents}><i className="fa fa-user"></i> {t('Add Contact', props.auth.language)}</button>&nbsp;
                                    </>
                                    : null
                                : null
                        }
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("add_companies") ?
                                    <>
                                        <button className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target="#add_company_modal" onClick={props.clear_modal_contents}><i className="fa fa-briefcase"></i> {t('Add Company', props.auth.language)}</button>&nbsp;
                                    </>
                                    : null
                                : null
                        }
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("add_groups") ?
                                    <>
                                        <button className="btn btn-sm btn-outline-info" data-toggle="modal" data-target="#add_group_modal" onClick={props.clear_modal_contents}><i className="fa fa-users"></i> {t('Add Group', props.auth.language)}</button>&nbsp;
                                    </>
                                    : null
                                : null
                        }
                        <button type="button" className="btn btn-sm btn-outline-warning" onClick={props.reload}><i className="fa fa-sync"></i> {t('Reload', props.auth.language)}</button>&nbsp;
                    </div>
                    <div className="card" >
                        <div className="card-header">
                            <h3 className="card-title">{t('Contact List', props.auth.language)}</h3>
                            <div align="right">
                                <button type="button" className="btn btn-sm btn-outline-success" data-toggle="modal" data-target="#search_tag_modal"><i className="fa fa-filter"></i> {t('Filter By Tags', props.auth.language)}</button>&nbsp;
                                <button type="button" className="btn btn-sm btn-outline-default">
                                    <div className="input-group input-group-sm" style={{ width: 200 }}>
                                        <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_contact(event.target.value)} />
                                        <div className="input-group-append">
                                            <Link to="#" type="submit" disabled className="btn btn-sm btn-default">
                                                <i className="fa fa-search"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="card card-primary card-outline card-outline-tabs">
                            <div className="card-header p-0 border-bottom-0">
                                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                    {
                                        props.auth.user ?
                                            props.auth.user.privileges.includes("view_contacts") ?
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">{t('Contacts', props.auth.language)}</a>
                                                </li>
                                                : null
                                            : null
                                    }
                                    {
                                        props.auth.user ?
                                            props.auth.user.privileges.includes("view_companies") ?
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">{t('Companies', props.auth.language)}</a>
                                                </li>
                                                : null
                                            : null
                                    }
                                    {
                                        props.auth.user ?
                                            props.auth.user.privileges.includes("view_groups") ?
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-four-groups-tab" data-toggle="pill" href="#custom-tabs-four-groups" role="tab" aria-controls="custom-tabs-four-groups" aria-selected="false">{t('Groups', props.auth.language)}</a>
                                                </li>
                                                : null
                                            : null
                                    }
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-four-tabContent">
                                    {
                                        props.auth.user ?
                                            props.auth.user.privileges.includes("view_contacts") ?
                                                <div className={`tab-pane fade ${props.auth.user.privileges.includes("view_contacts") ? 'show active' : ''}`} id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                                    {
                                                        props.isLoading === "true" ?
                                                            <div align="center">
                                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your contact list. Please wait...', props.auth.language)}</p></span>
                                                            </div>
                                                            :
                                                            <table id="example1" className="table table-bordered table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>{t('Name', props.auth.language)}</th>
                                                                        <th>{t('Phone', props.auth.language)}</th>
                                                                        <th>{t('Email', props.auth.language)}</th>
                                                                        <th>{t('Company', props.auth.language)}</th>
                                                                        <th>{t('Contact Type/Category', props.auth.language)}</th>
                                                                        <th>{t('Action', props.auth.language)}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        props.c_state.contact_list ?
                                                                            // eslint-disable-next-line array-callback-return
                                                                            props.c_state.contact_list.map((data) => {
                                                                                return (
                                                                                    <tr key={data.contact_id}>
                                                                                        <td>
                                                                                            <img src={data.photo !== '' && data.photo !== null ? types.SERVER_URL + 'static/contacts/' + data.photo : "static/assets/dist/img/user1-128x128.jpg"} alt="user-avatar" className="img-circle" width="40" height="40" />
                                                                                        </td>
                                                                                        <td>{`${data.first_name} ${data.last_name} ${data.title ? '(' + data.title + ')' : ' '}`}</td>
                                                                                        <td>{data.phone}</td>
                                                                                        <td>{data.email}</td>
                                                                                        <td>{data.company}</td>
                                                                                        <td>{data.category} {data.category && data.contact_type ? "|" : null} {data.contact_type}</td>
                                                                                        <td className="text-right py-0 align-middle">
                                                                                            <div className="btn-group btn-group-sm">
                                                                                                {
                                                                                                    props.auth.user ?
                                                                                                        props.auth.user.privileges.includes("view_contact_details") ?
                                                                                                            <>
                                                                                                                <button type="button" className="btn btn-xs btn-info" title={t('View More', props.auth.language)} data-toggle="modal" data-target="#view_company_contact_details_modal" onClick={() => props.setModalContent(data, 'contact')}><i className="fas fa-eye"></i></button>&nbsp;
                                                                                                            </>
                                                                                                            : null
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    props.auth.user ?
                                                                                                        props.auth.user.privileges.includes("edit_all_contacts") ?
                                                                                                            <>
                                                                                                                <button type="button" className="btn btn-xs btn-success" title={t('Edit', props.auth.language)} data-toggle="modal" data-target="#edit_staff_modal" onClick={() => props.setModalContent(data, 'contact')}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                                            </>
                                                                                                            : props.auth.user.contact_id + '' === data.added_by + '' ?
                                                                                                                props.auth.user.privileges.includes("edit_personal_contact") ?
                                                                                                                    <>
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title={t('Edit', props.auth.language)} data-toggle="modal" data-target="#edit_staff_modal" onClick={() => props.setModalContent(data, 'contact')}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                                                    </>
                                                                                                                    : null
                                                                                                                : null
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    props.auth.user ?
                                                                                                        props.auth.user.privileges.includes("delete_all_contacts") ?
                                                                                                            <button type="button" className="btn btn-xs btn-danger" title={t('Delete', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.first_name} ${data.last_name}'s contact?`) ? props.deleteContact(data.contact_id) : ''}><i className="fas fa-trash"></i></button>
                                                                                                            : props.auth.user.contact_id + '' === data.added_by + '' ?
                                                                                                                props.auth.user.privileges.includes("edit_personal_contact") ?
                                                                                                                    <button type="button" className="btn btn-xs btn-danger" title={t('Delete', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.first_name} ${data.last_name}'s contact?`) ? props.deleteContact(data.contact_id) : ''}><i className="fas fa-trash"></i></button>
                                                                                                                    : null
                                                                                                                : null
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                    }
                                                </div>
                                                : null
                                            : null
                                    }
                                    {
                                        props.auth.user ?
                                            props.auth.user.privileges.includes("view_companies") ?
                                                <div className={`tab-pane fade ${props.auth.user.privileges.includes("view_companies") ? 'show' : ''}`} id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                                    {
                                                        props.isLoading === "true" ?
                                                            <div align="center">
                                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your company list. Please wait...', props.auth.language)}</p></span>
                                                            </div>
                                                            :
                                                            <table id="example2" className="table table-bordered table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        {/* <th></th> */}
                                                                        <th>{t('Company Name', props.auth.language)}</th>
                                                                        <th>{t('Phone', props.auth.language)}</th>
                                                                        <th>{t('Email', props.auth.language)}</th>
                                                                        <th>{t('City', props.auth.language)}</th>
                                                                        <th>{t('Country', props.auth.language)}</th>
                                                                        <th>{t('Region', props.auth.language)}</th>
                                                                        <th>{t('URL', props.auth.language)}</th>
                                                                        <th>{t('Action', props.auth.language)}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        props.c_state.company_list ?
                                                                            // eslint-disable-next-line array-callback-return
                                                                            props.c_state.company_list.map((data) => {
                                                                                return (
                                                                                    <tr key={data.company_id}>
                                                                                        {/* <td>
                                                                                <img src="static/assets/dist/img/user1-128x128.jpg" alt="user-avatar" className="img-circle" width="40" height="40" />
                                                                            </td> */}
                                                                                        <td>{`${props.titleCase(data.name)}`}</td>
                                                                                        <td>{data.phone_number}</td>
                                                                                        <td>{data.email}</td>
                                                                                        <td>{data.city}</td>
                                                                                        <td>{data.country}</td>
                                                                                        <td>{data.region}</td>
                                                                                        <td>{data.url}</td>
                                                                                        <td className="text-right py-0 align-middle">
                                                                                            <div className="btn-group btn-group-sm">
                                                                                                {
                                                                                                    props.auth.user ?
                                                                                                        props.auth.user.privileges.includes("view_company_details") ?
                                                                                                            <>
                                                                                                                <button type="button" className="btn btn-xs btn-info" title={t('View More', props.auth.language)} data-toggle="modal" data-target="#view_personal_contact_details_modal" onClick={() => props.setModalContent(data, 'company')}><i className="fas fa-eye"></i></button>&nbsp;
                                                                                                            </>
                                                                                                            : null
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    props.auth.user ?
                                                                                                        props.auth.user.privileges.includes("edit_company") ?
                                                                                                            <>
                                                                                                                <button type="button" className="btn btn-xs btn-success" title={t('Edit', props.auth.language)} data-toggle="modal" data-target="#edit_company_modal" onClick={() => props.setModalContent(data, 'company')}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                                            </>
                                                                                                            : null
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    props.auth.user ?
                                                                                                        props.auth.user.privileges.includes("delete_company") ?
                                                                                                            <button type="button" className="btn btn-xs btn-danger" title={t('Delete', props.auth.language)} onClick={() => window.confirm(`Are you sure you want to delete ${data.first_name} ${data.last_name}'s contact?`) ? props.deleteCompany(data.company_id) : ''}><i className="fas fa-trash"></i></button>
                                                                                                            : null
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                    }
                                                </div>
                                                : null
                                            : null
                                    }
                                    {
                                        props.auth.user ?
                                            props.auth.user.privileges.includes("view_groups") ?
                                                <div className={`tab-pane fade ${props.auth.user.privileges.includes("view_groups") ? 'show' : ''}`} id="custom-tabs-four-groups" role="tabpanel" aria-labelledby="custom-tabs-four-groups-tab">
                                                    {
                                                        props.isLoading === "true" ?
                                                            <div align="center">
                                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('Loading. Please wait...', props.auth.language)}</p></span>
                                                            </div>
                                                            :
                                                            <table id="example2" className="table table-bordered table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t('Group Name', props.auth.language)}</th>
                                                                        {/* <th>Added By</th> */}
                                                                        <th>{t('Date Added', props.auth.language)}</th>
                                                                        <th>{t('Action', props.auth.language)}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        props.c_state.group_list ?
                                                                            // eslint-disable-next-line array-callback-return
                                                                            props.c_state.group_list.map((data) => {
                                                                                return (
                                                                                    <tr key={data.group_id}>
                                                                                        <td>{data.group_name}</td>
                                                                                        <td>{data.created_at}</td>
                                                                                        <td className="py-0 align-middle">
                                                                                            <div className="btn-group btn-group-sm">
                                                                                                {
                                                                                                    props.auth.user ?
                                                                                                        props.auth.user.privileges.includes("edit_group") ?
                                                                                                            <>
                                                                                                                <button type="button" className="btn btn-xs btn-success" title={t('Edit', props.auth.language)} data-toggle="modal" data-target="#edit_group_modal" onClick={() => props.setModalContent(data, 'group')}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                                            </>
                                                                                                            : null
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    props.auth.user ?
                                                                                                        props.auth.user.privileges.includes("delete_group") ?
                                                                                                            <button type="button" className="btn btn-xs btn-danger" title={t('Delete', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} ${data.group_name}?`) ? props.deleteGroup(data.group_id) : ''}><i className="fas fa-trash"></i></button>
                                                                                                            : null
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                    }
                                                </div>
                                                : null
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* modals */}

                    <div className="modal fade" id="search_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="tags"
                                            // value={props.c_state.contact_details.tags ? props.c_state.contact_details.tags : []}
                                            id="custom_select"
                                            value={typeof props.c_state.contact_details.search_params === "string" ? JSON.parse(props.c_state.contact_details.search_params.replace(/'/g, '"')) : props.c_state.contact_details.search_params}
                                            onChange={(value) => props.handleChange({ "field": "search_params", "value": value }, 'contact')}
                                            isMulti style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select" />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_params_modal">{t('Close', props.auth.language)}</button>
                                    <button type="button" className="btn btn-primary" onClick={props.search_contact_by_tags}>{t('Search', props.auth.language)}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="add_contact_modal">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add Contact', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div align="center">
                                            <img src={props.c_state.contact_details.photo !== '' ? types.SERVER_URL + 'static/contacts/' + props.c_state.contact_details.photo : "static/assets/dist/img/user1-128x128.jpg"} alt={t('user-avatar', props.auth.language)} className="img-circle" width="100" height="100" />
                                            <br /><br />
                                            <div className="btn btn-sm btn-outline-danger btn-file fileinput-button btn-block">
                                                <i className="fas fa-paperclip"></i> {props.c_state.contact_photo ? props.c_state.contact_photo.name : t('Upload Photo', props.auth.language)}
                                                <input type="file" name="photo" accept="image/png, image/gif, image/jpeg" onChange={(event) => props.handleChange({ "field": "photo", "value": event.target.files }, "contact")} />
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="category">{t('Contact Category', props.auth.language)} *</label>
                                                <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, 'contact')} value={props.c_state.contact_details.category ? props.c_state.contact_details.category : ""}>
                                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                                    <option value="Personal">{t('Personal', props.auth.language)}</option>
                                                    {
                                                        props.auth.user ?
                                                            props.auth.user.privileges.includes("create_positional_contacts") ?
                                                                <option value="Positional">{t('Positional', props.auth.language)}</option>
                                                                : null
                                                            : null
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="contact_type">Contact Type *</label>
                                                <select className="form-control" name="contact_type" onChange={(event) => props.handleChange({ "field": "contact_type", "value": event.target.value }, 'contact')} value={props.c_state.contact_details.contact_type ? props.c_state.contact_details.contact_type : ""}>
                                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                                    <option value="Private">{t('Private', props.auth.language)}</option>
                                                    <option value="Public">{t('Public', props.auth.language)}</option>
                                                    {
                                                        props.auth.user ?
                                                            props.auth.user.privileges.includes("add_groups") ?
                                                                <option value="Group">{t('Group', props.auth.language)}</option>
                                                                : null
                                                            : null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                props.c_state.contact_details.category ?
                                                    props.c_state.contact_details.category === "Positional" ?
                                                        <div className="form-group col-6">
                                                            <label htmlFor="position">{t('Position Name', props.auth.language)}</label>
                                                            <input type="text" value={props.c_state.contact_details.position ? props.c_state.contact_details.position : ''} onChange={(event) => props.handleChange({ "field": "position", "value": event.target.value }, 'contact')} className="form-control" name="position" placeholder={t('Position', props.auth.language)} />
                                                        </div>
                                                        : null
                                                    : null
                                            }
                                            {
                                                props.c_state.contact_details.contact_type ?
                                                    props.c_state.contact_details.contact_type === "Group" ?
                                                        <div className="form-group col-6">
                                                            <label htmlFor="group">{t('Group', props.auth.language)} *</label>
                                                            <Select
                                                                options={props.c_state.groups_dropdown}
                                                                name="groups"
                                                                // value={props.c_state.contact_details.tags ? props.c_state.contact_details.tags : []}
                                                                id="custom_select"
                                                                value={props.c_state.contact_details.groups ? typeof props.c_state.contact_details.groups === "string" ? JSON.parse(props.c_state.contact_details.groups.replace(/'/g, '"')) : props.c_state.contact_details.groups : []}
                                                                onChange={(value) => props.handleChange({ "field": "groups", "value": value }, 'contact')}
                                                                isMulti
                                                                style={{ width: '100%' }}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select" />
                                                        </div>
                                                        : null
                                                    : null
                                            }
                                        </div>
                                        {
                                            props.c_state.contact_details.category ?
                                                props.c_state.contact_details.category === "Personal" ?
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label htmlFor="first_name">{t('First Name', props.auth.language)} *</label>
                                                            <input type="text" value={props.c_state.contact_details.first_name ? props.c_state.contact_details.first_name : ''} onChange={(event) => props.handleChange({ "field": "first_name", "value": event.target.value }, 'contact')} className="form-control" name="first_name" placeholder={t('First Name', props.auth.language)} />
                                                        </div>

                                                        <div className="form-group col-6">
                                                            <label htmlFor="last_name">{t('Last Name', props.auth.language)} *</label>
                                                            <input type="text" value={props.c_state.contact_details.last_name ? props.c_state.contact_details.last_name : ''} onChange={(event) => props.handleChange({ "field": "last_name", "value": event.target.value }, 'contact')} className="form-control" name="last_name" placeholder={t('Last Name', props.auth.language)} />
                                                        </div>
                                                    </div>
                                                    : null
                                                : null
                                        }
                                        <div className="row">
                                            {
                                                props.c_state.contact_details.category ?
                                                    props.c_state.contact_details.category === "Personal" ?
                                                        <div className="form-group col-6">
                                                            <label htmlFor="title">{t('Title', props.auth.language)} *</label>
                                                            <input type="text" value={props.c_state.contact_details.title ? props.c_state.contact_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value }, 'contact')} className="form-control" name="title" placeholder={t('Title', props.auth.language)} />
                                                        </div>
                                                        : null
                                                    : null
                                            }
                                            <div className="form-group col-6">
                                                <label htmlFor="company_name">{t('Company Name', props.auth.language)} *</label>
                                                <select className="form-control" name="company_name" onChange={(event) => props.handleChange({ "field": "company_name", "value": event.target.value }, 'contact')} value={props.c_state.contact_details.company_name ? props.c_state.contact_details.company_name : ""}>
                                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                                    {
                                                        props.c_state.company_list ?
                                                            // eslint-disable-next-line array-callback-return
                                                            props.c_state.company_list.map((data, i) => {
                                                                return (
                                                                    <option key={i} value={data.company_id}>{data.name}</option>
                                                                )
                                                            })
                                                            : null
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                                <Select
                                                    options={props.c_state.tags_dropdown}
                                                    name="tags"
                                                    value={props.c_state.contact_details.tags ? props.c_state.contact_details.tags : []}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'contact')}
                                                    isMulti style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder={t('Select', props.auth.language)}
                                                />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="phone">{t('Phone', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.contact_details.phone ? props.c_state.contact_details.phone : ''} onChange={(event) => props.handleChange({ "field": "phone", "value": event.target.value }, 'contact')} className="form-control" name="phone" placeholder={t('Phone', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="email">{t('Email', props.auth.language)} *</label>
                                                <input type="email" value={props.c_state.contact_details.email ? props.c_state.contact_details.email : ''} onChange={(event) => props.handleChange({ "field": "email", "value": event.target.value }, 'contact')} className="form-control" name="email" placeholder={t('Email', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="department">{t('Department', props.auth.language)} *</label>
                                                <Select
                                                    options={props.c_state.departments_dropdown}
                                                    name="department"
                                                    value={props.c_state.contact_details.department ? props.c_state.contact_details.department : []}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "department", "value": value }, 'contact')}
                                                    style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder={t('Select', props.auth.language)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="address">{t('Address', props.auth.language)} *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "address", "value": event.target.value }, 'contact')} style={{ resize: 'none' }} value={props.c_state.contact_details.address ? props.c_state.contact_details.address : ''} className="form-control" name="address" placeholder={t('Address', props.auth.language)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_contact_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.add_contact}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="add_company_modal">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add New Company', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="company_name">{t('Company Name', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.name ? props.c_state.company_details.name : ''} onChange={(event) => props.handleChange({ "field": "name", "value": event.target.value }, 'company')} className="form-control" name="company_name" placeholder={t('Company Name', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="company_name">{t('Company Short Name', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.short_name ? props.c_state.company_details.short_name : ''} onChange={(event) => props.handleChange({ "field": "short_name", "value": event.target.value }, 'company')} className="form-control" name="short_name" placeholder={t('Short Name', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="url">{t('Company Website', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.url ? props.c_state.company_details.url : ''} onChange={(event) => props.handleChange({ "field": "url", "value": event.target.value }, 'company')} className="form-control" name="url" placeholder="https://" />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="is_internal">{t('Status', props.auth.language)} *</label>
                                                <select className="form-control" name="is_internal" onChange={(event) => props.handleChange({ "field": "is_internal", "value": event.target.value }, 'company')} >
                                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                                    <option value="1">{t('Internal', props.auth.language)}</option>
                                                    <option value="0">{t('External', props.auth.language)}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="contact_person">{t('Contact Person', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.contact_person ? props.c_state.company_details.contact_person : ''} onChange={(event) => props.handleChange({ "field": "contact_person", "value": event.target.value }, 'company')} className="form-control" name="contact_person" placeholder={t('Contact Person', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="position">{t('Position of Contact Person', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.position ? props.c_state.company_details.position : ''} onChange={(event) => props.handleChange({ "field": "position", "value": event.target.value }, 'company')} className="form-control" name="position" placeholder={t('Position', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="phone_number">{t('Company Phone', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.phone_number ? props.c_state.company_details.phone_number : ''} onChange={(event) => props.handleChange({ "field": "phone_number", "value": event.target.value }, 'company')} className="form-control" name="phone_number" placeholder="024XXXXXXX" />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                                <Select options={props.c_state.tags_dropdown} name="tags" value={props.c_state.company_details.tags ? props.c_state.company_details.tags : []} id="custom_select" onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'company')} isMulti style={{ width: '100%' }} className="basic-multi-select" classNamePrefix="select" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="email">{t('Company Email', props.auth.language)} *</label>
                                                <input type="email" value={props.c_state.company_details.email ? props.c_state.company_details.email : ''} onChange={(event) => props.handleChange({ "field": "email", "value": event.target.value }, 'company')} className="form-control" name="email" placeholder={t('Email', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="country">{t('Country', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.country ? props.c_state.company_details.country : ''} onChange={(event) => props.handleChange({ "field": "country", "value": event.target.value }, 'company')} className="form-control" name="country" placeholder={t('Country', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="city">{t('City', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.city ? props.c_state.company_details.city : ''} onChange={(event) => props.handleChange({ "field": "city", "value": event.target.value }, 'company')} className="form-control" name="city" placeholder={t('City', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="region">{t('Region', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.region ? props.c_state.company_details.region : ''} onChange={(event) => props.handleChange({ "field": "region", "value": event.target.value }, 'company')} className="form-control" name="region" placeholder={t('Region', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="address">{t('Address', props.auth.language)} *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "address", "value": event.target.value }, 'company')} style={{ resize: 'none' }} value={props.c_state.company_details.address ? props.c_state.company_details.address : ''} className="form-control" name="address" placeholder="Address"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_company_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.add_company}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_company_modal">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Company Details', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="company_name">{t('Company Name', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.name ? props.c_state.company_details.name : ''} onChange={(event) => props.handleChange({ "field": "name", "value": event.target.value }, 'company')} className="form-control" name="company_name" placeholder={t('Company Name', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="short_name">{t('Company Short Name', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.short_name ? props.c_state.company_details.short_name : ''} onChange={(event) => props.handleChange({ "field": "short_name", "value": event.target.value }, 'company')} className="form-control" name="short_name" placeholder={t('Short Name', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="url">{t('Company Website', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.url ? props.c_state.company_details.url : ''} onChange={(event) => props.handleChange({ "field": "url", "value": event.target.value }, 'company')} className="form-control" name="url" placeholder="https://" />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="is_internal">{t('Status', props.auth.language)} *</label>
                                                <select className="form-control" name="is_internal" onChange={(event) => props.handleChange({ "field": "is_internal", "value": event.target.value }, 'company')} value={props.c_state.company_details.is_internal ? props.c_state.company_details.is_internal : ''} >
                                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                                    <option value="1">{t('Internal', props.auth.language)}</option>
                                                    <option value="0">{t('External', props.auth.language)}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="contact_person">{t('Contact Person', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.contact_person ? props.c_state.company_details.contact_person : ''} onChange={(event) => props.handleChange({ "field": "contact_person", "value": event.target.value }, 'company')} className="form-control" name="contact_person" placeholder={t('Contact Person', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="position">{t('Position of Contact Person', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.position ? props.c_state.company_details.position : ''} onChange={(event) => props.handleChange({ "field": "position", "value": event.target.value }, 'company')} className="form-control" name="position" placeholder={t('Position', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="phone_number">{t('Company Phone', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.phone_number ? props.c_state.company_details.phone_number : ''} onChange={(event) => props.handleChange({ "field": "phone_number", "value": event.target.value }, 'company')} className="form-control" name="phone_number" placeholder="024XXXXXXX" />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                                <Select options={props.c_state.tags_dropdown} name="tags" value={typeof props.c_state.company_details.tags === "string" ? JSON.parse(props.c_state.company_details.tags.replace(/'/g, '"')) : props.c_state.company_details.tags} id="custom_select" onChange={(value) => props.handleChange({ "field": "tags", "value": value })} isMulti style={{ width: '100%' }} className="basic-multi-select" classNamePrefix="select" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="email">{t('Company Email', props.auth.language)} *</label>
                                                <input type="email" value={props.c_state.company_details.email ? props.c_state.company_details.email : ''} onChange={(event) => props.handleChange({ "field": "email", "value": event.target.value }, 'company')} className="form-control" name="email" placeholder={t('Email', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="country">{t('Country', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.country ? props.c_state.company_details.country : ''} onChange={(event) => props.handleChange({ "field": "country", "value": event.target.value }, 'company')} className="form-control" name="country" placeholder={t('Country', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="city">{t('City', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.city ? props.c_state.company_details.city : ''} onChange={(event) => props.handleChange({ "field": "city", "value": event.target.value }, 'company')} className="form-control" name="city" placeholder={t('City', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="region">{t('Region', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.company_details.region ? props.c_state.company_details.region : ''} onChange={(event) => props.handleChange({ "field": "region", "value": event.target.value }, 'company')} className="form-control" name="region" placeholder={t('Region', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="address">{t('Address', props.auth.language)} *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "address", "value": event.target.value }, 'company')} style={{ resize: 'none' }} value={props.c_state.company_details.address ? props.c_state.company_details.address : ''} className="form-control" name="address" placeholder={t('Address', props.auth.language)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_company_modal">{t('CLose', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_company}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_staff_modal">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Update', props.auth.language)} {props.c_state.contact_details.first_name}'s {t('Info', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div align="center">
                                            <img src={props.c_state.contact_details.photo !== '' ? types.SERVER_URL + 'static/contacts/' + props.c_state.contact_details.photo : "static/assets/dist/img/user1-128x128.jpg"} alt="user-avatar" className="img-circle" width="100" height="100" />
                                            <br /><br />
                                            <div className="btn btn-sm btn-outline-danger btn-file fileinput-button btn-block">
                                                <i className="fas fa-paperclip"></i> {props.c_state.contact_photo ? props.c_state.contact_photo.name : t('Upload Photo', props.auth.language)}
                                                <input type="file" name="photo" accept="image/png, image/gif, image/jpeg" onChange={(event) => props.handleChange({ "field": "photo", "value": event.target.files }, "contact")} />
                                            </div>
                                        </div>
                                        <br /><br />
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="category">{t('Contact Category', props.auth.language)} *</label>
                                                <select className="form-control" name="category" onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, 'contact')} value={props.c_state.contact_details.category ? props.c_state.contact_details.category : ''}>
                                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                                    <option value="Personal">{t('Personal', props.auth.language)}</option>
                                                    {
                                                        props.auth.user ?
                                                            props.auth.user.privileges.includes("create_positional_contacts") ?
                                                                <option value="Positional">{t('Positional', props.auth.language)}</option>
                                                                : null
                                                            : null
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="contact_type">{t('Contact Type', props.auth.language)} *</label>
                                                <select className="form-control" name="contact_type" onChange={(event) => props.handleChange({ "field": "contact_type", "value": event.target.value }, 'contact')} value={props.c_state.contact_details.contact_type ? props.c_state.contact_details.contact_type : ''}>
                                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                                    <option value="Public">{t('Public', props.auth.language)}</option>
                                                    <option value="Private">{t('Private', props.auth.language)}</option>
                                                    {
                                                        props.auth.user ?
                                                            props.auth.user.privileges.includes("add_groups") ?
                                                                <option value="Group">{t('Group', props.auth.language)}</option>
                                                                : null
                                                            : null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                props.c_state.contact_details.category ?
                                                    props.c_state.contact_details.category === "Positional" ?
                                                        <div className="form-group col-6">
                                                            <label htmlFor="position">{t('Position Name', props.auth.language)}</label>
                                                            <input type="text" value={props.c_state.contact_details.position} onChange={(event) => props.handleChange({ "field": "position", "value": event.target.value }, 'contact')} className="form-control" name="position" placeholder={t('Position', props.auth.language)} />
                                                        </div>
                                                        : props.c_state.contact_details.category === "Personal" ?
                                                            <div className="form-group col-6">
                                                                <label htmlFor="position">{t('Assign a position to', props.auth.language)} {props.c_state.contact_details.first_name}</label>
                                                                <Select
                                                                    options={props.c_state.contacts_dropdown}
                                                                    name="other_accounts"
                                                                    value={props.c_state.contact_details.other_accounts ? typeof props.c_state.contact_details.other_accounts === "string" && props.c_state.contact_details.other_accounts !== "None" ? JSON.parse(props.c_state.contact_details.other_accounts.replace(/'/g, '"')) : props.c_state.contact_details.other_accounts : []}
                                                                    id="custom_select"
                                                                    onChange={(value) => props.handleChange({ "field": "other_accounts", "value": value }, "contact")}
                                                                    isMulti
                                                                    style={{ width: '100%' }}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Position"
                                                                />
                                                            </div>
                                                            : null
                                                    : null
                                            }
                                            {
                                                props.c_state.contact_details.contact_type ?
                                                    props.c_state.contact_details.contact_type === "Group" ?
                                                        <div className="form-group col-6">
                                                            <label htmlFor="group">{t('Group', props.auth.language)} *</label>
                                                            <Select
                                                                options={props.c_state.groups_dropdown}
                                                                name="groups"
                                                                // value={props.c_state.contact_details.tags ? props.c_state.contact_details.tags : []}
                                                                id="custom_select"
                                                                value={props.c_state.contact_details.groups ? typeof props.c_state.contact_details.groups === "string" ? JSON.parse(props.c_state.contact_details.groups.replace(/'/g, '"')) : props.c_state.contact_details.groups : []}
                                                                onChange={(value) => props.handleChange({ "field": "groups", "value": value }, 'contact')}
                                                                isMulti
                                                                style={{ width: '100%' }}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select" />
                                                        </div>
                                                        : null
                                                    : null
                                            }
                                        </div>
                                        {
                                            props.c_state.contact_details.category ?
                                                props.c_state.contact_details.category === "Personal" ?
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label htmlFor="first_name">{t('First Name', props.auth.language)} *</label>
                                                            <input type="text" value={props.c_state.contact_details.first_name} onChange={(event) => props.handleChange({ "field": "first_name", "value": event.target.value }, 'contact')} className="form-control" name="first_name" placeholder={t('First Name', props.auth.language)} />
                                                        </div>

                                                        <div className="form-group col-6">
                                                            <label htmlFor="last_name">{t('Last Name', props.auth.language)} *</label>
                                                            <input type="text" value={props.c_state.contact_details.last_name} onChange={(event) => props.handleChange({ "field": "last_name", "value": event.target.value }, 'contact')} className="form-control" name="last_name" placeholder={t('Last Name', props.auth.language)} />
                                                        </div>
                                                    </div>
                                                    : null
                                                : null
                                        }
                                        <div className="row">
                                            {
                                                props.c_state.contact_details.category ?
                                                    props.c_state.contact_details.category === "Personal" ?
                                                        <div className="form-group col-6">
                                                            <label htmlFor="title">{t('Title', props.auth.language)} *</label>
                                                            <input type="text" value={props.c_state.contact_details.title} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value }, 'contact')} className="form-control" name="title" placeholder={t('Title', props.auth.language)} />
                                                        </div>
                                                        : null
                                                    : null
                                            }
                                            <div className="form-group col-6">
                                                <label htmlFor="company_name">{t('Company Name', props.auth.language)}</label>
                                                {/* <input type="text" value={props.c_state.contact_details.company_name} onChange={(event) => props.handleChange({ "field": "company_name", "value": event.target.value }, 'contact')} className="form-control" name="company_name" /> */}
                                                <select className="form-control" name="company_name" value={props.c_state.contact_details.company_name} onChange={(event) => props.handleChange({ "field": "company_name", "value": event.target.value }, 'contact')} >
                                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                                    {
                                                        props.c_state.company_list ?
                                                            // eslint-disable-next-line array-callback-return
                                                            props.c_state.company_list.map((data, i) => {
                                                                return (
                                                                    <option key={i} value={data.company_id}>{data.name}</option>
                                                                )
                                                            })
                                                            : null
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                                <Select
                                                    options={props.c_state.tags_dropdown}
                                                    name="tags"
                                                    value={props.c_state.contact_details.tags ? typeof props.c_state.contact_details.tags === "string" && props.c_state.contact_details.tags !== 'None' ? JSON.parse(props.c_state.contact_details.tags.replace(/'/g, '"')) : props.c_state.contact_details.tags : []}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'contact')}
                                                    isMulti 
                                                    style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="phone">{t('Phone', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.contact_details.phone} onChange={(event) => props.handleChange({ "field": "phone", "value": event.target.value }, 'contact')} className="form-control" name="phone" placeholder={t('Phone', props.auth.language)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="email">{t('Email', props.auth.language)} *</label>
                                                <input type="email" value={props.c_state.contact_details.email} onChange={(event) => props.handleChange({ "field": "email", "value": event.target.value }, 'contact')} className="form-control" name="email" placeholder={t('Email', props.auth.language)} />
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="department">{t('Department', props.auth.language)} *</label>
                                                <Select
                                                    options={props.c_state.departments_dropdown}
                                                    name="department"
                                                    value={props.c_state.contact_details.department ? typeof props.c_state.contact_details.department === "string" && props.c_state.contact_details.department !== 'None' ? JSON.parse(props.c_state.contact_details.department.replace(/'/g, '"')) : props.c_state.contact_details.department: null}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "department", "value": value }, 'contact')}
                                                    style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder={t('Select', props.auth.language)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="address">{t('Address', props.auth.language)} *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "address", "value": event.target.value }, 'contact')} style={{ resize: 'none' }} value={props.c_state.contact_details.address} className="form-control" name="address" placeholder={t('Address', props.auth.language)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_contact_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_contact}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PersonalContactDetailsModal
                        c_state={props.c_state}
                        fixTags={props.fixTags}
                        auth={props.auth}
                    />

                    <CompanyContactDetailsModal
                        c_state={props.c_state}
                        fixTags={props.fixTags}
                        auth={props.auth}
                    />

                    <div className="modal fade" id="upload_contact_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Upload Contacts', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <span>{t('Click', props.auth.language)} <a href={`${types.SERVER_URL}static/contacts_template.xlsx`} target="_blank" rel="noreferrer">{t('here', props.auth.language)}</a> {t('to download Excel template', props.auth.language)}</span>
                                    </div>
                                    <br />
                                    <div className="form-group">
                                        <label htmlFor="exampleInputFile">{t('Select File To Upload', props.auth.language)}</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="custom-file-input" id="exampleInputFile" onChange={(event) => props.handleChange({ "field": "contacts_file", "value": event.target.files[0] }, 'contact')} />
                                                <label className="custom-file-label" htmlFor="exampleInputFile">{props.c_state.contacts_file.name ? props.c_state.contacts_file.name : 'Choose file'}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_upload_contacts_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.upload_contacts}>{t('Upload', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="add_group_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Add A New Group', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="phone">{t('Enter group name', props.auth.language)} *</label>
                                        <input type="text" value={props.c_state.group_details.phone} onChange={(event) => props.handleChange({ "field": "group_name", "value": event.target.value }, 'group')} className="form-control" name="group_name" placeholder={t('Group Name', props.auth.language)} />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_group_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.add_group}>{t('Submit', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_group_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Edit Group', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label htmlFor="group_name">{t('Group Name', props.auth.language)} *</label>
                                                <input type="text" value={props.c_state.group_details.group_name ? props.c_state.group_details.group_name : ''} onChange={(event) => props.handleChange({ "field": "group_name", "value": event.target.value }, 'group')} className="form-control" name="group_name" placeholder={t('Group Name', props.auth.language)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                        <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_group_modal">{t('Close', props.auth.language)}</button>
                                        <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_group}>{t('Submit', props.auth.language)}</button></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /.content --> */}
            </div >
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default Contacts