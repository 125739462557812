import types from './../types';

let defaultState = {
    isLoading: "false",
    dashboard: []
}

const Dashboard = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.DASHBOARD_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.DASHBOARD:

            newState.dashboard = payload.data;

            return newState;

        default:
            return newState;
    }
}

export default Dashboard