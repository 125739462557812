import {combineReducers} from 'redux'
import authReducer from './AuthReducer'
import contactsReducer from './ContactsReducer'
import dashboardReducer from './DashboardReducer'
import tagsReducer from './TagsReducer'
import profileReducer from './ProfileReducer'
import tasksReducer from './TasksReducer'
import templatesReducer from './TemplatesReducer'
import emailReducer from './EmailReducer'
import correspondenceReducer from './CorrespondenceReducer'
import attachmentsReducer from './AttachmentsReducer'
import routesReducer from './RoutesReducer'
import conversionsReducer from './ConversionsReducer'
import privilegesReducer from './PrivilegesReducer'
import rolesReducer from './RolesReducer'
import departmentsReducer from './DepartmentsReducer'
import questionnairesReducer from './QuestionnairesReducer'

const Reducers = combineReducers({
    auth: authReducer,
    contacts: contactsReducer,
    dashboard: dashboardReducer,
    tags: tagsReducer,
    profile: profileReducer,
    tasks: tasksReducer,
    templates: templatesReducer,
    email: emailReducer,
    correspondence: correspondenceReducer,
    attachments: attachmentsReducer,
    routes: routesReducer,
    conversions: conversionsReducer,
    privileges: privilegesReducer,
    roles: rolesReducer,
    departments: departmentsReducer,
    questionnaires: questionnairesReducer
})

export default  Reducers