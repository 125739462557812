import React, { Component } from 'react'
import EditDocument from './EditDocument';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { serverLoad, clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverRenameDocument } from '../../actions/TemplatesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class EditDocumentContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags_dropdown: [],
            single_document: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.editDocument = this.editDocument.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (this.props.templates.edit_docs_config) {
            if (!this.props.templates.edit_docs_config.document) {
                this.props.history.push("/documents");
            }
        }

        if (this.props.templates.single_document) {
            this.setState({
                single_document: this.props.templates.single_document,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);
        if (prevProps.templates.single_document !== this.props.templates.single_document) {
            $("#dismiss_rename_document_modal").click();
        }

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.templates.edit_docs_config !== this.props.templates.edit_docs_config) {
            if (!this.props.templates.edit_docs_config.document) {
                this.props.history.push("/documents");
            }
        }


    }

    handleChange = (data, type) => {
        switch (type) {
            case 'document':
                this.setState(prevState => ({
                    single_document: {                   // object that we want to update
                        ...prevState.single_document,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;

            default:
                break;
        }
    }

    editDocument = () => {

        if (!this.state.single_document.name) {
            toast.error(t("Document title cannot be empty", this.props.auth.language), {
                position: 'top-center'
            });
        } else {
            this.props.serverLoad("true");
            this.props.serverRenameDocument(this.state.single_document, this.props.auth.user.token)
        }
    }

    render() {
        return (
            <>
                <Main>
                    <EditDocument
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        isLoading={this.props.auth.isLoading}
                        handleChange={this.handleChange}
                        edit_docs_config={this.props.templates.edit_docs_config}
                        single_document={this.props.templates.single_document}
                        edit_document={this.editDocument}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        templates: { ...state.templates }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverRenameDocument
    }
)(EditDocumentContainer)
