import React from 'react'
import { Link } from 'react-router-dom'
import { getTrans as t } from '../../translations';

const ViewEmails = (props) => {

    // console.log("emails", props.isLoading);

    return (
        <div className="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{t('Emails', props.auth.language)}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                <li className="breadcrumb-item active">{t('Emails', props.auth.language)}</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section className="content">
                <div className="row">
                    <div className="col-md-3">
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("compose_email") ?
                                    <Link to="/compose_email" className="btn btn-primary btn-block mb-3">{t('Compose', props.auth.language)}</Link>
                                    : null
                                : null
                        }
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{t('Folders', props.auth.language)}</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <ul className="nav nav-pills flex-column">
                                    <li className="nav-item active">
                                        <Link to="#" onClick={() => props.load_emails('Inbox')} className="nav-link" style={{ color: props.c_state.mail_category === 'Inbox' ? 'red' : null }}>
                                            <i className="fas fa-inbox"></i> {t('Inbox', props.auth.language)}
                                            {props.c_state.mail_category === 'Inbox' ?
                                                <span className="badge bg-primary float-right">{props.c_state.emails.limit}</span>
                                                : null}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" onClick={() => props.load_emails('Sent')} className="nav-link" style={{ color: props.c_state.mail_category === 'Sent' ? 'red' : null }}>
                                            <i className="far fa-envelope"></i> {t('Sent', props.auth.language)}
                                            {props.c_state.mail_category === 'Sent' ?
                                                <span className="badge bg-info float-right">{props.c_state.emails.limit}</span>
                                                : null}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" onClick={() => props.load_emails('Drafts')} className="nav-link" style={{ color: props.c_state.mail_category === 'Drafts' ? 'red' : null }}>
                                            <i className="far fa-file-alt"></i> {t('Drafts', props.auth.language)}
                                            {props.c_state.mail_category === 'Drafts' ?
                                                <span className="badge bg-success float-right">{props.c_state.emails.limit}</span>
                                                : null}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" onClick={() => props.load_emails('Spam')} className="nav-link" style={{ color: props.c_state.mail_category === 'Spam' ? 'red' : null }}>
                                            <i className="fas fa-filter"></i> {t('Spam', props.auth.language)}
                                            {props.c_state.mail_category === 'Spam' ?
                                                <span className="badge bg-warning float-right">{props.c_state.emails.limit}</span>
                                                : null}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" onClick={() => props.load_emails('Trash')} className="nav-link" style={{ color: props.c_state.mail_category === 'Trash' ? 'red' : null }}>
                                            <i className="far fa-trash-alt"></i> {t('Trash', props.auth.language)}
                                            {props.c_state.mail_category === 'Trash' ?
                                                <span className="badge bg-danger float-right">{props.c_state.emails.limit}</span>
                                                : null}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{t('Labels', props.auth.language)}</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <ul className="nav nav-pills flex-column">
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link">
                                            <i className="far fa-circle text-danger"></i>
                                            {t('Important', props.auth.language)}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link">
                                            <i className="far fa-circle text-warning"></i> {t('Promotions', props.auth.language)}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link">
                                            <i className="far fa-circle text-primary"></i>
                                            {t('Social', props.auth.language)}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="card card-primary card-outline">
                            <div className="card-header">
                                <h3 className="card-title">{props.c_state.mail_category ? t(props.c_state.mail_category, props.auth.language) : t('Inbox', props.auth.language)}</h3>

                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <input type="text" className="form-control" placeholder={t('Search Mail', props.auth.language)} />
                                        <div className="input-group-append" />
                                        <div className="btn btn-sm btn-primary">
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-body p-0">
                            <div className="mailbox-controls">
                                {/* <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="far fa-square"></i>
                                </button> */}
                                <div className="btn-group">
                                    {/* <button type="button" className="btn btn-default btn-sm">
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-reply"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-share"></i>
                                    </button> */}
                                    <button type="button" className="btn btn-outline-success btn-sm" title={t('Reload your emails', props.auth.language)} onClick={props.reload_emails}><i className="fas fa-sync-alt"></i>&nbsp;{t('Reload', props.auth.language)}</button>&nbsp;&nbsp;
                                    {
                                        props.auth.user ?
                                            props.auth.user.privileges.includes("sync_emails") ?
                                                props.c_state.mail_category === "Inbox" ?
                                                    <><button className="btn btn-sm btn-outline-info" onClick={() => window.confirm(`${t('Are you sure you want to synchronize your email with your correspondences', props.auth.language)}?`) ? props.sync_emails() : ''}><i className="fas fa-retweet"></i>&nbsp;{t('Sync Your Emails', props.auth.language)}</button>&nbsp;&nbsp;</>
                                                    : null
                                                : null
                                            : null
                                    }
                                    {
                                        props.auth.user ?
                                            props.auth.user.privileges.includes("update_email_config") ?
                                                <>
                                                    <button className="btn btn-sm btn-outline-danger" data-toggle="modal" data-target="#email_config_modal" id="show_email_config_modal"><i className="fas fa-cogs"></i>&nbsp;{t('Update Email Config', props.auth.language)}</button>&nbsp;&nbsp;
                                                </>
                                                : null
                                            : null
                                    }
                                </div>
                                <div className="float-right">
                                    {props.c_state.start}-{props.c_state.finish}/{props.c_state.emails.limit}
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive mailbox-messages">
                                {
                                    props.isLoading === "true" ?
                                        <div align="center">
                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your emails. Please wait...', props.auth.language)}</p></span>
                                        </div>
                                        : props.c_state.emails.emails ?
                                            <table className="table table-hover table-striped" style={{ width: '100%' }}>
                                                <tbody>
                                                    {
                                                        props.c_state.emails.emails.map((data) => {
                                                            return (
                                                                <tr key={data.mail_id}>
                                                                    <td>
                                                                        <div className="icheck-primary">
                                                                            {/* <input type="checkbox" value="" id="check4" />
                                                                            <label htmlFor="check4"></label> */}
                                                                            {data.synced === "1" ? <i className="fa fa-sync text-warning"></i> : null}
                                                                        </div>
                                                                    </td>
                                                                    {/* <td className="mailbox-star"><Link to="#"><i className="fas fa-star text-warning"></i></Link></td> */}
                                                                    <td className="mailbox-name"><Link to="#" onClick={() => props.single_email(data)}>{props.c_state.mail_category === "Sent" ? `${t('To', props.auth.language)}: ${data.to}` : `${t('From', props.auth.language)}: ${data.from}`}</Link></td>
                                                                    <td className="mailbox-subject"><b>{data.subject}</b></td>
                                                                    {/* <td className="mailbox-attachment"></td> */}
                                                                    <td className="mailbox-date">{new Date(data.date).toLocaleString('en-GB', { timeZone: 'GMT' })}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            // : <div align="center">
                                            //     <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>We are loading your emails. Please wait...</p></span>
                                            // </div>
                                            : <div align="center"><br /><br /><p>{t('Sorry! Your emails failed to load due to poor internet connection', props.auth.language)}.<br />{t('Click the reload icon to reload', props.auth.language)}.</p></div>
                                }
                            </div>
                        </div>
                        <div className="card-footer p-0">
                            <div className="mailbox-controls">
                                {/* <button type="button" className="btn btn-default btn-sm checkbox-toggle">
                                    <i className="far fa-square"></i>
                                </button>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-reply"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-share"></i>
                                    </button>
                                </div>
                                <button type="button" className="btn btn-default btn-sm" title="Reload your emails" onClick={props.reload_emails}>
                                    <i className="fas fa-sync-alt"></i>
                                </button> */}
                                <div className="float-right">
                                    {props.c_state.start}-{props.c_state.finish}/{props.c_state.emails.limit}
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modals */}

                <div className="modal fade" id="email_config_modal">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Email Configurations', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="email">{t('Email', props.auth.language)} *</label>
                                        <input type="text" value={props.c_state.email_config.email ? props.c_state.email_config.email : ''} onChange={(event) => props.handleChange({ "field": "email", "value": event.target.value }, 'email_config')} className="form-control" name="email" placeholder={t('Email', props.auth.language)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="password">{t('Password', props.auth.language)} *</label>
                                        <input type="password" value={props.c_state.email_config.password ? props.c_state.email_config.password : ''} onChange={(event) => props.handleChange({ "field": "password", "value": event.target.value }, 'email_config')} className="form-control" name="password" placeholder={t('Password', props.auth.language)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="sever_name">{t('Email Server', props.auth.language)} *</label>
                                        <select className="form-control" name="server_name" onChange={(event) => props.handleChange({ "field": "server_name", "value": event.target.value }, 'email_config')} value={props.c_state.email_config.server_name ? props.c_state.email_config.server_name : ''}>
                                            <option value="">--{t('Select', props.auth.language)}--</option>
                                            <option value="gmail">Google</option>
                                            <option value="outlook">Microsoft Outlook</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_email_config_modal">{t('Close', props.auth.language)}</button>
                                <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.save_email_config}>{t('Save changes', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ViewEmails
