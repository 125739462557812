import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.TASKS_LOADING,
            payload: action
        })
    }
}

export const serverGetEmailConfig = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.EMAIL_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        await axios({
            headers,
            url: types.SERVER_URL + 'get_email_config',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.EMAIL_CONFIG,
                    payload: response.data
                }); 
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.EMAIL_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.EMAIL_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.EMAIL_LOADING,
                payload: "false"
            });
        })
    }
}

export const serverSaveEmailConfig = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.EMAIL_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'save_email_config',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.EMAIL_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.EMAIL_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.EMAIL_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.EMAIL_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetEmails = (mail_category, start, finish, mail_config, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.EMAIL_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'get_emails',
            data: {
                "mail_category": mail_category,
                "start": start,
                "finish": finish,
                "mail_config": mail_config
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.EMAIL_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.EMAIL_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.EMAIL_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.EMAIL_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverSingleEmail = (data, mail_category, mail_config) => {
    return (dispatch) => {
        dispatch({
            type: types.SINGLE_EMAIL,
            payload: {
                "single_email": data,
                "mail_category": mail_category,
                "mail_config": mail_config
            }
        });

        return

    }
}

export const serverGetEmailDetails = (mail_id, mail_config, mail_category, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.EMAIL_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'get_email_details',
            data: {
                "mail_id": mail_id,
                "mail_config": mail_config,
                "mail_category": mail_category
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.EMAIL_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.EMAIL_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.EMAIL_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.EMAIL_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverSendEmail = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.EMAIL_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'send_email',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.EMAIL_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.EMAIL_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.EMAIL_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.EMAIL_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverSyncEmails = (start, finish, email_config, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.EMAIL_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'sync_emails',
            data: {
                "start": start,
                "finish": finish,
                "email_config": email_config
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.EMAIL_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.EMAIL_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.EMAIL_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.EMAIL_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.EMAIL_LOADING,
                payload: "false"
            });
            return
        })
    }
}
