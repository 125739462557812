/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import AddAttachmentModal from '../AttachmentComponent/AddAttachmentModal';
import ViewAttachmentModal from '../AttachmentComponent/ViewAttachmentModal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { getTrans as t } from '../../translations';
import types from '../../types';
import PrintTasks from './PrintTasks';

const badges = ['badge badge-danger', 'badge badge-primary', 'badge badge-warning', 'badge badge-info', 'badge badge-success', 'badge badge-default'];
let random = 0;

const Task = (props) => {

    // console.log(props.c_state.company_list);

    return (
        <div className="content-wrapper kanban">
            {/* <!-- Content Header (Page header) --> */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{t('Task Manager', props.auth.language)}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#">{t('Home', props.auth.language)}</Link></li>
                                <li className="breadcrumb-item active">{t('Tasks', props.auth.language)}</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <div className="card">
                <div className="card-header">
                    {/* <h3 className="card-title">{t('Manage Your Tasks', props.auth.language)}</h3> */}
                    <div align="right">
                        <button type="button" className="btn btn-sm btn-outline-default">
                            <div className="input-group input-group-sm" style={{ width: 150 }}>
                                <select className="form-control form-control-sm" onChange={(event) => props.switch_tab_all(event.target.value)}>
                                    <option value="">--Task Category--</option>
                                    <option value="all">All Tasks</option>
                                    <option value="received">Received Tasks</option>
                                    <option value="sent">Sent Tasks</option>
                                    <option value="copied">Copied Tasks</option>
                                </select>
                            </div>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={props.reload}><i className="fa fa-sync"></i> {t('Reload', props.auth.language)}</button>&nbsp;
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("add_tasks") ?
                                    <>
                                        <button type="button" className="btn btn-sm btn-outline-danger" title={t('Add New', props.auth.language)} data-toggle="modal" data-target="#add_task_modal" onClick={props.clear_modal_contents}>
                                            <i className="fas fa-plus"></i> {t('Add New', props.auth.language)}
                                        </button>&nbsp;
                                    </>
                                    : null
                                : null
                        }
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("all_tasks") ?
                                    <>
                                        <button type="button" className="btn btn-sm btn-outline-info" title={t('All Tasks', props.auth.language)} onClick={props.all_tasks}>
                                            <i className="fas fa-list"></i> {t('All Tasks', props.auth.language)}
                                        </button>&nbsp;
                                    </>
                                    : null
                                : null
                        }
                        <button type="button" className="btn btn-sm btn-outline-success" data-toggle="collapse" href="#collapseFilter"><i className="fa fa-filter"></i> </button>&nbsp;
                        <button type="button" className="btn btn-sm btn-outline-secondary" data-toggle="collapse" href="#collapsePrint"><i className="fa fa-print"></i> </button>
                        <button type="button" className="btn btn-sm btn-outline-default">
                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                <input type="search" className="form-control form-control-sm" placeholder={t('Type your keywords here', props.auth.language)} onChange={(event) => props.search_tasks(event.target.value)} />
                                <div className="input-group-append">
                                    <Link to="#" type="submit" disabled className="btn btn-sm btn-default">
                                        <i className="fa fa-search"></i>
                                    </Link>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-1"></div>
                        <div id="collapseFilter" className="collapse col-10" data-parent="#accordion">
                            <div className="row">
                                <small style={{ paddingTop: 10, paddingBottom: 10, textDecoration: 'underline' }}>Filters - Select option to filter by</small><br />
                            </div>
                            <div className="row col-12">
                                <button type="button" className="btn btn-sm btn-outline-success" data-toggle="modal" data-target="#search_tag_modal"><i className="fa fa-filter"></i> {t('Filter By Tags', props.auth.language)}</button>&nbsp;
                                <button type="button" className="btn btn-sm btn-outline-info" data-toggle="modal" data-target="#search_recipients_modal"><i className="fa fa-filter"></i> {t('Filter By Users', props.auth.language)}</button>&nbsp;
                                <button type="button" className="btn btn-sm btn-outline-danger" data-toggle="modal" data-target="#search_companies_modal"><i className="fa fa-filter"></i> {t('Filter By Companies', props.auth.language)}</button>&nbsp;
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-1"></div>
                        <div id="collapsePrint" className="collapse col-10" data-parent="#accordion">
                            <div className="row">
                                <small style={{ paddingTop: 10, paddingBottom: 10, textDecoration: 'underline' }}>Print Options - Select options to print by</small><br />
                            </div>
                            <div className="row col-12">
                                <div className="col-4">
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_sender" onChange={() => props.selected_filters({ order: 1, name: "Sender" })} />
                                            <label htmlFor="p_sender" className="custom-control-label">Sender</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_recipients" onChange={() => props.selected_filters({ order: 2, name: "Recipient" })} />
                                            <label htmlFor="p_recipients" className="custom-control-label">Recipients</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_copied" onChange={() => props.selected_filters({ order: 3, name: "Copied" })} />
                                            <label htmlFor="p_copied" className="custom-control-label">Copied</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_subject" onChange={() => props.selected_filters({ order: 4, name: "Subject" })} />
                                            <label htmlFor="p_subject" className="custom-control-label">Subject</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_task" onChange={() => props.selected_filters({ order: 5, name: "Task" })} />
                                            <label htmlFor="p_task" className="custom-control-label">Task</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_deadline" onChange={() => props.selected_filters({ order: 6, name: "Deadline" })} />
                                            <label htmlFor="p_deadline" className="custom-control-label">Deadline</label>
                                        </div>
                                        {/* <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_tags" onChange={() => props.selected_filters({order: 7, name: "Tags"})} />
                                            <label htmlFor="p_tags" className="custom-control-label">Tags</label>
                                        </div> */}
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_priority" onChange={() => props.selected_filters({ order: 8, name: "Priority" })} />
                                            <label htmlFor="p_priority" className="custom-control-label">Priority</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_reminders" onChange={() => props.selected_filters({ order: 9, name: "Reminders" })} />
                                            <label htmlFor="p_reminders" className="custom-control-label">Reminders</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_status" onChange={() => props.selected_filters({ order: 10, name: "Status" })} />
                                            <label htmlFor="p_status" className="custom-control-label">Task Status</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_date_created" onChange={() => props.selected_filters({ order: 11, name: "Date Created" })} />
                                            <label htmlFor="p_date_created" className="custom-control-label">Date Created</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_rating" onChange={() => props.selected_filters({ order: 12, name: "Rating" })} />
                                            <label htmlFor="p_rating" className="custom-control-label">Rating</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="p_comments" onChange={() => props.selected_filters({ order: 13, name: "Comments" })} />
                                            <label htmlFor="p_comments" className="custom-control-label">Comments</label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-3">
                                    <div className="form-group">
                                        <div className="custom-control">
                                            <select onChange={(event) => props.selected_filters({ id: "Group", name: event.target.value })}>
                                                <option value="all_groups">{t('All Groups', props.auth.language)}</option>
                                                {
                                                    props.c_state.contact_groups.map((data, i) => {
                                                        return (
                                                            <option key={i} value={data.group_id}>{data.group_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <br />
                                        <div className="custom-control">
                                            <select onChange={(event) => props.selected_filters({ id: "Company", name: event.target.value })}>
                                                <option value="all_companies">{t('All Companies', props.auth.language)}</option>
                                                {
                                                    props.c_state.contact_groups.map((data, i) => {
                                                        return (
                                                            <option key={i} value={data.group_id}>{data.group_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div align="center">
                                <button className="btn btn-sm btn-primary" onClick={() => props.print_tasks()}>Print</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content pb-3">
                {
                    props.isLoading === "true" ?
                        <div align="center">
                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your tasks. Please wait...', props.auth.language)}</p></span>
                        </div>
                        :
                        <div className="container-fluid h-100">
                            <div className="card card-row card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        {t('To Do', props.auth.language)} - <strong>{props.c_state.task_list_no.length}</strong>
                                    </h3>
                                    <div className="card-tools">
                                        {/* <button id="accordion" style={{ color: 'white' }} type="button" className="btn btn-tool" data-toggle="collapse" href="#collapseTodoOne">
                                            <i className="fas fa-list"></i>
                                        </button> */}
                                        <button id="accordion" style={{ color: 'white' }} type="button" className="btn btn-tool" data-toggle="collapse" href="#collapseTodoTwo">
                                            <i className="fa fa-sort"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="collapseTodoOne" className="collapse" data-parent="#accordion">
                                        <div className="row col-md-12 mb-1">
                                            <select className="form-control" onChange={(event) => props.switch_tab(event.target.value, 'no')}>
                                                <option value="">--Task Category--</option>
                                                <option value="all">All Tasks</option>
                                                <option value="received">Received Tasks</option>
                                                <option value="sent">Sent Tasks</option>
                                                <option value="copied">Copied Tasks</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="collapseTodoTwo" className="collapse" data-parent="#accordion">
                                        <div className="row col-md-12 mb-1">
                                            <select className="form-control" onChange={(event) => props.sort_tasks(event.target.value, 'no')}>
                                                <option value="">--Sort By--</option>
                                                <option value="date_created_asc">Date Created Ascending</option>
                                                <option value="date_created_desc">Date Created Descending</option>
                                                <option value="date_updated_asc">Date Updated Ascending</option>
                                                <option value="date_updated_desc">Date Updated Descending</option>
                                                <option value="priority_asc">Priority Ascending</option>
                                                <option value="priority_desc">Priority Descending</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        props.c_state.task_list_no.map((data, index) => {
                                            if (data.task.state) {
                                                return (<div key={index} className="card card-primary card-outline">
                                                    <div className="card-header">
                                                        <span style={{ fontSize: 12 }} className="card-title">{t('From', props.auth.language)}: {props.getContactName(data.task.user, "single")} | {t('To', props.auth.language)}: {props.getContactName(data.task.recipient, "single")} {data.task.cc && data.task.cc.includes('"' + props.auth.user.contact_id + '"') ? <small className="badge badge-danger">Copied</small> : ''}</span><br />
                                                        <Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_note_modal" onClick={() => props.set_modal_content_alt(data.task)}><h5 className="card-title">{data.task.text}</h5></Link>
                                                        <br />
                                                        <div className="card-tools">
                                                            <Link to="#" className="btn btn-tool btn-link">{data.task.priority === '1' ? '#high priority' : data.task.priority === '2' ? '#medium priority' : data.task.priority === '3' ? '#low priority' : ''}</Link>
                                                            <Link to="#" className="btn btn-tool">
                                                                {/* <i className="fas fa-tag"></i> */}
                                                                <small className="badge badge-info">
                                                                    {props.first_task_log(data.task_logs[0], data.task.text)}
                                                                </small>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            !data.task.cc ?
                                                                <>
                                                                    <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("yes", data.task.task_id)}><span className="text-success font-weight-bold">{t('done', props.auth.language)}</span></Link>
                                                                    <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("progress", data.task.task_id)}><span className="text-info font-weight-bold">{t('in progress', props.auth.language)}</span></Link>
                                                                </>
                                                                : data.task.cc !== "" && data.task.cc !== null ?
                                                                    !JSON.parse(data.task.cc.replace(/'/g, '"')).includes(props.auth.user.contact_id) ?
                                                                        <>
                                                                            <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("yes", data.task.task_id)}><span className="text-success font-weight-bold">{t('done', props.auth.language)}</span></Link>
                                                                            <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("progress", data.task.task_id)}><span className="text-info font-weight-bold">{t('in progress', props.auth.language)}</span></Link>
                                                                        </>
                                                                        : null
                                                                    : null
                                                        }
                                                        {/* {
                                                            data.task.deadline_date && data.task.deadline_time ?
                                                                <span className="text-danger" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.deadline_date} {data.task.deadline_time}</span>
                                                                : null
                                                        } */}
                                                        <span className="text-success text-left" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.date_created}</span>
                                                    </div>

                                                    <div className="card-footer">
                                                        {/* {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("task_logs") ? */}
                                                        <>
                                                            <Link to="#" title='task logs' data-toggle="modal" data-target="#task_logs_modal" onClick={() => props.task_logs(data.task_logs)}><i className="fas fa-list-alt text-info"> {data.task_logs.length}</i></Link>&nbsp;&nbsp;&nbsp;
                                                            {
                                                                data.attachments.length ?
                                                                    <><Link to="#" data-toggle="modal" data-target="#task_attachments_modal" onClick={() => props.selected_attachments(data.attachments)}><i className="fas fa-paperclip text-danger"></i></Link>&nbsp;&nbsp;&nbsp;</>
                                                                    : null
                                                            }
                                                            <Link className="text-secondary" to="#" data-toggle="modal" data-target="#task_details_modal" onClick={() => props.set_task_comment(data)}><i className="fas fa-comment"> {data.task.task_comments.length}</i></Link>&nbsp;&nbsp;&nbsp;
                                                            <Link className="text-danger" to="#" data-toggle="modal" data-target="#task_cc_modal" onClick={() => props.set_task_cc(data)}><button className="btn btn-xs btn-outline-danger"><strong>CC</strong></button></Link>&nbsp;&nbsp;&nbsp;
                                                        </>
                                                        {/* : null
                                                                : null
                                                        } */}
                                                        {
                                                            data.task.user + '' === props.auth.user.contact_id + '' ?
                                                                <span className="float-right">
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("edit_tasks") ?
                                                                                <>
                                                                                    <button type="button" className="btn btn-xs btn-outline-success" title={t('Edit Task', props.auth.language)} data-toggle="modal" data-target="#edit_task_modal" onClick={() => props.set_modal_content(data)}><i className="fa fa-edit"></i> {t('Edit', props.auth.language)}</button>&nbsp;&nbsp;
                                                                                </>
                                                                                : null
                                                                            : null
                                                                    }
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("delete_tasks") ?
                                                                                <button type="button" className="btn btn-xs btn-outline-danger" title={t('Delete Task', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} "${data.task.text}" ?`) ? props.delete_task(data.task.task_id) : ''}><i className="fa fa-trash"></i> {t('Delete', props.auth.language)}</button>
                                                                                : null
                                                                            : null
                                                                    }
                                                                </span>
                                                                : null
                                                        }
                                                        <span className="float-right">{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" width="30" height="30" /> : ''}</span>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <div className="card card-row card-default">
                                <div className="card-header bg-info">
                                    <h3 className="card-title">
                                        {t('In Progress', props.auth.language)} - <strong>{props.c_state.task_list_progress.length}</strong>
                                    </h3>
                                    <div className="card-tools">
                                        {/* <button id="accordion" style={{ color: 'white' }} type="button" className="btn btn-tool" data-toggle="collapse" href="#collapseProgressOne">
                                            <i className="fas fa-list"></i>
                                        </button> */}
                                        <button id="accordion" style={{ color: 'white' }} type="button" className="btn btn-tool" data-toggle="collapse" href="#collapseProgressTwo">
                                            <i className="fa fa-sort"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="collapseProgressOne" className="collapse" data-parent="#accordion">
                                        <div className="row col-md-12 mb-1">
                                            <select className="form-control" onChange={(event) => props.switch_tab(event.target.value, 'progress')}>
                                                <option value="">--Task Category--</option>
                                                <option value="all">All Tasks</option>
                                                <option value="received">Received Tasks</option>
                                                <option value="sent">Sent Tasks</option>
                                                <option value="copied">Copied Tasks</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="collapseProgressTwo" className="collapse" data-parent="#accordion">
                                        <div className="row col-md-12 mb-1">
                                            <select className="form-control" id='progress_sort' onChange={(event) => props.sort_tasks(event.target.value, 'progress')}>
                                                <option value="">--Sort By--</option>
                                                <option value="date_created_asc">Date Created Ascending</option>
                                                <option value="date_created_desc">Date Created Descending</option>
                                                <option value="date_updated_asc">Date Updated Ascending</option>
                                                <option value="date_updated_desc">Date Updated Descending</option>
                                                <option value="priority_asc">Priority Ascending</option>
                                                <option value="priority_desc">Priority Descending</option>
                                            </select>
                                        </div>
                                    </div>
                                    {props.c_state.task_list_progress.map((data, index) => {
                                        if (data.task.state) {
                                            return (<div key={index} className="card card-info card-outline">
                                                <div className="card-header">
                                                    <span style={{ fontSize: 12 }} className="card-title">{t('From', props.auth.language)}: {props.getContactName(data.task.user, "single")} | {t('To', props.auth.language)}: {props.getContactName(data.task.recipient, "single")} {data.task.cc && data.task.cc.includes('"' + props.auth.user.contact_id + '"') ? <small className="badge badge-danger">Copied</small> : ''}</span><br />
                                                    <Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_note_modal" onClick={() => props.set_modal_content_alt(data.task)}><h5 className="card-title">{data.task.text}</h5></Link>
                                                    <br />
                                                    <div className="card-tools">
                                                        <Link to="#" className="btn btn-tool btn-link">{data.task.priority === '1' ? '#high priority' : data.task.priority === '2' ? '#medium priority' : data.task.priority === '3' ? '#low priority' : ''}</Link>
                                                        <Link to="#" className="btn btn-tool">
                                                            {/* <i className="fas fa-tag"></i> */}
                                                            <small className="badge badge-info">
                                                                {props.first_task_log(data.task_logs[0])}
                                                            </small>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        !data.task.cc ?
                                                            <>
                                                                <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("yes", data.task.task_id)}><span className="text-success font-weight-bold">{t('done', props.auth.language)}</span></Link>
                                                                <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("no", data.task.task_id)}><span className="text-primary font-weight-bold">{t('to do', props.auth.language)}</span></Link>
                                                            </>
                                                            : data.task.cc !== "" && data.task.cc !== null ?
                                                                !JSON.parse(data.task.cc.replace(/'/g, '"')).includes(props.auth.user.contact_id) ?
                                                                    <>
                                                                        <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("yes", data.task.task_id)}><span className="text-success font-weight-bold">{t('done', props.auth.language)}</span></Link>
                                                                        <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("no", data.task.task_id)}><span className="text-primary font-weight-bold">{t('to do', props.auth.language)}</span></Link>
                                                                    </>
                                                                    : null
                                                                : null
                                                    }
                                                    <span className="text-success" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.date_created}</span>
                                                    {/* <span className="text-danger" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.deadline_date} {data.task.deadline_time}</span> */}
                                                </div>
                                                <div className="card-footer">
                                                    {/* {
                                                        props.auth.user ?
                                                            props.auth.user.privileges.includes("task_logs") ? */}
                                                    <>
                                                        <Link to="#" title='task logs' data-toggle="modal" data-target="#task_logs_modal" onClick={() => props.task_logs(data.task_logs)}><i className="fas fa-list-alt text-info"> {data.task_logs.length}</i></Link>&nbsp;&nbsp;&nbsp;
                                                        {
                                                            data.attachments.length ?
                                                                <><Link to="#" data-toggle="modal" data-target="#task_attachments_modal" onClick={() => props.selected_attachments(data.attachments)}><i className="fas fa-paperclip text-danger"></i></Link>&nbsp;&nbsp;&nbsp;</>
                                                                : null
                                                        }
                                                        <Link to="#" className="text-secondary" data-toggle="modal" data-target="#task_details_modal" onClick={() => props.set_task_comment(data)}><i className="fas fa-comment"> {data.task.task_comments.length}</i></Link>&nbsp;&nbsp;&nbsp;
                                                        <Link className="text-danger" to="#" data-toggle="modal" data-target="#task_cc_modal" onClick={() => props.set_task_cc(data)}><button className="btn btn-xs btn-outline-danger"><strong>CC</strong></button></Link>&nbsp;&nbsp;&nbsp;
                                                    </>
                                                    {/* : null
                                                            : null
                                                    } */}
                                                    {
                                                        data.task.user + '' === props.auth.user.contact_id + '' ?
                                                            <span className="float-right">
                                                                {
                                                                    props.auth.user ?
                                                                        props.auth.user.privileges.includes("edit_tasks") ?
                                                                            <>
                                                                                <button type="button" className="btn btn-xs btn-outline-success" data-toggle="modal" data-target="#edit_task_modal" onClick={() => props.set_modal_content(data)}><i className="fa fa-edit"></i> {t('Edit', props.auth.language)}</button>&nbsp;&nbsp;
                                                                            </>
                                                                            : null
                                                                        : null
                                                                }
                                                                {
                                                                    props.auth.user ?
                                                                        props.auth.user.privileges.includes("delete_tasks") ?
                                                                            <button type="button" className="btn btn-xs btn-outline-danger" title={t('Delete Task', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} "${data.task.text}" ?`) ? props.delete_task(data.task.task_id) : ''}><i className="fa fa-trash"></i> {t('Delete', props.auth.language)}</button>
                                                                            : null
                                                                        : null
                                                                }
                                                            </span>
                                                            : null
                                                    }
                                                    <span className="float-right">{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" width="30" height="30" /> : ''}</span>
                                                </div>

                                            </div>)
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="card card-row card-success">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        {t('Done', props.auth.language)} - <strong>{props.c_state.task_list_yes.length}</strong>
                                    </h3>
                                    <div className="card-tools">
                                        {/* <button id="accordion" style={{ color: 'white' }} type="button" className="btn btn-tool" data-toggle="collapse" href="#collapseDoneOne">
                                            <i className="fas fa-list"></i>
                                        </button> */}
                                        <button id="accordion" style={{ color: 'white' }} type="button" className="btn btn-tool" data-toggle="collapse" href="#collapseDoneTwo">
                                            <i className="fa fa-sort"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="collapseDoneOne" className="collapse" data-parent="#accordion">
                                        <div className="row col-md-12 mb-1">
                                            <select className="form-control" onChange={(event) => props.switch_tab(event.target.value, 'yes')}>
                                                <option value="">--Task Category--</option>
                                                <option value="all">All Tasks</option>
                                                <option value="received">Received Tasks</option>
                                                <option value="sent">Sent Tasks</option>
                                                <option value="copied">Copied Tasks</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="collapseDoneTwo" className="collapse" data-parent="#accordion">
                                        <div className="row col-md-12 mb-1">
                                            <select className="form-control" id='yes_sort' onChange={(event) => props.sort_tasks(event.target.value, 'yes')}>
                                                <option value="">--Sort By--</option>
                                                <option value="date_created_asc">Date Created Ascending</option>
                                                <option value="date_created_desc">Date Created Descending</option>
                                                <option value="date_updated_asc">Date Updated Ascending</option>
                                                <option value="date_updated_desc">Date Updated Descending</option>
                                                <option value="priority_asc">Priority Ascending</option>
                                                <option value="priority_desc">Priority Descending</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        props.c_state.task_list_yes.map((data, index) => {
                                            if (data.task.state) {
                                                return (<div key={index} className="card card-success card-outline">
                                                    <div className="card-header">
                                                        <span style={{ fontSize: 12 }} className="card-title">{t('From', props.auth.language)}: {props.getContactName(data.task.user, "single")} | {t('To', props.auth.language)}: {props.getContactName(data.task.recipient, "single")} {data.task.cc && data.task.cc.includes('"' + props.auth.user.contact_id + '"') ? <small className="badge badge-danger">Copied</small> : ''}</span><br />
                                                        <Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_note_modal" onClick={() => props.set_modal_content_alt(data.task)}><h5 className="card-title">{data.task.text} {data.task.rating ?
                                                            <small className="float-right btn-white btn-xs"><i className='fa fa-star'></i> {data.task.rating}</small> : null}</h5>
                                                        </Link>
                                                        <div className="card-tools">
                                                            <Link to="#" className="btn btn-tool btn-link">{data.task.priority === '1' ? '#high priority' : data.task.priority === '2' ? '#medium priority' : data.task.priority === '3' ? '#low priority' : ''}</Link>
                                                            <Link to="#" className="btn btn-tool">
                                                                {/* <i className="fas fa-tag"></i> */}
                                                                <small className="badge badge-info">
                                                                    {props.first_task_log(data.task_logs[0])}
                                                                </small>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            data.task.user + "" === props.auth.user.contact_id + "" && !data.task.rating ?
                                                                <Link to="#" className="float-right btn-white btn-xs" data-toggle="modal" data-target="#task_rating_modal" onClick={() => props.set_modal_content_alt(data.task)}><span className="text-secondary font-weight-bold">{t('confirm', props.auth.language)}</span></Link>
                                                                : null
                                                        }
                                                        {
                                                            !data.task.cc ?
                                                                <>
                                                                    <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("progress", data.task.task_id)}><span className="text-info font-weight-bold">{t('in progress', props.auth.language)}</span></Link>
                                                                    <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("no", data.task.task_id)}><span className="text-primary font-weight-bold">{t('to do', props.auth.language)}</span></Link>
                                                                </>
                                                                : data.task.cc !== "" && data.task.cc !== null ?
                                                                    !JSON.parse(data.task.cc.replace(/'/g, '"')).includes(props.auth.user.contact_id) ?
                                                                        <>
                                                                            <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("progress", data.task.task_id)}><span className="text-info font-weight-bold">{t('in progress', props.auth.language)}</span></Link>
                                                                            <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("no", data.task.task_id)}><span className="text-primary font-weight-bold">{t('to do', props.auth.language)}</span></Link>
                                                                        </>
                                                                        : null
                                                                    : null
                                                        }
                                                        <span className="text-success" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.date_created}</span>
                                                        {/* <span className="text-danger" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.deadline_date} {data.task.deadline_time}</span> */}
                                                    </div>
                                                    <div className="card-footer">
                                                        {/* {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("task_logs") ? */}
                                                        <>
                                                            <Link to="#" title='task logs' data-toggle="modal" data-target="#task_logs_modal" onClick={() => props.task_logs(data.task_logs)}><i className="fas fa-list-alt text-info"> {data.task_logs.length}</i></Link>&nbsp;&nbsp;&nbsp;
                                                            {
                                                                data.attachments.length ?
                                                                    <><Link to="#" data-toggle="modal" data-target="#task_attachments_modal" onClick={() => props.selected_attachments(data.attachments)}><i className="fas fa-paperclip text-danger"></i></Link>&nbsp;&nbsp;&nbsp;</>
                                                                    : null
                                                            }
                                                            <Link to="#" className="text-secondary" data-toggle="modal" data-target="#task_details_modal" onClick={() => props.set_task_comment(data)}><i className="fas fa-comment text-secondary"> {data.task.task_comments.length}</i></Link>&nbsp;&nbsp;&nbsp;
                                                            <Link className="text-danger" to="#" data-toggle="modal" data-target="#task_cc_modal" onClick={() => props.set_task_cc(data)}><button className="btn btn-xs btn-outline-danger"><strong>CC</strong></button></Link>&nbsp;&nbsp;&nbsp;
                                                        </>
                                                        {/* : null
                                                                : null
                                                        } */}
                                                        {
                                                            data.task.user + '' === props.auth.user.contact_id + '' ?
                                                                <span className="float-right">
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("edit_tasks") ?
                                                                                <>
                                                                                    <button type="button" className="btn btn-xs btn-outline-success" data-toggle="modal" data-target="#edit_task_modal" onClick={() => props.set_modal_content(data)}><i className="fa fa-edit"></i> {t('Edit', props.auth.language)}</button>&nbsp;&nbsp;
                                                                                </>
                                                                                : null
                                                                            : null
                                                                    }
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("delete_tasks") ?
                                                                                <button type="button" className="btn btn-xs btn-outline-danger" title={t('Delete Task', props.auth.language)} onClick={() => window.confirm(`${t('Are you sure you want to delete', props.auth.language)} "${data.task.text}" ?`) ? props.delete_task(data.task.task_id) : ''}><i className="fa fa-trash"></i> {t('Delete', props.auth.language)}</button>
                                                                                : null
                                                                            : null
                                                                    }
                                                                </span>
                                                                : null
                                                        }
                                                        <span className="float-right">{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" width="30" height="30" /> : ''}</span>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                }
            </section>

            {/* Modals */}

            <div className="modal fade" id="search_tag_modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Select Tags', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group col-12">
                                <Select
                                    options={props.c_state.tags_dropdown}
                                    name="tags"
                                    // value={props.c_state.task_details.tags ? props.c_state.task_details.tags : []}
                                    id="custom_select"
                                    value={typeof props.c_state.task_details.search_params === "string" ? JSON.parse(props.c_state.task_details.search_params.replace(/'/g, '"')) : props.c_state.task_details.search_params}
                                    onChange={(value) => props.handleChange({ "field": "search_params", "value": value }, 'task')}
                                    isMulti style={{ width: '100%' }}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_params_modal">{t('Close', props.auth.language)}</button>
                            <button type="button" className="btn btn-primary" onClick={props.search_task_by_tags}>{t('Search', props.auth.language)}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="search_recipients_modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Select Users', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group col-12">
                                <label htmlFor="action_type">{t('Select Category', props.auth.language)}</label>
                                <select className="form-control" onChange={(event) => props.handleChange({ "field": "search_category", "value": event.target.value }, 'task')} value={props.c_state.task_details.search_category ? props.c_state.task_details.search_category : ""}>
                                    <option value=""></option>
                                    <option value="From">{t('From', props.auth.language)}</option>
                                    <option value="To">{t('To', props.auth.language)}</option>
                                </select>
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="action_type">{t('Select Users', props.auth.language)}</label>
                                <Select
                                    options={props.c_state.contact_list}
                                    name="search_users"
                                    id="custom_select"
                                    value={typeof props.c_state.task_details.search_users === "string" ? JSON.parse(props.c_state.task_details.search_users.replace(/'/g, '"')) : props.c_state.task_details.search_users}
                                    onChange={(value) => props.handleChange({ "field": "search_users", "value": value }, 'task')}
                                    isMulti
                                    style={{ width: '100%' }}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_recipients_modal">{t('Close', props.auth.language)}</button>
                            <button type="button" className="btn btn-primary" onClick={props.search_task_by_users}>{t('Search', props.auth.language)}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="search_companies_modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Select Company', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group col-12">
                                <label htmlFor="action_type">{t('Select Company', props.auth.language)}</label>
                                <select className="form-control" name="search_companies" value={props.c_state.task_details.company_name} onChange={(event) => props.handleChange({ "field": "search_company", "value": event.target.value }, 'task')} >
                                    <option value="">--{t('Select', props.auth.language)}--</option>
                                    {
                                        // eslint-disable-next-line array-callback-return
                                        props.c_state.company_list?.map((data, i) => {
                                            return (
                                                <option key={i} value={data.company_id}>{data.name}</option>
                                            )
                                        })
                                    }

                                </select>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_companies_modal">{t('Close', props.auth.language)}</button>
                            <button type="button" className="btn btn-primary" onClick={props.search_task_by_company}>{t('Search', props.auth.language)}</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="add_task_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Add New Task', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="recipients">{t('For', props.auth.language)} *</label>
                                    <Select options={props.c_state.contact_list} name="recipients" value={props.c_state.task_details.recipients ? props.c_state.task_details.recipients : []} id="custom_select" onChange={(value) => props.handleChange({ "field": "recipients", "value": value }, 'task')} isMulti style={{ width: '100%' }} className="basic-multi-select" classNamePrefix="select" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="cc">CC </label>
                                    <Select options={props.c_state.contact_list} name="cc" value={props.c_state.task_details.cc ? props.c_state.task_details.cc : []} id="custom_select" onChange={(value) => props.handleChange({ "field": "cc", "value": value }, 'task')} isMulti style={{ width: '100%' }} className="basic-multi-select" classNamePrefix="select" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="text">{t('Subject', props.auth.language)} *</label>
                                    <input type="text" onChange={(event) => props.handleChange({ "field": "text", "value": event.target.value }, 'task')} className="form-control" name="text" placeholder={t('Subject', props.auth.language)} value={props.c_state.task_details.text ? props.c_state.task_details.text : ''} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="note">{t('Task', props.auth.language)} *</label>
                                    {/* <textarea style={{ resize: 'none' }} type="text" onChange={(event) => props.handleChange({ "field": "note", "value": event.target.value }, 'task')} className="form-control" name="note" placeholder={t('Task', props.auth.language)} value={props.c_state.task_details.note ? props.c_state.task_details.note : ''}>{props.c_state.task_details.note ? props.c_state.task_details.note : ''}</textarea> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{ placeholder: t('Type task details here...', props.auth.language) }}
                                        data={props.c_state.task_details.note ? props.c_state.task_details.note : ''}
                                        onReady={editor => {
                                            if (editor) {
                                                // You can store the "editor" and use when it is needed.
                                                editor.ui.view.editable.element.style.minHeight = "150px";

                                                if (props.c_state.task_details.note) {
                                                    editor.setData(props.c_state.task_details.note);
                                                    // console.log( 'Editor1 is ready to use!', editor );
                                                }
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            if (editor) {
                                                const data = editor.getData();
                                                // console.log({ event, editor, data });
                                                props.handleChange({ "field": "note", "value": data }, 'task')
                                            }
                                        }}
                                        onBlur={(event, editor) => {
                                            // console.log('Blur.', editor);
                                            if (editor)
                                                editor.ui.view.editable.element.style.minHeight = "150px";
                                        }}
                                        onFocus={(event, editor) => {
                                            // console.log('Focus.', editor);
                                            if (editor)
                                                editor.ui.view.editable.element.style.minHeight = "150px";

                                            if (props.c_state.task_details.note) {
                                                editor.setData(props.c_state.task_details.note);
                                                // console.log( 'Editor1 is ready to use!', editor );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_date">{t('Deadline Date', props.auth.language)} </label>
                                    <TextField
                                        id="date"
                                        type="date"
                                        // defaultValue={props.c_state.task_details.deadline_date}
                                        value={props.c_state.task_details.deadline_date ? props.c_state.task_details.deadline_date : ''}
                                        className="form-control"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => props.handleChange({ "field": "deadline_date", "value": event.target.value }, 'task')}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_time">{t('Deadline Time', props.auth.language)} </label>
                                    <TextField
                                        id="time"
                                        type="time"
                                        // defaultValue={props.c_state.task_details.deadline_time}
                                        value={props.c_state.task_details.deadline_time ? props.c_state.task_details.deadline_time : '00:00'}
                                        className="form-control"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={(event) => props.handleChange({ "field": "deadline_time", "value": event.target.value }, 'task')}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        value={props.c_state.task_details.tags ? props.c_state.task_details.tags : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'task')}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="priority">{t('Priority', props.auth.language)} </label>
                                    <select className="form-control" name="priority" onChange={(event) => props.handleChange({ "field": "priority", "value": event.target.value }, 'task')} value={props.c_state.task_details.priority ? props.c_state.task_details.priority : '2'}>
                                        <option value="" disabled>--{t('Select', props.auth.language)}--</option>
                                        <option value="3">{t('Low Priority', props.auth.language)}</option>
                                        <option value="2">{t('Medium Priority', props.auth.language)}</option>
                                        <option value="1">{t('High Priority', props.auth.language)}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-5">
                                    <label htmlFor="reminder_date">{t('Reminder Date', props.auth.language)} </label>
                                </div>
                                <div className="form-group col-5">
                                    <label htmlFor="reminder_time">{t('Reminder Time', props.auth.language)} </label>
                                </div>
                            </div>

                            {props.c_state.reminders.map((data, index) => {
                                return (
                                    <div className="row" key={index}>
                                        <div className="form-group col-5">
                                            <TextField
                                                id="date"
                                                type="date"
                                                // defaultValue={data.r_date}
                                                value={data.r_date ? data.r_date : ''}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => props.set_reminder_date(event.target.value, index)}
                                            />
                                        </div>
                                        <div className="form-group col-5">
                                            <TextField
                                                id="time"
                                                type="time"
                                                // defaultValue={data.r_time}
                                                value={data.r_time ? data.r_time : '00:00'}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                                onChange={(event) => props.set_reminder_time(event.target.value, index)}
                                            />
                                        </div>
                                        {index === 0 ?
                                            <div className="form-group col-2">
                                                <button className="btn btn-sm btn-outline-info" onClick={props.add_reminder}><i className="fa fa-plus"></i> {t('Add New', props.auth.language)}</button>
                                            </div>

                                            :
                                            <div className="form-group col-2">
                                                <button className="btn btn-sm btn-outline-danger" onClick={() => props.remove_reminder(index)}><i className="fa fa-times"></i> {t('Remove', props.auth.language)}</button>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                            <div className="row">
                                <div className="form-group">
                                    <div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal">
                                        <i className="fas fa-paperclip"></i> {t('Add Attachments', props.auth.language)}
                                    </div>
                                </div>
                                {
                                    props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                        <div className="form-group">
                                            <div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
                                                <i className="fas fa-times"></i> {t('Clear Attachments', props.auth.language)}
                                                {/* <input type="button" onClick={props.clear_attachments} /> */}
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="row">
                                {
                                    props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                        props.c_state.a_type === "tasks" ?
                                            <div className="table table-striped files" id="previews">
                                                {/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
                                                {
                                                    props.c_state.my_computer_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.file_name}, </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    props.c_state.my_documents_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    props.c_state.files_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : null
                                        : null
                                }
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_task_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                            <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.add_task}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="edit_task_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Edit Task', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="recipients">{t('For', props.auth.language)} *</label>
                                    <Select
                                        options={props.c_state.contact_list}
                                        name="recipients"
                                        value={typeof props.c_state.task_details.recipients === "string" ? JSON.parse(props.c_state.task_details.recipients.replace(/'/g, '"')) : props.c_state.task_details.recipients}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "recipients", "value": value }, 'task')}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="cc">CC</label>
                                    <Select
                                        options={props.c_state.contact_list}
                                        name="cc"
                                        value={props.c_state.task_details.ccs ? typeof props.c_state.task_details.ccs === "string" ? JSON.parse(props.c_state.task_details.ccs.replace(/'/g, '"')) : props.c_state.task_details.ccs : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "ccs", "value": value }, 'task')}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="text">{t('Subject', props.auth.language)} *</label>
                                    <input type="text" onChange={(event) => props.handleChange({ "field": "text", "value": event.target.value }, 'task')} className="form-control" name="text" placeholder={t('Subject', props.auth.language)} value={props.c_state.task_details ? props.c_state.task_details.text : ''} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="note">{t('Task', props.auth.language)} *</label>
                                    {/* <textarea style={{ resize: 'none' }} type="text" onChange={(event) => props.handleChange({ "field": "note", "value": event.target.value }, 'task')} className="form-control" name="note" placeholder={t('Task', props.auth.language)} value={props.c_state.task_details ? props.c_state.task_details.note : ''}></textarea> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{ placeholder: t('Type task details here...', props.auth.language) }}
                                        data={props.c_state.task_details.note ? props.c_state.task_details.note : ''}
                                        onReady={editor => {
                                            if (editor) {
                                                // You can store the "editor" and use when it is needed.
                                                editor.ui.view.editable.element.style.minHeight = "150px";

                                                if (props.c_state.task_details.note) {
                                                    editor.setData(props.c_state.task_details.note);
                                                    // console.log( 'Editor1 is ready to use!', editor );
                                                }
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            if (editor) {
                                                const data = editor.getData();
                                                // console.log({ event, editor, data });
                                                props.handleChange({ "field": "note", "value": data }, 'task')
                                            }
                                        }}
                                        onBlur={(event, editor) => {
                                            // console.log('Blur.', editor);
                                            if (editor)
                                                editor.ui.view.editable.element.style.minHeight = "150px";
                                        }}
                                        onFocus={(event, editor) => {
                                            // console.log('Focus.', editor);
                                            if (editor)
                                                editor.ui.view.editable.element.style.minHeight = "150px";

                                            if (props.c_state.task_details.note) {
                                                editor.setData(props.c_state.task_details.note);
                                                // console.log( 'Editor1 is ready to use!', editor );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_date">{t('Deadline Date', props.auth.language)} </label>
                                    <TextField
                                        id="date"
                                        type="date"
                                        value={props.c_state.task_details ? props.c_state.task_details.deadline_date : ''}
                                        className="form-control"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => props.handleChange({ "field": "deadline_date", "value": event.target.value }, 'task')}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_time">{t('Deadline Time', props.auth.language)} </label>
                                    <TextField
                                        id="time"
                                        type="time"
                                        value={props.c_state.task_details ? props.c_state.task_details.deadline_time : ''}
                                        className="form-control"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={(event) => props.handleChange({ "field": "deadline_time", "value": event.target.value }, 'task')}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="tags">{t('Tags', props.auth.language)}</label>
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        value={props.c_state.task_details.tags && typeof props.c_state.task_details.tags === "string" ? JSON.parse(props.c_state.task_details.tags.replace(/'/g, '"')) : props.c_state.task_details.tags}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'task')}
                                        isMulti style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="priority">{t('Priority', props.auth.language)} </label>
                                    <select className="form-control" name="priority" onChange={(event) => props.handleChange({ "field": "priority", "value": event.target.value }, 'task')} value={props.c_state.task_details ? props.c_state.task_details.priority : ''}>
                                        <option value="" disabled>--{t('Select', props.auth.language)}--</option>
                                        <option value="3">{t('Low Priority', props.auth.language)}</option>
                                        <option value="2">{t('Medium Priority', props.auth.language)}</option>
                                        <option value="1">{t('High Priority', props.auth.language)}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-5">
                                    <label htmlFor="reminder_date">{t('Reminder Date', props.auth.language)} </label>
                                </div>
                                <div className="form-group col-5">
                                    <label htmlFor="reminder_time">{t('Reminder Time', props.auth.language)} </label>
                                </div>
                            </div>

                            {props.c_state.reminders.map((data, index) => {
                                return (
                                    <div className="row" key={index}>
                                        <div className="form-group col-5">
                                            <TextField
                                                id="date"
                                                type="date"
                                                value={data.r_date}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => props.set_reminder_date(event.target.value, index)}
                                            />
                                        </div>
                                        <div className="form-group col-5">
                                            <TextField
                                                id="time"
                                                type="time"
                                                value={data.r_time}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                                onChange={(event) => props.set_reminder_time(event.target.value, index)}
                                            />
                                        </div>
                                        {index === 0 ?
                                            <div className="form-group col-2">
                                                <button className="btn btn-sm btn-outline-info" onClick={props.add_reminder}><i className="fa fa-plus"></i> {t('Add New', props.auth.language)}</button>
                                            </div>

                                            :
                                            <div className="form-group col-2">
                                                <button className="btn btn-sm btn-outline-danger" onClick={() => props.remove_reminder(index)}><i className="fa fa-times"></i> {t('Remove', props.auth.language)}</button>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                            <div className="row">
                                <div className="form-group">
                                    <div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal">
                                        <i className="fas fa-paperclip"></i> {t('Add Attachments', props.auth.language)}
                                    </div>
                                </div>
                                {
                                    props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                        <div className="form-group">
                                            <div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
                                                <i className="fas fa-times"></i> {t('Clear Attachments', props.auth.language)}
                                                {/* <input type="button" onClick={props.clear_attachments} /> */}
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="row">
                                {
                                    props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                        props.c_state.a_type === "tasks" ?
                                            <div className="table table-striped files" id="previews">
                                                {/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
                                                {
                                                    props.c_state.my_computer_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.file_name}, </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    props.c_state.my_documents_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    props.c_state.files_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : null
                                        : null
                                }
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_task_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                            <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_task}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="view_note_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Task Details', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="card card-primary card-outline">
                                <div className="card-body">
                                    <h5 className="card-title mb-2">{props.c_state.task_info.text}</h5><br /><br />
                                    <div dangerouslySetInnerHTML={{ __html: props.c_state.task_info.note }} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal">{t('Close', props.auth.language)}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="task_logs_modal" style={{ overflow: "scroll" }}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Task Logs', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="card card-primary card-outline">
                                <div className="card-body">
                                    <ul className="todo-list ui-sortable" data-widget="todo-list">
                                        {
                                            props.c_state.task_logs.map((data, index) => {
                                                random = Math.floor(Math.random() * badges.length);
                                                return (
                                                    <li className="" key={index}>
                                                        <span className="text">{`${props.getContactName(data.moved_by, "single")} moved this task to "${data.state === 'no' ? 'To Do' : data.state === 'progress' ? 'In Progress' : data.state === 'yes' ? 'Completed' : data.state}"`} <strong>{`(${props.get_day_difference(index, data)})`}</strong></span>
                                                        <small className={badges[random]}><i className="far fa-clock"></i> {data.date_created}</small>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal">{t('Close', props.auth.language)}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="task_attachments_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Attachments', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                                {
                                    props.c_state.selected_attachments.map((data, index) => {
                                        return (
                                            <li key={index}>
                                                {/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

                                                <div className="mailbox-attachment-info col-12">
                                                    <Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'attachment')}><i className="fas fa-paperclip"></i> {data.file_name}{data.ext}</Link>
                                                    <span className="mailbox-attachment-size clearfix mt-1">
                                                        <Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', data.aid, data.ext, data.file_name)}><i className="fas fa-download"></i></Link>
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_task_attachments_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="task_details_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('View Task', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="recipients">{t('For', props.auth.language)} *</label>
                                    <Select
                                        options={props.c_state.contact_list}
                                        name="recipients"
                                        value={typeof props.c_state.task_details.recipients === "string" ? JSON.parse(props.c_state.task_details.recipients.replace(/'/g, '"')) : props.c_state.task_details.recipients}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "recipients", "value": value }, 'task')}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="cc">CC *</label>
                                    <Select
                                        options={props.c_state.contact_list}
                                        name="cc"
                                        value={props.c_state.task_details.ccs ? typeof props.c_state.task_details.ccs === "string" ? JSON.parse(props.c_state.task_details.ccs.replace(/'/g, '"')) : props.c_state.task_details.ccs : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "ccs", "value": value }, 'task')}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isDisabled={true}
                                    />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="text">{t('Subject', props.auth.language)}</label><br />
                                    <span>{props.c_state.task_details ? props.c_state.task_details.text : ''}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="note">{t('Task', props.auth.language)} </label><br />
                                    <div dangerouslySetInnerHTML={{ __html: props.c_state.task_details ? props.c_state.task_details.note : '' }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_date">{t('Deadline', props.auth.language)}</label><br />
                                    <span>{props.c_state.task_details ? props.c_state.task_details.deadline_date : ''} {props.c_state.task_details ? props.c_state.task_details.deadline_time : ''}</span>
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="priority">{t('Priority', props.auth.language)}</label><br />
                                    <span>{props.c_state.task_details ? props.c_state.task_details.priority === '3' ? 'Low Priority' : props.c_state.task_details.priority === '2' ? 'Medium Priority' : props.c_state.task_details.priority === '1' ? 'High Priority' : '' : ''}</span>
                                </div>
                            </div>
                            {/* <!-- DIRECT CHAT --> */}
                            <div className="card direct-chat direct-chat-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Comments</h3>

                                    {/* <div className="card-tools">
                                        <span title="3 New Messages" className="badge badge-primary">3</span>
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                            <i className="fas fa-minus"></i>
                                        </button>
                                        <button type="button" className="btn btn-tool" title="Contacts" data-widget="chat-pane-toggle">
                                            <i className="fas fa-comments"></i>
                                        </button>
                                        <button type="button" className="btn btn-tool" data-card-widget="remove">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div> */}
                                </div>
                                {/* <!-- /.card-header --> */}
                                <div className="card-body">
                                    <div className="direct-chat-messages">
                                        {
                                            props.c_state.task_comments.map((comment, index) => {
                                                return (
                                                    <div key={index} className={`direct-chat-msg ${props.auth.user.contact_id + "" !== comment.user ? 'right' : ''}`}>
                                                        <div className="direct-chat-infos clearfix">
                                                            <span className="direct-chat-name float-left">{props.getContactName(comment.user, "single")}</span>
                                                            <span className="direct-chat-timestamp float-right">{comment.date_created}</span>
                                                        </div>
                                                        <img className="direct-chat-img" src={props.getContactDetails(comment.user).photo ? props.getContactDetails(comment.user).photo !== "" ? types.SERVER_URL + 'static/contacts/' + props.getContactDetails(comment.user).photo : "static/assets/dist/img/user1-128x128.jpg" : "static/assets/dist/img/user1-128x128.jpg"} alt="user" />
                                                        <div className="direct-chat-text">
                                                            <div dangerouslySetInnerHTML={{ __html: comment.comment }} />
                                                            {/* Comment Attachments */}
                                                            {
                                                                props.filter_comment_attachment(comment.comment_id).length ?
                                                                    <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                                                                        {
                                                                            props.filter_comment_attachment(comment.comment_id).map((dd, ix) => {
                                                                                return (
                                                                                    <li key={ix}>
                                                                                        <div className="mailbox-attachment-info col-12">
                                                                                            <Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(dd, 'attachment')}><i className="fas fa-paperclip"></i> {dd.file_name}{dd.ext}</Link>
                                                                                            <span className="mailbox-attachment-size clearfix mt-1">
                                                                                                <Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', dd.aid, dd.ext, dd.file_name)}><i className="fas fa-download"></i></Link>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="card-footer">
                                    {/* <div className="input-group"> */}
                                    {/* <input type="text" onChange={(event) => props.handleChange({ "field": "comment", "value": event.target.value }, 'task_comment')} placeholder="Type Message ..." className="form-control" value={props.c_state.task_comment} /> */}

                                    <div className="row col-12">
                                        <div className="col-md-10">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{ placeholder: t('Type Message ...', props.auth.language) }}
                                                data={props.c_state.task_comment ? props.c_state.task_comment : ''}
                                                onReady={editor => {
                                                    if (editor) {
                                                        // You can store the "editor" and use when it is needed.
                                                        editor.ui.view.editable.element.style.minHeight = "30px";

                                                        if (props.c_state.task_comment) {
                                                            editor.setData(props.c_state.task_comment);
                                                            // console.log( 'Editor1 is ready to use!', editor );
                                                        }
                                                    }
                                                }}
                                                onChange={(event, editor) => {
                                                    if (editor) {
                                                        const data = editor.getData();
                                                        // console.log({ event, editor, data });
                                                        props.handleChange({ "field": "comment", "value": data }, 'task_comment')
                                                    }
                                                }}
                                                onBlur={(event, editor) => {
                                                    // console.log('Blur.', editor);
                                                    if (editor)
                                                        editor.ui.view.editable.element.style.minHeight = "30px";
                                                }}
                                                onFocus={(event, editor) => {
                                                    // console.log('Focus.', editor);
                                                    if (editor)
                                                        editor.ui.view.editable.element.style.minHeight = "30px";

                                                    if (props.c_state.task_comment) {
                                                        editor.setData(props.c_state.task_comment);
                                                        // console.log( 'Editor1 is ready to use!', editor );
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <span className="input-group-append">
                                                <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                                                <button type="button" className="btn btn-primary" onClick={props.send_comment} disabled={props.isLoading === "true" ? true : false}>Send</button>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row col-12 mt-3">
                                        {
                                            props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                                props.c_state.a_type === "tasks" ?
                                                    <div className="table table-striped files" id="previews">
                                                        {/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
                                                        {
                                                            props.c_state.my_computer_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.file_name}, </span>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            props.c_state.my_documents_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            props.c_state.files_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : null
                                                : null
                                        }
                                    </div>

                                    <div className="row col-12">
                                        <div className="form-group">
                                            <div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal">
                                                <i className="fas fa-paperclip"></i> {t('Add Attachments', props.auth.language)}
                                            </div>
                                        </div>
                                        {
                                            props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                                <div className="form-group">
                                                    <div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
                                                        <i className="fas fa-times"></i> {t('Clear Attachments', props.auth.language)}
                                                        {/* <input type="button" onClick={props.clear_attachments} /> */}
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_task_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="task_cc_modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Share Task', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group col-12">
                                <p><strong>Task: </strong>{props.c_state.task_cc.text ? props.c_state.task_cc.text : ''}</p>
                                <label htmlFor="cc">{t('Who do you want to share this task with?', props.auth.language)}</label>
                                <Select
                                    options={props.c_state.contact_list}
                                    name="search_users"
                                    id="custom_select"
                                    value={props.c_state.task_details.task_cc_users ? props.c_state.task_details.task_cc_users : []}
                                    onChange={(value) => props.handleChange({ "field": "task_cc_users", "value": value }, 'task')}
                                    isMulti
                                    style={{ width: '100%' }}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_task_cc_modal_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.send_task_cc}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="task_rating_modal" style={{ overflow: "scroll" }}>
                <div className="modal-dialog modal-sm">
                    <div className="modal-content" align="center">
                        {/* <div className="modal-header">
                            <h4 className="modal-title">{t('Rate Task', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> */}
                        <div className="modal-body">
                            <h5>{props.c_state.task_info.text}</h5><br />
                            <Stack spacing={1} style={{ marginLeft: 60 }}>
                                <Rating name="size-large" defaultValue={0} onChange={(event) => props.confirm_task_rating(event.target.value)} size="large" />
                            </Stack>
                            <small>Rate this task</small>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_task_rating_modal">{t('Close', props.auth.language)}</button>
                        </div>
                    </div>
                </div>
            </div>

            <AddAttachmentModal
                handleChange={props.handleChange}
                templates={props.templates}
                attachments={props.attachments}
                set_attachment_info={props.set_attachment_info}
                set_template_modal_content={props.set_template_modal_content}
                select_my_document={props.select_my_document}
                select_template={props.select_template}
                select_files={props.select_files}
                enter_folder={props.enter_folder}
                click_breadcrumbs={props.click_breadcrumbs}
                c_state={props.c_state}
                use_template={props.use_template}
                auth={props.auth}
                save_attachments={props.save_attachments}
                search_attachment={props.search_attachment}
            />

            <ViewAttachmentModal
                c_state={props.c_state}
                clear_attachment_info={props.clear_attachment_info}
                file_type={props.file_type}
                download={props.download}
                auth={props.auth}
            />

            <PrintTasks
                c_state={props.c_state}
                auth={props.auth}
                get_contact_name={props.getContactName}
            />
        </div>
    )
}

export default Task
