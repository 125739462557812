import React, { Component } from 'react'
import NewTemplate from './NewTemplate';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverCreateTemplate, serverUploadTemplate, serverSavePlaceholders, serverLoad } from '../../actions/TemplatesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import html2canvas from 'html2canvas'
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class NewTemplateContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template_details: [],
            placeholder_details: [],
            placeholder_table: [],
            tags_dropdown: [],
            placeholder_index: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.createTemplate = this.createTemplate.bind(this);
        this.addPlaceholder = this.addPlaceholder.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.savePlaceholders = this.savePlaceholders.bind(this);
        this.deletePlaceholder = this.deletePlaceholder.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.uploadTemplate = this.uploadTemplate.bind(this);
        // this.takeScreenshot = this.takeScreenshot.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        this.props.clearData("false");

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.auth.clear_data !== this.props.auth.clear_data) {

            if (this.props.auth.clear_data === "true") {

                $("#dismiss_create_template_modal").click();
                $("#dismiss_add_placeholder_modal").click();
                $("#dismiss_upload_template_modal").click();

                this.clearModalContents();

                this.props.clearData("false");
            }
        }

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }
    }

    handleChange = (data, type) => {
        switch (type) {
            case 'template':
                this.setState(prevState => ({
                    template_details: {                   // object that we want to update
                        ...prevState.template_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;

            case 'placeholder':
                this.setState(prevState => ({
                    placeholder_details: {                   // object that we want to update
                        ...prevState.placeholder_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;

            default:
                break;
        }
    }

    setModalContent = (data, index) => {

        this.setState({
            placeholder_details: data,
            placeholder_index: index
        });

        return;
    }

    clearModalContents = () => {
        this.setState({ template_details: {}, placeholder_details: {} });

        return;
    }

    createTemplate = () => {
        // check entry
        if (!this.state.template_details.template_name || !this.state.template_details.document_type || !this.state.template_details.category) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverLoad("true");

        this.props.serverCreateTemplate(this.state.template_details, this.props.auth.user.token)
    }

    uploadTemplate = () => {
        // check entry
        if (!this.state.template_details.template_name || !this.state.template_details.document_type || !this.state.template_details.category
            || !this.state.template_details.template_file) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        // Create an object of formData
        const formData = new FormData();

        formData.append("template_file", this.state.template_details.template_file);

        let item = this.state.template_details;

        for (var key in item) {
            if (key === "tags")
                formData.append(key, JSON.stringify(item[key]));
            else
                formData.append(key, item[key]);
        }

        this.props.serverLoad("true");

        this.props.serverUploadTemplate(formData, this.props.auth.user.token);

    }

    addPlaceholder = () => {

        if (!this.state.placeholder_details.placeholder || !this.state.placeholder_details.field_type || !this.state.placeholder_details.question) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (this.state.placeholder_details.field_type === "drop_down" && !this.state.placeholder_details.options) {
            toast.error(t("Please specify drop down options", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.setState({ placeholder_table: [...this.state.placeholder_table, this.state.placeholder_details] });

        $("#dismiss_add_placeholder_modal").click();

        this.clearModalContents();
    }

    deletePlaceholder = (marker) => {
        // eslint-disable-next-line array-callback-return
        let placeholder_table = this.state.placeholder_table.filter((data, index) => { // loop through state.todo
            if (index !== marker) { // check what data to update using key
                return data
            }
        });

        this.setState({ placeholder_table: placeholder_table });

        return;
    }

    editPlaceholder = () => {
        let p_table = this.state.placeholder_table.map((data, index) => { // loop through state.todo
            if (index === this.state.placeholder_index) { // check what data to update using key
                data = { ...data, ...this.state.placeholder_details } // replace resulting data with new payload
            }
            return data
        });

        this.setState({ placeholder_table: p_table });

        $("#dismiss_edit_placeholder_modal").click();
    }

    savePlaceholders = () => {
        let placeholder_table = this.state.placeholder_table;
        let table = placeholder_table.map((data, index) => { // loop through state.todo
            data.placeholder = `${data.placeholder}`
            return data
        });

        this.props.serverSavePlaceholders(table, this.props.templates.tid, this.props.auth.user.token)
    }

    // takeScreenshot = () => {
    //     console.log("hello world::id_viewer_overlay");
    //     html2canvas(window.document.getElementById('the_canvas')).then(canvas => {
    //         console.log(canvas.toDataURL());
    //         window.document.getElementById('screen_shot_img').src = canvas.toDataURL()
    //     })
    // }


    render() {
        return (
            <>
                <Main>
                    <NewTemplate
                        c_state={{ ...this.state }}
                        isLoading={this.props.templates.isLoading}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        create_template={this.createTemplate}
                        upload_template={this.uploadTemplate}
                        set_modal_content={this.setModalContent}
                        clear_modal_contents={this.clearModalContents}
                        docs_config={this.props.templates.docs_config}
                        add_placeholder={this.addPlaceholder}
                        save_placeholders={this.savePlaceholders}
                        delete_placeholder={this.deletePlaceholder}
                        edit_placeholder={this.editPlaceholder}
                        // take_screenshot = {this.takeScreenshot}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        templates: { ...state.templates }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverCreateTemplate,
        serverSavePlaceholders,
        serverUploadTemplate
    }
)(NewTemplateContainer)
