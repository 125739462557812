const en = {
    'Dashboard' : 'Dashboard',
    'TASKS' : 'TASKS',
    'Task Manager': 'Task Manager',
    'PERSONAL': 'PERSONAL',
    'My Profile': 'My Profile',
    'Tags': 'Tags',
    'My Contacts': 'My Contacts',
    'MAILINGS': 'MAILINGS',
    'Routing': 'Routing',
    'My Emails': 'My Emails',
    'Correspondence': 'Correspondence',
    'TEMPLATING': 'TEMPLATING',
    'New Template': 'New Template',
    'View Templates': 'View Templates',
    'Documents': 'Documents',
    'MISCELLANEOUS': 'MISCELLANEOUS',
    'Conversion Service': 'Conversion Service',
    'Privileges & Access Ctrl': 'Privileges & Access Ctrl',
    'User Roles': 'User Roles',
    'Help': 'Help',
    'Home': 'Home',
    'We are loading some stats. Please wait...': 'We are loading some stats. Please wait...',
    'Tasks To Do': 'Tasks To Do',
    'More Info': 'More Info',
    'Pending Tasks': 'Pending Tasks',
    'Unread Correspondences': 'Unread Correspondences',
    'Read Correspondences': 'Read Correspondences',
    'Sent Correspondences': 'Sent Correspondences',
    'Correspondences Worked On': 'Correspondences Worked On',
    'Completed Tasks': 'Completed Tasks',
    'No of Completed Tasks': 'No of Completed Tasks',
    'We are loading your info. Please wait...': 'We are loading your info. Please wait...',
    'Logout': 'Logout',
    'Other Modules': 'Other Modules',
    'Switch To This Account': 'Switch To This Account',
    'Search': 'Search',
    'Tasks': 'Tasks',
    'Manage Your Tasks': 'Manage Your Tasks',
    'Reload': 'Reload',
    'Add New': 'Add New',
    'Filter By Tags': 'Filter By Tags',
    'Filter By Users': 'Filter By Users',
    'Type your keywords here': 'Type your keywords here',
    'We are loading your tasks. Please wait...': 'We are loading your tasks. Please wait...',
    'To Do': 'To Do',
    'From': 'From',
    'To': 'To',
    'done': 'done',
    'in progress': 'in progress',
    'to do': 'to do',
    'Edit': 'Edit',
    'Edit Task': 'Edit Task',
    'Delete Task': 'Delete Task',
    'Are you sure you want to delete': 'Are you sure you want to delete',
    'In Progress': 'In Progress',
    'Done': 'Done',
    'Delete': 'Delete',
    'Select Tags': 'Select Tags',
    'Close': 'Close',
    'Select Users': 'Select Users',
    'Select Category': 'Select Category',
    'Add New Task': 'Add New Task',
    'For': 'For',
    'Subject': 'Subject',
    'Task': 'Task',
    'Deadline Date': 'Deadline Date',
    'Deadline Time': 'Deadline Time',
    'Priority': 'Priority',
    'Select': 'Select',
    'Reminder Date': 'Reminder Date',
    'Reminder Time': 'Reminder Time',
    'Remove': 'Remove',
    'Add Attachments': 'Add Attachments',
    'Clear Attachments': 'Clear Attachments',
    'Save changes': 'Save changes',
    'Task Details': 'Task Details',
    'Task Logs': 'Task Logs',
    'Select Attachment': 'Select Attachment',
    'My Computer': 'My Computer',
    'My Documents': 'My Documents',
    'File Manager': 'File Manager',
    'Templates': 'Templates',
    'Choose Files': 'Choose Files',
    'Selected Documents': 'Selected Documents',
    'File Name': 'File Name',
    'Date Created': 'Date Created',
    'View Document': 'View Document',
    'View Template': 'View Template',
    'Use Template': 'Use Template',
    'Save': 'Save',
    'Document Name': 'Document Name',
    'Please answer the ff questions to generate your document': 'Please answer the ff questions to generate your document',
    'No placeholders have been defined for the template you selected': 'No placeholders have been defined for the template you selected',
    'This file cannot be previewed': 'This file cannot be previewed',
    'Click here to download': 'Click here to download',
    'Attachments': 'Attachments',
    'Profile': 'Profile',
    'User Profile': 'User Profile',
    'About Me': 'About Me',
    'Company/Institution': 'Company/Institution',
    'Position': 'Position',
    'Contact': 'Contact',
    'Address': 'Address',
    'Type/Category': 'Type/Category',
    'Timeline': 'Timeline',
    'Settings': 'Settings',
    'Manage Tags': 'Manage Tags',
    'Name': 'Name',
    'Category': 'Category',
    'Edit Sub Tag': 'Edit Sub Tag',
    'Delete Sub Tag': 'Delete Sub Tag',
    'Add Sub Tag': 'Add Sub Tag',
    'Add New Tag': 'Add New Tag',
    'Tag Name': 'Tag Name',
    'Private': 'Private',
    'Public': 'Public',
    'Edit Tag': 'Edit Tag',
    'Add New Tag Under': 'Add New Tag Under',
    'Contacts': 'Contacts',
    'Upload Contacts': 'Upload Contacts',
    'Add Contact': 'Add Contact',
    'Add Company': 'Add Company',
    'Add Group': 'Add Group',
    'Contact List': 'Contact List',
    'Companies': 'Companies',
    'Groups': 'Groups',
    'We are loading your contact list. Please wait...': 'We are loading your contact list. Please wait...',
    'Phone': 'Phone',
    'Email': 'Email',
    'Company': 'Company',
    'Contact Type/Category': 'Contact Type/Category',
    'Action': 'Action',
    'View File': 'View File',
    'View More': 'View More',
    'We are loading your company list. Please wait...': 'We are loading your company list. Please wait...',
    'Company Name': 'Company Name',
    'City': 'City',
    'Country': 'Country',
    'Region': 'Region',
    'URL': 'URL',
    'Loading. Please wait...': 'Loading. Please wait...',
    'Group Name': 'Group Name',
    'Date Added': 'Date Added',
    'user-avatar': 'user-avatar',
    'Contact Category': 'Contact Category',
    'Contact Type': 'Contact Type',
    'Personal': 'Personal',
    'Positional': 'Positional',
    'Group': 'Group',
    'Position Name': 'Position Name',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Title': 'Title',
    'Add New Company': 'Add New Company',
    'Company Short Name': 'Company Short Name',
    'Short Name': 'Short Name',
    'Company Website': 'Company Website',
    'Status': 'Status',
    'Internal': 'Internal',
    'External': 'External',
    'Contact Person': 'Contact Person',
    'Position of Contact Person': 'Position of Contact Person',
    'Company Phone': 'Company Phone',
    'Company Email': 'Company Email',
    'Edit Company Details': 'Edit Company Details',
    'Upload Photo': 'Upload Photo',
    'Update': 'Update',
    'Info': 'Info',
    'Assign a position to': 'Assign a position to',
    'User Profile Picture': 'User Profile Picture',
    'View Contact Details': 'View Contact Details',
    'Click': 'Click',
    'here': 'here',
    'to download Excel template': 'to download Excel Template',
    'Select File To Upload': 'Select File To Upload',
    'Upload': 'Upload',
    'Add A New Group': 'Add A New Group',
    'Enter group name': 'Enter group name',
    'Submit': 'Submit',
    'Edit Group': 'Edit Group',
    'View Routes': 'View Routes',
    'Routes': 'Routes',
    'Add New Route': 'Add New Route',
    'All Routes': 'All Routes',
    'Correspondence Routes': 'Correspondence Routes',
    'We are loading your routes. Please wait...': 'We are loading your routes. Please wait...',
    'No of Steps': 'No of Steps',
    'Edit Route': 'Edit Route',
    'Delete Route': 'Delete Route',
    'New Route': 'New Route',
    'Route Name': 'Route Name',
    'Make changes to': 'Make changes to',
    'Finish': 'Finish',
    'Correspondence Subject': 'Correspondence Subject',
    'Correspondence ID': 'Correspondence ID',
    'Route Steps': 'Route Steps',
    'Add Route Step': 'Add Route Step',
    'Deadline': 'Deadline',
    'Hours': 'Hours',
    'Recipient': 'Recipient',
    'Auto Forward': 'Auto Forward',
    'Can Close': 'Can Close',
    'Edit Route Step': 'Edit Route Step',
    'Delete Route Step': 'Delete Route Step',
    'Move Up': 'Move Up',
    'Move Down': 'Move Down',
    'Save Route': 'Save Route',
    'New Route Step': 'New Route Step',
    'Yes': 'Yes',
    'No': 'No',
    'Emails': 'Emails',
    'Compose': 'Compose',
    'Folders': 'Folders',
    'Inbox': 'Inbox',
    'Sent': 'Sent',
    'Drafts': 'Drafts',
    'Spam': 'Spam',
    'Trash': 'Trash',
    'Labels': 'Labels',
    'Important': 'Important',
    'Promotions': 'Promotions',
    'Social': 'Social',
    'Archived': 'Archived',
    'Search Mail': 'Search Mail',
    'Sync Your Emails': 'Sync Your Emails',
    'Are you sure you want to synchronize your email with your correspondences': 'Are you sure you want to synchronize your email with your correspondences',
    'Update Email Config': 'Update Email Config',
    'We are loading your emails. Please wait...': 'We are loading your emails. Please wait...',
    'Click the reload icon to reload': 'Click the reload icon to reload',
    'Sorry! Your emails failed to load due to poor internet connection': 'Sorry! Your emails failed to load due to poor internet connection',
    'Email Configurations': 'Email Configurations',
    'Password': 'Password',
    'Email Server': 'Email Server',
    'Read Your Email': 'Read Your Email',
    'Email List': 'Email List',
    'Email Details': 'Email Details',
    'Read Email': 'Read Email',
    'Back To': 'Back To',
    'Reload your emails': 'Reload your emails',
    'Attach this email to a new correspondence': 'Attach this email to a new correspondence',
    'Attach To Correspondence': 'Attach To Correspondence',
    'Your email is loading. Please wait...': 'Your email is loading. Please wait...',
    'Reply': 'Reply',
    'Forward': 'Forward',
    'Print': 'Print',
    'Sorry! Your email failed to load due to poor internet connection': 'Sorry! Your email failed to load due to poor internet connection',
    'Compose Email': 'Compose Email',
    'Compose New Message': 'Compose New Message',
    'Back To Inbox': 'Back To Inbox',
    'Type your message here...': 'Type your message here...',
    'Send': 'Send',
    'Discard': 'Discard',
    'Compose Correspondence': 'Compose Correspondence',
    'View Correspondence': 'View Correspondence',
    'Type body of correspondence here...': 'Type body of correspondence here...',
    'Add Attachments To Main Correspondence': 'Add Attachments To Main Correspondence',
    'Recipients': 'Recipients',
    'Use Route': 'Use Route',
    'Select Route': 'Select Route',
    'NB: This is a cloned version of the original route; updates will be lost once you exit this page.': 'NB: This is a cloned version of the original route; updates will be lost once you exit this page.',
    'Through': 'Through',
    'Use Tags': 'Use Tags',
    'Correspondence Scope': 'Correspondence Scope',
    'Forward To Anyone': 'Forward To Anyone',
    'Forward Back To Me': 'Forward Back To Me',
    'Forward To Anyone In This List': 'Forward To Anyone In This List',
    'User(s)': 'User(s)',
    'Correspondence Info': 'Correspondence Info',
    'Correspondence Type': 'Correspondence Type',
    'Memo': 'Memo',
    'Fax': 'Fax',
    'Text Message': 'Text Message',
    'Call': 'Call',
    'Enquiry': 'Enquiry',
    'Envelope': 'Envelope',
    'Other': 'Other',
    'Tag This Correspondence': 'Tag This Correspondence',
    'Low Priority': 'Low Priority',
    'Medium Priority': 'Medium Priority',
    'High Priority': 'High Priority',
    'Confidential': 'Confidential',
    'Attached Email': 'Attached Email',
    'Remove this email attachment': 'Remove this email attachment',
    'Are you sure you want to remove this email attachment': 'Are you sure you want to remove this email attachment',
    'Type minutes/comments for your recipients here...': 'Type minutes/comments for your recipients here...',
    'Save As Draft': 'Save As Draft',
    'Incoming': 'Incoming',
    'Outgoing': 'Outgoing',
    'Worked On': 'Worked On',
    'Sorry! Your correspondence failed to load due to poor internet connection': 'Sorry! Your correspondence failed to load due to poor internet connection',
    'We are loading your correspondences. Please wait...': 'We are loading your correspondences. Please wait...',
    'Correspondence Details': 'Correspondence Details',
    'Read Correspondence': 'Read Correspondence',
    'Close this correspondence': 'Close this correspondence',
    'Recipients will not be able to work on this correspondence. Are you sure you want to close this correspondence?': 'Recipients will not be able to work on this correspondence. Are you sure you want to close this correspondence?',
    'Are you sure you want to re-open this correspondence?': 'Are you sure you want to re-open this correspondence?',
    'Make Active': 'Make Active',
    'Your correspondence is loading. Please wait...': 'Your correspondence is loading. Please wait...',
    'Initiated By': 'Initiated By',
    'Key Info': 'Key Info',
    'This correspondence is still open': 'This correspondence is still open',
    'This correspondence has been closed': 'This correspondence has been closed',
    'Confidentiality': 'Confidentiality',
    'Not Confidential': 'Not Confidential',
    'Route Used': 'Route Used',
    'Body': 'Body',
    'Download': 'Download',
    'Upload To File Manager': 'Upload To File Manager',
    'Active Trail': 'Active Trail',
    'Expected Response Date': 'Expected Response Date',
    'Seen?': 'Seen?',
    'Date Seen': 'Date Seen',
    'Minutes/Comments': 'Minutes/Comments',
    'Forwarded message': 'Forwarded message',
    'Actions Performed by': 'Actions Performed by',
    'Date Started': 'Date Started',
    'Description': 'Description',
    'Date Completed': 'Date Completed',
    'Nullify': 'Nullify',
    'to': 'to',
    'You will not be able to work on an archived trail. Are you sure you want to mark this trail as worked on?': 'You will not be able to work on an archived trail. Are you sure you want to mark this trail as worked on?',
    'Mark As Worked On': 'Mark As Worked On',
    'Add An Action': 'Add An Action',
    'Send Email': 'Send Email',
    'This trail has been archived': 'This trail has been archived',
    'Nullify This Trail': 'Nullify This Trail',
    'Activate This Trail': 'Activate This Trail',
    'All Trails': 'All Trails',
    'Pending': 'Pending',
    'Completed': 'Completed',
    'View Structure': 'View Structure',
    'Nullified': 'Nullified',
    'Active': 'Active',
    'Forwarded Message': 'Forwarded Message',
    'Source/Type': 'Source/Type',
    'You will not be able to work on an archived trail. Are you sure you want to archive this trail?': 'You will not be able to work on an archived trail. Are you sure you want to archive this trail?',
    'All Emails': 'All Emails',
    'Description...': 'Description...',
    'Subject:': 'Subject:',
    'Action Type': 'Action Type',
    'Forward Correspondence': 'Forward Correspondence',
    'Expected Response Date/Time': 'Expected Response Date/Time',
    'Reply To': 'Reply To',
    'Schedule Date/Time': 'Schedule Date/Time',
    'Create Template': 'Create Template',
    'Upload Template': 'Upload Template',
    'Open Document In New Tab': 'Open Document In New Tab',
    'Add Placeholders': 'Add Placeholders',
    'View/Edit': 'View/Edit',
    'Create New Template': 'Create New Template',
    'Template Name': 'Template Name',
    'Document Type': 'Document Type',
    'Word Document': 'Word Document',
    'Choose File': 'Choose File',
    'Add Placeholder': 'Add Placeholder',
    'Placeholder': 'Placeholder',
    'Field Type': 'Field Type',
    'Text': 'Text',
    'Text Area': 'Text Area',
    'Drop Down': 'Drop Down',
    'Options': 'Options',
    'Options separated by commas. e.g. Book, Pen, Pencil': 'Options separated by commas. e.g. Book, Pen, Pencil',
    'Question': 'Question',
    'Type your question here...': 'Type your question here...',
    'Edit Placeholder': 'Edit Placeholder',
    'Show Modal': 'Show Modal',
    'Create Documents Using Templates': 'Create Documents Using Templates',
    'We are loading your templates. Please wait...': 'We are loading your templates. Please wait...',
    'Download Template': 'Download Template',
    'Delete Template': 'Delete Template',
    'Clone Template': 'Clone Template',
    'Edit Template': 'Edit Template',
    'Preview Document': 'Preview Document',
    'Preview Template': 'Preview Template',
    'New Template Name': 'New Template Name',
    'Update Your Template': 'Update Your Template',
    'Documents Created From Templates': 'Documents Created From Templates',
    'We are loading your documents. Please wait...': 'We are loading your documents. Please wait...',
    'Download Document': 'Download Document',
    'Delete Document': 'Delete Document',
    'Edit Document': 'Edit Document',
    'Update The Contents Of Your Document': 'Update The Contents Of Your Document',
    'Rename Document': 'Rename Document',
    'Document Title': 'Document Title',
    'View Documents': 'View Documents',
    'Define Route Steps': 'Define Route Steps',
    'Add New Step': 'Add New Step',
    'Conversions': 'Conversions',
    'We are loading your files. Please wait...': 'We are loading your files. Please wait...',
    'File manager': 'File Manager',
    'Coverting File. Please wait...': 'Coverting File. Please wait...',
    'Convert To Image': 'Convert To Image',
    'Convert To PDF': 'Convert To PDF',
    'Convert To Text File': 'Convert To Text File',
    'You can only convert text, pdf, word, spreadsheet and powerpoint files': 'You can only convert text, pdf, word, spreadsheet and powerpoint files',
    'Privileges & Access Control': 'Privileges & Access Control',
    'We are loading some data. Please wait...': 'We are loading some data. Please wait...',
    'Note': 'Note',
    'Select a privilege category to assign individual privileges to users': 'Select a privilege category to assign individual privileges to users',
    'Users': 'Users',
    'User': 'User',
    'Privilege Category': 'Privilege Category',
    'Privilege': 'Privilege',
    'View Tasks': 'View Tasks',
    'Add Tasks': 'Add Tasks',
    'Edit Tasks': 'Edit Tasks',
    'Delete Tasks': 'Delete Tasks',
    'View Task Logs': 'View Task Logs',
    'View Timeline': 'View Timeline',
    'Update Profile': 'Update Profile',
    'View Profile/Timeline': 'View Profile/Timeline',
    'View Tags': 'View Tags',
    'Create New Tag': 'Create New Tag',
    'Delete Tag': 'Delete Tag',
    'Add Sub Tags': 'Add Sub Tags',
    'View Sub Tags': 'View Sub Tags',
    'Edit Sub Tags': 'Edit Sub Tags',
    'Delete Sub Tags': 'Delete Sub Tags',
    'My Contacts Menu': 'My Contacts Menu',
    'View Contacts': 'View Contacts',
    'View Companies': 'View Companies',
    'Add Contacts': 'Add Contacts',
    'Add Companies': 'Add Companies',
    'Edit Personal Contact': 'Edit Personal Contact',
    'Edit All Contacts': 'Edit All Contacts',
    'Delete Personal Contact': 'Delete Personal Contact',
    'Delete All Contacts': 'Delete All Contacts',
    'View Company Details': 'View Company Details',
    'Delete Company Details': 'Delete Company Details',
    'Create Positional Contacts': 'Create Positional Contacts',
    'View Groups': 'View Groups',
    'Add Groups': 'Add Groups',
    'Edit Groups': 'Edit Groups',
    'Delete Groups': 'Delete Groups',
    'Create Private Routes': 'Create Private Routes',
    'Create Public Routes': 'Create Public Routes',
    'Edit Private Routes': 'Edit Private Routes',
    'Edit Public Routes': 'Edit Public Routes',
    'Delete Private Routes': 'Delete Private Routes',
    'Delete Public Routes': 'Delete Public Routes',
    'View Emails': 'View Emails',
    'Send/Compose Emails': 'Send/Compose Emails',
    'Synchronize Emails With Correspondence': 'Synchronize Emails With Correspondence',
    'Update/Set Email Configurations': 'Update/Set Email Configurations',
    'Attach Email To Correspondence': 'Attach Email To Correspondence',
    'View Correspondences': 'View Correspondences',
    'New Template Menu': 'New Template Menu',
    'Create Private Template': 'Create Private Template',
    'Create Public Template': 'Create Public Template',
    'Upload Private Template': 'Upload Private Template',
    'Upload Public Template': 'Upload Public Template',
    'View Template Documents': 'View Template Documents',
    'Assign System Privileges': 'Assign System Privileges',
    'Create User Roles': 'Create User Roles',
    'Assign User Roles': 'Assign User Roles',
    'Update User Roles': 'Update User Roles',
    'Are you sure you want to disable this user\'s account' : 'Are you sure you want to disable this user\'s account',
    'Disable': 'Disable',
    'Enable': 'Enable',
    'Account': 'Account',
    'Create/Assign User Roles': 'Create/Assign User Roles',
    'Create or assign pre-defined roles to software users': 'Create or assign pre-defined roles to software users',
    'Role': 'Role',
    'E.g. Administrator': 'E.g. Administrator',
    'Roles': 'Roles',
    'Email and password is required': 'Email and password is required',
    'Please fill all required fields': 'Please fill all required fields',
    'Please select files to add as attachments': 'Please select files to add as attachments',
    'Please select your recipients': 'Please select your recipients',
    'Please specify the list of people for action type': 'Please specify the list of people for action type',
    'Please specify the source of your correspondence': 'Please specify the source of your correspondence',
    'Route step updated succesfully': 'Route step updated succesfully',
    'Route step deleted succesfully': 'Route step deleted succesfully',
    'Please set reminder date and time before you add a new one': 'Please set reminder date and time before you add a new one',
    'Please specify the position for this positional account': 'Please specify the position for this positional account',
    'Please select a group': 'Please select a group',
    'Please type your message': 'Please type your message',
    'Document title cannot be empty': 'Document title cannot be empty',
    'Please specify the route name and category': 'Please specify the route name and category', 
    'Please define at least one route step': 'Please define at least one route step',
    'Please wait for your email to load first': 'Please wait for your email to load first',
    'Please specify drop down options': 'Please specify drop down options',
    'Please enter your email': 'Please enter your email',
    'The passwords you entered do not match': 'The passwords you entered do not match',
    'Request failed! Please enter a role': 'Request failed! Please enter a role',
    'Request failed! Please select at least a single privilege': 'Request failed! Please select at least a single privilege',
    'Request failed! Please select a role': 'Request failed! Please select a role',
    'Request failed! Please fill all required fields': 'Request failed! Please fill all required fields',
    'Tags loaded successfully': 'Tags loaded successfully',
    'Your tasks have been reloaded successfully': 'Your tasks have been reloaded successfully',
    'Routes reloaded successfully': 'Routes reloaded successfully',
    'Department': 'Department',
    'Departments': 'Departments',
    'Signature': 'Signature',
    'Signature Passcode': 'Signature Passcode',
    'Passcode': 'Passcode',
    'The passcode must be more than six characters long': 'The passcode must be more than six characters long',
    'Add Signature': 'Add Signature',
    'Wrong passcode entered!': 'Wrong passcode entered!',
    'Enter signature passcode': 'Enter signature passcode',
    'Your signature has been attached': 'Your signature has been attached',
    'Signature not found! Please upload your signature under \'My Profile\' section': 'Signature not found! Please upload your signature under \'My Profile\' section',
    'Dept./Reference No': 'Dept./Reference No',
    'Manage Departments / Reference Numbers': 'Manage Departments / Reference No.',
    'This section allows you to create/manage departments and assign reference numbers to them. Reference numbers can be used in correspondence.': 'This section allows you to create/manage departments and assign reference numbers to them. Reference numbers can be used in correspondence.',
    'Add New Department': 'Add New Department',
    'Reference No Format': 'Reference No Format',
    'Edit Department': 'Edit Department',
    'Reference No. Format': 'Reference No. Format',
    'Confirm Passcode': 'Confirm Passcode',
    'Repeat Signature Passcode': 'Repeat Signature Passcode',
    'Letter': 'Letter',
    'INITIATED BY': 'INITIATED BY',
    'DATE': 'DATE',
    'Correspondence Folders': 'Correspondence Folders',
    'Month': 'Month',
    'No data available for the selected category...': 'No data available for the selected category...',
    'Failed to login. 😢': 'Failed to login. 😢',
    'Grouped By': 'Grouped By',
    'Cancel': 'Cancel',
    'Are you sure you want to delete this draft?': 'Are you sure you want to delete this draft?',
    'Attach An Exisiting Correspondence': 'Attach An Exisiting Correspondence',
    'Questionnaires': 'Questionnaires',
    'Manage Questionnaires For Correspondence Routing': 'Manage Questionnaires For Correspondence Routing',
    'Add New Questionnnaire': 'Add New Questionnnaire',
    'Questionnaire Title': 'Questionnaire Title',
    'Edit Question': 'Edit Question',
    'Is The Field Required?': 'Is The Field Required?',
    'Field Options': 'Field Options',
    'Add Question': 'Add Question',
    'View Questionnaires': 'View Questionnaires',
    'Delete Question': 'Question',
    'Add Questionnaire': 'Add Questionnaire',
    'Edit Questionnaire': 'Edit Questionnaire',
    'Delete Questionnaire': 'Delete Questionnaire',
    'Please complete the questionnaire before you can proceed': 'Please complete the questionnaire before you can proceed',
    'Your answer(s) have been saved successfully': 'Your answer(s) have been saved successfully',
    'Questions answered': 'Questions answered',
    'Advanced Search': 'Advanced Search',
    'Please specify the date range correctly': 'Please specify the date range correctly',
    'Type your comment first': 'Type your comment first',
    'All Tasks': 'All Tasks',
    'Type task details here...': 'Type task details here...',
    'Who do you want to copy this task to?': 'Who do you want to copy this task to?'
  };
  export default en;