/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import EditRoute from './EditRoute';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverGetContacts } from '../../actions/ContactsAction';
import { serverLoad, serverRemoveRouteStep, serverHoldRouteStep, serverFinish, serverUpdateRouteStep, serverMoveOrder } from '../../actions/RoutesAction';
import { serverGetQuestionnaires } from "../../actions/QuestionnairesAction";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrans as t } from '../../translations';

const $ = require("jquery");

class EditRouteContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags_dropdown: [],
            route_details: {},
            route_step_details: {},
            contacts_dropdown: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.contactDetails = this.contactDetails.bind(this);
        this.contactTags = this.contactTags.bind(this);
        this.editRouteStep = this.editRouteStep.bind(this);
        this.finish = this.finish.bind(this);
        this.addRouteStep = this.addRouteStep.bind(this);
        this.moveDown = this.moveDown.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.questionnaireInfo = this.questionnaireInfo.bind(this);
        this.explode = this.explode.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (!this.props.questionnaires.questionnaires.length) {
            this.props.serverGetQuestionnaires(this.props.auth.user.token);
        }

        if (!this.props.routes.edit_routes) {
            this.props.history.push("/view_routes");
        }

        // console.log(this.props.routes.edit_routes);

        this.setState({
            route_details: this.props.routes.edit_routes,
            route_step_details: this.props.routes.edit_route_steps
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.contacts !== this.props.contacts) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (prevProps.auth.clear_data !== this.props.auth.clear_data) {
            if (this.props.auth.clear_data === "true") {


                this.clearModalContents();

                this.props.clearData("false");
            }
        }

        if (prevProps.routes.edit_route_steps !== this.props.routes.edit_route_steps) {

            // this.setState({ single_route: [...this.props.routes.single_route] });

            $("#dismiss_edit_route_step_modal").click();
            $("#dismiss_add_route_step_modal").click();

            this.clearModalContents();
        }

        if (prevProps.routes.edit_routes !== this.props.routes.edit_routes) {
            if (!this.props.routes.edit_routes) {
                this.props.history.push("/view_routes");
            }
        }
    }

    handleChange = (data, type) => {
        switch (type) {
            case "routes":
                this.setState(prevState => ({
                    route_details: {                   // object that we want to update
                        ...prevState.route_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            case "route_steps":
                this.setState(prevState => ({
                    route_step_details: {                   // object that we want to update
                        ...prevState.route_step_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            default:
                break;
        }

    }

    setModalContent = (data) => {
        this.setState({
            route_step_details: data
        });
    }

    clearModalContents = () => {
        this.setState({ route_step_details: [] });

        return;
    }

    contactDetails = (contact_id) => {
        // eslint-disable-next-line array-callback-return
        let contact_details = this.props.contacts.contacts.filter((data, index) => {
            if (data.contact_id === contact_id) {
                return data;
            }
        })

        if (contact_details.length) {
            contact_details = contact_details[0];
            return `${contact_details.first_name} ${contact_details.last_name}`;
        } else {
            return '';
        }
    }

    contactTags = (contact_id, field) => {

        if (typeof contact_id === "string" && contact_id !== "") {
            if (field === "cc") {
                let temp_contacts = JSON.parse(contact_id.replace(/'/g, '"'));
                let contact = this.props.contacts.contacts.filter(item => temp_contacts.includes(item.contact_id));
                let result = []

                // eslint-disable-next-line array-callback-return
                contact.filter((data, index) => {
                    let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company})` };
                    result.push(v);
                });

                this.setState(prevState => ({
                    route_step_details: {                   // object that we want to update
                        ...prevState.route_step_details,    // keep all other key-value pairs
                        [field]: result     // update the value of specific key
                    }
                }));

                return result;

            } else if (field === "user") {
                let contact = this.props.contacts.contacts.filter(item => item.contact_id === contact_id);

                let result = { "value": contact[0].contact_id, "label": `${contact[0].first_name} ${contact[0].last_name}` };

                this.setState(prevState => ({
                    route_step_details: {                   // object that we want to update
                        ...prevState.route_step_details,    // keep all other key-value pairs
                        [field]: result     // update the value of specific key
                    }
                }));

                return result;
            }
        }

        return contact_id;
    }

    editRouteStep = () => {

        // console.log(this.state.route_steps);

        // check entry
        if (!this.state.route_step_details.user || !this.state.route_step_details.deadline
            || !this.state.route_step_details.auto_forward || !this.state.route_step_details.can_close) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let route_info = { ...this.state.route_step_details }

        if (route_info.user) {
            route_info['user'] = route_info['user']['value'];
        }

        // if (route_info.cc) {
        //     route_info['cc'] = route_info['cc']
        // }

        // console.log("route_info", route_info['cc']);

        this.props.serverUpdateRouteStep(route_info);
    }

    finish = () => {
        if (!this.state.route_details.title || !this.state.route_details.title) {
            toast.error(t("Please specify the route name and category", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        if (!this.props.routes.edit_route_steps.length) {
            toast.error(t("Please define at least one route step", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverFinish(this.state.route_details, this.props.routes.edit_route_steps, this.props.auth.user.token);
    }

    addRouteStep = () => {
        // check entry
        if (!this.state.route_step_details.user || !this.state.route_step_details.deadline
            || !this.state.route_step_details.auto_forward || !this.state.route_step_details.can_close) {
            toast.error(t("Please fill all required fields", this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let route_info = { ...this.state.route_step_details }

        if (route_info.user) {
            route_info['user'] = route_info['user']['value']
        }

        if (route_info.cc) {
            route_info['cc'] = route_info['cc']['value']
        }

        // add order
        route_info['ordering'] = this.props.routes.edit_route_steps.filter(item => item.step_id).length + 1;

        this.props.serverHoldRouteStep(route_info);
    }

    deleteRouteStep = (step_id) => {
        this.props.serverRemoveRouteStep(step_id);
    }

    swapIncrement = (arr, order) => {

        let result = [...arr];

        let order_value = arr[order];

        let order_plus_one_value = arr[order + 1];
        if (!order_plus_one_value) {
            return result;
        }

        order_value.ordering = parseInt(order_value.ordering) + 1;
        order_plus_one_value.ordering = parseInt(order_plus_one_value.ordering) - 1;

        // swap
        result[order] = order_plus_one_value;

        result[order + 1] = order_value;

        // result.sort((a, b) => (a.ordering > b.ordering) ? 1 : -1);

        return result;
    }

    swapDecrement = (arr, order) => {

        let result = [...arr];

        let order_value = arr[order];

        let order_plus_one_value = arr[order - 1];
        if (!order_plus_one_value) {
            return result;
        }

        order_value.ordering = parseInt(order_value.ordering) - 1;
        order_plus_one_value.ordering = parseInt(order_plus_one_value.ordering) + 1;

        // console.log("order_plus_one_value", order_plus_one_value);
        // console.log("order_value", order_value);

        // swap
        result[order] = order_plus_one_value;

        result[order - 1] = order_value;

        // result.sort((a, b) => (a.ordering > b.ordering) ? 1 : -1);

        return result;
    }

    moveUp = (data) => {
        let res = this.swapDecrement(this.props.routes.edit_route_steps, parseInt(data.ordering) - 1);

        this.props.serverMoveOrder(res);
    }

    moveDown = (data) => {

        let res = this.swapIncrement(this.props.routes.edit_route_steps, parseInt(data.ordering) - 1);

        this.props.serverMoveOrder(res);
    }

    questionnaireTitle = (questionnaire_id) => {
        if (questionnaire_id) {
            let title = this.props.questionnaires.questionnaires.filter((data) => data.questionnaire_id + "" === questionnaire_id + "");

            if (title.length) {
                return title[0].title;
            }
        }

        return null;
    }

    questionnaireInfo = (questionnaire_id) => {
        if (questionnaire_id) {
            let questionnaire = this.props.questionnaires.questionnaires.filter((data, index) => {
                if (data.questionnaire_id + "" === questionnaire_id + "") {
                    return data;
                }
            });

            return questionnaire[0];
        }

        return [];
    }

    explode = (field_options) => {

        if (field_options) {
            let options = [];

            if (field_options.includes('@')) {
                options = field_options.split('@');
            } else {
                options = [field_options];
            }

            return options;
        }

        return [];
    }

    render() {
        return (
            <>
                <Main>
                    <EditRoute
                        c_state={{ ...this.state }}
                        routes={this.props.routes}
                        auth={this.props.auth}
                        questionnaires={this.props.questionnaires}
                        handleChange={this.handleChange}
                        set_modal_content={this.setModalContent}
                        clear_modal_contents={this.clearModalContents}
                        contact_details={this.contactDetails}
                        contact_tags={this.contactTags}
                        edit_route_step={this.editRouteStep}
                        finish={this.finish}
                        add_route_step={this.addRouteStep}
                        delete_route_step={this.deleteRouteStep}
                        move_up={this.moveUp}
                        move_down={this.moveDown}
                        questionnaire_title={this.questionnaireTitle}
                        questionnaire_info={this.questionnaireInfo}
                        explode={this.explode}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        routes: { ...state.routes },
        contacts: { ...state.contacts },
        questionnaires: { ...state.questionnaires }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        serverGetTags,
        clearData,
        // checkUser,
        serverGetContacts,
        serverUpdateRouteStep,
        serverFinish,
        serverHoldRouteStep,
        serverRemoveRouteStep,
        serverMoveOrder,
        serverGetQuestionnaires
    }
)(EditRouteContainer)
