import { Link } from "react-router-dom";
import types from "../../types";
import { getTrans as t } from '../../translations';

const NavBar = (props) => {

    return (
        <div className="wrapper">
            {/* <!-- Preloader --> */}
            {
                !props.contacts.length ?
                    <div className="preloader flex-column justify-content-center align-items-center">
                        <img className="animation__shake" src="static/assets/dist/img/logo.png" alt="CMS" height="60" width="60" />
                        <p>{t('We are loading your info. Please wait...', props.auth.language)}</p>
                    </div>
                    : null
            }
            {/* <!-- Navbar --> */}
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* <!-- Left navbar links --> */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to="#" className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></Link>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <Link to="#" onClick={props.logout} className="nav-link">{t('Logout', props.auth.language)}</Link>
                    </li>
                    {/* <li className="nav-item d-none d-sm-inline-block">
                        <Link to="#" className="nav-link">Contact</Link>
                    </li> */}
                </ul>

                {/* <!-- Right navbar links --> */}
                <ul className="navbar-nav ml-auto">
                    {/* <!-- Navbar Search -->
                    <li className="nav-item">
                        <Link to="#" className="nav-link" data-widget="navbar-search" role="button">
                            <i className="fas fa-search"></i>
                        </Link>
                        <div className="navbar-search-block">
                            <div className="input-group input-group-sm">
                                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                                <div className="input-group-append">
                                    <button className="btn btn-navbar" type="submit">
                                        <i className="fas fa-search"></i>
                                    </button>
                                    <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li> */}

                    <li className="nav-item dropdown">
                        <Link to="#" className="nav-link" data-toggle="dropdown">
                            <i className="fa fa-globe"></i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <Link to="#" onClick={() => props.changeLanguage('en')} className="dropdown-item"> English - en </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="#" onClick={() => props.changeLanguage('fr')} className="dropdown-item"> French - fr </Link>
                        </div>
                    </li>

                    {/* <!-- Messages Dropdown Menu --> */}
                    {
                        props.user.other_accounts ?
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link" data-toggle="dropdown">
                                    <i className="fa fa-users"></i>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                    {
                                        // eslint-disable-next-line array-callback-return
                                        props.otherAccounts.map((data, index) => {
                                            if (data.value !== props.user.contact_id) {
                                                return (
                                                    <span key={index}>
                                                        <Link to="#" onClick={() => props.switchAccount(data.value)} className="dropdown-item">
                                                            <div className="media">
                                                                <img alt="img" src="static/assets/dist/img/logo.png"
                                                                    className="img-size-50 mr-3 img-circle" />
                                                                <div className="media-body">
                                                                    <h3 className="dropdown-item-title">
                                                                        {data.label}
                                                                        {/* <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span> */}
                                                                    </h3>
                                                                    {/* <p className="text-sm">{data.email}</p> */}
                                                                    <p className="text-sm text-muted"><i className="far fa-user mr-1"></i> {t('Switch To This Account', props.auth.language)}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className="dropdown-divider"></div>
                                                    </span>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </li>
                            : null
                    }
                    <li className="nav-item dropdown">
                        <Link to="#" className="nav-link" data-toggle="dropdown">
                            <i className="fa fa-cogs"></i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">{t('Other Modules', props.auth.language)}</span>
                            <div className="dropdown-divider"></div>
                            <a href={`${types.CHAT_URL}?q=${props.user.old_token}`} target="chat" className="dropdown-item">
                                <i className="far fa-comment mr-2"></i> Chat
                                <span className="float-right text-muted text-sm"><i className="fas fa-arrow-right"></i></span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a href={`${types.FILES_URL}?q=${props.user.token}`} target="file_manager" className="dropdown-item">
                                <i className="far fa-file mr-2"></i> File Manager
                                <span className="float-right text-muted text-sm"><i className="fas fa-arrow-right"></i></span>
                            </a>
                            {/* <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item">
                                <i className="far fa-envelope mr-2"></i> iCubicle Support
                                <span className="float-right text-muted text-sm"><i className="fas fa-arrow-right"></i></span>
                            </Link> */}
                        </div>
                    </li>
                    {/* <!-- Notifications Dropdown Menu --> */}
                    {/* <li className="nav-item dropdown">
                        <Link to="#" className="nav-link" data-toggle="dropdown">
                            <i className="far fa-bell"></i>
                            <span className="badge badge-warning navbar-badge">15</span>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">15 Notifications</span>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item">
                                <i className="fas fa-envelope mr-2"></i> 4 new messages
                                <span className="float-right text-muted text-sm">3 mins</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item">
                                <i className="fas fa-users mr-2"></i> 8 friend requests
                                <span className="float-right text-muted text-sm">12 hours</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item">
                                <i className="fas fa-file mr-2"></i> 3 new reports
                                <span className="float-right text-muted text-sm">2 days</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item dropdown-footer">See All Notifications</Link>
                        </div>
                    </li> */}
                    <li className="nav-item">
                        <Link to="#" className="nav-link" data-widget="fullscreen" role="button">
                            <i className="fas fa-expand-arrows-alt"></i>
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link to="#" className="nav-link" data-widget="control-sidebar" data-slide="true" role="button">
                            <i className="fas fa-th-large"></i>
                        </Link>
                    </li> */}
                </ul>
            </nav>
            {/* <!-- /.navbar --> */}
        </div>
    )
}

export default NavBar